import React from 'react';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';

const ReviewChecklist = ( { 
    checklist, 
    updateChecklist, 
    setNeedsFollowUp , 
    setCommentToStudent, 
    setCommentToInstructor 
}) => {

    const { pushOverlay } = useOverlays();

    const sendUpdates = (key, index, name, value) => {
        let updatedChecklist = structuredClone(checklist);
        
        updatedChecklist[key][index][name] = value

        updateChecklist(updatedChecklist);
    }

    const toggleRatings = (clear=false) => {
        let updatedChecklist = structuredClone(checklist);

        for (let key of Object.keys(updatedChecklist)){
            for (let i = 0; i < updatedChecklist[key].length; i ++) {
                if (clear) {
                    updatedChecklist[key][i]['feedback'] = 0;
                    updatedChecklist[key][i]['comment'] = '';
                } else {
                    updatedChecklist[key][i]['feedback'] = 1;
                }
            }
        }

        updateChecklist(updatedChecklist);
        
        if (clear) {
            setNeedsFollowUp(false);
            setCommentToStudent("");
            setCommentToInstructor("");
        }
    }

    return (
        <div id='review_checklist' className='row '>
            <div className='col-6 mb-2'>
                <h3><u>Review Checklist</u></h3>
            </div>
            <div className='col-6 text-right'>
                <button
                    className='btn btn-sm btn-success'
                    onClick={() => toggleRatings()}
                >Toggle All 'Good'</button>
                <button
                    className='btn btn-sm btn-danger ml-3'
                    onClick={(e) => 
                        pushOverlay({
                            type: OVERLAY_TYPES.CONFIRM,
                            position: { x: e.clientX, y: e.clientY },
                            component: <>This will clear all ratings and comments, including comments to instructor, comments to student, and follow-up flagging.<br/><br/>Are you sure?</>,
                            respond: (response) => {
                                if (response) toggleRatings(true);
                            }
                        })
                    }
                >Clear All</button>
            </div>
            
            {
                Object.keys(checklist).map( (key)=> {
                    let area = checklist[key];
                    let alternateColorIndex = 0;

                    return (
                        <div className='col-12 '>
                            <h4 className="layer layer-1 p-2">{key}:</h4>

                            {
                                area.map( (row, index) => 
                                    {
                                        let feedback = row['feedback'] || 0;
                                        alternateColorIndex ++

                                        return (
                                            <div className={`review_checklist__rows row mb-1 pt-1 pb-1 ${alternateColorIndex % 2 ? 'bg-info text-light' : ''}`} key={`checklist_row-${index}`}>
                                                <div className={`col-4 `}>
                                                    <div className='review_checklist__row_category'>
                                                        {row['category']}
                                                    </div>
                                                    <div className='review_checklist__row_note'>
                                                        {row['user_note'] || " "}
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <select 
                                                        className={`form-control assignment_review__feedback assignment_review__feedback-${feedback}`}
                                                        onChange={(e) => sendUpdates(key,index, 'feedback',e.target.value)}
                                                        name="feedback"
                                                        value={feedback}
                                                        >
                                                        <option value={0}>N/A</option>
                                                        <option value={1}>Good</option>
                                                        <option value={2}>Needs Improvement</option>
                                                    </select>
                                                </div>
                                                <div className='col-4'>
                                                    <textarea 
                                                        className="form-control assignment_review__comment"
                                                        onChange={(e) => sendUpdates(
                                                            key,
                                                            index,
                                                            'comment',
                                                            e.target.value
                                                        )}
                                                        name="comment"
                                                        value={row['comment'] || ''}
                                                        ></textarea>
                                                </div>
                                            </div>
                                        )
                                    })
                            }

                            <hr></hr>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default ReviewChecklist;