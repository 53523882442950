import React, { useState, useContext, useEffect } from 'react';

import TagSelector from './TagSelector';
import { AppContext } from '../../store/AppContext';
import wikiService from '../../services/WikiService';
import WikiResult from './WikiResult';
import Paginator from '../elements/Paginator';
import { WIKIBROWSERMODES } from './WikiBrowser';
import useBtrSearchParams from '../../hooks/useBtrSearchParams';
import eventHandler, { CLOSE_OVERLAY, OVERLAY_CLOSE_CLICK } from '../../events';

const SearchWikis = ( { setBrowserMode } ) => {

    const { wikiData } = useContext(AppContext);

    const [ selectedTags, setSelectedTags ] = useState([]);
    const [ searchText, setSearchText ] = useState("");
    const [ stack, setStack ] = useState(0); // default to N/A
    const [ wikis, setWikis ] = useState([]);
    const [ allResultsLoaded, setAllResultsLoaded ] = useState(false);
    const [ searchResponse, setSearchResponse ] = useState(null);
    const [ searchParam, setSearchParam, deleteParam ] = useBtrSearchParams();
    const selectedWiki = searchParam.get('wiki'); //returns null if not found

    useEffect(() => {
        eventHandler.on( OVERLAY_CLOSE_CLICK, onCloseClicked)
        return () => eventHandler.remove( OVERLAY_CLOSE_CLICK, onCloseClicked )
    }, [])

    const onCloseClicked = () => {
        eventHandler.dispatch( CLOSE_OVERLAY );
        deleteParam('wiki')
    };

    useEffect(() => {

        search();

    }, [])

    const search = ( page = 1 ) => {

        setWikis([]);
        setSearchResponse(null)
        setAllResultsLoaded(false);

        wikiService.search(searchText, page, stack === 0 ? null : stack, selectedTags)
            .then( resp => {
                setWikis(resp.results);
                setSearchResponse(resp);
                setAllResultsLoaded(true);
            })
            .catch( err => console.log(err))
    }

    return (
        <>
            <fieldset className='row p-2' disabled={searchResponse === null}>
                <div className='col-8'>
                    <div className='subheader-text'>
                        Search Text
                    </div>
                    <input 
                        className='form-control'
                        id='searchText'
                        type='text'
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div className='col-4'>
                    <div className='subheader-text'>
                        Stack
                    </div>
                    <select
                        className='form-control'
                        onChange={(e) => setStack(parseInt(e.target.value))}
                    >
                        <option value={0}>N/A</option>
                        {
                            wikiData &&
                                wikiData.stacks
                                    .sort((a , b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                                    .map( stack =>
                                        <option
                                            value={stack.id}
                                            key={`stack_key_${stack.id}`}
                                        >
                                            {stack.name}
                                        </option>
                                    )
                        }
                    </select>
                </div>
                <div className='col-12 mt-4'>
                    {
                        <TagSelector selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                    }
                </div>
                <div className='col-12 text-center mt-4'>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => search()}
                    >
                        search
                    </button>
                    <button
                        className='btn btn-sm btn-info ml-2'
                        onClick={() => setBrowserMode(WIKIBROWSERMODES.add)}
                    >
                        new article
                    </button>
                    <div className='divider mt-2'><div></div></div>
                </div>
            </fieldset>
            <div className='row'>
                <div className='col-12'>
                    <Paginator 
                        isTicketsLoaded={allResultsLoaded}
                        searchResponse={searchResponse} 
                        onPageSelected={search} 
                        itemPrompt={'article'}
                        />
                    {
                        wikiData &&
                            wikis.map( wiki => 
                                    <WikiResult 
                                        wiki={{
                                            ...wiki,
                                            stack: wikiData.stacks.filter( stack => stack.id === wiki.stack_id)[0] || {name: ""} 
                                        }} 
                                        key={`wiki_result_${wiki.id}`}
                                        selected={selectedWiki !== null && wiki.id == selectedWiki}
                                        selectWiki={setSearchParam}
                                        removeParam={deleteParam}
                                        />
                            )
                    }
                </div>
            </div>
        </>
    )
}

export default SearchWikis;