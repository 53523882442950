import React, { useState, useEffect } from "react";
import useOverlays from "../../hooks/useOverlays";
import SemToolsSelector from "./SemToolsSelector";
import {semService} from "../../services/SEMService";
import spinnerIcon from "../../assets/loading_spinner.gif";
import {cohortService} from "../../services/CohortService";
import LoadingSpinner from '../modal_assets/LoadingSpinner';
import SemToolsStudents from "./SemToolsStudents";


const SemTools = () => {

    const { pushOverlay, popOverlay } = useOverlays();

    const [sems, setSems] = useState(null);
    const [cohorts, setCohorts] = useState(null);
    const [students, setStudents] = useState(null);
    const [loadingStudents, setLoadingStudents] = useState(false);

    useEffect(() => {

        semService.getSems()
            .then((res) => {
                setSems(res);
            })
            .catch((err) => {
                console.log(err);
            });

        cohortService.get_active_cohorts()
            .then( cohorts => {
                setCohorts(cohorts);
            })
            .catch( err => console.log(err) );
    }, []);

    const loadStudents = (selectedSems, selectedCohorts) => { console.log(selectedCohorts, selectedSems)
        setLoadingStudents(true);
        setStudents(null);
        
        semService.pullStudents(selectedSems, selectedCohorts)
            .then((res) => {
                setStudents(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingStudents(false);
            });
    }

    const CAN_LOAD_STUDENTS = sems && cohorts;

    return (
        <>
            <div className="row layer layer-1 mb-2">
                <div
                    className="col-6 ">
                    <h1>SEM Tools</h1>
                </div>
                <div className="col-6 ">
                    {
                        CAN_LOAD_STUDENTS &&
                            <button
                                className="btn btn-info mt-2"
                                onClick={(e) => {
                                    pushOverlay({
                                        component:<SemToolsSelector 
                                            close={() => popOverlay()}
                                            sems={sems}
                                            cohorts={cohorts}
                                            loadStudents={(selectedSems, selectedCohorts) => {
                                                popOverlay();
                                                loadStudents(selectedSems, selectedCohorts);
                                            }}
                                        />
                                    })
                                }}
                                disabled={loadingStudents}
                            >Load Students</button>
                    }
                    { !CAN_LOAD_STUDENTS &&
                        <img
                            className="ml-4"
                            src={spinnerIcon}
                            alt="loading spinner"
                            style={{
                                width: "50px",
                                height: "50px"
                            }}
                        />  
                    }
                </div> 
            </div>
            <div className="row layer layer-2">
                <div className="col-12">
                    {loadingStudents && <LoadingSpinner />}
                    {students && <SemToolsStudents students={students} />}
                </div>
            </div>
        </>
    )
};

export default SemTools;