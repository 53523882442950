import React from "react";
import TrivjoAnswerSelectBox from "./TrivjoAnswerSelectBox";
import QuestionFrame from "../question_frame/QuestionFrame";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import TrivjoAnswerBuilder from "./TrivjoAnswerBuilder";

export const MODES = {
    VIEW: "view",
    EDIT: "edit"
}

const TrivjoAnswer = ({ 
    answer, 
    selected, 
    setSelected, 
    removeAnswer, //!!  check if user wants to delete before calling deleteAnswer
    updateAnswer,
    disabled = false,
}) => { 

    const { pushOverlay } = useOverlays();

    return (
        <div className="tj_answer">
            <div className="tj_answer__controls">
                <TrivjoAnswerSelectBox 
                    selected={selected} 
                    setSelected={() => setSelected(answer)}
                    disabled={disabled}
                    />
                {
                    !disabled &&
                        <button
                            className={`btn btn-sm btn-${selected ? "secondary" : "danger"}`}
                            onClick={(e) => 
                                pushOverlay({
                                    type: OVERLAY_TYPES.CONFIRM,
                                    position: { x: e.clientX, y: e.clientY},
                                    component: <>Are you sure you want to delete this answer?</>,
                                    respond: (response) => {
                                        if ( response ) removeAnswer(answer);
                                    }
                                })}
                            disabled={selected}
                        >X</button>
                }
            </div>
            <div className="tj_answer__frame">
                <QuestionFrame
                    obj={answer}
                />
                {
                    !disabled &&
                        <div 
                            className="click_screen"
                            onClick={(e) => 
                                pushOverlay({
                                    position: { x: e.clientX, y: e.clientY },
                                    component: <TrivjoAnswerBuilder 
                                        answer={answer}
                                        updateAnswer={updateAnswer}
                                        removeAnswer={removeAnswer}
                                    />
                                })
                            }
                            ></div>
                }
            </div>
        </div>
    )
}

export default TrivjoAnswer;