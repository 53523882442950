import React, { useEffect, useState } from 'react';
import Sorter from './Sorter';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import EmailsFilter from '../filter_sorter/filters/EmailsFilter';
import MyCohortStatusSetter from '../status_setter/MyCohortStatusSetter';
import {StudentHistoryDisplay} from '../../pull_student/history/StudentHistory';
import ActionButtons, { ICONS, BasicActionButton, CopyToClipboardButton, Label } from '../../elements/ActionButtons';
import StatusesStats from "../status_setter/StatusesStats";
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import { DisplayInstructorFlag } from '../my_cohort_elements/InstructorFlags';
import MyCohortContext from '../../../store/MyCohortContext';
import useStudent from '../../../hooks/useStudent';
import useRoster from '../../../hooks/useRoster';

const MyCohortList = ({
}) => { 

    const { 
        addSelectedStudent, 
        removeSelectedStudent, 
        checkStudentSelected, 
        selectStudents,
        clearSelectedStudents,
        selectedStudents,
        hoveredStudentId,
        setHoveredStudentId,
        clearHoveredStudent,
     } = useRoster();

    const { pushOverlay, popOverlay } = useOverlays();
    const { saveStudent } = useStudent();

    const { 
        sortedRoster,
        sheetsData,
        inStatsMode,
        studentStatuses,
    } = React.useContext(MyCohortContext);

    const [ selectedStudent, setSelectedStudent ] = useState(null);
    
    // for multi-select students
    const [ shiftEngaged, setShiftEngaged ] = useState(false);
    const [ multiRange, setMultiRange] = useState(null);

    useEffect( () => {

        // reset multi-select
        if ( selectedStudents.length === 0 ) doMultiSelect({low: 1000, high: -1});

    }, [selectedStudents])

    // object for storing selected students and handling multi-select
    const studentsIndex = {};
    sortedRoster.forEach( (student, index) => studentsIndex[student.id] = index)

    useEffect( () => {
       
        document.body.addEventListener('keydown', onKeyDown);
        document.body.addEventListener('keyup', onKeyUp);
        return (() => {
            document.body.removeEventListener('keydown', onKeyDown);
            document.body.removeEventListener('keyup', onKeyUp);
        })
    }, [])

    useEffect( () => { 

        let filter = filterSorter.getFilter('manual_email_filter');
        if (filter) {
            if ( filter.emails.length === 1 ) setSelectedStudent(filter.emails[0])
        } else {
            setSelectedStudent(null);
        }

    }, [sortedRoster]);

    const doMultiSelect = (updatedMultiRange = null) => { // handle multi-select

        updatedMultiRange = updatedMultiRange || multiRange;
        let newSelectedStudents = [...selectedStudents];
        if ( updatedMultiRange ) {
            for ( let i = updatedMultiRange.low; i <= updatedMultiRange.high; i++ ) {
                if ( !newSelectedStudents.includes(sortedRoster[i].id) ) {
                    newSelectedStudents.push(sortedRoster[i].id);
                };
            }
        }

        if ( newSelectedStudents.length ) selectStudents(newSelectedStudents);
    }

    const onKeyDown = (e) => setShiftEngaged(e.shiftKey);
    const onKeyUp = (e) => setShiftEngaged(false);

    const onCheckboxChange = (e, student) => { 

        if ( !e.target.checked ) { // remove

            removeSelectedStudent(student.id);
            
            return;
        }

        if ( shiftEngaged && selectedStudents.length > 0 ) { // multiselect
            const newMR = {...multiRange};
            if ( studentsIndex[student.id] > newMR.high ) newMR.high = studentsIndex[student.id];
            if ( studentsIndex[student.id] < newMR.low ) newMR.low = studentsIndex[student.id];
            doMultiSelect(newMR);
            
        } else {
            addSelectedStudent(student.id);
            setMultiRange({low: studentsIndex[student.id], high: studentsIndex[student.id]});
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-12 my_cohort__filters_bar layer layer-3'>
                    <Sorter
                        statuses={sheetsData.student_statuses}
                    />
                </div>
                <div className="col-12">
                    {
                        sortedRoster.length === 0 ?
                            <div className='my_cohort__no_students text-center'>
                                <p>
                                    No matches found
                                </p>
                                <button
                                    className='btn btn-sm btn-warning mr-2'
                                    onClick={() => filterSorter.clear()}
                                    disabled={!filterSorter.isActive()}
                                >Clear Filtering</button>
                            </div>
                            :
                            <>
                                {
                                    inStatsMode &&
                                        <div
                                            className='cohort_list__row row'
                                        >
                                            <div className='col-3 sub_note'>

                                            </div>
                                            <div className='col-4 text-center'>
                                            </div>
                                            <div className='col-3 text-center'>
                                                <ActionButtons
                                                    buttons={[
                                                        Label({
                                                            text: 'Status Stats',
                                                            inStatsMode: true,
                                                            onClick: (e) => 
                                                                pushOverlay({
                                                                    position: {x: e.clientX, y: e.clientY},
                                                                    component: <StatusesStats
                                                                        sortedRoster={sortedRoster}
                                                                        studentsData={filterSorter.studentsData}
                                                                        statuses={sheetsData.student_statuses}
                                                                        fullMode={false}
                                                                        close={() => popOverlay()}
                                                                        />
                                                                })
                                                        })
                                                    
                                                    ]}
                                                />
                                            </div>
                                            <div className='col-2 text-center'></div>
                                        </div>
                                }
                                {
                                    sortedRoster.map( (student, index) => {
                                        return (
                                            <div className={`cohort_list__row row
                                                                cohort_list__row__responsive
                                                                cohort_list__row--${student.data_issues.length > 0 ? 'data_issues' : 'no_data_issues'}
                                                            ${
                                                                checkStudentSelected(student.id) ?
                                                                    'bg-secondary text-white'
                                                                    :
                                                                    index % 2 ? 
                                                                        '' 
                                                                        : 
                                                                        'assignments_browser__student_row--highlight'
                                                            }
                                                            ${hoveredStudentId === student.id ? 'cohort_list__row--selected_student' : ''}
                                                            `
                                                            } key={`sls_${student['id']}`}
                                                            onMouseEnter={() => setHoveredStudentId(student.id)}
                                                            onMouseLeave={() => clearHoveredStudent()}
                                                            >
            
                                                <div className='col-3 sub_note'>
                                                    <ActionButtons
                                                        buttons={[
                                                            BasicActionButton({
                                                                icon: ICONS.SELECT,
                                                                toolTip: 'Isolate row',
                                                                isSelected: sortedRoster.length === 1,
                                                                onClick: 
                                                                    selectedStudent ?
                                                                        () => {
                                                                            setSelectedStudent(null);
                                                                            filterSorter.removeFilter('manual_email_filter');
                                                                            clearSelectedStudents();
                                                                        }
                                                                        :
                                                                        () => {
                                                                            setSelectedStudent(student.id);
                                                                            selectStudents([student.id]);
                                                                            filterSorter.addFilter(
                                                                                new EmailsFilter('manual_email_filter', [student.email_address])
                                                                                );
                                                                        }
                                                                
                                                            }),
                                                            BasicActionButton({
                                                                icon: ICONS.HISTORY,
                                                                toolTip: 'View history',
                                                                onClick: (e) => 
                                                                    pushOverlay({
                                                                        type: OVERLAY_TYPES.MODAL,
                                                                        component: <StudentHistoryDisplay
                                                                            student={student}
                                                                            close={() => popOverlay()}
                                                                            />
                                                                    
                                                                    })
                                                            }),
                                                            CopyToClipboardButton({
                                                                icon: ICONS.EMAIL,
                                                                clipboardContent: student.email_address,
                                                                toolTip: 'Copy email address to clipboard'
                                                            }),
                                                        ]}
                                                    />
                                                    <span className='ml-3'>
                                                        <DisplayInstructorFlag
                                                            student={student}
                                                            />
                                                    </span>
                                                </div>
                                                <div 
                                                    className={`
                                                        cohort_list__row_student_name 
                                                        ${hoveredStudentId === student.id ? 'cohort_list__row_student_name--hovered_student' : ''}
                                                        col-4 
                                                        noselect`}
                                                    data-toggle="tooltip" 
                                                    title={student.data_issues.length > 0 ?
                                                        `${student['first_name']}'s Data has the following issues:\n
                                                        ${student.data_issues}
                                                        `
                                                        :
                                                        `Click to view ${student['first_name']}'s profile`}
                                                    onClick={() => window.open(`/student/pull/${student['id']}`, '_blank')}
                                                >

                                                    { `${student['first_name']} ${student['last_name']}` }
                                                </div>
            
                                                <div className='col-3 text-right'>
                                                    <MyCohortStatusSetter
                                                            currentStatus={student.status_slug}
                                                            currentStatusId={student.status_id}
                                                            updateStatus={status => saveStudent({
                                                                ...student,
                                                                log_note: `Status changed from ${student.status_slug} to ${status.slug}`,
                                                                status_id: status.id,
                                                                status_slug: status.slug
                                                            })}
                                                            studentStatuses={studentStatuses}
                                                        />
                                                </div>
            
                                                <div className="col-2 text-center">
                                                    <input
                                                        type="checkbox"
                                                        className='mr-1 select_student_checkbox'
                                                        checked={checkStudentSelected(student.id)}
                                                        disabled={sortedRoster.length === 1}
                                                        onChange={(e) => onCheckboxChange(e, student)}
                                                        />
                                                </div>
                                                
                                            </div>
                                        )
                                    })
                                }
                            </>
                            
                    }
                </div>
            </div>
        </>
    )
}

export default MyCohortList;