import TABService from './TABService';

class StackService extends TABService {

    model = 'stack';

    getStacks = ( stack_id = null ) => 
        this._get(
            `${this.model}`,
            stack_id === null ? {} : {stack_id}
        )
}

export default new StackService();