import React, { useState } from "react";
import QuestionFrame from "../question_frame/QuestionFrame";
import TrivjoQuestionBuilder from "./TrivjoQuestionBuilder";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import useTrivjo from "../../../hooks/useTrivjo";

const TrivjoQuestion = ({ 
    question: defaultQuestion,
    removeQuestion,
    library,
    isInPlayMode = false,
 }) => {

    const { pushOverlay } = useOverlays();
    const { givenQuestions } = useTrivjo();

    const [ question, setQuestion ] = useState(defaultQuestion);
    
    const isValid = question.answer_count > 1 && question.time > 0 && question.points > 0;
    const enabled = (isInPlayMode && givenQuestions.indexOf(question.id) < 0 && isValid) || (!isInPlayMode && isValid);

    return (
        <div 
            className={`
                tj_question 
                tj_question--${isValid ? "" : "invalid"}
                tj_question--${enabled ? "enabled" : "disabled"}
                `}  
            >
            <div className="tj_question__frame" >
                <QuestionFrame
                    obj={question}
                    disabled={isInPlayMode}
                /> 
            </div>
            <div>
                Answers: {question.answer_count} &nbsp; | &nbsp;
                Time: {question.time} &nbsp; | &nbsp;
                Points: {question.points}
            </div>
            {
                ( enabled ) &&
                    <div 
                        className="tj_question__click_screen"
                        onClick={() => { 
                            pushOverlay({
                                type: OVERLAY_TYPES.MODAL,
                                component: <TrivjoQuestionBuilder
                                    question={question}
                                    updateQuestion={(q) => {console.log(q); setQuestion(q)}}
                                    removeQuestion={removeQuestion}
                                    library={library}
                                    isInPlayMode={isInPlayMode}
                                />,
                            })
                        }}
                        ></div>
            }
        </div>
    )
}

export default TrivjoQuestion;