/* sorts the roster */
import Sorter from "./Sorter";

class RosterSorter extends Sorter {

    constructor( sortPattern ) {
        super('roster_sorter');
        this.sortPattern = sortPattern;
    }

    sort = ( roster, studentsData ) => {

        let newRoster = [...roster];

        newRoster.sort( (a, b) => {

            if ( this.sortPattern.indexOf('f') > -1 ) { // sort on first name
                if ( this.sortPattern.indexOf('a') > -1 ) { // ascending
                    return a.first_name.toUpperCase() < b.first_name.toUpperCase() ? -1 : a.first_name.toUpperCase() > b.first_name.toUpperCase() ? 1 : 0
                } else {
                    return a.first_name.toUpperCase() < b.first_name.toUpperCase() ? 1 : a.first_name.toUpperCase() > b.first_name.toUpperCase() ? -1 : 0
                }

            } else {
                if ( this.sortPattern.indexOf('a') > -1 ) { // ascending
                    return a.last_name.toUpperCase() < b.last_name.toUpperCase() ? -1 : a.last_name.toUpperCase() > b.last_name.toUpperCase() ? 1 : 0
                } else {

                    return a.last_name.toUpperCase() < b.last_name.toUpperCase() ? 1 : a.last_name.toUpperCase() > b.last_name.toUpperCase() ? -1 : 0
                }
            }
        })

        return newRoster;
    }
}

export default RosterSorter;