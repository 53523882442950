import React, { useRef } from "react";

const LinkToClipboard = ({link, prompt = null}) => {

    return (
        <span 
            className="link_to_clipboard"
            onClick={(e)=> {
                navigator.clipboard.writeText(link);
            }}
            data-toggle="tooltip" title="Click To Copy"
            >
            {
                prompt ? prompt : link
            }
            <div className="link_to_clipboard__copied_overlay">
                Copied!
            </div>
        </span>
    )
}

export default LinkToClipboard