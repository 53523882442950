import {useState} from 'react';
import useUserAccess from '../../../hooks/useUserAccess';
import SimpleStudentHeader from '../my_cohort_elements/SimpleStudentHeader';
import useStudent from '../../../hooks/useStudent';
import useOverlays from '../../../hooks/useOverlays';

const NotesModal = ({
    student
}) => {

    const { saveStudent } = useStudent();
    const { popOverlay } = useOverlays();

    const [notes, setNotes] = useState(student["notes"]);
    const [ USER_LEVELS, checkAccess ] = useUserAccess();

    return (
        <div className="d-flex justify-content-center my_cohort_notes">
            <div className='layer layer-1'>
                <div className=" row mb-2">
                    <div className='col-10'>
                        <SimpleStudentHeader student={student} />
                    </div>
                    <div className='col-2'>
                    <button 
                            className="btn btn-sm btn-info rounded"
                            onClick={() => {
                                if (notes !== student["notes"])
                                    saveStudent({
                                        ...student, 
                                        notes: notes
                                    });
                                popOverlay();
                            }}
                            >Close</button>
                    </div>
                </div>

                <div className='font-weight-bold text-center mb-2'>
                    These notes are just for general information about the student. 
                    For more serious issues, or issues that need to be tracked, <a href={`/student/pull/${student.id}`} target="_blank">open a ticket</a>.
                </div>
                <div className='sub_sub_note text-center'>
                    Updates are automatically saved.
                </div>
                <textarea className="form-control p-3" 
                    onChange={(e) => setNotes(e.target.value)} 
                    cols="20" 
                    rows="10"
                    disabled={!checkAccess(USER_LEVELS.INSTRUCTOR)}
                >{notes}</textarea>

            </div>
        </div>
    )

    
}

export default NotesModal;