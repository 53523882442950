import React, { useState } from 'react';
import filterSorter from "../filter_sorter/MyCohortFilterSorter";
import EmailsFilter from "../filter_sorter/filters/EmailsFilter";
import ActionButtons, { Label, DIRECTIONS } from '../../elements/ActionButtons';
import helpers from '../../../helpers/Helpers';
import useOverlays from '../../../hooks/useOverlays';

const EmailTools = ({
    filterEmails,
    sortedRoster,
    setFilterEmails
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    const [ copyMessage, setCopyMessage ] = useState('Copy Roster Emails');
    const [ starterDataMessage, setStarterDataMessage ] = useState(null);
    // const [ getEmailList, setGetEmailList ] = useState(false);

    const buildStarterData = () => {
        setStarterDataMessage(`
            Data for ${sortedRoster.length} students has been copied to your clipboard.
            `);
        return helpers.studentDataToGSheetsData(sortedRoster);
    }

    return (
        <div className="row">

        <div className='sub_sub_note text-center'>
            Filter roster before using the blue buttons below.
        </div>
            
            <div className="col-7 layer layer-1">
                <ActionButtons
                    buttons={[
                        Label({
                            text: copyMessage,
                            toolTip: `Good for group emailing and moving students to your Discord channel.`,
                            onClick: () => {
                                navigator.clipboard.writeText(sortedRoster.map(student => student.email_address).join(', '));
                                setCopyMessage('Roster Emails Copied!');
                                setTimeout(() => setCopyMessage('Copy Roster Emails'), 2000);
                            }
                        }),
                        Label({
                            text: "Copy Data for Sheets",
                            toolTip:'Copies basic student progress in sheets format.',
                            onClick: () => { 
                                navigator.clipboard.writeText(buildStarterData());
                            }
                        })
                    ]}
                />
            </div>

            <div className="col-4 layer layer-1 text-center">
                <ActionButtons
                    direction={DIRECTIONS.COLUMN}
                    buttons={[
                        Label({
                            text: "Filter By Email List",
                            bgColor: "success",
                            onClick: (e) => 
                                pushOverlay({
                                    component: <PasteEmails />,
                                    position: {x: e.clientX, y: e.clientY}
                                }),
                        }),
                        Label({
                            text: "Clear Pasted Emails",
                            bgColor: "success",
                            visible: filterEmails !== "",
                            onClick: () => {
                                filterSorter.removeFilter('emails_filter');
                                setFilterEmails("");
                            }
                        }),
                    ]}
                /> 
            </div>

            {
                starterDataMessage &&
                <div className="modal_overlay modal_overlay--30">
                    <div className="row layer layer-1">
                        <div className='col-12'>
                            {starterDataMessage}
                            <div className='text-center mt-2'>
                                <button
                                    className="btn btn-sm btn-info"
                                    onClick={() => setStarterDataMessage(null)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EmailTools;

const PasteEmails = ({ 
}) => {

    const { popOverlay } = useOverlays();

    const [ emails, setEmails ] = useState("");

    return (
        <div className='row'>

            <div className='col-12 text-center mb-2'>
                Enter emails to filter the roster. One email per line.
            </div>

            <div className='col-6'>
                <textarea 
                    className={`form-control form-control-sm ` }
                    onChange={(e) => setEmails(e.target.value)}
                    value={emails}
                    style={{height: "300px"}}
                /> 
            </div>

            <div className='col-6'>
                <ActionButtons
                    direction={DIRECTIONS.COLUMN}
                    buttons={[
                        Label({
                            text: "Filter Roster By Email List",
                            bgColor: "success",
                            disabled: emails === "",
                            onClick: () => {
                                filterSorter.addFilter(
                                    new EmailsFilter(
                                        "emails_filter", 
                                        emails.split('\n').map(email => email.trim())
                                        )
                                );
                                popOverlay();
                            }
                        }),
                        Label({
                            text: "Clear Pasted Emails",
                            bgColor: "warning",
                            visible: emails !== "",
                            onClick: () => {
                                filterSorter.removeFilter('emails_filter');
                            }
                        }),
                        Label({
                            text: "Close",
                            onClick: () => {
                                popOverlay();
                            }
                        })
                    ]}

                />
            </div>
        </div>

    )
}

