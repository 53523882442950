import TABService from './TABService';
import Helpers from '../helpers/Helpers';

class AssignmentService extends TABService {

    model = 'assignment'

    _hideStudentInfo = ( student ) => ({
        ...student,
        name: Helpers.privatizeName(student.name || student.student_name),
        last_name: student.last_name.substring(0,1) + ".",
        email_address: "***@***.com"
    })

    get_all_by_cohort = ( instructorId, cohortStartDate ) => 
        new Promise( (resolve, reject) => {
            this._get(
                `${this.model}/cohort/${instructorId}/${cohortStartDate}`
            )
            .then( results => { 
                if ( Helpers.getPrivacyMode() ) {
                    results.students = results.students.map( student => this._hideStudentInfo(student))
                }
                resolve(results);
            })
            .catch( err => reject(err))
        })
        

    get_my_cohort = () => 
        this._get(
            `${this.model}/my-cohort`
        )

    save_review = ( review ) =>
        this._post(
            `${this.model}/review/update`, review
        )

    get_review = ( studentId, assignmentId, cohortStartDate ) => 
        new Promise( (resolve, reject) => {
            this._get(
                `${this.model}/review/${studentId}/${assignmentId}/${cohortStartDate}`
            )
            .then( result => {
                if ( Helpers.getPrivacyMode() && result.review ) {
                    result.review = {
                        ...result.review,
                        student_name: Helpers.privatizeName(result.review.student_name),
                        student_email: "***@***.com",
                        reviewer_email_address: "***@***.com",
                        reviewer: Helpers.privatizeName(result.review.reviewer),
                        instructor_email: "***@***.com"
                    }
                }
                resolve(result);
            })
            .catch( err => reject(err))

        })
}

export default new AssignmentService();