import { useState, useEffect } from "react";
import probationService from "../../../services/ProbationService";
import SimpleStudentHeader from "../my_cohort_elements/SimpleStudentHeader";
import useUserAccess from "../../../hooks/useUserAccess";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import useStudent from "../../../hooks/useStudent";

const ProbationsModal = ({ 
    student,
    student_stats = null,
}) => { 

    const [ USER_LEVELS, checkAccess ] = useUserAccess();
    const { saveStudent, updateStudent } = useStudent();
    const { popOverlay, pushOverlay } = useOverlays();

    const canUpdate = checkAccess(USER_LEVELS.INSTRUCTOR);

    // const [displayingModal, setDisplayingModal] = useState(false);
    const [probationsList, setProbationsList] = useState(null);
    const [waivedList, setWaivedList] = useState(null);
    const [removedList, setRemovedList] = useState(null);
    const [probationData, setProbationData] = useState({
        student_user_id: student.id,
        description: "",
        stats_json: student_stats ? 
            student_stats
            :
            {
                assignments: Math.floor(
                    parseFloat(
                        student[
                            "core_assignments_to_date_percent"
                        ]
                    ) * 100
                ),
                attendance: Math.floor(
                    parseFloat(
                        student[
                            "attendance_to_date_percent"
                        ]
                    ) * 100
                ),
            }
    });
    const [addingProbation, setAddingProbation] = useState(false);
    const [showingActive, setShowingActive] = useState(true);
    const [showingWaived, setShowingWaived] = useState(false);
    const [showingCancelled, setShowingCancelled] = useState(false);

    useEffect(() => {
            probationService
                .get_by_student(student.id)
                .then((response) => {
                    setProbationsList(response);
                    filterProbations(response);
                })
                .catch((err) => console.log(err));
        
    }, []);

    const handleProbations = (e) => {
        e.preventDefault();
        

        probationService
            .add_probation({
                ...probationData,
                description: probationData.description + buildAdditionalInfo(),
                cohort_start_date: student.cohort_start_date_short
            })
            .then((newProbation) => {
                setProbationsList([...probationsList, newProbation]);
                saveStudent(
                    { 
                        ...student,
                        log_note: "Added probation",
                        probations: student["probations"] + 1 
                    }
                );
            })
            .catch((err) => console.log(err));
        setAddingProbation(false);
    };

    const buildAdditionalInfo = () => `
        \n --------------------------------------- \n
        Instructor: ${student.instructor_name}
        Program: ${student.program_name}
        Stack: ${student.stack_name}
        Cohort Start: ${student.cohort_start_date_short}
        Current Week: ${student.current_week}
        Assignments:
            To-Date: ${student.core_assignments_to_date_percent * 100}%
            Overall: ${student.core_assignments_overall_percent * 100}%
        Attendance:
            To-Date: ${student.attendance_to_date_percent * 100}%
            Overall: ${student.attendance_overall_percent * 100}%
            Consecutive Missed: ${student.missed_attendance}
    `

    const handleUpdateProbation = (targetProbation, status) => {
        console.log("Probation waived");
        probationService.update_probation({
            ...targetProbation,
            status: status,
        });

        if (targetProbation.status === 0) { // remove from active probations
            setProbationsList(probationsList.filter(p => p.id != targetProbation.id));
            saveStudent(
                { 
                    ...student, 
                    probations: student["probations"] - 1,
                    log_note: "Waived/Canceled probation"
                }
            );
        } else { // remove waived list
            setWaivedList(waivedList.filter(p => p.id != targetProbation.id));
        }

        if (status === 1) {
            setWaivedList([...waivedList, {...targetProbation, status: status}])
        }

        if (status === 2) {
            setRemovedList([...removedList, {...targetProbation, status:status}]);
            updateStudent({
                ...student,
                probation_waivers: waivedList.filter( ap => ap.cohort_start_date === student.cohort_start_date_short)
            })
        }
    };

    const filterProbations = (probations) => {
        // console.log(probations);
        const filteredLists = {
            0: [],
            1: [],
            2: [],
        };

        probations.forEach((current) => {
            filteredLists[current.status].push(current);
        });

        setProbationsList([...filteredLists[0]]);
        setWaivedList([...filteredLists[1]]);
        setRemovedList([...filteredLists[2]]);
    };

    const waivedIds =waivedList ? waivedList.map( ap => ap.cohort_start_date ) : [];
    console.log(waivedIds)
    return (
        <div className="layer layer-1 window_900">
            <div className="row">
                <div className="col-6">
                    <SimpleStudentHeader student={student} />
                    
                </div>
                <div className="col-6 modal_navigation__row">
                    {
                        canUpdate &&
                            <button
                                className="btn btn-sm btn-info"
                                onClick={() => setAddingProbation(true)}
                            >
                                Add Probation
                            </button>
                    }
                    
                    <div className="text-center">
                        <button
                            className="btn btn-sm btn-info ml-2"
                            onClick={popOverlay}
                        >
                            Close
                        </button>
                    </div>
                </div>
                <div className="col-12 divider mt-2 mb-2"><div></div></div>
            </div>
                {addingProbation ? (
                <div className='row'>
                    <div className="col-7">
                        <form onSubmit={handleProbations}>
                            <textarea
                                onChange={(e) =>
                                    setProbationData({
                                        ...probationData,
                                        description: e.target.value,
                                    })
                                }
                                name="comment"
                                cols="30"
                                rows="13"
                            ></textarea>
                            <div className="text-center">
                                <button className="btn btn-success">Add</button>
                                <button
                                    className="btn btn-info ml-2"
                                    onClick={() => setAddingProbation(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-5">
                        <h4><u>The following information will be added:</u></h4>
                        <table className="table table-sm table-borderless">
                            <tr>
                                <td>Instructor:</td>
                                <td>{student.instructor_name}</td>
                            </tr>
                            <tr>
                                <td>Program:</td>
                                <td>{student.program_name}</td>
                            </tr>
                            <tr>
                                <td>Stack:</td>
                                <td>{student.stack_name}</td>
                            </tr>
                            <tr>
                                <td>Cohort Start:</td>
                                <td>{student.cohort_start_date_short}</td>
                            </tr>
                            <tr>
                                <td>Current Week:</td>
                                <td>{student.current_week}</td>
                            </tr>
                            <tr>
                                <td>Assignments:</td>
                                <td>
                                    <ul>
                                        <li>To-Date: {student.core_assignments_to_date_percent * 100}%</li>
                                        <li>Overall: {student.core_assignments_overall_percent * 100}%</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>Attendance:</td>
                                <td>
                                    <ul>
                                        <li>To-Date: {student.attendance_to_date_percent * 100}%</li>
                                        <li>Overall: {student.attendance_overall_percent * 100}%</li>
                                        <li>Consecutive Missed: {student.missed_attendance}</li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-12 divider mt-2 mb-2"><div></div></div>
                </div>
            ) : ""}
                <div className="d-flex align-items-center">
                    <label className="mb-1 mr-2 ml-2" for="">Active</label>
                    <input
                        onClick={() => setShowingActive(!showingActive)}
                        checked={showingActive}
                        type="checkbox"
                        name=""
                        id=""
                    />
                    <label className="mb-1 mr-2 ml-2" for="">Waived</label>
                    <input className="rounded"
                        onClick={() => setShowingWaived(!showingWaived)}
                        checked={showingWaived}
                        type="checkbox"
                        name=""
                        id=""
                    />
                    <label className="mb-1 mr-2 ml-2" for="">Cancelled</label>
                    <input
                        onClick={() =>
                            setShowingCancelled(!showingCancelled)
                        }
                        checked={showingCancelled}
                        type="checkbox"
                        name=""
                        id=""
                    />
                </div>
            {showingActive && <h2>Active</h2>}
            <hr />
            {probationsList != null &&
                showingActive &&
                probationsList.map((probation, i) => (
                    <div className="layer layer-3 mb-2 p-2">
                        <pre>{probation.description}</pre>
                        {
                            canUpdate &&
                                <div className="text-right">
                                    {/* removed following to allow for unlimited waivers */}
                                        {/* ( waivedIds.indexOf(student.cohort_start_date_short) == -1 ) && */}
                                            <button
                                                className="btn btn-sm btn-outline-success"
                                                onClick={(e) =>
                                                    pushOverlay({
                                                        type: OVERLAY_TYPES.CONFIRM,
                                                        position: { x: e.clientX, y: e.clientY },
                                                        component: <>Are you sure you want to waive this probation?</>,
                                                        respond: (response) => {
                                                            if ( response ) handleUpdateProbation(probation, 1);
                                                        }
                                                    })
                                                }
                                            >
                                                Waive
                                            </button>
                                    
                                    <button
                                        className="btn btn-sm btn-outline-danger ml-2"
                                        onClick={(e) =>
                                            pushOverlay({
                                                type: OVERLAY_TYPES.CONFIRM,
                                                position: { x: e.clientX, y: e.clientY },
                                                component: <>Are you sure you want to cancel this probation?</>,
                                                respond: (response) => {
                                                    if ( response ) handleUpdateProbation(probation, 2);
                                                }
                                            })
                                        }
                                    >
                                        Cancel
                                    </button>
                                </div>
                        }
                    </div>
                ))}

            {showingWaived && <h2>Waived</h2>}
            {waivedList != null &&
                showingWaived &&
                waivedList.map((waivedProbation, idx) => (
                    <div className="layer layer-3 mb-2 p-2">
                        <pre>{waivedProbation.description}</pre>
                        <div className="text-right">
                            <button
                                className="btn btn-sm btn-outline-danger ml-2"
                                onClick={() =>
                                    handleUpdateProbation(
                                        waivedProbation,
                                        2
                                    )
                                }
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ))}
            {showingCancelled && <h2>Cancelled</h2>}
            {removedList != null &&
                showingCancelled &&
                removedList.map((cancelledProbation, idx) => (
                    <div className="layer layer-3 mb-2 p-2">
                        <pre>{cancelledProbation.description}</pre>
                    </div>
                ))}
        </div>
    );
};

export const ProbationsDisplay = ({
    student
}) => {

    const [ USER_LEVELS, checkAccess ] = useUserAccess();
    const { pushOverlay, popOverlay } = useOverlays();

    const canActivate = checkAccess(USER_LEVELS.INSTRUCTOR);

    return (
        <div 
            className="assign_attend__percent_col probations_button__container"

            data-toggle="tooltip"
            title={` ${student.name}:\n\n${student.probations > 0 ? `${student.probations} probation${student.probations != 1 ? 's' : ''}` : "No probations"}`}
            >
            <p 
                className={`${ canActivate ? "hover_text" : "noselect"}`} 
                onClick={(e) => {
                    if ( canActivate ) 
                        pushOverlay({
                            type: OVERLAY_TYPES.PROBATIONS_MODAL,
                            component: <ProbationsModal
                                canUpdate={checkAccess(USER_LEVELS.LEAD)}
                                student={student}
                                student_stats={{
                                    assignments: Math.floor(
                                        parseFloat(
                                            student[
                                                "core_assignments_to_date_percent"
                                            ]
                                        ) * 100
                                    ),
                                    attendance: Math.floor(
                                        parseFloat(
                                            student[
                                                "attendance_to_date_percent"
                                            ]
                                        ) * 100
                                    ),
                                }}
                            />
                        })
                }}
            >{student.probations} {student.probation_waivers > 0 ? 'W' : ''}</p>
        </div>
    )

}
export default ProbationsModal;
