import React, { useContext } from 'react';
import ActionButtons, { Label, DIRECTIONS } from '../../elements/ActionButtons';
import helpers from '../../../helpers/Helpers';
import { bulkSetStatuses } from '../status_setter/MyCohortSetStatuses';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import MyCohortContext from '../../../store/MyCohortContext';

const NextStackBreakdown = ({
    statuses,
}) => { 

    const { pushOverlay, popOverlay } = useOverlays();
    
    const { sortedRoster } = useContext(MyCohortContext);

    // sort each student into their next stack
    let stacks = {}; // keys == stack names, values == array of students
    sortedRoster.forEach( student => {

        if ( student.status_slug === 'ready_to_move' ) {
            if ( !stacks[student.next_stack] ) stacks[student.next_stack] = [];
            stacks[student.next_stack].push(student);
        }
    })

    return (
        <div className='row layer layer-1 window_1200'>
            <div className='col-12 text-center bg-warning text-danger'>
                All students with a Ready To Move Forward status are included. 
            </div>
            <div className='col-12'>
                <div className='row justify-content-center'>
                    {
                        Object.keys(stacks).map( stack => {
                            return (
                                <div className='col-4 layer layer-2'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h4><u>{stack}</u></h4>
                                        </div>
                                        <div className='col-12'>
                                            {
                                                stacks[stack].map( student => {
                                                    return (
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                {student.first_name} {student.last_name}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='col-12 p-1'>
                        <div className='row justify-content-center'>
                            {
                                Object.keys(stacks).map( stack => {
                                    return (
                                        <div className='col-4 layer layer-2'>
                                            <ActionButtons
                                                direction={DIRECTIONS.COLUMN}
                                                buttons={[
                                                    Label({
                                                        text: 'Copy Emails for LP3',
                                                        onClick: () => navigator.clipboard.writeText(stacks[stack].map( student => student.email_address).join(`\n`)),
                                                    }),
                                                    Label({
                                                        text: 'Copy Starter Data for Google Sheets',
                                                        bgColor: 'warning',
                                                        onClick: () => navigator.clipboard.writeText(helpers.studentDataToGSheetsData(stacks[stack])),
                                                    }),
                                                    Label({
                                                        text: 'Set Statuses to MOVED FORWARD',
                                                        bgColor: 'danger',
                                                        visible: stacks[stack][0]['status_slug'] === 'ready_to_move',
                                                        onClick: (e) => {
                                                            pushOverlay({
                                                                type: OVERLAY_TYPES.CONFIRM,
                                                                position: { x: e.clientX, y: e.clientY },
                                                                component: <>This will set the status of {stacks[stack].length} students to MOVED FORWARD. Continue?</>,
                                                                respond: (response) => {
                                                                    if ( response ) {
                                                                        bulkSetStatuses(stacks[stack], statuses.find( status => status.slug === 'moved-forward'));
                                                                        pushOverlay({
                                                                            type: OVERLAY_TYPES.ALERT,
                                                                            component: <>Statuses set.</>,
                                                                            position: { x: e.clientX, y: e.clientY },
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        },
                                                    }),
                                                ]}
                                                />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12 text-center mt-2'>
                <button className='btn btn-sm btn-info' onClick={popOverlay}>Close</button>
            </div>  
        </div>
    )
}

export default NextStackBreakdown;