import React, { useEffect, useState } from "react";
import instructorNotesService from "../../../services/InstructorNotesService";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";

const InstructorNotes = () => {

    const { pushOverlay } = useOverlays();

    const [ originalNote, setOriginalNote ] = useState("");
    const [ note, setNote ] = useState("");
    const [ isWorking, setIsWorking ] = useState(true);

    const exam_template = `
    Duration: 
    Code(s): 
    1) 5EG3F9 (Option FF)
    2) 
    3) 
    TAs can provide code?: 
    TAs can provide exam code review?: 
    Preferences: 
    `

    useEffect(() => {

        instructorNotesService.get()
            .then( note => {
                setNote(note);
                setOriginalNote(note);
                
            } )
            .catch( err => console.log(err) )
            .finally( data => setIsWorking(false) )
    }, [])

    const updateNote = () => {

        setIsWorking(true);

        instructorNotesService.update(note)
            .then( data => setOriginalNote(note))
            .catch( err => console.log(err) )
            .finally( data => setIsWorking(false))
    }

    return (
        <div 
            id="instructor_notes"
            className="row"
        >
            
            <div className="col-12">
                
                <div className="dashboard__side_header mt-2">
                    Calendly Link:
                </div>

                <input 
                    className="form-control"
                    value={note.calendly_link}
                    onChange={(e) => setNote({...note, calendly_link: e.target.value})}
                />
            </div>
            <div className="col-12">
                
                <div className="dashboard__side_header mt-2">
                    Class Schedule Link:
                </div>

                <input 
                    className="form-control"
                    value={note.calendar_link}
                    onChange={(e) => setNote({...note, calendar_link: e.target.value})}
                />
            </div>
            <div className="col-12">
                <div className="dashboard__side_header mt-2">
                    Exam Instructions:
                </div>

                <textarea 
                    className="form-control" 
                    onChange={(e)=> {
                        e.preventDefault();
                        if ( e.target.value.length <= 1024 )
                            setNote({...note, note: e.target.value})
                    }}
                    value={note.note}
                    disabled={isWorking}
                    ></textarea>
                <div className="sub_note">{note ? 1024 - note.note.length : 0} characters remaining</div>
            </div>
            <div className="col-3 mt-2">
                <button
                    className="btn btn-sm btn-success"
                    disabled={isWorking || 
                        (note.note === originalNote.note && 
                            note.calendar_link === originalNote.calendar_link && 
                            note.calendly_link === originalNote.calendly_link 
                                )}
                    onClick={updateNote}
                >Save Updates</button>
            </div>
            <div className="col-9 mt-2 text-right">
                <button
                    className="btn btn-sm btn-info ml-2"
                    onClick={(e)=> {setNote({...note, note: exam_template})}}
                    >Exam Template</button>
                <button
                    className="btn btn-sm btn-warning ml-2"
                    disabled={isWorking}
                    onClick={(e) =>
                        pushOverlay({
                            type: OVERLAY_TYPES.CONFIRM,
                            position: { x: e.clientX, y: e.clientY },
                            component: <>Are you sure you want to clear your notes?</>,
                            respond: (response) => {
                                if (response) setNote({...note, note: ""})
                            }
                        })
                    }
                >Clear</button>
            </div>

        </div>
    )
}

export default InstructorNotes;