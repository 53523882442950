import TABService from './TABService';

class TrivjoPlayService extends TABService {

    model = 'tj_play';

    startPlay = (libraryId) =>
        this._post(
            `${this.model}/start/${libraryId}`,
        );

    cancelPlay = ( gameId ) =>
        this._post(
            `${this.model}/cancel-play/${gameId}`,
        );

    giveQuestion = ( gameId, questionId ) =>
        this._post(
            `${this.model}/give-question/${gameId}/${questionId}`,
        );

    cancelQuestion = ( gameId ) =>
        this._post(
            `${this.model}/cancel-question/${gameId}`,
        );

    giveFollowUp = ( gameId ) =>
        this._post(
            `${this.model}/give-followup/${gameId}`,
        );

    giveLeaderboard = ( gameId ) =>
        this._post(
            `${this.model}/give-leaderboard/${gameId}`,
        );

    setState = ( gameId, state ) =>
        this._post(
            `${this.model}/set-state/${gameId}/${state}`,
        );

}
const trivjoPlayService = new TrivjoPlayService();
export default trivjoPlayService;