import React from "react";

const AssignmentCountsBreakdown = ({
    statsData
}) => {

    return (
        <div className="row">
            <div className="col-12">

                <div className="row mt-2">
                    <div className="col-5">
                        Students: {statsData.processedStudentCount}
                    </div>
                    <div className="col-7">
                        Attendance: {Math.round(statsData.totalAttendance / (statsData.attendanceDays * statsData.processedStudentCount) * 100)}% ({statsData.attendanceDays} attendance days)
                    </div>
                </div>

                <table className="table table-sm table-striped mt-2">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Due</th>
                            <th>Submitted</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Core ({statsData.assignmentTypeDue.core / statsData.processedStudentCount})</td>
                            <td>{statsData.assignmentTypeDue.core}</td>
                            <td>{statsData.assignmentTypeCounts.core}</td>
                            <td>{Math.round(statsData.assignmentTypeCounts.core / statsData.assignmentTypeDue.core * 100)}%</td>
                        </tr>
                        <tr>
                            <td>Practice ({statsData.assignmentTypeDue.practice / statsData.processedStudentCount})</td>
                            <td>{statsData.assignmentTypeDue.practice}</td>
                            <td>{statsData.assignmentTypeCounts.practice}</td>
                            <td>{Math.round(statsData.assignmentTypeCounts.practice / statsData.assignmentTypeDue.practice * 100)}%</td>
                        </tr>
                        <tr>
                            <td>Optional ({statsData.assignmentTypeDue.optional / statsData.processedStudentCount})</td>
                            <td>{statsData.assignmentTypeDue.optional}</td>
                            <td>{statsData.assignmentTypeCounts.optional}</td>
                            <td>{Math.round(statsData.assignmentTypeCounts.optional / statsData.assignmentTypeDue.optional * 100)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AssignmentCountsBreakdown;