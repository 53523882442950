import React, {useState} from "react";
import trivjoLibararyService from "../../../services/TrivjoLibraryService";
import CategorySelector from "./TrivjoCategorySelector";

const TrivjoAddLibrary = ( { 
    addLibrary
 }) => {

    const [ category, setCategory ] = useState(null);
    const [ libraryName, setLibraryName ] = useState(null);

    const createLibrary = (  ) => {
        trivjoLibararyService.create({ name: libraryName, tj_category_id: category.id })
            .then((data) => addLibrary(data))
            .catch((error) => window.alert(error.response?.data?.errors[0]));
    }

    return (
        <div 
            id="tivjo_add_library" 
            className="window_400"
        >
            <div className="col-12">
                Add Library
            </div>
            <div className="col-12">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Library Name" 
                    value={libraryName}
                    onChange={(e) => setLibraryName(e.target.value)}
                />
            </div>
            <div className="col-12">
                <CategorySelector
                    selectedCategory={category}
                    setSelectedCategory={setCategory}
                    />
            </div>
            <div className="col-12 text-center mt-2">
                <button 
                    className="btn btn-primary"
                    disabled={!category || !libraryName}
                    onClick={() => createLibrary()}
                >Add Library</button>
                <button 
                    className="btn btn-secondary ml-2"
                    onClick={() => addLibrary(null)}
                >Cancel</button>
            </div>
        </div>
    )
}

export default TrivjoAddLibrary;