import TABService from './TABService';
import Helpers from '../helpers/Helpers';

class CommentService extends TABService {

    model = 'comment';

    _privatizeComments = ( comments ) => 
        comments.map( comment => {
            return {
                ...comment,
                poster_email_address: "***@***.com"
            }
        })
    
    add = (ticket_id, text) =>
        this._post(
            `${this.model}/add`,
            { ticket_id, text }
        )

    getRecent = ( ticket_id, student_id ) =>
        new Promise((resolve, reject) => {

            this._post(
                `${this.model}/recent`,
                {ticket_id, student_id}
            )
            .then( comments => {
                if (Helpers.getPrivacyMode()) {
                    comments = this._privatizeComments(comments);
                }
                resolve(comments)
            })
            .catch( err => reject(err))
        })

    search = ( ticket_id, student_id ) => 
        new Promise((resolve, reject) => {

            this._post(
                `${this.model}/search`,
                {
                    ticket_id,
                    student_id
                }
            )
            .then( comments => {
                if (Helpers.getPrivacyMode()) {
                    comments = this._privatizeComments(comments);
                }
                resolve(comments)
            })
            .catch( err => reject(err))
        })
        

    getByTicket = (ticketId) => 
        new Promise((resolve, reject) => {

            this._get(
                `${this.model}/by-ticket/${ticketId}`
            )
            .then( comments => {
                if (Helpers.getPrivacyMode()) {
                    comments = this._privatizeComments(comments);
                }
                resolve(comments)
            })
            .catch( err => reject(err))
        })
        
}

export default new CommentService();