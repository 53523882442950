import React, { useState, useEffect, useContext } from "react";
import { ExamsChart } from "../exams_sheet/ExamSheetsStats";
import filterSorter from "../filter_sorter/MyCohortFilterSorter";
import AssignmentAttendanceStats from "./AssignmentAttendanceStats";
import AssignmentCountsBreakdown from "./AssignmentCountsBreakdown";
import StatusesStats from "../status_setter/StatusesStats";
import { calculateBPR } from "../exams_sheet/ExamsSheet";
import ActionButtons, { Label } from "../../elements/ActionButtons";
import MyCohortContext from "../../../store/MyCohortContext";

const MyCohortEOSStats = ({
    studentsData,
    sheetsData,
    // sortedRoster,
    cohortCalendar,
}) => {

    const { sortedRoster } = useContext(MyCohortContext);

    const [ filterBy, setFilterBy ] = useState(1); // 1==roster; 2==statuses
    const [ statsData, setStatsData ] = useState(null); // holds all stats used across components
    const [ finalRoster, setFinalRoster ] = useState([]); // either sortedRoster or studentsData depending on filterBy

    useEffect(() => {
        if (filterSorter.isActive()) setFilterBy(1); // set to roster if filter is active
    }, [])

    useEffect(() => {
        // do all calculations needed for all stat components.

        let stats = {
            processedStudentCount: 0,
            toDateAttendancePercent: 0,
            overallAttendancePercent: 0,
            overallAssignmentPercent: 0,
            toDateAssignmentPercent: 0,
            OPPMStatusCounts: {
                moved_forward: 0,
                dropped: 0,
                postponed: 0,
                retake: 0,
                transfer: 0,
            },
            assignmentPercents: new Array(sortedRoster[0].weekCount).fill(0),
            attendancePercents: new Array(sortedRoster[0].weekCount).fill(0),
            onTimePercents: new Array(sortedRoster[0].weekCount).fill(0),

            studentsData: {},

            courseWeeks: Object.keys(cohortCalendar).length,
            totalAttendance: 0,
            assignmentTypeDue: { // total assignments for each type
                'core': 0,
                'practice': 0,
                'optional': 0,
            },
            assignmentTypeCounts: { // number of assignments submitted for each type
                'core': 0,
                'practice': 0,
                'optional': 0,
            }
            ,
            attendanceDays: 0,
        }

        console.log(stats)

        if (filterBy === 2) {

            for ( const [key, student] of Object.entries(studentsData) ) 
                if ( student.status_is_active) processStudent(student, stats);
            
        } else {
            sortedRoster.forEach(student => processStudent(student, stats));
        }
        
        // calculate averages
        stats.toDateAssignmentPercent = (stats.toDateAssignmentPercent / stats.processedStudentCount) * 100 ;
        stats.overallAssignmentPercent = (stats.overallAssignmentPercent / stats.processedStudentCount) * 100 ;
        stats.toDateAttendancePercent = (stats.toDateAttendancePercent / stats.processedStudentCount) * 100 ;
        stats.overallAttendancePercent = (stats.overallAttendancePercent / stats.processedStudentCount) * 100 ;

        stats.assignmentPercents = stats.assignmentPercents.map( percent => (percent / stats.processedStudentCount) * 100 );
        stats.attendancePercents = stats.attendancePercents.map( percent => (percent / stats.processedStudentCount) * 100 );
        stats.onTimePercents = stats.onTimePercents.map( percent => (percent / stats.processedStudentCount) * 100 );

        setStatsData(stats);
        setFinalRoster(filterBy === 1 ? sortedRoster : 
            Object.values(studentsData).map(student => student)
            )

    }, [filterBy])

    const processStudent = (student, stats) => {

        stats.processedStudentCount ++;
        stats.studentsData[student.id] = student;
        stats.toDateAssignmentPercent = stats.toDateAssignmentPercent + student.core_assignments_to_date_percent;
        stats.overallAssignmentPercent = stats.overallAssignmentPercent + student.core_assignments_overall_percent;
        stats.toDateAttendancePercent = stats.toDateAttendancePercent + student.attendance_to_date_percent;
        stats.overallAttendancePercent = stats.overallAttendancePercent + student.attendance_overall_percent;

        for ( let week = 0; week <= student.current_week; week++ ) {
            if ( student[`week_${week+1}_attendance_percent`] ) stats.attendancePercents[week] += student[`week_${week+1}_attendance_percent`];
            if ( student[`week_${week+1}_assignment_percent`] ) stats.assignmentPercents[week] += student[`week_${week+1}_assignment_percent`];
            if ( student[`week_${week+1}_on_time_percent`] ) stats.onTimePercents[week] += student[`week_${week+1}_on_time_percent`];
        }

        // statuses breakdown
        if ( student.status_slug === 'moved-forward' ) stats.OPPMStatusCounts.moved_forward++;
        if ( student.status_slug === 'dropped' ) stats.OPPMStatusCounts.dropped++;
        if ( student.status_slug === 'postponed' ) stats.OPPMStatusCounts.postponed++;
        if ( student.status_slug.indexOf('retake') > -1 || student.status_slug.indexOf('rollback') > -1 ) stats.OPPMStatusCounts.retake++;
        if ( student.status_slug === 'transfer' ) stats.OPPMStatusCounts.transfer++;

        // assignment types due
        let assignmentTypeMem = {} //memoize assignments to keep type for each assignment
        for ( const [date, weeksData] of Object.entries(cohortCalendar) ) { 
            
            stats.attendanceDays = weeksData[0].attendance.days.length * weeksData[0].attendance.frequency * stats.courseWeeks;

            weeksData.forEach( day => {
                day.assignments.forEach( assignment => {
                    stats.assignmentTypeDue[assignment.type]++;
                    assignmentTypeMem[assignment.id] = assignment.type;
                })
            })
        }

        // assignment types submitted
        Object.keys(student.assignments).forEach( assignmentId => {
            stats.assignmentTypeCounts[assignmentTypeMem[student.assignments[assignmentId].assignment.chapter_module_id]]++;
        })

        // calculate attendance
        for ( const [date, periods] of Object.entries(student.attendance_details) ) {

            for (const [key, period] of Object.entries(periods)) { 

                if (
                    period.status === 1 ||
                    period.status === 3 ||
                    period.status === 4 ||
                    period.status === 5
                ) stats.totalAttendance++;
            }
        }

        return stats;
    }

    if ( !statsData ) return (<div className="window_1200">Calculating...</div>)

    return (
        <div className="row window_1200">
            <div className="col-12 mb-3">
                <div className="row justify-content-center">
                    <div className="col-3">
                        Student Count: {statsData?.processedStudentCount}
                    </div>
                </div>
            </div>

            <div className="col-12 divider mb-2 mt-2"><div></div></div>

            <div className="col-12">
                <h5>Assignments and Exams</h5>
            </div>
            <div className="col-6">
                <AssignmentCountsBreakdown
                    statsData={statsData}
                    currentWeek={sortedRoster[0].current_week}
                    />
            </div>
            <div className="col-6">
                <ExamsChart
                    roster={finalRoster}
                    cohortStartDate={sortedRoster[0].cohort_start_date}
                />
            </div>

            <div className="col-12 divider mb-2 mt-3"><div></div></div>

            <div className="col-6">
                <h5>Statuses</h5>
                <StatusesStats
                    studentsData={studentsData}
                    sortedRoster={sortedRoster}
                    statuses={sheetsData.student_statuses}
                    />
            </div>
            <div className="col-6">
                <OPPMStats
                    statsData={statsData}
                    />
            </div>

            <div className="col-12 divider mb-2 mt-3"><div></div></div>
            
            {
                statsData && statsData.processedStudentCount > 0 &&
                    <div className="col-12">
                        <h5>Attendance And On-time Assignments</h5>
                        <AssignmentAttendanceStats
                            statsData={statsData}
                            currentWeek={sortedRoster[0].current_week}
                        />
                    </div>
            }
        </div>
    )
}

export default MyCohortEOSStats;

const OPPMStats = ({ 
    statsData
 }) => {

    const BPR = calculateBPR(
        Object.keys(statsData.studentsData).map( key=> statsData.studentsData[key]),
        statsData.studentsData[Object.keys(statsData.studentsData)[0]].cohort_start_date
        )

    return (
        <div className="row">
            <div className="col-12">
                <h5>OPPM</h5>
                <table className="table table-sm">
                    <tr>
                        <td>Moved Forward:</td>
                        <td>{statsData.OPPMStatusCounts.moved_forward}</td>
                    </tr>
                    <tr>
                        <td>Dropped:</td>
                        <td>{statsData.OPPMStatusCounts.dropped}</td>
                    </tr>
                    <tr>
                        <td>Postponed:</td>
                        <td>{statsData.OPPMStatusCounts.postponed}</td>
                    </tr>
                    <tr>
                        <td>Retake / Rollback:</td>
                        <td>{statsData.OPPMStatusCounts.retake}</td>
                    </tr>
                    <tr>
                        <td>Transfer:</td>
                        <td>{statsData.OPPMStatusCounts.transfer}</td>
                    </tr>
                    <tr>
                        <td>BPR:</td>
                        <td>{BPR}%</td>
                    </tr>
                </table>
                <div className="text-center">
                    <ActionButtons
                        buttons={[
                            Label({
                                text: "Copy OPPM values to clipboard",
                                onClick: () => {
                                    navigator.clipboard.writeText( `${statsData.OPPMStatusCounts.moved_forward}
                                        ${statsData.OPPMStatusCounts.dropped}
                                        ${statsData.OPPMStatusCounts.postponed}
                                        ${statsData.OPPMStatusCounts.retake}
                                        ${statsData.OPPMStatusCounts.transfer}
                                        ${BPR}%`
                                        )}
                            })
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}