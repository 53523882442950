// extracts values from column for user to filter by

import React from 'react';
import { Chart } from "react-google-charts";
import filterSorter from '../../filter_sorter/MyCohortFilterSorter';
import { CORE_ASSIGNMENTS_DISPLAY } from '../AssignmentAttendanceSheet';

const AssignStatsCore = ({
    studentsData, // array
    close,
    coreAssignmentsDisplay,
    setCoreAssignmentsDisplay
}) => { 

    let columnValueName = coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ? "core_assignments_to_date_percent" : "core_assignments_overall_percent";

    // extract values
    let runningTotal = 0;
    let studentCount = 0;
    let count = {
        50: 0,
        60: 0,
        70: 0,
        80: 0,
        90: 0,
        100: 0
    };

    studentsData.forEach( student => {

        runningTotal += student[columnValueName];
        studentCount ++;

        if ( student[columnValueName] < .5 )
            count[50] ++;
        else if ( student[columnValueName] < .6 )
            count[60] ++;
        else if ( student[columnValueName] < .7 )
            count[70] ++;
        else if ( student[columnValueName] < .9 )
            count[80] ++;
        else if ( student[columnValueName] < .9 )
            count[90] ++;
        else if ( student[columnValueName] <= 1 )
            count[100] ++;
     })

    const data = [
        ["Percent", "Count"],
        ["below 50%", count[50]],
        ["50-59%", count[60]],
        ["60-69%", count[70]],
        ["70-79%", count[80]],
        ["80-89%", count[90]],
        ["90-100%", count[100]],
    ];
    
    return (
        <div className='value_sort_selector window_1200 layer layer-1 '>
            <span>
                Core Assignments 
                <select
                    className="ml-2"
                    value={coreAssignmentsDisplay}
                    onChange={(e) => setCoreAssignmentsDisplay(e.target.value)}
                >
                    <option value={CORE_ASSIGNMENTS_DISPLAY.OVERALL}>Overall</option>
                    <option value={CORE_ASSIGNMENTS_DISPLAY.TODATE}>To Date</option>
                </select>
            </span>
            <span>
                Average: { parseInt((runningTotal / filterSorter.roster.length) * 100) }%
            </span>
            <Chart
                chartType="PieChart"
                data={data}
                options={{
                    title: `Completion Percentages - ${studentCount} students`,
                }}
                width={"100%"}
                height={"400px"}
            />
            <button className="btn btn-primary btn-sm" onClick={close}>Close</button>
        </div>
    )

}

export default AssignStatsCore;