import React, { useState, useContext, useEffect } from 'react';

import TagSelector from './TagSelector';
import { AppContext } from '../../store/AppContext';
import wikiService from '../../services/WikiService';
import WikiResult from './SearchResult';
import Paginator from '../elements/Paginator';
import { WIKIBROWSERMODES } from './SearchTicketsBrowser';
import TicketsBrowser from '../tickets_browser/TicketsBrowser';

const SearchTickets = (props) => {

    const { wikiData, stacks } = useContext(AppContext);

    const { setBrowserMode } = props;

    const [ selectedTags, setSelectedTags ] = useState([]);
    const [ searchText, setSearchText ] = useState("");
    const [ stack, setStack ] = useState(0); // default to N/A
    const [ wikis, setWikis ] = useState([]);
    const [ selectedWiki, setSelectedWiki ] = useState(null);
    const [ searchResponse, setSearchResponse ] = useState(null);

    useEffect(() => {

        search();

    }, [])

    const search = ( page = 1 ) => {

        setWikis([]);
        setSelectedWiki(null);
        setSearchResponse(null)

        wikiService.search(searchText, page, stack === 0 ? null : stack, selectedTags)
            .then( resp => {
                setWikis(resp.results);
                setSearchResponse(resp);
            })
            .catch( err => console.log(err))
    }

    return (
        <>
            <fieldset className='row p-2' disabled={searchResponse === null}>
                <div className='col-8'>
                    <div className='subheader-text'>
                        Search Text
                    </div>
                    <input 
                        className='form-control'
                        id='searchText'
                        type='text'
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div className='col-4'>
                    <div className='subheader-text'>
                        Stack
                    </div>
                    <select
                        className='form-control'
                        onChange={(e) => setStack(parseInt(e.target.value))}
                    >
                        <option value={0}>N/A</option>
                        {
                            wikiData &&
                                wikiData.stacks
                                    .sort((a , b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                                    .map( stack =>
                                        <option
                                            value={stack.id}
                                            key={`stack_key_${stack.id}`}
                                        >
                                            {stack.name}
                                        </option>
                                    )
                        }
                    </select>
                </div>
                <div className='col-12 mt-4'>
                    {
                        <TagSelector selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                    }
                </div>
                <div className='col-12 text-center mt-4'>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => search()}
                    >
                        search
                    </button>
                    <div className='divider mt-2'><div></div></div>
                </div>
            </fieldset>
            <div className='row'>
                <div className='col-12'>
                    <Paginator 
                            searchResponse={searchResponse} 
                            onPageSelected={search} 
                            itemPrompt={'ticket'}
                            />
                    <TicketsBrowser initialTicketQuery={{status: 2}} showName={true} />
                </div>
            </div>
        </>
    )
}

export default SearchTickets;