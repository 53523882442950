import React from 'react';
import helpers from '../../helpers/Helpers';

import ticketService from '../../services/TicketService';
import Timer from '../elements/Timer';
import { useSearchParams } from 'react-router-dom';
import eventListener, { CLEAR_ALERT } from '../../events';
import LinkToClipboard from '../elements/LinkToClipboard';
import useOverlays, { OVERLAY_TYPES } from '../../hooks/useOverlays';

export const TICKET_MODES = {
    FULL: 1,
    COMPACT: 2
}

const Ticket = ( { 
    ticket, 
    ticketUpdated = null, 
    mode = TICKET_MODES.COMPACT 
} ) => { 

    const { pushOverlay } = useOverlays();

    const status = ticket.status === 1 ? 'Open' : 'Closed';
    const sourceTexts = ['DM', 'Discord', 'Zoom', 'Calendly', 'Email', 'Assignment Review']
    const [search,setSearch] = useSearchParams();

    const minutesToFormat = ( total_minutes ) => {
        let hours = parseInt(total_minutes / 60);
        let minutes = total_minutes - ( hours * 60 );

        return (`${hours}:${("00" + minutes).slice(-2)}`);
    }

    const clearAlert = () => {
        eventListener.dispatch( CLEAR_ALERT, { alertId:ticket.alert_id } );
        /*TODO: Possibly implement logic that requires the above event to be successful (eg actually clearing the even in the DB)
        for this ticketUpdated to run. Can also try to get ticketUpdated to the Alerts.js component, but that might be a bit messy
        */
        ticketUpdated({
            ...ticket,
            has_alert: 0,
            alert_id: null
        }, true, true);
    }

    const TicketStatus = () =>
        <div className={`text-center ticket__status ${ticket.status === 1 ? 'bg-success': ''}`}>
            {
                status === 'Open' ?
                    <div className={`text-center ticket__status ticket__status--${ticket.time_open < 21 ? "ok" : ticket.time_open < 46 ? "warning" : "danger"}`}>
                        {status}  |  <Timer 
                                        startMinutes={helpers.minutesBetween(ticket.date_loaded, new Date) + ticket.time_open}
                                        />
                    </div>
                    :
                    <div className=''>
                        {status} |  {minutesToFormat(ticket.total_time_open)}
                    </div>
            }
        </div>

    const ModifyTicket = () =>
        <div className='row '>

            <fieldset  > {/* this needs to be changed to col */}

                {/* student name */}

                <div className='col-12'  >
                    <div className='row mb-1'>
                        <div className='col-12'>
                            <div className=' header-text'>
                            <a href={`/student/pull/${ticket.student.id}`}>{ticket.student.first_name} {ticket.student.last_name}</a>
                            </div>

                            <div className='subheader-text'>
                                <LinkToClipboard link={ticket.student.email_address} />
                            </div>
                        </div>
                        <div className='col-12 mt-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <span className='subheader-text'>
                                        Instructor:
                                    </span>
                                    <span className=' subheader-text'>
                                        {
                                            ticket.instructor === null ?
                                                <>N/A</>
                                                :
                                                <>
                                                    {ticket.instructor_name} 
                                                    <span className='ml-1'>
                                                        ( <LinkToClipboard link={ticket.instructor_email_address} /> )
                                                    </span>
                                                </>
                                        }
                                    </span>
                                </div>

                                <div className='col-12'>
                                    <span className='subheader-text mr-1'>
                                        Program:
                                    </span>
                                    <span>
                                        {ticket.program_name}
                                    </span>
                                </div>

                                <div className='col-12'>
                                    <span className='subheader-text'>
                                        Stack:
                                    </span>
                                    <span className='col-9'>
                                        {ticket.track_name}
                                    </span>
                                </div>                           
                            </div>
                        </div>
                    </div>
                </div>
                <div className='divider'><div></div></div>
                {/* source, topics, track */}
                <div className='col-12 mt-2 mb-2'>
                    <div className='row'>
                        <div className='col-12'>
                            <span className='create_ticket__header'>Contact Source:</span>
                            
                            <select 
                                className='form-control'
                                value={ticket.source}
                                onChange={(e) => ticketUpdated({
                                    ...ticket,
                                    source: e.target.value
                                }, true)}
                                >
                                <option 
                                    value={ticketService.TICKET_SOURCE_DOJO_HALL}
                                    >Zoom</option>

                                <option 
                                    value={ticketService.TICKET_SOURCE_DISCORD}
                                    >Discord</option>

                                <option 
                                    value={ticketService.TICKET_SOURCE_DM}
                                    >DM</option>

                                <option 
                                    value={ticketService.TICKET_SOURCE_CALENDLY}
                                    >Calendly</option>

                                <option 
                                    value={ticketService.TICKET_SOURCE_EMAIL}
                                    >Email</option>
                            </select>
                            
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>

    const ClosedTicket = () => 
        <div className='row p-2'>
            <div className='col-12'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row mb-1'>
                            <div className='col-12 header-text'>
                                <a href={`/student/pull/${ticket.student.id}`}>{ticket.student.first_name} {ticket.student.last_name}</a>
                            
                                <div className='sub_note'>
                                    <LinkToClipboard link={ticket.student.email_address} />
                                </div>
                                
                            </div>
                            <div className='col-12 mt-3'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <span className='subheader-text mr-1'>
                                            Instructor:
                                        </span>
                                        <span>
                                            {
                                                ticket.instructor === null ?
                                                    <>N/A</>
                                                    :
                                                    <>
                                                        {ticket.instructor_name} 
                                                        <span>
                                                            ( <LinkToClipboard link={ticket.instructor_email_address} /> )
                                                        </span>
                                                    </>
                                            }
                                        </span>
                                    </div>

                                    <div className='col-12'>
                                        <span className='subheader-text mr-1'>
                                            Program:
                                        </span>
                                        <span>
                                            {ticket.program_name}
                                        </span>
                                    </div>

                                    <div className='col-12'>
                                        <span className='subheader-text'>
                                            Stack:
                                        </span>
                                        <span className='col-9'>
                                            {ticket.track_name}
                                        </span>
                                    </div>                           
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 divider mt-2 mb-2'><div></div></div>
                    
                </div>
            </div>
            <div className='col-12 mt-2'>
                <div className='row'>
                    <div className='col-3 subheader-text align-text-top'>
                        Source:
                    </div>
                    <div className='col-9'>
                        {sourceTexts[ticket.source - 1]}
                    </div>
                </div>
            </div>
            {
                ticket.topics.length > 0 &&
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-3 subheader-text align-text-top'>
                                Topics:
                            </div>
                            <div className='col-9'>
                                {
                                    ticket.topics.map((topic,idx,arr) =>
                                                <span key={`ticket_topictype_${topic.id}`}>
                                                    {topic.name}
                                                    {
                                                        idx < arr.length - 1 &&
                                                        <span>
                                                            &nbsp; | &nbsp;
                                                        </span>
                                                    }
                                                </span>
                                            )
                                }
                            </div>
                        </div>
                    </div>
            }
            
            {   
                ticket.assignment_name !== null &&
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-3 subheader-text align-text-top'>
                                Assignment:
                            </div>
                            <div className='col-9'>
                                {ticket.assignment_name}
                            </div>
                        </div>
                    </div>
            }

            <div className='col-12'>
                <div className='row'>
                    <div className='col-3 subheader-text align-text-top'>
                        Opened:
                    </div>
                    <div className='col-9'>
                        <span>
                            {helpers.formatDate(ticket.date_added)}
                        </span>
                        <span className='subheader-text'>
                            &nbsp; by &nbsp; 
                        </span>
                        <div>
                            {ticket.staff_name}
                            <span className='sub_note ml-1'>
                                <LinkToClipboard link={ticket.staff_email_address} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                {
                    ticket.closed_by !== null &&
                        <div className='row'>
                            <div className='col-3 subheader-text align-text-top'>
                                Closed:
                            </div>
                            <div className='col-9'>
                                <span>
                                    {helpers.formatDate(ticket.close_date)}
                                </span>
                                <span className='subheader-text'>
                                    &nbsp; by &nbsp; 
                                </span>
                                <div>
                                    {ticket.closed_by_name}
                                    <span className='sub_note ml-1'>
                                        <LinkToClipboard link={ticket.closed_by_email_address} />
                                    </span>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>

    const CompactTicketInfo = () => {
        let headerColor = ticket.source === 6 ?
                            "bg-info text-dark font-weight-bold"
                            :
                            ticket.escalated  && ! ticket.resolved ?
                                "bg-danger text-light font-weight-bold"
                                :
                                ticket.escalated  && ticket.resolved ?
                                    "bg-warning text-dark"
                                    :
                                    status === 'Open' ?
                                        "bg-success"
                                        :
                                        "bg-secondary text-light"
                                
        return (
            <div className='row compact_ticket layer layer-2'>
                <div className='col-12'>
                    <div className={`row ${headerColor}`}>
                        {
                            ticket.source == 6 ?
                                <div className='col-12 text-center '>
                                    {ticket.assignment_name}
                                </div>
                                :
                                ticket.source == 7 ?
                                <div className='col-12 text-center substantive_interaction_tab--submitted text-warning  font-weight-bold'>
                                    Substantive Interaction
                                </div>
                                    :
                                    ticket.escalated  && ! ticket.resolved ?
                                        <div className='col-12 text-center'>
                                            Escalated
                                        </div>
                                        :
                                        <>
                                            <div className='col-6 sub_note'>
                                                <TicketStatus />
                                            </div>
                                            <div className='col-2'>
                                                {
                                                    helpers.localStorageObjFind('comment_drafts',`${ticket.id}`) !== false &&
                                                        <span className='ticket__draft_text text-warning text-capitalize'>Draft</span>
                                                }
                                            </div>
                                            <div className='sub_note'>
                                                {
                                                    helpers.formatDate(status === 'Open' ? ticket.date_added : ticket.close_date)
                                                }
                                            </div>
                                        </>

                        }
                    </div>
                    <div className='divider mt-2 mb-2'><div></div></div>
                    <div className='row mt-1'>
                        <div className='col-12 subheader-text'>
                            <a href={`/student/pull/${ticket.student_id}`}>{ticket.student_name}</a>
                        </div>
                    </div>
                    <div className="col-12 row">
                        <span style={{fontSize:".85em"}}>Opened by: {ticket.staff_email_address || ticket.staff_email || "Open Ticket for Info"}</span>
                    </div>
                    <div className='divider mt-2 mb-2'><div></div></div>
                    <div className='row'>
                        <div className='col-12 ticket__last_comment'>
                            <p className='sub_note comment__text'>
                                { 
                                    ticket.last_comment === null ?
                                        <>No Comments</>
                                        :
                                        <>{ticket.last_comment}</>
                                }
                                
                            </p>
                        </div>
                    </div>
                    <div className='row ticket__compact_text mt-2'>
                        <div className='col-12 text-right'>
                            {
                                ticket.has_alert === 1 &&
                                    <button
                                        className='btn btn-sm btn-warning mr-2'
                                        onClick={(e) => 
                                            pushOverlay({
                                                type: OVERLAY_TYPES.CONFIRM,
                                                position: {x:e.clientX, y:e.clientY},
                                                component: <>Are you sure you want to clear this alert?</>,
                                                respond: (response) => {
                                                    if ( response ) clearAlert();
                                                }
                                            })
                                        }
                                    >
                                        Clear Alert
                                    </button>

                            }
                            <button
                                className='btn btn-sm btn-info'
                                onClick={() => {
                                    if(ticket.has_alert) clearAlert();
                                    setSearch({ticket:ticket.id});
                                }}
                            >
                                View
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if ( ticket === null ) return <></>

    return (
        <>
                {
                    mode === TICKET_MODES.FULL && status === 'Open' ? 
                        <ModifyTicket   /> 
                        : mode === TICKET_MODES.FULL ? <ClosedTicket /> : <CompactTicketInfo />
                }
        </>
    )
}

export default Ticket;

