import React, { useContext, useState, useEffect } from 'react';
import CohortSelector from './cohort_selector/CohortSelector';
import LoadingSpinner from '../modal_assets/LoadingSpinner';
import MyCohortList from './cohort_list/MyCohortList';
import MyCohortSheets from './MyCohortSheets';
import useBtrSearchParams from '../../hooks/useBtrSearchParams';
import MyCohortSheetSelector from './MyCohortSheetSelector';
import studentService from '../../services/StudentService';
import filterSorter from './filter_sorter/MyCohortFilterSorter';
import cohortService from '../../services/CohortService';
import EmailsFilter from './filter_sorter/filters/EmailsFilter';
import eventHandler, { UPDATE_STUDENT, UPDATE_SHEET_STUDENTS, RELOAD_COHORT } from '../../events';
import ActionButtons, { ICONS, CopyToClipboardButton, BasicActionButton, Label } from '../elements/ActionButtons';
import useStudent from '../../hooks/useStudent';
import useOverlays, { OVERLAY_TYPES } from '../../hooks/useOverlays';
import MyCohortContext from '../../store/MyCohortContext';

export const LOAD_COHORT_STATES = {
    IDLE: 'IDLE',
    GETTING_COHORT_INFO: 'GETTING_COHORT_INFO',
    LOADING_ROSTER: 'LOADING_ROSTER',
    LOADING_COHORT: 'LOADING_COHORT',
}

export const LOAD_COHORT_ERRORS = {
    ROSTER_NOT_FOUND: 'No roster found for this cohort. Please double check the instructor name and cohort start date.',
    SHEET_DATA_NOT_FOUND: 'Unable to load sheet data for this cohort.',
}

export const DATA_ISSUES = {
    NO_SEM: "No SEM Assigned",
    NO_STACK_PROGRESSION: "No Stack Progression"
}

export const MODES = {
    DEFAULT: 'DEFAULT',
    SEM_TOOLS: 'SEM_TOOLS',
}

const MyCohort = ({
    mode = MODES.DEFAULT
}) => {

    const { processStudent } = useStudent();
    const { pushOverlay, popOverlay } = useOverlays();
    const { 
        sheetsData,
        setSheetsData,
        cohortId,
        setCohortId,
        sortedRoster,
        setSortedRoster,
        setCohortCalendar,
        cohortInfo,
        setCohortInfo,
        studentsData,
    } = useContext(MyCohortContext);

    const [ selectedInstructor, setSelectedInstructor ] = useState(null);
    const [ search, setSearch ] = useBtrSearchParams();
    const [ loadCohortState, setLoadCohortState ] = useState(null);
    const [ loadCohortError, setLoadCohortError ] = useState("");
    const [ hasLoadError, setHasLoadError ] = useState(false);
    const [ isRefreshing, setIsRefreshing ] = useState(true);

    useEffect(() => {  
        // check if cohort info is in url
        const cohortIdParam = search.get('cohort_id');

        if ( cohortIdParam === null  ) {
            if ( mode === MODES.SEM_TOOLS ) {

            } else {
                setLoadCohortState(LOAD_COHORT_STATES.GETTING_COHORT_INFO);
            }
        } else{ 
            setLoadCohortState(LOAD_COHORT_STATES.LOADING_ROSTER);
            loadRoster(cohortIdParam, search.get('suid'));
        }

        // events
        document.addEventListener(`rosterUpdated`, (event) => setSortedRoster(event.detail));
        eventHandler.on(UPDATE_STUDENT, (event) => {
            onUpdateStudent(event.detail.student, event.detail.localOnly);
        });

        eventHandler.on(UPDATE_SHEET_STUDENTS, (event) => {
            onUpdateStudents(event.detail.students, event.detail.localOnly, event.detail.callback);
        });

        return () => {
            document.removeEventListener(`rosterUpdated`, () => {});
            eventHandler.remove(UPDATE_STUDENT, onUpdateStudent);
            eventHandler.remove(UPDATE_SHEET_STUDENTS, onUpdateStudents);
            eventHandler.remove(RELOAD_COHORT, loadRoster);
        }
            
    }, [])

    useEffect(() => {

        eventHandler.remove(RELOAD_COHORT, loadRoster);
        eventHandler.on(RELOAD_COHORT, (event) => loadRoster());

    }, [cohortId])

    useEffect(() => {

        if (loadCohortState === LOAD_COHORT_STATES.GETTING_COHORT_INFO) {
            pushOverlay({
                type: OVERLAY_TYPES.MODAL,
                component:<CohortSelector 
                    cohortSelected={(cohortId) => { 
                        setLoadCohortState(LOAD_COHORT_STATES.LOADING_COHORT)
                        onCohortSelected(cohortId);
                        popOverlay();
                    }}
                    defaultSelectedInstructor={selectedInstructor}
                    loadCohortError={loadCohortError}
                    close={
                        selectedInstructor !== null && loadCohortError === null ?
                            () => {
                                setLoadCohortState(LOAD_COHORT_STATES.IDLE);
                                popOverlay();
                            }
                            : null
                    }
                />
            })
        }

    }, [loadCohortState])

    const handleGetRosterResponse = ( response, selectedStudentUID ) => { 

        setLoadCohortState(LOAD_COHORT_STATES.IDLE);

        if ( 
            !response 
            ) handleNoCohortFound(LOAD_COHORT_ERRORS.ROSTER_NOT_FOUND);

        //same thing as above, but adding instructor_id since doing ...search doesn't work due to search being an array instead of obj
        setSearch({
            cohort_id: response.cohort_info.id,
            suid: selectedStudentUID || ''
        })
        setCohortId(response.cohort_info.id);
        let roster = [];
        
        Object.keys(response.students).map(studentId => {
            // add extra info to students data; speeds up switching between sheets
            /*
                assignments -
                    weekly breakdown of assignments
                        counts: on time, late, unsubmitted
                        submitted assignments
            */

            let student = processStudent(response.students[studentId], response.cohort_calendar);
            
            roster.push({
                ...student,
            })
            
            response.students[studentId] = student;

            // console.log(data.students[studentId]) // keep this for development
        })

        delete response.cohort_calendar.current_week;

        setSheetsData(response);
        setCohortCalendar(response.cohort_calendar);
        setSelectedInstructor(response.cohort_info.instructor_info);
        filterSorter.init(roster, response.students);


        if ( selectedStudentUID ) {
            filterSorter.addFilter(
                new EmailsFilter('manual_email_filter', [response.students[selectedStudentUID].email_address])
                );
            }
    }

    const onCohortSelected = ( cohortId ) => {

        filterSorter.reset();
        setSheetsData(undefined);

        loadRoster( cohortId );
    }

    const onUpdateStudents = ( students, localOnly = false, callback = null ) => {

        let newStudentsData = {...filterSorter.studentsData};

        students.forEach(student => {
            newStudentsData[student.id] = student;
        })

        filterSorter.setStudentsData(newStudentsData);

        if ( !localOnly) {
            studentService.updateStudents(students)
                .then( response => {
                    let newStudentsData = {...filterSorter.studentsData};

                    Object.keys(response.students).forEach(key => {
                        newStudentsData[key] = processStudent(response.students[key], response.cohort_calendar);
                    })

                    filterSorter.setStudentsData(newStudentsData);
                } )
                .catch( err => {
                    console.log(err);
                    window.alert("Unable to update student info.")
                } )
                .finally(() => {
                    if ( callback ) callback();
                })
        } else if ( callback ) callback();
    }

    const onUpdateStudent = ( student, localOnly = false ) => { 
        
        if ( typeof student !== 'object' ) return;
        //if ( student.isUpdating ) return; // prevent multiple updates from being called at once

        student.isUpdating = true;
        // update local copy
        filterSorter.setStudentsData({
            ...filterSorter.studentsData,
            [student.id]: student
        })

        if ( ! localOnly ) {
            studentService.updateStudent(student)
                .then( response => { 
                    filterSorter.setStudentsData({
                        ...filterSorter.studentsData,
                        [student.id]: processStudent(response.students[Object.keys(response.students)[0]], response.cohort_calendar)
                    })
                }) 
                .catch( err => {
                    console.log(err);
                    window.alert("Unable to update student info. The sheet will reload.")
                    window.location.reload();
                } )       
        }
    }

    const loadRoster = ( cohortToLoadId = null, selectedStudentUID = null, isRefreshing = false) => { console.log(cohortId)
        
        cohortToLoadId = cohortToLoadId || cohortId;

        if ( !cohortToLoadId ) return;

        setIsRefreshing(isRefreshing);

        if ( !isRefreshing ) {
            reset();
            setLoadCohortState(LOAD_COHORT_STATES.LOADING_COHORT);
        }

        cohortService.get_cohort( cohortToLoadId )
            .then( response => {
                if ( response ) {
                    setCohortInfo(response.cohort_info);
                    handleGetRosterResponse(response, selectedStudentUID);
                } else {
                    handleNoCohortFound(LOAD_COHORT_ERRORS.ROSTER_NOT_FOUND);
                }
            } )
            .catch( err => {
                console.log(err);
                setHasLoadError(true);
            } )
            .finally(() => setIsRefreshing(false))
    }

    const handleNoCohortFound = ( errorMessage = null ) => { 
        reset();
        filterSorter.setRoster(null);
        filterSorter.setStudentsData(null);
        setSheetsData(null);

        setLoadCohortError(errorMessage);

        setLoadCohortState(LOAD_COHORT_STATES.GETTING_COHORT_INFO);
    }

    const reset = () => { // reset everything before loading new cohort
        filterSorter.reset();
        setSheetsData(undefined);
        setLoadCohortError(null);
    }

    return (
        <>
            <div 
                id='my_cohort' 
                className='row  layer layer-2 p-2'
                    >
                        {
                            loadCohortState === LOAD_COHORT_STATES.IDLE  ?
                                <>
                                    <div id="my_cohort__cohort_info" className="col-12">
                                        <div className=" row">
                                            <div className='col-8 text-center'>
                                                <ActionButtons
                                                    buttons={[
                                                        BasicActionButton({
                                                            icon: ICONS.RELOAD,
                                                            toolTip: "Reload Cohort",
                                                            onClick: () => loadRoster( cohortId, null, false )
                                                        }),
                                                        CopyToClipboardButton({
                                                            icon: ICONS.EMAIL,
                                                            toolTip: 'Copy Instructor Email',
                                                            clipboardContent: cohortInfo?.instructor_info.email_address,
                                                        })
                                                    ]}
                                                    />
                                                <span className="ml-2 my_cohort__cohort_info_row ">
                                                    {cohortInfo?.instructor_info.name} - &nbsp;
                                                </span>

                                                <span 
                                                    className='mt-3 my_cohort__cohort_info_row '
                                                    >
                                                    {cohortInfo?.stack_name} : {new Date(cohortInfo?.cohort_start_date).toISOString().slice(0, 10)} to {new Date(cohortInfo?.cohort_end_date).toISOString().slice(0, 10)}
                                                </span>
                                            </div>
                                            <div className='col-4 text-center'>
                                                <ActionButtons
                                                    buttons={[
                                                        Label({
                                                            text: "Load New Cohort",
                                                            onClick: () => setLoadCohortState(LOAD_COHORT_STATES.GETTING_COHORT_INFO)
                                                        }),
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <MyCohortSheetSelector />
                                    </div>
                                </> : null

                        }
            </div>
            <div 
                id = "my_cohort_active_area"
                className='layer layer-3'
                >
                {
                    sheetsData === undefined || !studentsData  ?
                        <div className='col-12'>
                            <LoadingSpinner/>
                        </div>
                        :
                        !sortedRoster ?
                            <div className='col-12 text-center'>
                                No cohort information found
                            </div>
                            :
                            <>

                                <div className="col-6 col-md-5">
                                    <MyCohortList
                                        key={"MyCohortList"}
                                     />
                                </div>

                                <div className="col-6 col-md-7">
                                    <MyCohortSheets
                                        key={"MyCohortSheets"}
                                    />
                                </div>
                            </>
                }
            </div>

            {
                hasLoadError &&
                <div className="modal_overlay modal_overlay--30">
                    <div className='layer layer-1 text-center font-weight-bold'>
                        <p>
                            Sorry, this cohort cannot be loaded. Some older cohorts cannot be retrieved correctly from LP3.
                        </p>
                        
                        <p>
                            If this is a current cohort, please contact TAB2 support.
                        </p>

                        <button
                            className='btn btn-primary'
                            onClick={() => {
                                setHasLoadError(false);
                                setLoadCohortState(LOAD_COHORT_STATES.GETTING_COHORT_INFO);
                            }}
                        >Load a new Cohort</button>
                    </div>
                </div>
            }
        </>
    )
}

export default MyCohort;
