import React from "react";

/* Used to display text and / image for question and answers */
const QuestionFrame = ({ 
    obj, 
    selectedAnswer = null, 
    isQuestion=false,
    setSelectedAnswer=null,
    isSelected=false,
    disabled=false,
}) => {

    return (
        <div 
            className={`
                question_frame 
                question_frame--${isQuestion ? 'is_question' : ''}
                question_frame--${selectedAnswer && !isSelected ? 'is_selectable' : ''}
                question_frame--${isSelected ? 'is_selected' : ''}
                `
            } 
            onClick={() => {
                if (setSelectedAnswer) setSelectedAnswer(selectedAnswer)
            }}
            >
                {
                    obj.image_url &&
                    <div className={`
                        question_frame_image 
                        question_frame_image--${obj.text === null || obj.text === "" ? 'no-text' : ''}
                        question_frame_image--${isQuestion ? 'is-question' : ''}`
                        }>
                        <img src={obj.image_url} alt={obj.text} />
                    </div>
                }
                {
                    obj.text &&
                    <div className={`
                        question_frame_text 
                        question_frame_text--${obj.image_url === null || obj.image_url === "" ? 'no-image' : ''}
                        question_frame_text--${isQuestion ? 'is-question' : ''}
                        `
                    }
                        >
                        {obj.text}
                    </div>
                }
        </div>
    )
}

export default QuestionFrame;