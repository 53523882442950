import LinkToClipboard from "../elements/LinkToClipboard";

const WikiDisplay = ({wiki}) => {
    return (
        <div className='dojo-overflow__article'>
            <div className='row'>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-2 subheader-text'>
                            added:
                        </div>
                        <div className='col-10'>
                            <div>{wiki.date_added}</div>

                            {wiki.user.first_name} {wiki.user.last_name.charAt(0)}. &nbsp;
                            ( <LinkToClipboard link={wiki.user.email_address} /> )
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-2 subheader-text'>
                                    ticket:
                                </div>
                                <div className='col-10'>
                                    {wiki.ticket_id}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2 subheader-text'>
                                    stack:
                                </div>
                                <div className='col-10'>
                                    {wiki.stack.name}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='col-12 mt-2 mb-2'>
                    {
                        wiki.tags.length > 0 &&
                        <>
                            <div className='subheader-text'>Tags:</div>
                            {
                                wiki.tags
                                    .sort((a, b) => a.title > b.title ? 1 : a.title < b.title ? -1 : 0)
                                    .map((wiki, idx) =>
                                        <span key={idx} className='layer layer-1 p-1 mr-1'>
                                            {wiki.title}
                                        </span>
                                    )
                            }
                        </>
                    }
                </div>

                <div className='col-12'>
                    <div className='subheader-text'>description:</div>
                    <p className='layer layer-1 p-2 dojo-overflow__text'>{wiki.description}</p>
                </div>

                <div className='col-12'>
                    <div className='subheader-text'>solution:</div>
                    <p className='layer layer-1 p-2 dojo-overflow__text'>{wiki.solution}</p>
                </div>

                {
                    wiki.code !== "" &&
                    <div className='col-12'>
                        <div className='subheader-text'>code:</div>
                        <p className='layer p-2 dojo-overflow__code'>{wiki.code}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default WikiDisplay;