import React, { useContext } from 'react';
import TicketsBrowser from './tickets_browser/TicketsBrowser';
import QueryBuilder from '../helpers/QueryBuilder';
import { AppContext } from '../store/AppContext';

const OpenTickets = () => {

    const { user } = useContext(AppContext);

    return (
        <div className='row justify-content-center'>
            <div className='col-12'>
                <h1 className='text-center mb-2'>Open Tickets</h1>
                <TicketsBrowser 
                    initialTicketQuery={
                        new QueryBuilder()
                            .test(`status = 1`)
                    }
                    filter={{status: 1}}
                    showName={true} 
                    decrementTicketCount={true}
                    />
            </div>
        </div>
    )
}

export default OpenTickets;