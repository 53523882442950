import TABService from './TABService';

class TrivjoLibraryService extends TABService {

    model = 'tj_library';

    getCategories = () => 
        this._get(
            `${this.model}/categories`,
        );

}
const trivjoLibraryService = new TrivjoLibraryService();
export default trivjoLibraryService;