import { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../store/AppContext";
import trivjoPlayService from "../services/TrivjoPlayService";

const PLAY_STATES = {
    PRE_GAME: "PRE_GAME", // display library image and name
    PRE_QUESTION: "PRE_QUESTION", // 
    GET_READY: "GET_READY", // 3 seconds
    DISPLAYING_QUESTION: "DISPLAYING_QUESTION", // displays just the question 5 seconds
    GIVING_QUESTION: "GIVING_QUESTION", // displays question, answers and timer
    POST_QUESTION: "POST_QUESTION", // 
    REVEAL_ANSWER: "REVEAL_ANSWER", //
    GIVING_FOLLOW_UP: "GIVING_FOLLOW_UP",
    GIVING_LEADERBOARD: "GIVING_LEADERBOARD",
}

const useTrivjo = () => {

    const { trivjo, setTrivjo } = useContext(AppContext);

    const [ playState, setPlayState ] = useState(null);
    const [ remainingTime, setRemainingTime ] = useState(0);
    const [ givenQuestions, setGivenQuestions ] = useState([]);
    const [ xclTimer, setXclTimer ] = useState(false);

    useEffect(() => {
        setPlayState(trivjo.playState);
        setGivenQuestions(trivjo.givenQuestions);
    }, [trivjo])

    const updatePlayState = (state, broadcast = true) => {
        setTrivjo(trivjo => ({
            ...trivjo,
            playState: state,
            t: 1,
        }));
        if ( trivjo.isHost && broadcast ) trivjoPlayService.setState(trivjo.gameId, state);
    }

    const startPlay = (libraryId) => {
        return new Promise((resolve, reject) => {
            trivjoPlayService.startPlay(libraryId)
                .then((response) => {
                    setTrivjo({
                        ...trivjo,
                        gameId: response.uuid,
                        library: response.library,
                        isHost: true,
                        givenQuestions: [],
                        currentQuestion: null,
                        playState: PLAY_STATES.PRE_GAME,
                    })
                    resolve(response);
                })
                .catch((error) => reject(error))
        })
    }

    const giveQuestion = (question) => {
        setTrivjo({
            ...trivjo,
            givenQuestions: [...trivjo.givenQuestions, question.id],
            currentQuestion: question,
        });
        updatePlayState(PLAY_STATES.GET_READY);


        startCountdownTimer(3, (completed) => {
            if ( !completed ) return; 

            updatePlayState(PLAY_STATES.DISPLAYING_QUESTION); // show question before answers and timer

            if ( trivjo.isHost ) trivjoPlayService.giveQuestion(trivjo.gameId, question.id);

            startCountdownTimer(5, (completed) => {
                if ( !completed ) return; 
                updatePlayState(PLAY_STATES.GIVING_QUESTION); // show question, answers and timer
                
                startCountdownTimer(question.time, (completed) => {
                    if ( completed )
                        updatePlayState(PLAY_STATES.POST_QUESTION, false); // pause waiting for host to do something
                        // no need to broadcast state here because expiring timer in player app will set this
                })
            })
            
        }); 
    }

    const startCountdownTimer = ( seconds, callback ) => {

        setRemainingTime(seconds);

        const startTime = new Date();
        const interval = setInterval(() => {
    
                let newTime = seconds - Math.floor((new Date() - startTime) / 1000);
                
                if ( newTime < 0 ) newTime = 0;
                let cancel = false;
                // check if timer was canceled
                setXclTimer( xclTmr => {
                    if ( xclTmr ) cancel = true;
                    return false; // reset the flag
                })
    
                setRemainingTime(newTime);
    
                if ( cancel || newTime === 0) { 
                    clearInterval(interval);
                    callback(!cancel); // return if counter completed
                }
            }, 1000);
    }

    const giveFollowUp = () => updatePlayState(PLAY_STATES.GIVING_FOLLOW_UP, true);

    const giveLeaderboard = (questionId) => {
        trivjoPlayService.giveLeaderboard(trivjo.gameId)
            .then((response) => {
            })
            .catch((error) => {
            })
    }

    const cancelPlay = () => {
    }

    const cancelQuestion = () => {
        //clearInterval(timer);
        setXclTimer(true);
        updatePlayState(PLAY_STATES.PRE_QUESTION);
        setTrivjo( trivjo => ({
            ...trivjo,
            givenQuestions: trivjo.givenQuestions.filter((qId) => qId !== trivjo.currentQuestion.id),
            currentQuestion: null,
        }))

        if ( trivjo.isHost ) {
            trivjoPlayService.cancelQuestion(trivjo.gameId)
                .then((response) => {
                })
                .catch((error) => {
                })
        }
    }

    const revealAnswer = () => updatePlayState(PLAY_STATES.REVEAL_ANSWER);

    return {
        trivjo,
        startPlay,
        playState,
        updatePlayState,
        giveQuestion,
        remainingTime,
        cancelPlay,
        cancelQuestion,
        giveFollowUp,
        giveLeaderboard,
        givenQuestions,
        revealAnswer,
    }

}

export {useTrivjo as default, PLAY_STATES}