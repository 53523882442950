import { useEffect } from "react";
import { useState } from "react";
import helpers from '../../helpers/Helpers';
import commentService from "../../services/CommentService";

const AddComment = ( { addComment, ticketId } ) => {

    const [ comment, setComment ] = useState("");
    const [ addingComment, setAddingComment ] = useState(false);

    useEffect(() => {
        if(localStorage.getItem('comment_drafts') === null) localStorage.setItem('comment_drafts',JSON.stringify({}));

        const commentDraft = helpers.localStorageObjFind('comment_drafts',`${ticketId}`)

        if(commentDraft !== false) setComment(commentDraft);
    },[])

    const saveComment = () => {
        if (comment === '') return;
        setAddingComment(true);

        commentService.add(ticketId, comment)
            .then( newComment => {

                if (newComment === null) {
                    window.alert('Unable to save comment.');
                } else {
                    addComment(newComment)
                    setComment("");
                    helpers.localStorageObjRemove('comment_drafts',`${ticketId}`)
                }
            })
            .catch( err => console.log(err))
            .finally( resp => {
                setAddingComment(false);
            })
        
    }

    return (
        <div className='col-12  p-2'>
            <div>
                <textarea
                    rows={4}
                    className='form-control'
                    onChange={(e) => {
                        setComment(e.target.value)
                        if (e.target.value.trim() !== '') helpers.localStorageObjAdd('comment_drafts',`${ticketId}`,e.target.value)
                        else helpers.localStorageObjRemove('comment_drafts',`${ticketId}`)
                    }}
                    onKeyDown={e => helpers.keyboardShortcut({e,callback:saveComment,keyCode:"Enter",useCtrl:true})}
                    value={comment}
                    disabled={addingComment}
                ></textarea>
            </div>
            <div className='text-right'>
                <button
                    className='btn btn-sm btn-success mt-1'
                    onClick={saveComment}
                    disabled={addingComment || comment.length === 0}
                >
                    Save Comment
                </button>
            </div>
        </div>
    )
}

export default AddComment