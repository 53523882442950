import React, { useState } from "react";

import { ExamsDisplay } from "../my_cohort/exams_sheet/ExamsSheet";
import StudentExamAssignment from "../my_cohort/exams_sheet/assign_exams/StudentExamAssignment";
import ExamEmail from "../my_cohort/exams_sheet/exam_email/ExamEmail";
import { ExamTabsLegend } from "../my_cohort/exams_sheet/ExamsSheet";
import AddTempExam from "../my_cohort/exams_sheet/assign_exams/AddTempExam";
import ActionButtons, { BasicActionButton, Label, ICONS } from "../elements/ActionButtons";
import useOverlays, { OVERLAY_TYPES } from "../../hooks/useOverlays";

const PullStudentExams = ({
    student
}) => { 

    const { pushOverlay, popOverlay } = useOverlays();

    const [ temporaryExam, setTemporaryExam ] = useState(null);
    const [ sendingExamCode, setSendingExamCode ] = useState(false);

    const onSetExam = (exam) => { 
        
    }

    let examDueDate = new Date(student.cohort_end_date);
    examDueDate.setDate(examDueDate.getDate() -2); // due date is 2 days before cohort end date

    let activeExams = [...student.instructor_info.open_exams];
    if (temporaryExam) activeExams.push(temporaryExam);

    return (
        <div className="row">
            <div className="col-12 text-center mb-2">
                <span className='mr-2'><b>Exams</b></span>
                <ActionButtons
                    buttons={[
                        BasicActionButton({
                            icon: ICONS.SELECT,
                            text: 'Exam Statuses Legend',
                            onClick: (e) => {
                                pushOverlay({
                                    position: { x: e.clientX, y: e.clientY },
                                    component: <ExamTabsLegend
                                        close={() => popOverlay()}
                                    />
                                })
                            }
                        })
                    ]}
                />
            </div>

            <div 
                className={`col-12`}
            >
                <ExamsDisplay
                    student={student}
                    />
            </div>

            <div 
                className={`col-12 mt-2 text-center`}
            >
                {
                    activeExams.length === 0 ?
                    <div className='text-center'>
                       
                        No Active Exams | &nbsp;
                        <ActionButtons
                            buttons={[
                                Label({
                                    text: 'Add an Exam on LP3',
                                    onClick: () => window.open("https://login.codingdojo.com/admin/exam", '_blank').focus()
                                }),
                                Label({
                                    text: 'Add a Temporary Exam',
                                    onClick: () =>
                                        pushOverlay({
                                            type: OVERLAY_TYPES.MODAL,
                                            component: <AddTempExam
                                                addExam={(exam) => {
                                                    popOverlay();
                                                    setTemporaryExam(exam);
                                                }}
                                                close={() => popOverlay()}
                                            />
                                        })
                                     
                                })
                            ]}
                        />
                    </div>
                    :
                    <a>
                        <StudentExamAssignment
                            student={student}
                            activeExams={activeExams}
                            key={`student_exam_assignment_${student.id}_${Math.random()}`}
                            assignExam={() => 
                                pushOverlay({
                                    type: OVERLAY_TYPES.MODAL,
                                    component:<ExamEmail
                                        minimumAssignments={Math.ceil(student.core_assignments_total_overall * .90)}
                                        dueDate={examDueDate}
                                        students={[student]}
                                        close={() => popOverlay()}
                                    />
                                })}
                            setTempExam={(exam) => onSetExam(exam)}
                            issueExam={() => 
                                pushOverlay({
                                    type: OVERLAY_TYPES.MODAL,
                                    component:<ExamEmail
                                        minimumAssignments={Math.ceil(student.core_assignments_total_overall * .90)}
                                        dueDate={examDueDate}
                                        students={[student]}
                                        close={() => popOverlay()}
                                    />
                                })}
                        />
                        <ActionButtons
                            buttons={[
                                Label({
                                    text: 'Add a Temporary Exam',
                                    onClick: () =>
                                        pushOverlay({
                                            type: OVERLAY_TYPES.MODAL,
                                            component: <AddTempExam
                                                addExam={(exam) => {
                                                    popOverlay();
                                                    setTemporaryExam(exam);
                                                }}
                                                close={() => popOverlay()}
                                            />
                                        })
                                     
                                })
                            ]}
                        />

                    </a>
                }
            </div>

            {
                sendingExamCode &&
                <div className='modal_overlay modal_overlay--30'>
                    <ExamEmail
                        minimumAssignments={Math.ceil(student.core_assignments_total_overall * .90)}
                        dueDate={examDueDate}
                        students={[student]}
                        close={() => setSendingExamCode(false)}
                    />
                </div>
            }
        </div>
    );
}

export default PullStudentExams;