import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../modal_assets/LoadingSpinner";
import studentService from "../../../services/StudentService";
import helpers from "../../../helpers/Helpers";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import BeltDisplay from "../../my_cohort/exams_sheet/BeltDisplay";
import ActionButtons, { ICONS, BasicActionButton, CopyToClipboardButton, OpenURLButton } from "../../elements/ActionButtons";

const ATTENDANCE_STATUSES = {
    1: "P ", // present
    2: "A ", // absent
    3: "P ", // late
    4: "4?", // 
    5: "E", // Excused
    6: "A", // student did not answer discussion question
}

const StudentHistory = ({
    student
}) => {

    const [ showPanel, setShowPanel ] = useState(false);

    return (
        <div className="modal_overlay modal_overlay--80">
            <div className="row">
                <div className="col-12">
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => setShowPanel(true)}
                    >History</button>
                </div>
                {
                    showPanel &&
                    <div className="modal_overlay">
                        <StudentHistoryDisplay
                            student={student}
                            close={() => setShowPanel(false)}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default StudentHistory;

export const StudentHistoryDisplay = ({
    student,
    close,
}) => { 

    const [ history, setHistory ] = useState();
    const [ viewCourse, setViewCourse ] = useState();

    useEffect(() => {
        
        studentService.pullHistory(student.id)
            .then(res => {
                setHistory(res);
                // setViewCourse(res[Object.keys(res)[0]]); // uncomment to force view of first course
            })
            .catch(err => console.log(err))

    }, []);

    const convertToSnakeCase = (str) => {
        return str && str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(s => s.toLowerCase())
            .join('_');
    }

    return (
        
        <div id="student_history_display" className="row layer layer-1 window_1200 ">
            <div className="col-12">

            {
                history === undefined &&
                    <div className="row">
                        <div className="col-12 text-center">
                                <LoadingSpinner />
                        </div>
                    </div>
            }
            {
                history !== undefined &&
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>History for {student.full_name || `${student.first_name} ${student.last_name}`}</h3>
                    </div>
                    <table className="col-12 table table-sm table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Start Date</th>
                                <th>Program</th>
                                <th>Course</th>
                                <th className="text-center">Attendance</th>
                                <th className="text-center">Assignments</th>
                                <th className="text-center">Belt</th>
                                <th className="text-center">Instructor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(history).map( key => 
                                    <tr 
                                        key={`history_row${key}`}
                                        className=""
                                        >
                                        <th>
                                            <ActionButtons
                                                buttons={[
                                                    BasicActionButton({
                                                        icon: ICONS.VIEW,
                                                        tooltip: "View Assignments and Attendance",
                                                        onClick: () => setViewCourse(history[key])
                                                    }),
                                                    CopyToClipboardButton({
                                                        icon: ICONS.EMAIL,
                                                        tooltip: "Copy Instructor's Email Address",
                                                        clipboardContent: history[key].instructor_email_address
                                                    }),
                                                    OpenURLButton({
                                                        icon: ICONS.COHORT,
                                                        tooltip: "Open Cohort Sheet",
                                                        url: `/assignment-reviews?cohort_id=${history[key].cohort_id}&suid=${student.id}`,
                                                        newTab: true
                                                    })

                                                ]}

                                            />
                                        </th>
                                        <td>{helpers.formatDate(new Date(history[key].start_date))}</td>
                                        <td>{history[key].program_name}</td>
                                        <td>{history[key].course_name}</td>
                                        <td className="text-center">{parseInt(history[key].attendance_per * 100)}%</td>
                                        <td className="text-center">{parseInt(history[key].assignment_per * 100)}%</td>
                                        <td className="text-center">
                                            <BeltDisplay
                                                belt_level={history[key].belt_level}
                                                belt_score={history[key].exam_score}
                                                />
                                            </td>
                                        <td className="text-center">
                                            {history[key].instructor_name}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <div className="col-12 text-center">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={close}
                        >Close</button>
                    </div>

                    {
                        viewCourse &&
                        <div className="modal_overlay">
                            <div id="student_history_details" className="row layer layer-1">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6">
                                            <h3>{student.full_name}</h3>
                                        </div>
                                        <div className="col-6 text-right">
                                            <b>{viewCourse.program_name}</b> <br/>
                                            <b>{viewCourse.course_name}</b> <br/>
                                            {helpers.formatDate(new Date(viewCourse.start_date))} - {helpers.formatDate(new Date(viewCourse.end_date))}
                                        </div>
                                        <div className="col-12 divider"><div></div></div>
                                    </div>
                                </div>

                                <div className="col-12 mt-3">
                                    <h5>Attendance - {parseInt(viewCourse.attendance_per * 100)}%</h5>
                                    <div className="row">
                                        {
                                            viewCourse.attendance.map( (day, i) =>{

                                                // add 1 day to date to make it display correctly
                                                let date_attended = new Date(day.date_attended);
                                                date_attended = new Date(date_attended.setDate(date_attended.getDate() + 1));
                                                return(
                                                    <div className="col-4">
                                                        {ATTENDANCE_STATUSES[day.status]} - &nbsp;
                                                        {helpers.formatDate(date_attended)}
                                                    </div>
                                                )}
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="col-12 mt-3">
                                    <h5>Submitted Core Assignments - {parseInt(viewCourse.assignment_per * 100)}%</h5>
                                    <div className="row">
                                        {
                                            viewCourse.assignments.map( (assignment, i) =>
                                                <div 
                                                    key={`assignment_row${i}`}
                                                    className="col-4"
                                                    >
                                                    <b>{assignment.title}</b>
                                                    <td>{helpers.formatDate(new Date(assignment.submit_date))}</td>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>

                                <div className="col-12 text-center mt-3">
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => setViewCourse()}
                                    >Close</button>

                                    <PDFDownloadLink document={<MyDocument student={student} courseData={viewCourse} />} fileName={`${convertToSnakeCase(student.full_name)}.pdf`}>
                                        {({ blob, url, loading, error }) => 
                                            (loading ? 
                                                'Loading document...' : 
                                                <button
                                                    className="btn btn-sm btn-success ml-3"
                                                >Download PDF</button>)}
                                    </PDFDownloadLink>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

            </div>
        </div>
            
        

    )
}



const MyDocument = ({
    student,
    courseData
}) => {

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#E4E4E4',
            padding: 20,
            fontSize: 12,
        },
        row: {
        margin: 10,
        padding: 10,
        flexGrow: 1
        },
        studentName: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        courseTitle: {
            fontSize: 16,
            fontWeight: 'bold',
            marginTop: 10,
        },
        sectionTitle: {
            fontSize: 16,
            marginTop: 20,
        },
        sectionList: {
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        listItem: {
            margin: 5
        },
        sectionListCol: {
            width: '100%',
            flexDirection: 'column',
            flexWrap: 'wrap',
        },
        assignmentTitle: {
            fontWeight: 'bold',
            marginTop: 10,
        }
    });

    let date_attended = new Date(courseData.date_attended);
    date_attended = new Date(date_attended.setDate(date_attended.getDate() + 1));
    
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.studentName}>
                    <Text>{student.full_name}</Text>
                </View>
                <View style={styles.courseTitle}>
                    <Text>{courseData.program_name}</Text>
                </View>
                <View>
                    <Text>{courseData.course_name}</Text>
                </View>
                <View>
                    <Text>{helpers.formatDate(new Date(courseData.start_date))} - {helpers.formatDate(new Date(courseData.end_date))}</Text>
                </View>

                <View style={styles.sectionTitle}>
                    <Text>Exam - {courseData.exam_score || 'N/A'}</Text>
                </View>

                <View style={styles.sectionTitle}>
                    <Text>Attendance - {parseInt(courseData.attendance_per * 100)}%</Text>
                </View>

                <View style={styles.sectionList}>
                    {
                        courseData.attendance.map( (day, i) =>{

                            // add 1 day to date to make it display correctly
                            let date_attended = new Date(day.date_attended);
                            date_attended = new Date(date_attended.setDate(date_attended.getDate() + 1));
                            return(
                                <View style={styles.listItem}>
                                    <Text>
                                        {ATTENDANCE_STATUSES[day.status]} - &nbsp;
                                        {helpers.formatDate(date_attended)}
                                    </Text>
                                </View>
                            )}
                        )
                    }
                </View>

                <View style={styles.sectionTitle}>
                    <Text>Submitted Core Assignments - {parseInt(courseData.assignment_per * 100)}%</Text>
                </View>

                <View style={styles.sectionListCol}>
                {
                    courseData.assignments.map( (assignment, i) =>{
                    return (
                    <View >
                        <Text style={styles.assignmentTitle}>
                            {assignment.title}
                        </Text>
                        <Text>
                            {helpers.formatDate(new Date(assignment.submit_date))}
                        </Text>
                    </View>)}
                    )
                }
                </View>
                
            </Page>
        </Document>
    )
};