export const EXAM_STATUS = {
                "EXAM_STATUS_NA" : 0,
                "EXAM_STATUS_OPEN" : 1,
                "EXAM_STATUS_DEPLOY" : 2,
                "EXAM_STATUS_SUBMITTED" : 3,
                "EXAM_STATUS_CLOSED" : 4
            }

export const ASSIGNMENT_REVIEW_STATUS = {
    "NO_ACTIVITY": 0,
    "SUBMITTED": 1,
    "NEEDS_REVIEW": 2,
    "CHECKED": 3,
    "NEEDS_FOLLOW_UP": 4
}

export const ATTENDANCE_FILTER_CHOICES = [
    {
        display: "Status",
        value: "status"
    },
    {
        display: "Assignments",
        value: "core_assignments_to_date",
    },
    {
        display: "Attendance",
        value: "attendance_to_date"
    }
]

export const ATTENDANCE_FILTER_OPTIONS = {
    "status" : ["On Pace", "Falling Behind"],
    "core_assignments_to_date": ["< 60", "< 80", "< 90"],
    
}

export const STATUSES_COLORS = {
    'auditor': '3de20f',
    'on-pace': '5ca049',
    'falling-behind': 'ffff00',
    'ap-warning': 'ff9b00',
    'probation': 'bd8a08',
    'dismissal-review': 'ff0000',
    'expelled': 'ff9b00',
    'ap-warning': 'ff9b00',
}

export const ATTENDANCE_STATUSES = {
    PRESENT: 1,
    ABSENT: 2,
    TARDY: 3,
    UNKNOWN: 4,
    EXCUSED: 5,
    UNSUBMITTED: 6,
}

export const INSTRUCTOR_FLAGS = {
    INSTRUCTOR_FLAG_NONE: 0,
    INSTRUCTOR_FLAG_BLUE: 1,
    INSTRUCTOR_FLAG_GREEN: 2,
    INSTRUCTOR_FLAG_YELLOW: 3,
    INSTRUCTOR_FLAG_RED: 4,
}

export const INSTRUCTOR_FLAG_COLORS = {
    INSTRUCTOR_FLAG_NONE: "none",
    INSTRUCTOR_FLAG_YELLOW: "yellow",
    INSTRUCTOR_FLAG_GREEN: "green",
    INSTRUCTOR_FLAG_RED: "red",
    INSTRUCTOR_FLAG_BLUE: "blue",
    INSTRUCTOR_FLAG_ERROR: "error"
}


export const SHEETS = {
    ASSIGNMENTS: 1,
    SEM: 2,
    ATTENDANCE: 3,
    EXAMS: 4,
    INSTRUCTOR: 5,
}