import React from 'react';

const AssignAttendAssignmentsList = ({
        calendars,
        close
}) => { 

    const { studentCalendar } = calendars;

    // divide assignments into types and status
    let expectedAssignments = {
        core: {
            ontime: [],
            late: [],
            unsubmitted: []
        },
        practice: {
            ontime: [],
            late: [],
            unsubmitted: []
        },
        optional: {
            ontime: [],
            late: [],
            unsubmitted: []
        }
    };

    // go through student calendar add assignments to expectedAssignments
    for ( const [key, day] of Object.entries(studentCalendar) ) {
        for ( const [key_b, assignment] of Object.entries(day.assignments) ) { 
            expectedAssignments[assignment['type']][assignment.onTimeStatus].push(assignment.assignment);
        }
    }
   
    return (
        <div className='assign_attend_assignments_list row layer layer-1 window_1200'>
            {
                ['core', 'practice', 'optional'].map( (type) => 
                    <>
                        <div className='col-12 text-center'>
                            <h4 className='layer layer-2'>{type.toUpperCase()}</h4>
                        </div>
                        {
                            ['ontime', 'late', 'unsubmitted'].map( (status) => 
                                <div className='col-4'>
                                    <div className='mb-2'><u>{status.toUpperCase()}</u></div>
                                    {
                                        expectedAssignments[type][status].map( (assignment) =>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {assignment.title}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        
                    </>
                )
            }
            <div className='col-12 text-center'>
                <button
                    className='btn btn-primary'
                    onClick={close}
                >Close</button>
            </div>
        </div>
    )
}

export default AssignAttendAssignmentsList;