/*
    Handles displaying a student's assignments for a given week
    if student === null, then it displays the filters filter options instead
*/

import React, { useState, useEffect, useContext } from 'react';
import { ASSIGNMENT_TYPES, ONTIME_STATUSES } from './AssignmentAttendanceSheet';
import ReviewTab from './ReviewTab';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import FiltersSelector from './FiltersSelector';
import ReviewsFilter from '../filter_sorter/filters/ReviewsFilter';
import AssignmentSorter from '../filter_sorter/sorters/AssignmentSorter';
import { SORT_TYPES } from '../filter_sorter/sorters/Sorter';
import { getPercentColor } from '../my_cohort_elements/PercentDisplay';
import { ASSIGNMENT_REVIEW_STATUS } from '../../../helpers/Constants';
import ActionButtons, { BasicActionButton, Label, ICONS, SortButton } from '../../elements/ActionButtons';
import useOverlays from '../../../hooks/useOverlays';
import useStudent from '../../../hooks/useStudent';
import MyCohortContext from '../../../store/MyCohortContext';
import SubstantiveInteraction from '../substantive_interaction/SubstantiveInteraction';

const AssignAttendStudentWeek = ({ 
    student,
    studentsData,
    selectedAssignmentTypes,
    weekStartDate,
    downloadAssignments,
    setShowAssignmentStatsId,

    weekNumber = null,
    isCurrentWeek = false,
    isStatsRow = false,

    isViewWeek = false,
    toggleViewWeek = null,

    setShowWeekStatsStartDate = null,

}) => {

    const { popOverlay } = useOverlays();
    const { cohortCalendar } = useContext(MyCohortContext);

    let assignments = {};

    if(student === null){
        // extract assignments from the cohortCalendar
        for ( const [k, day] of Object.entries(cohortCalendar[[weekStartDate]])) {
            for ( const assignment of day.assignments){
                assignments[assignment.id] = {
                    ...assignment,
                    assignment: assignment
                };
            }
        }
       
    } else {
        // assignments need to be pulled from original list due to updates made by other parts of app
        assignments = {}
        for ( let assignment_id in studentsData[student.id].calendar[weekStartDate].assignments ) {
            assignments[assignment_id] = studentsData[student.id].assignments[assignment_id];
        }
    }

    return (
        <div className={`assign_attend_week`}>
            {   (student === null && !isStatsRow && isViewWeek) &&
                    <div className={`
                        week_count
                        ${isCurrentWeek ? 'bg-info' : ''}
                        `}>
                            <div>
                                <ActionButtons
                                    buttons={[
                                        <Label
                                            text={`${weekStartDate.slice(5, 7)}/${weekStartDate.slice(8, 10)}/${weekStartDate.slice(2, 4)}`}
                                            onClick={toggleViewWeek}
                                            bgColor='success'
                                            />
                                    ]}
                                />
                            </div>
                    </div>
            }

            {
                (student === null && !isViewWeek && !isStatsRow ) &&
                    <div className={`
                        text-center
                        ${isCurrentWeek ? 'bg-info' : ''}
                    `}>
                        <span className='ml-2'>
                            <ActionButtons
                                buttons={[
                                    <Label
                                        text={`Week ${weekNumber}`}
                                        onClick={toggleViewWeek}
                                        />
                                ]}
                                />
                        </span>
                        <div className='font-weight-bold sub_note'>
                            {weekStartDate.slice(5, 7)}/{weekStartDate.slice(8, 10)}/{weekStartDate.slice(2, 4)}
                        </div>
                    </div>
            }
            <div className={`
                assign_attend_week__breakdown
                ${!isViewWeek && student && !student[`week_${weekNumber}_substantive_interaction`] && weekNumber < student.current_week ? 'substantive_interaction_collapsed_week--missing' : ''}
                `}>
                {
                    (isStatsRow && !isViewWeek) ? // display week stats button instead of all 3 week segments
                        <AssignAttendAStudentWeekSegment
                            student={student}
                            weekNumber={weekNumber}
                            assignments={assignments}
                            assignmentType={ASSIGNMENT_TYPES.CORE}
                            downloadAssignments={downloadAssignments}
                            setShowAssignmentStatsId={setShowAssignmentStatsId}
                            isStatsRow={isStatsRow}
                            isViewWeek={isViewWeek}
                            toggleViewWeek={toggleViewWeek}
                            setShowWeekStatsStartDate={() => setShowWeekStatsStartDate(weekStartDate)}
                            />

                        :
                        
                    <>
                        {
                            selectedAssignmentTypes.indexOf(ASSIGNMENT_TYPES.CORE) > -1 &&
                            <AssignAttendAStudentWeekSegment
                                student={student}
                                weekNumber={weekNumber}
                                assignments={assignments}
                                assignmentType={ASSIGNMENT_TYPES.CORE}
                                downloadAssignments={downloadAssignments}
                                setShowAssignmentStatsId={setShowAssignmentStatsId}
                                isStatsRow={isStatsRow}
                                isViewWeek={isViewWeek}
                                toggleViewWeek={toggleViewWeek}
                                weekStartDate={weekStartDate}
                                />
                        }
                        {
                            selectedAssignmentTypes.indexOf(ASSIGNMENT_TYPES.PRACTICE) > -1 &&
                            <AssignAttendAStudentWeekSegment
                                student={student}
                                weekNumber={weekNumber}
                                assignments={assignments}
                                assignmentType={ASSIGNMENT_TYPES.PRACTICE}
                                downloadAssignments={downloadAssignments}
                                isStatsRow={isStatsRow}
                                setShowAssignmentStatsId={setShowAssignmentStatsId}
                                isViewWeek={isViewWeek}
                                weekStartDate={weekStartDate}
                                toggleViewWeek={toggleViewWeek}
                                />
                        }
                        {
                            selectedAssignmentTypes.indexOf(ASSIGNMENT_TYPES.OPTIONAL) > -1 &&
                            <AssignAttendAStudentWeekSegment
                                student={student}
                                weekNumber={weekNumber}
                                assignments={assignments}
                                assignmentType={ASSIGNMENT_TYPES.OPTIONAL}
                                downloadAssignments={downloadAssignments}
                                isStatsRow={isStatsRow}
                                setShowAssignmentStatsId={setShowAssignmentStatsId}
                                isViewWeek={isViewWeek}
                                weekStartDate={weekStartDate}
                                toggleViewWeek={toggleViewWeek}
                                />
                        }

                        
                        {
                        ( student != null && isViewWeek ) &&
                            <div className={`
                                assignment_reviews__column 
                                review_tab__column
                                `}
                            >
                                <div className="d-flex justify-content-center">
                                    <SubstantiveInteraction 
                                        student={student} 
                                        weekNumber={weekNumber}
                                        currentWeek={3}
                                        close={() => popOverlay()}
                                        />
                                </div>
                            </div>
                        }

                    </>
                }
                
            </div>
        </div>
    )
}

export default AssignAttendStudentWeek;

const AssignAttendAStudentWeekSegment = ({
    student,
    weekNumber,
    assignments,
    assignmentType,
    downloadAssignments,
    setShowAssignmentStatsId,
    isStatsRow,
    isViewWeek,
    toggleViewWeek,
    setShowWeekStatsStartDate,
    weekStartDate,
}) => {

    const { pushOverlay, popOverlay } = useOverlays();
    const { updateStudent } = useStudent();
    const { inStatsMode, sheetsData } = useContext(MyCohortContext);

    const [ defaultFilters, setDefaultFilters ] = useState([]); // filters that are applied when the page loads
    
    const [ activeAssignmentId, setActiveAssignmentId ] = useState(null); // the assignment that is currently being filtered by
    
    const [ sortAssignmentId, setSortAssignmentId ] = useState(null); // the assignment that is currently being sorted by
    const [ sortDirection, setSortDirection ] = useState(null); // the direction that the assignment is currently being sorted by

    useEffect(() => {

        let filter = filterSorter.getFilter('cohort_reviews_filter');
        if ( filter ) {
            setDefaultFilters(filter.filters);
        } else {
            setDefaultFilters([]);
        }

        filter = filterSorter.getFilter('assignment_reviews_filter');
        if ( filter ) {
            setDefaultFilters(filter.filters);
            setActiveAssignmentId(filter.assignmentId);
        } else {
            setDefaultFilters([]);
            setActiveAssignmentId(null);
        }

        let sorter = filterSorter.getSorter();
        if ( sorter && sorter.name === 'assignment_sorter' ) {

            setSortAssignmentId(sorter.sortPattern.assignment_id);
            setSortDirection(sorter.sortPattern.sortType);
        } else {
            setSortAssignmentId(null);
            setSortDirection(null);
        }

    }, [filterSorter.filters, filterSorter.sorter])

    const buildFilter = (filters, isAssignment = false, assignmentId = null) => { console.log(activeAssignmentId)

        // remove other filter so they don't conflict
        if ( isAssignment ) {
            filterSorter.removeFilter('cohort_reviews_filter');
        } else {
            filterSorter.removeFilter('assignment_reviews_filter');
        }

        filterSorter.addFilter(
            new ReviewsFilter(
                isAssignment ? 'assignment_reviews_filter' : 'cohort_reviews_filter', 
                filters, 
                isAssignment ? activeAssignmentId || assignmentId : null
                )
            );
    }

    const onSort = (assignmentId, sortDirection) => {

        setSortAssignmentId(assignmentId);
        setSortDirection(sortDirection);

        if (sortDirection === null) return;

        filterSorter.addSorter(
            new AssignmentSorter(
                {
                    assignment_id: assignmentId,
                    sortType: sortDirection
                })
        )
    }

    const onSetReviewStatus = (assignmentId, reviewStatus) => { console.log(reviewStatus)

        let newAssignments = {...assignments};
        newAssignments[assignmentId].status = reviewStatus;
        updateStudent(student.id, {calendar: newAssignments});
    }

    let totalAssignments = 0;
    let submittedAssignments = 0;

    // filter students assignments by assignment type
    let filteredAssignmentIds = Object.keys(assignments).filter( (assignmentId) => {
        
            if (assignments[assignmentId].type === assignmentType) {
                totalAssignments++;
                if (
                    assignments[assignmentId].onTimeStatus !== ONTIME_STATUSES.UNSUBMITTED
                    && assignments[assignmentId].status !== ASSIGNMENT_REVIEW_STATUS.NEEDS_REVIEW
                    ) { 
                    submittedAssignments++;
                }
                return true;
            }
            
            return false;
    })

    return (
        <>
            {   (student !== null && isViewWeek) && // review tabs
                <div className={`
                    ${ filteredAssignmentIds.length === 0 ? '' : 'assign_attend_week__segment' } 
                `}>
                {
                    filteredAssignmentIds.length === 0 ?
                    <></>
                    :
                    filteredAssignmentIds.map( (assignmentId, index) => { 
                        let assignment = assignments[assignmentId];
                        return(
                            <ReviewTab
                                key={index}
                                weekNumber={weekNumber}
                                assignment={assignment}
                                review={assignment.review}
                                reviewStatus={assignment.status}
                                setReviewStatus={onSetReviewStatus}
                                student={student}
                                setSelectedStudent={() => {}}
                                filtered={activeAssignmentId === assignmentId}
                                assignmentType={assignmentType}
                                displayDaysOffset={inStatsMode}
                                sheetsData={sheetsData}
                                />
                        )
                    })
                }
                </div>
            }

            {
                (student !== null && !isViewWeek && filteredAssignmentIds.length > 0) && // display assignment types on collapsed week
                <div 
                    className={`
                        filter_sorter_btn__shape 
                        filter_sorter_btn__shape--${assignments[parseInt(filteredAssignmentIds[0])].type === 'core' ? 'square' : assignments[parseInt(filteredAssignmentIds[0])].type === 'practice' ? 'diamond' : 'circle'}
                        text-center
                        mt-1
                        assign_attend_week__segment__shape
                    `}
                    style = {{
                        "backgroundColor": `#${
                            new Date() < new Date(weekStartDate) && submittedAssignments == 0 ? 
                                'cfcfcf'
                            :
                            getPercentColor(submittedAssignments/totalAssignments, .5, .9)
                        }`,
                        "opacity": .7,
                        "border": "1px solid black"
                    }}
                    data-toggle="tooltip" 
                    title={`${student.first_name} ${student.last_name}\n${assignments[parseInt(filteredAssignmentIds[0])].type} - ${parseInt(submittedAssignments /totalAssignments * 100)}% \nclick to open week`}
                    onClick={toggleViewWeek}
                    >
                </div>
            }

            {
                (student !== null && !isViewWeek && filteredAssignmentIds.length === 0) && // no assignments for an assignment type
                <div 
                    className={`
                        text-center
                        mt-1
                        
                    `}
                    >
                    </div>
            }

            {
                (student === null && !isStatsRow && isViewWeek)&& // display sort/filter options
                    <div className={`
                        ${ filteredAssignmentIds.length === 0 ? '' : 'assign_attend_week__segment' } 
                        assign_attend_week__segment--filters`}>
                            
                        <div className='assign_attend_week__segment--filters_assignments'>
                            {
                                filteredAssignmentIds.length === 0 ?
                                <></>
                                :
                                <>
                                {
                                    filteredAssignmentIds.map( (assignmentId, index) => {
                                        return (
                                            <div className={`
                                                    assignment_reviews__column 
                                                    review_tab__column--header
                                                    ${activeAssignmentId === assignmentId ? 'assignment_reviews__column--filtered' : ''}
                                                `}>
                                                    <div className='assignment_reviews__column--assignment_title'>
                                                        <a 
                                                            href={`https://login.codingdojo.com/m/${assignments[assignmentId]['track_id']}/${assignments[assignmentId]['chapter_id']}/${assignments[assignmentId]['id']}`} 
                                                            target='_blank'
                                                            data-toggle="tooltip" 
                                                            title={assignments[assignmentId].title}
                                                            >
                                                                    {assignments[assignmentId].title}
                                                                </a>
                                                    </div>
                                                    <ActionButtons
                                                        buttons={[
                                                            BasicActionButton({
                                                                icon: ICONS.FILTER,
                                                                tooltip: 'Filter',
                                                                isSelected: assignmentId === activeAssignmentId,
                                                                onClick: (e) =>{
                                                                    pushOverlay({
                                                                        position: {x: e.clientX, y: e.clientY},
                                                                        component: <FiltersSelector
                                                                            currentFilters={defaultFilters}
                                                                            applyFilters={(filters) => { console.log(assignmentId)
                                                                                popOverlay();
                                                                                setActiveAssignmentId(assignmentId);
                                                                                if ( filters ) { // if null, then the user clicked cancel
                                                                                    if ( filters.length === 0 ) { // remove filter if no filters are selected
                                                                                        filterSorter.removeFilter('assignment_reviews_filter');
                                                                                    } else {
                                                                                        buildFilter(filters, true, assignmentId);
                                                                                    }
                                                                                } else {
                                                                                    setDefaultFilters([]);
                                                                                }
                                                                            }}
                                                                        />
                                                                    })
                                                                }
                                                            }),
                                                            SortButton({
                                                                sortType: sortAssignmentId === assignmentId ? sortDirection : null,
                                                                setSortType: () => {
                                                                    onSort(
                                                                        assignmentId,
                                                                        sortDirection === SORT_TYPES.ASC ?
                                                                            SORT_TYPES.DESC
                                                                            :
                                                                            SORT_TYPES.ASC
                                                                    )
                                                                }
                                                            }),
                                                            BasicActionButton({
                                                                icon: ICONS.DOWNLOAD,
                                                                tooltip: 'Download',
                                                                visible: assignmentId === activeAssignmentId,
                                                                isSelected: true,
                                                                onClick: () => downloadAssignments(assignmentId)
                                                            }),
                                                        ]}
                                                    />
                                            </div>
                                        )
                                    })
                                }
                                <div className="assignment_reviews__column review_tab__column--header text-center">
                                    <div className="text-dark">
                                        SI
                                    </div>
                                </div>
                                </>
                            }
                        </div>
                    </div>
            }

            { /* add individual assignment stats */
                (inStatsMode && isStatsRow && isViewWeek) && 
                <div className={`
                    ${ filteredAssignmentIds.length === 0 ? '' : 'assign_attend_week__segment' } 
                    assign_attend_week__segment--filters`}>
                        <div className='assign_attend_week__segment--filters_assignments'>
                            {
                                filteredAssignmentIds.length === 0 ?
                                <></>
                                :
                                filteredAssignmentIds.map( (assignmentId, index) => {
                                    return (
                                        <div className={`
                                                assignment_reviews__column 
                                                review_tab__column--header
                                                text-center
                                                ${activeAssignmentId === assignmentId ? 'assignment_reviews__column--filtered' : ''}
                                            `}>
                                                <ActionButtons
                                                    buttons={[
                                                        BasicActionButton({
                                                            icon: ICONS.STATS,
                                                            tooltip: 'Stats',
                                                            inStatsMode: true,
                                                            onClick: (e) => setShowAssignmentStatsId(assignmentId, e)
                                                        }),
                                                    ]}
                                                />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }


            { /* add week assignment stats */
                (inStatsMode && isStatsRow && !isViewWeek) &&
                <div className={`
                        assignment_reviews__column 
                        review_tab__column--header
                        text-center
                    `}>
                        <ActionButtons
                            buttons={[
                                Label({
                                    text: 'Stats',
                                    inStatsMode: true,
                                    onClick: () => setShowWeekStatsStartDate(weekStartDate)
                                })
                            ]}
                        />
                </div>
            }

            {
                (!isViewWeek && !student) && // empty sorter/filter row when week is closed
                    <div >
                    </div>
            }
        </>
    )
}