import TABService from './TABService';

class SEMService extends TABService {

    model = 'sem'

    getSems = () =>
        this._get(
            `${this.model}/get-sems`
        )

    pullStudents = (sem_ids, cohort_ids) =>
        this._post(
            `${this.model}/pull-students`,
            {sem_ids, cohort_ids}
        )
}

export const semService =  new SEMService();