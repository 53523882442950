import TABService from './TABService';

class AlertService extends TABService {

    model = 'alert';

    ALERT_TYPE_NEW_TICKET = 1
    ALERT_TYPE_NEW_COMMENT = 2

    getMy = () => 
        this._get(
            `${this.model}/my`
        )

    clearMy = () => 
    this._get(
        `${this.model}/clear-my`
    )

    clearAlert = ( alertId ) => 
        this._get(
            `${this.model}/clear/${alertId}`
        )

    
}

export default new AlertService();