/*
    Handles updating and saving student data.
    update = only updates the UI
    save = updates the UI and saves to the backend
*/

import  eventHandler, { UPDATE_STUDENT, UPDATE_SHEET_STUDENTS } from '../events';
import helpers from "../helpers/Helpers";
import { INSTRUCTOR_FLAGS } from "../helpers/Constants";
import { RollbackJSONModel } from "../components/my_cohort/sem_sheet/RollbacksModal";
import { DATA_ISSUES } from "../components/my_cohort/MyCohort";
import { ONTIME_STATUSES } from '../components/my_cohort/assignments_sheet/AssignmentAttendanceSheet';
import { ASSIGNMENT_REVIEW_STATUS, ATTENDANCE_STATUSES } from '../helpers/Constants';

const useStudent = () => {

    // updates local copy only
    const updateStudent = (student) => {
        eventHandler.dispatch(UPDATE_STUDENT,{student , localOnly: true})
    }

    const updateMultipleStudents = (students, callback = null) => {

        eventHandler.dispatch(UPDATE_SHEET_STUDENTS, {
            localOnly: true,
            callback,
            students
        })
    }

    // updates local copy and saves to backend
    const saveStudent = (student) => {
        eventHandler.dispatch(UPDATE_STUDENT,{student, localOnly: false})
    }
    
    const saveMultipleStudents = (students, callback = null) => {

        eventHandler.dispatch(UPDATE_SHEET_STUDENTS, {
            localOnly: false,
            callback,
            students
        })
    }

    // Process raw student data from backend
    const processStudent = (student, cohortCalender = null) => { 

        // privacy mode updates
        if ( window.localStorage.getItem('is_in_privacy_mode') === 'true' ) {
            student.email_address = `${helpers.generateUUID()}@somemail.com`;
            student.last_name = student.last_name.substring(0,1) + ".";
            student.name = student.first_name;
            student.full_name = student.first_name;
        }

        // modify / extract student data
        student.cohort_start_date_short = new Date(student.cohort_start_date).toISOString().slice(0, 10);
        student.cohort_start_date = helpers.dateToUTC(new Date(student.cohort_start_date));

        student.weekCount = Object.keys(cohortCalender).length - 1; // -1 to account for the current_week entry
        student.current_week = cohortCalender.current_week;
        // delete cohortCalender.current_week;
        student.attempt_order = student.attempt_order > 0 ? student.attempt_order : null;
        student.notes = student.notes && student.notes.trim().length > 0 ? student.notes :  null;
        student.logNote = null; // when calling updateStudent, any note here will be logged to the student
        student.isSelected = false; // set when the user row is checkmarked in the roster
        student.isUpdating = false;
        student.substantive_interactions = student.substantive_interactions ? JSON.parse(student.substantive_interactions) : {};
        
        // note: student.rollbacks is the number of rollbacks after all waivers
        if ( student.rollbacks_json === null ||  student.rollbacks_json === undefined) {
            student.rollbacks_json = {...RollbackJSONModel}
        } else {
            student.rollbacks_json = typeof student.rollbacks_json ==="string" ? 
                JSON.parse(student.rollbacks_json) : student.rollbacks_json
        }

        student.data_issues = []
        if ( student.sem_name === null ) student.data_issues.push(DATA_ISSUES.NO_SEM);
        if (  student.stack_progress === null || student.stack_progress === 'None' || JSON.parse(student.stack_progress).length === 0  ) student.data_issues.push(DATA_ISSUES.NO_STACK_PROGRESSION);

        // build calendar for each student
        // memoize submitted assignments
        let assignments = {};
        student.assignments.forEach(assignment => {
            assignments[assignment.assignment.chapter_module_id] = assignment;
        })

        // add attributes for weekly TO DATE calculations 
        for ( let wk = 0; wk < student.weekCount; wk++ ) 
            student[`week_${wk + 1}_to_date`] = {
                submits: 0,
                due: 0
            };
        
        let calendar = {};
        let weekCount = 0;

        // set defaults for missing data
        
        student.instructor_flags = student.instructor_flags ? 
            JSON.parse(student.instructor_flags) : 
            {
                status: INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_NONE,
                comment: "",
                check_1: false,
                check_2: false,
            }
        student.instructorFlagStatus = student.instructor_flags.status;
        student.instructorFlagCheck1 = student.instructor_flags.check_1;
        student.instructorFlagCheck2 = student.instructor_flags.check_2;

        // used to calculate core assignment and attendance percent for the week.
        let runningAttendanceCount = 0;
        let runningAttendancePresentCount = 0;
        let runningDue = 0;
        let runningMissedAttendance = 0; // keep track of the number of missed attendances in a row; resets when the student attends

        console.log(student, assignments)
        // calculate core assignment percent for the week
        if ( cohortCalender ) {
            let itCohortCalendar = {...cohortCalender};
            delete itCohortCalendar.current_week;
            Object.keys(itCohortCalendar).forEach(week_date => {

                weekCount++;
                let week = itCohortCalendar[week_date];
                let hasWeekSubstantiveInteraction = student.substantive_interactions[weekCount] !== undefined;

                let newStudentWeek = { // week-by-week breakdown of assignment submissions
                    on_time: {
                        core: 0,
                        practice: 0,
                        optional: 0
                    },
                    late: {
                        core: 0,
                        practice: 0,
                        optional: 0
                    },
                    unsubmitted: {
                        core: 0,
                        practice: 0,
                        optional: 0
                    },
                    total: {
                        core: 0,
                        practice: 0,
                        optional: 0
                    },
                    assignments: {},
                }
                
                // go through all assignments and determine their onTimeStatus
                week.forEach(day => { 
                    day.assignments.forEach(assignment => { console.log(assignments[assignment.id])

                        newStudentWeek.total[assignment.type]++;

                        let dueDate = helpers.dateToUTC(new Date(assignment.dueDate));
                        let now = helpers.dateToUTC(new Date());
                        
                        // add default values
                        let finalAssignment = {
                            ...assignment,
                            review: null,
                            status: 0
                        };

                        // the assignment structure in studentsData is {assignment, {additionalAssignmentsData}}
                        let additionalAssignmentData = {
                            dateSubmitted: null,
                            dueDate: dueDate,
                            onTimeOffset: null,
                            type: assignment.type,
                            dateSubmitted: null,
                            onTimeStatus: ONTIME_STATUSES.UNSUBMITTED,
                            status: 0,
                            review: null
                        }
                        
                        if (assignments[assignment.id] !== undefined) { // student submitted assignment. Update on-time and review statuses
                            
                            let dateSubmitted = new Date(new Date(assignments[assignment.id].assignment.submit_date).toDateString());

                            let onTimeOffset = dueDate - dateSubmitted + 1;
                            
                            let isOntime = onTimeOffset >= 0; 
                            
                            finalAssignment = assignments[assignment.id].assignment;

                            additionalAssignmentData = {
                                ...additionalAssignmentData,
                                dateSubmitted: dateSubmitted,
                                onTimeOffset: onTimeOffset,

                                onTimeStatus: !isOntime ? 
                                    ONTIME_STATUSES.LATE :
                                    dueDate > now ? ONTIME_STATUSES.OUTOFDATE : ONTIME_STATUSES.ONTIME,

                                status: assignments[assignment.id].status,
                                review: assignments[assignment.id].review
                            }
                            
                            if ( isOntime ) { 
                                newStudentWeek.on_time[assignment.type]++;

                                if ( assignment.type === 'core' && assignments[assignment.id].status !== ASSIGNMENT_REVIEW_STATUS.NEEDS_REVIEW ) {
                                    student[`week_${weekCount}_to_date`].submits++;
                                }

                            } else {//if ( student.id === 63032 && assignment.type==='core' ) 
                                newStudentWeek.late[assignment.type]++;

                                if ( assignment.type === 'core' && assignments[assignment.id].status !== ASSIGNMENT_REVIEW_STATUS.NEEDS_REVIEW) {
                                    let weekSubmitted = Math.ceil((dateSubmitted - student.cohort_start_date) / (1000 * 3600 * 24 - 1) / 7);
                                    if ( weekSubmitted === weekCount ) weekSubmitted ++; // add one to push it to the next week if it was submitted late the week it was due
                                    if ( weekSubmitted < student.weekCount){ 
                                        student[`week_${weekSubmitted }_to_date`].submits++;
                                    }
                                    
                                }

                                
                            }

                            // set SI flag if an assignment was reviewed.
                            if ( assignment.type === 'core' && assignments[assignment.id].review !== null && assignments[assignment.id].review.notify_student){
                                hasWeekSubstantiveInteraction = true;
                            }

                        } else { // student did not submit an assignment
                            newStudentWeek.unsubmitted[assignment.type]++;
                        }

                        newStudentWeek.assignments[assignment.id] = {
                            assignment: finalAssignment,
                            ...additionalAssignmentData
                        }; 

                        // save for processing on sheets
                        assignments[assignment.id] = {
                            assignment: finalAssignment,
                            ...additionalAssignmentData
                        }
                    })
                })
                
                runningDue += newStudentWeek.total.core;
                student[`week_${weekCount}_substantive_interaction`] = hasWeekSubstantiveInteraction; // || !student.status_is_active;
                student[`week_${weekCount}_to_date`].due = runningDue;
                student[`week_${weekCount}_on_time_percent`] = newStudentWeek.on_time.core === 0 ? 0 : newStudentWeek.on_time.core / newStudentWeek.total.core;
                calendar[week_date] = newStudentWeek;
            })
        }

        // calculate attendance percent for each week
        weekCount = 0;
        let totalWeekCount = 0; // how many times in the week the student was present
        for( let [date, weekData] of Object.entries(student.attendance_details) ) {
            for ( let [period, periodData] of Object.entries(weekData) ) {
                runningAttendanceCount++;
                if ( periodData.status === ATTENDANCE_STATUSES.PRESENT ){ 
                    runningAttendancePresentCount++;
                    totalWeekCount++;
                    if ( weekCount + 1 < student.current_week ) runningMissedAttendance = 0;
                } else if (
                    periodData.status === ATTENDANCE_STATUSES.ABSENT ||
                    periodData.status === ATTENDANCE_STATUSES.UNSUBMITTED &&
                    weekCount + 1 < student.current_week  // only count up through previous week
                    ) {
                        runningMissedAttendance++;
                    }
                
                
            }   

            weekCount++;
            if ( weekCount === student.current_week ){

            }
            student[`week_${weekCount}_attendance_count`]  = totalWeekCount
            student[`week_${weekCount}_attendance_percent`] = runningAttendancePresentCount === 0 ? 0 : runningAttendancePresentCount / runningAttendanceCount;
            totalWeekCount = 0;
        }

        // calculate weekly assignment percents
        Object.keys(calendar).forEach((week_date, index )=> {
            student[`week_${index + 1}_assignment_percent`] = calendar[week_date].on_time.core === 0 ? 0 : calendar[week_date].on_time.core / calendar[week_date].total.core;
        })

        // assignments to_date and percent
        student[`week_${1}_assignment_percent`] = student[`week_${1}_to_date`].submits === 0 ? 0 : student[`week_${1}_to_date`].submits / student[`week_${1}_to_date`].due;
        if ( student.id == 46823 ) {
            console.log(1, student[`week_${1}_to_date`] )
        }
        for ( let week = 2; week < student.weekCount; week ++) { 
            if ( student.id == 46823 ) {
                console.log(week, student[`week_${week}_to_date`] )
            }
            student[`week_${week}_to_date`].submits += student[`week_${week - 1}_to_date`].submits;
            student[`week_${week}_assignment_percent`] = student[`week_${week}_to_date`].submits === 0 ? 0 : student[`week_${week}_to_date`].submits / student[`week_${week}_to_date`].due;
        }

        //if ( student.id === 85579 )console.log(student)

        const updatedStudent = {
            ...student,
            assignments: assignments,
            calendar: calendar,
            attendance_percent: parseFloat(student.attendance_percent),
            missed_attendance: runningMissedAttendance, 
            core_assignment_percent: parseFloat(student.core_assignment_percent),
            temp_exam: null, // used for assigning exams before student is updated on backend
            //rollbacks: extractRollbackDataFromStudent(student)['rollbackCount'],
        }

        // post processing
        // console.log("snapshot")
        return {
            ...updatedStudent,
            snapshot: {...updatedStudent}, // used for logging when student is updated
        }
    }

    const addStudentUpdateListener = (callback) => {
        eventHandler.on(UPDATE_STUDENT, callback);
    }

    const removeStudentUpdateListener = (callback) => {
        eventHandler.remove(UPDATE_STUDENT, callback);
    }

    return {
        updateStudent,
        saveStudent,
        processStudent,
        addStudentUpdateListener,
        removeStudentUpdateListener,
        updateMultipleStudents,
        saveMultipleStudents,
    }
}

export default useStudent;