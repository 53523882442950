import React, { useEffect, useState, useContext } from 'react';
import studentService from '../../../../services/StudentService';
import { AppContext } from '../../../../store/AppContext';
import useStudent from '../../../../hooks/useStudent';

const ExamEmail = ({
    students,
    minimumAssignments,
    close,
    dueDate=null,
}) => {

    const { user } = useContext(AppContext);
    const { saveStudent } = useStudent();

    const [ dueDateState, setDueDateState ] = useState(dueDate);
    const [ minimumAssignmentsState, setMinimumAssignmentsState ] = useState(minimumAssignments);
    const [ instructorNote, setInstructorNote ] = useState('');

    const assignExams = () => { // TODO: Update this to use updateStudents instead of each individual saveStudent

        let emailQueueItems = [];

        students.forEach(student => { console.log(student)

            if (student.temp_exam) { 

                emailQueueItems.push({
                    user_id: student.id,
                    exam_code: student.temp_exam.code,
                    minimum_assignments: minimumAssignmentsState,
                    due_date: new Date(dueDateState).toISOString().split('T')[0],
                    stack_id: student.stack_id,
                    note: instructorNote
                })

                student.assigned_exam = {
                    ...student.temp_exam,
                    added_by:`${user.first_name} ${user.last_name}`,
                    added_on: new Date().toISOString().split('T')[0]
                };
                student.temp_exam = null;

                saveStudent(student);

                // eventHandler.dispatch(UPDATE_STUDENT,{student: student, localOnly: false})

                // if (assignExamToStudent) assignExamToStudent(student);
            }
        });

        if ( emailQueueItems.length > 0)
            studentService.sendExamEmails(emailQueueItems)
                .then( () => {
                })
                .catch( err => console.log(err))

        close();
    }

    return (
        <div className="row layer layer-1">
            {/* <div className='col-12 text-center mb-2'>
                <input  
                    type="checkbox"
                    checked={sendEmails}
                    onChange={(e) => setSendEmails(e.target.checked)}
                    />
                    <span className="ml-2">Send Emails</span>
            </div> */}

            <>
                <div className="col-4">
                    Minimum Assignments:
                </div>
                <div className="col-6">
                    <input 
                        type="number"
                        min={1}
                        value={minimumAssignmentsState}
                        onChange={(e) => setMinimumAssignmentsState(e.target.value)}
                    />
                </div>
                <div className="col-4">
                    Due Date:
                </div>
                <div className="col-6">
                    <input 
                        type="date"
                        value={dueDateState.toISOString().split('T')[0]}
                        onChange={(e) => setDueDateState(new Date(e.target.value))}
                    />
                </div>
                <div className="col-4">
                    Note: ( optional )
                </div>
                <div className="col-6">
                    <textarea
                        onChange={(e) => setInstructorNote(e.target.value)}
                        value={instructorNote}
                        rows={4}
                        cols={30}
                    />
                </div>
            </>

            <div className="col-12 text-center">
                <button
                    className="btn btn-success mr-4"
                    onClick={close}>Cancel</button>

                <button
                    className="btn btn-primary btn-sm"
                    disabled={
                        dueDateState === null || minimumAssignmentsState === 0
                    }
                    onClick={assignExams}
                >
                    Assign Exams to {students.length} Student{students.length > 1 ? 's' : ''}
                </button>
            </div>
        </div>
    )
}

export default ExamEmail;