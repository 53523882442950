import React, { useState } from "react";
import { ASSIGNMENT_TYPES } from "../AssignmentAttendanceSheet";
import AssignmentStats from "./AssignmentStats";

const CohortAssignmentStats = ({
    studentsData,
    close,
    weekStartDate = null // set to date to display weekly stats
}) => { console.log(studentsData)
    
    const [ selectedAssignmentTypes, setSelectedAssignmentTypes ] = useState([ASSIGNMENT_TYPES.CORE]);

    // separate assignments into types
    // need a sample of the assignments to sort into types
    let sample = studentsData[Object.keys(studentsData)[0]].assignments;
    let selectedAssignments = {};
    Object.keys(sample).forEach( assignmentId => {

        
        if ( selectedAssignmentTypes.includes(sample[assignmentId].type) ) { 
            let keep = true
            // check date
            if ( weekStartDate ) {
                weekStartDate = new Date(weekStartDate);
                let assignmentDate = new Date(sample[assignmentId].dueDate);
                console.log(sample[assignmentId].assignment)
                if ( assignmentDate < weekStartDate || assignmentDate > new Date(weekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000) ) keep = false
                console.log(keep)
            }

            if ( keep )
                selectedAssignments[assignmentId] = {
                    ...sample[assignmentId],
                    filteredStudentsData: []
                };
        }
    });

    // go through studentsData
    for ( const [key, value] of Object.entries(studentsData) ) {
        let assignments = value.assignments;
        Object.keys(assignments).forEach( assignmentId => {
            if ( selectedAssignments[assignmentId] ) 
                selectedAssignments[assignmentId].filteredStudentsData.push(value);
        })

    }

    return (
        <div id="cohort_assignment_stats" className='row layer layer-1 window_1200'>
            <div className='col-12 text-center'>
                <button
                    className="btn btn-sm btn-success"
                    onClick={() => setSelectedAssignmentTypes([ASSIGNMENT_TYPES.CORE])}
                    disabled={selectedAssignmentTypes.includes(ASSIGNMENT_TYPES.CORE)}
                >Core</button>
                <button
                    className="btn btn-sm btn-success ml-2"
                    onClick={() => setSelectedAssignmentTypes([ASSIGNMENT_TYPES.PRACTICE])}
                    disabled={selectedAssignmentTypes.includes(ASSIGNMENT_TYPES.PRACTICE)}
                >Practice</button>
                <button
                    className="btn btn-sm btn-success ml-2"
                    onClick={() => setSelectedAssignmentTypes([ASSIGNMENT_TYPES.OPTIONAL])}
                    disabled={selectedAssignmentTypes.includes(ASSIGNMENT_TYPES.OPTIONAL)}
                >Optional</button>
                <button
                    className="btn btn-sm btn-info ml-5"
                    onClick={close}
                >Close</button>
            </div>
            <div className="col-12">
                <div className="row">
                    {
                        Object.keys(selectedAssignments).map( assignmentId => 
                            <div className="col-6 cohort_assignment_stats__stat_box">
                                <AssignmentStats
                                    key={assignmentId}
                                    assignmentId={assignmentId}
                                    filteredStudentsData={selectedAssignments[assignmentId].filteredStudentsData}
                                />
                            </div>
                        )
                    }
                </div>
            </div>  
        </div>
    )

}

export default CohortAssignmentStats;