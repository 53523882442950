import axios from 'axios';
import { config } from '../config'

const axiosInstance = axios.create({
    baseURL: config.API_URL,
    timeout: 0,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'withCredentials': true
    }
});

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
        if (error.response.status === 401) { // && error.response.statusText === "Unauthorized"
            const refresh_token = window.localStorage.getItem('refresh_token');
            try {
                const response = await axiosInstance.post('/auth/refresh-token', { refresh: refresh_token });
                window.localStorage.setItem('access_token', response.data.auth_token);
                window.localStorage.setItem('refresh_token', response.data.refresh_token);
                /*axiosInstance.defaults.headers['Authorization'] = response.data.access;*/
                originalRequest.headers['Authorization'] = response.data.auth_token;
                return axiosInstance(originalRequest);
            }
            catch (err) {
                
                console.log(err);
                window.localStorage.removeItem('access_token');
                window.localStorage.removeItem('refresh_token');

                window.location.href='/';
            }
        }
      return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
    config => {
      config.headers['Authorization'] = window.localStorage.getItem('access_token');
          return config;
      },
      error => {
          return Promise.reject(error);
      }
  );

export default axiosInstance;