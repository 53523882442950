/* renamed to Instructor on the sheets */

import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import ValueSorter from '../filter_sorter/sorters/ValueSorter';
import { SORT_TYPES } from '../filter_sorter/sorters/Sorter';
import PercentDisplay from '../my_cohort_elements/PercentDisplay';
import SimpleCheckColumn from '../sem_sheet/SimpleCheckColumn';
import ValueSortSelector from '../my_cohort_elements/ValueSortSelector';
import ValuesFilter from '../filter_sorter/filters/ValuesFilter';
import { studentService } from '../../../services/StudentService';
import StudentRowAttendance from '../my_cohort_elements/StudentRowAttendance';
import ActionButtons, { ICONS, BasicActionButton, SortButton, Label } from '../../elements/ActionButtons';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import useStudent from '../../../hooks/useStudent';
import SimpleStudentHeader from '../my_cohort_elements/SimpleStudentHeader';
import MyCohortContext from '../../../store/MyCohortContext';
import useRoster from '../../../hooks/useRoster';
import CertificateData from './CertificateData';

const InstructorSheet = ({
}) => {

    const { pushOverlay, popOverlay } = useOverlays();
    const { saveStudent } = useStudent();
    const {
        sortedRoster,
        studentsData,
        inStatsMode,
        cohortInfo,
    } = React.useContext(MyCohortContext);
    const { 
        checkStudentSelected,
        hoveredStudentId,
        setHoveredStudentId,
        clearHoveredStudent,
    } = useRoster();

    const [sorter, setSorter] = useState(null);
    const [percentsSortType, setPercentsSortType] = useState('to-date');
    const [ filters, setFilters ] = useState({}); // key == filter name, value == filter object
    const [reminders, setReminders] = useState({});

    const onSortHandler = ( name, column, sortType) => {
        filterSorter.addSorter(
            new ValueSorter(name, column, sortType)
        );}

    useEffect(() => {

        setSorter(filterSorter.getSorter());

        setFilters(
            // if no filters, or filters length is 0, set to null
            filterSorter.settings.sem_filters !== undefined && Object.keys(filterSorter.settings.sem_filters).length > 0 ? 
            filterSorter.settings.sem_filters : {}
        )

        if (
            'pt_percent_sort_type' in filterSorter.settings
        ){ 
            setPercentsSortType(filterSorter.settings.pt_percent_sort_type);
        }
        else setPercentsSortType('to-date');

        // build reminders
        let tReminders = {};
        sortedRoster.forEach((student) => { console.log(student.current_week)
            tReminders[student.id] = 
                student.current_week > 0 && student.status_is_active ?
                    {
                        assignments: !(student[`week_${student.current_week}_to_date`]['submits'] >= student[`week_${student.current_week}_to_date`]['due']),
                        attendance: student[`week_${student.current_week}_attendance_count`] != 2,
                    }
                    : 
                    {
                        assignments: false,
                        attendance: false,
                    }
        })

        console.log(tReminders);

        if ( Object.keys(tReminders).length > 0 ) setReminders(tReminders);

    }, [sortedRoster]);

    const assignSorter = ( name, column, sortType ) => 
        filterSorter.addSorter(
            new ValueSorter(
                    name,
                    column,
                    sortType
                )
        );

    const addFilter = ( filter ) => {
        filterSorter.addFilter(filter);
        filterSorter.setSettings({
            sem_filters: {
                ...filters,
                [filter.name]: filter
            }
        })
    }

    const removeFilter = ( name ) => {
        filterSorter.removeFilter(name);

        let tFilters = {...filters};
        delete tFilters[name];
        
        if ( tFilters.length > 0 ) {
            filterSorter.setSettings({
                sem_filters: tFilters
            });
        }
        else { 
            filterSorter.deleteSetting('pt_filters');
        }
    }

    const onSendWeeklyReminders = (e) => {

        let emailsToSend = [];
        let finalActiveStudentCount = 0;

        sortedRoster.forEach((student) => {

            if ( student.status_is_active && reminders[student.id].assignments || reminders[student.id].attendance ) {
                finalActiveStudentCount++;
                emailsToSend.push({
                    student_email: student.email_address,
                    first_name: student.first_name,
                    instructor_name: student.instructor_name,
                    instructor_email: student.instructor_email_address,
                    assignments_completed: !reminders[student.id].assignments,
                    attendance_completed: !reminders[student.id].attendance,
                    is_up_to_date: !reminders[student.id].assignments && !reminders[student.id].attendance,
                });

            }
        })
        console.log(emailsToSend)

        if ( emailsToSend.length == 0 ) {
            pushOverlay({
                type: OVERLAY_TYPES.ALERT,
                component: <>There are no reminders to send.</>,
                respond: popOverlay
            })
        } else {
            pushOverlay({
                type: OVERLAY_TYPES.CONFIRM,
                position: {x: e.clientX, y: e.clientY},
                component: <div dangerouslySetInnerHTML={{__html: `Are you sure you want to send ${emailsToSend.length} emails?`}} />,
                respond: (response) => {

                    if ( response )
                        studentService.sendEOWReminderEmails(emailsToSend)
                            .then( () => {
                                pushOverlay({
                                    type: OVERLAY_TYPES.ALERT,
                                    position: {x: e.clientX, y: e.clientY},
                                    component: <>Emails sent.</>,
                                })
                            })
                            .catch( err => {
                                window.alert("Error sending emails.")
                            })
                }
            })

            
        }
    }

    const onDownloadWeeklyReminderEmails = () => {

        let message = ""

        sortedRoster.forEach((student) => {
            if ( student.status_is_active && reminders[student.id].assignments || reminders[student.id].attendance ) {
                message += `${student.first_name} ${student.last_name} - ${student.email_address} - `;
                if ( reminders[student.id].assignments && reminders[student.id].attendance ) {
                    message += " Both";
                } else {
                    message += reminders[student.id].assignments ? " Assignments " : "";
                    message += reminders[student.id].attendance ? " Attendance " : "";
                }
            }
            message += "\n\n";
        })

        navigator.clipboard.writeText(message);
        window.alert("List copied to clipboard.");
    }

    if (Object.keys(reminders) == 0) return (<></>);

    return (
        <>
            <div className='my_cohort__right_panel'>
                <div className='my_cohort__filters_bar  '>
                    <div className='my_cohort__filters_bar__header'>
                        {
                            cohortInfo.stack_id == 9 &&
                                <ActionButtons
                                    buttons={[
                                        Label({
                                            text: "Certificate Data",
                                            onClick: (e) =>
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.PALETTE,
                                                    position: {x: e.clientX, y: e.clientY},
                                                    component: <CertificateData />
                                                })
                                        })
                                    ]}
                                />
                        }
                    </div>
                    <div className="sem_data__filter_row ">
                        <div className="sem_data__column sem_data__filter_column sem_data__column--200">
                            <div>
                                <select
                                    onChange={(e) => {
                                        setPercentsSortType(e.target.value);
                                        filterSorter.settings['pt_percent_sort_type'] = e.target.value;
                                    }}
                                >
                                    <option value="to-date" selected={percentsSortType === 'to-date'}>To Date</option>
                                    <option value="overall" selected={percentsSortType === 'overall'}>Overall</option>
                                </select>
                            </div>
                            <div className="sem_data__column  sem_data__column--200">
                                <div className="sem_data__column sem_data__filter_column">
                                    <div className="sem_data__column__title">
                                        Assignments
                                    </div>

                                    <ActionButtons
                                        buttons={[
                                            BasicActionButton({
                                                icon: ICONS.FILTER,
                                                isSelected: filterSorter.getFilter("pt_assignments_percent") !== undefined,
                                                onClick: (e) => 
                                                    pushOverlay({
                                                        position: {x: e.clientX, y: e.clientY},
                                                        component: <ValueSortSelector
                                                            close={() => popOverlay()}
                                                            columnValueName={
                                                                percentsSortType === 'to-date' ? 
                                                                    'core_assignments_to_date_percent'
                                                                    :
                                                                    'core_assignments_overall_percent'
                                                            }
                                                            studentsData={studentsData}
                                                            isPercent={true}
                                                            filterValues={filters["pt_assignments_percent"] ? filters["pt_assignments_percent"].values : []}
                                                            setFilterValues={(values) => {
                                                                if (values.length > 0) {
                                                                    popOverlay();
                                                                    addFilter(
                                                                        new ValuesFilter(
                                                                            "pt_assignments_percent",
                                                                            values,
                                                                            percentsSortType === 'to-date' ? 
                                                                                'core_assignments_to_date_percent'
                                                                                :
                                                                                'core_assignments_overall_percent'
                                                                        )
                                                                    );
                                                                } else {
                                                                    // filters were cleared
                                                                    popOverlay();
                                                                    removeFilter("pt_assignments_percent");
                                                                }
                                                            }}
                                                        />
                                                    })
                                                ,
                                            }),
                                            BasicActionButton({
                                                icon: ICONS.CANCEL,
                                                isSelected: true,
                                                visible: filterSorter.getFilter("pt_assignments_percent") !== undefined,
                                                onClick: () => removeFilter("pt_assignments_percent")
                                            }),
                                            SortButton({
                                                sortType: sorter && sorter.name === "pt_assignments_percent" ? sorter.sortType : null,
                                                setSortType: () => {
                                                    assignSorter(
                                                        'pt_assignments_percent',
        
                                                        percentsSortType === 'to-date' ? 
                                                                'core_assignments_to_date_percent'
                                                                :
                                                                percentsSortType === 'overall' ?
                                                                    'core_assignments_overall_percent'
                                                                    :
                                                                    `week_${percentsSortType.split('_')[1]}_assignment_percent`,
        
                                                        sorter && sorter.sortType === SORT_TYPES.ASC
                                                            ? SORT_TYPES.DESC
                                                            : SORT_TYPES.ASC
                                                    )
                                                }
                                            })
                                        ]}
                                    />
                                </div>

                                <div className="sem_data__column sem_data__filter_column">
                                    <div className="sem_data__column__title">
                                        Attendance
                                    </div>

                                    <ActionButtons
                                        buttons={[
                                            BasicActionButton({
                                                icon: ICONS.FILTER,
                                                isSelected: filterSorter.getFilter("pt_attendance_percent") !== undefined,
                                                onClick: (e) => 
                                                    pushOverlay({
                                                        position: {x: e.clientX, y: e.clientY},
                                                        component: <ValueSortSelector
                                                            close={() => popOverlay()}
                                                            columnValueName={
                                                                percentsSortType === 'to-date' ? 
                                                                    'attendance_to_date_percent'
                                                                    :
                                                                    'attendance_overall_percent'
                                                            }
                                                            studentsData={studentsData}
                                                            isPercent={true}
                                                            filterValues={filters["pt_attendance_percent"] ? filters["pt_attendance_percent"].values : []}
                                                            setFilterValues={(values) => {
                                                                if (values.length > 0) {
                                                                    popOverlay();
                                                                    addFilter(
                                                                        new ValuesFilter(
                                                                            "pt_attendance_percent",
                                                                            values,
                                                                            percentsSortType === 'to-date' ? 
                                                                                'attendance_to_date_percent'
                                                                                :
                                                                                'attendance_overall_percent'
                                                                        )
                                                                    );
                                                                } else {
                                                                    // filters were cleared
                                                                    popOverlay();
                                                                    removeFilter("pt_assignments_percent");
                                                                }
                                                            }}
                                                        />
                                                    })
                                                ,
                                            }),
                                            BasicActionButton({
                                                icon: ICONS.CANCEL,
                                                isSelected: true,
                                                visible: filterSorter.getFilter("pt_attendance_percent") !== undefined,
                                                onClick: () => removeFilter("pt_attendance_percent")
                                            }),
                                            SortButton({
                                                sortType: sorter && sorter.name === "pt_attendance_percent" ? sorter.sortType : null,
                                                setSortType: () => {assignSorter(
                                                    'pt_attendance_percent',
    
                                                    percentsSortType === 'to-date' ? 
                                                            'attendance_to_date_percent'
                                                            :
                                                            percentsSortType === 'overall' ?
                                                                'attendance_overall_percent'
                                                                :
                                                                `week_${percentsSortType.split('_')[1]}_attendance_percent`,
    
                                                    sorter && sorter.sortType === SORT_TYPES.ASC
                                                        ? SORT_TYPES.DESC
                                                        : SORT_TYPES.ASC
                                                )
                                                }
                                            })
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="sem_data__column sem_data__filter_column sem_data__column--200">
                            <div>
                                <button
                                    className='btn btn-sm btn-info'
                                    onClick={onSendWeeklyReminders}
                                    >
                                        Send Reminders
                                    </button>
                                    
                                    <span className='ml-1'>
                                        <ActionButtons
                                            buttons={[
                                                BasicActionButton({
                                                    icon: ICONS.OPEN_TAB,
                                                    toolTip: "Copy To Clipboard",
                                                    onClick: onDownloadWeeklyReminderEmails,
                                                }), 
                                            ]}
                                        />
                                    </span>
                            </div>
                            <div className="sem_data__column   sem_data__column--200">
                                <span className="sem_data__column sem_data__filter_column">
                                    <div className='sem_data__column__title'>
                                        Assignments
                                    </div>
                                </span>

                                <span className="sem_data__column sem_data__filter_column">
                                    <div className='sem_data__column__title'>
                                        Attendance
                                    </div>
                                </span>
                            </div>
                        </div> */}

                        <div className='sem_data__column sem_data__filter_column'>
                            <div className='sem_data__column__title'>
                                TA Check 
                            </div>
                            <ActionButtons
                                buttons={[
                                    BasicActionButton({
                                        icon: sorter && sorter.name === "instructor_ta_check" ? ICONS.CANCEL :  ICONS.CHECKMARK,
                                        isSelected: sorter && sorter.name === "instructor_ta_check",
                                        onClick: () => {
                                            !sorter || sorter.name !== "instructor_ta_check" ?
                                                onSortHandler(
                                                    "instructor_ta_check",
                                                    "instructorFlagCheck1",
                                                    SORT_TYPES.DESC
                                                )
                                                :
                                                filterSorter.clearSorter();
                                        }
                                    })
                                ]}
                            />
                        </div>

                        <div className='sem_data__column sem_data__filter_column'>
                            <div className='sem_data__column__title'>
                                Other
                            </div>
                            <ActionButtons
                                buttons={[
                                    BasicActionButton({
                                        icon: sorter && sorter.name === "instructor_other_check" ? ICONS.CANCEL :  ICONS.CHECKMARK,
                                        isSelected: sorter && sorter.name === "instructor_other_check",
                                        onClick: () => {
                                            !sorter || sorter.name !== "instructor_other_check" ?
                                                onSortHandler(
                                                    "instructor_other_check",
                                                    "instructorFlagCheck2",
                                                    SORT_TYPES.DESC
                                                )
                                                :
                                                filterSorter.clearSorter();
                                        }
                                    })
                                ]}
                            />
                        </div>

                        <div className='sem_data__column sem_data__filter_column sem_data__column--200'>
                            <div className='sem_data__column__title'>
                                Instructor Note
                            </div>
                        </div>
                    </div>
                </div>
                <div className="responsive_browser_row_max">{/* stats row */}
                {
                    inStatsMode &&
                    <> 
                        <div
                            className=' text-center mb-1'
                        >
                            &nbsp;
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </>
                }
                    {
                        sortedRoster.map((student, index) => {
                            return(
                                <>
                                <div
                                    className={`assignment_reviews__row assign_attend__row
                                        ${
                                            index % 2
                                                ? ""
                                                : "assignments_browser__student_row--highlight"
                                        }
                                        ${
                                            hoveredStudentId ===
                                            student.id
                                                ? "cohort_list__row--selected_student"
                                                : ""
                                        }
                                        ${checkStudentSelected(student.id) ? 'bg-secondary text-white' : ''}
                                    `}
                                    key={student["id"]}
                                    onMouseEnter={() =>
                                        setHoveredStudentId(student.id)
                                    }
                                    onMouseLeave={() =>
                                        clearHoveredStudent()
                                    }
                                >
                                    <div className="sem_data__column sem_data__column--200">
                                        <div className="sem_data__column ">
                                            <PercentDisplay
                                                percent={parseFloat(
                                                    student[
                                                        percentsSortType === 'to-date' ? 
                                                            'core_assignments_to_date_percent'
                                                            :
                                                            percentsSortType === 'overall' ?
                                                                'core_assignments_overall_percent'
                                                                :
                                                                `week_${percentsSortType.split('_')[1]}_assignment_percent`
                                                    ]
                                                )}
                                                bottomPercent={0.6}
                                                highPercent={0.9}
                                            />
                                        </div>

                                        <div className="sem_data__column ">
                                            <StudentRowAttendance 
                                                student={student}
                                                dateGroup={percentsSortType}
                                                />
                                        </div>
                                    </div>
                                    
                                    {/* <div className="sem_data__column sem_data__column--200">
                                        <div className='sem_data__column'>
                                            <SimpleCheckColumn
                                                student={student}
                                                rawResponse={ checked => setReminders({
                                                    ...reminders,
                                                    [student.id]: {
                                                        ...reminders[student.id],
                                                        assignments: checked,
                                                    }
                                                })}
                                                // column={student.status_is_active ? 'assignmentsCompleted' : null}
                                                isChecked={ reminders[student.id]  && reminders[student.id].assignments }
                                            />
                                        </div>

                                        <div className='sem_data__column'>
                                            <SimpleCheckColumn
                                                student={student}
                                                rawResponse={ checked => setReminders({
                                                    ...reminders,
                                                    [student.id]: {
                                                        ...reminders[student.id],
                                                        attendance: checked,
                                                    }
                                                })}
                                                isChecked={ reminders[student.id] && reminders[student.id].attendance }
                                            />
                                        </div>
                                    </div> */}

                                    <div className='sem_data__column'>
                                            <SimpleCheckColumn
                                                student={student}
                                                rawResponse={ checked => 
                                                    saveStudent({
                                                        ...student,
                                                        instructor_flags: {
                                                            ...student.instructor_flags,
                                                            check_1: checked
                                                        },
                                                        log_note: `${checked ? 'Checked' : 'Unchecked'} TA Check`
                                                    })
                                                }
                                                
                                                isChecked={ student.instructor_flags.check_1 }
                                            />
                                    </div>

                                    <div className='sem_data__column'>
                                            <SimpleCheckColumn
                                                student={student}
                                                rawResponse={ checked => 
                                                    saveStudent({
                                                        ...student,
                                                        instructor_flags: {
                                                            ...student.instructor_flags,
                                                            check_2: checked
                                                        },
                                                        log_note: `${checked ? 'Checked' : 'Unchecked'} Other Check`
                                                    })
                                                }
                                                
                                                isChecked={ student.instructor_flags.check_2 }
                                            />
                                    </div>

                                    <div className='sem_data__column sem_data__column--200'>
                                        <DisplayInstructorNote student={student} />
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                                </>
                            )})
                    }  
                </div>
            </div> 
        </>
    );
}

export default InstructorSheet;

const DisplayInstructorNote = ({
    student
}) => {

    const { pushOverlay } = useOverlays();

    return (
        <div
            className={`
                instructor__note
                noselect
            `}

            data-toggle="tooltip" 
            title={student.instructor_flags.comment || 'No Note'}

            onClick={(e) => {
                pushOverlay({
                    position: {x: e.clientX, y: e.clientY},
                    component: <UpdateInstructorNote
                        student={student}
                    />
                })
            
            }}
        >
            {student.instructor_flags.comment || 'No Note'}
        </div>
    )
}

const UpdateInstructorNote = ({
    student,
}) => {

    const { saveStudent } = useStudent();
    const { popOverlay, pushOverlay } = useOverlays();

    const [note, setNote] = useState(student.instructor_flags.comment || "");

    return (
        <div 
            className={`
                row
                window_400
            `}
        >
            <SimpleStudentHeader student={student} />
            <textarea
                onChange={(e) => setNote(e.target.value)}
             >{note}</textarea>
             <div className='text-center mt-2 col-12'>
                <button
                    className="btn btn-sm btn-info"
                    disabled={note === student.instructor_flags.comment}
                    onClick={() => {
                        saveStudent({
                            ...student,
                            instructor_flags: {
                                ...student.instructor_flags,
                                comment: note
                            },
                            log_note: "Updated Instructor Note"
                        })
                        popOverlay();
                    }}
                    >Save and Close</button>
                <button
                    className="btn btn-sm btn-secondary ml-2"
                    onClick={(e) => {
                        if (note !== student.instructor_flags.comment ) {
                            pushOverlay({
                                type: OVERLAY_TYPES.CONFIRM,
                                position: {x: e.clientX, y: e.clientY},
                                component: <>Are you sure you want to close without saving?</>,
                                respond: (response) => {
                                    if (response) popOverlay();
                                }
                            
                            })
                        } else {
                            popOverlay();
                        }
                    }}
                    >Close</button>
            </div>
        </div>
    )
}


