import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../store/AppContext";
import SearchStudents from "../elements/search_students/SearchStudents";
import Alerts from "../elements/Alerts";
import DojoTime from "../DojoTime";
import supportIcon from '../../assets/support_icon.png';
import useUserAccess from "../../hooks/useUserAccess";
import useOverlays, { OVERLAY_TYPES } from "../../hooks/useOverlays";

const Navigation = () => {

    const navigate = useNavigate();
    const { user, setUser, theme, isInPrivacyMode, setPrivacyMode } = useContext(AppContext);
    const { pushOverlay, popOverlay } = useOverlays();
    const [ USER_LEVELS, checkAccess ] = useUserAccess();

    const logout = (e) =>
        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>Are you sure you want to log out?</>,
            respond: (response) => {
                if ( response ) {
                    setUser(null);
                    window.localStorage.removeItem('access_token');
                    window.localStorage.removeItem('refresh_token');
                    localStorage.setItem('comment_drafts',JSON.stringify({}))
                    navigate('/');
                }
            }
        })

    const onSetPrivacyMode = (e) => 
        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>
                This will require a page reload. Any unsaved work may be lost. Are you sure you want to continue?</>,
            respond: (response) => {
                if ( response ) {
                    setPrivacyMode(!isInPrivacyMode);
                    window.location.reload();
                }
            }
        })
    
    return(
            <div className="row layer layer-1 mb-2">
                <div className="col-12 mt-2">
                    <div className="row">
                        <div className="col-2">
                            <div id="logo">
                                <Link to="/">
                                    TAB2
                                </Link>
                            </div>
                        </div>
                        <div className="col-3 mt-3">
                            <SearchStudents key="search_students" />
                        </div>
                        <div className="col-2 navigation_icons">
                            <span className="navigation_single_column mt-2">
                                    <Alerts />
                            </span>
                            <span 
                                className="navigation_single_column ml-4 d-sm-none d-lg-block pt-1"
                                data-toggle="tooltip"
                                title={"TAB2 Manual"}
                                onClick={() => window.open("https://sites.google.com/codingdojo.com/tab2-manual/home", "_blank")}
                                >
                                    <div className="infolink infolink--large" ></div>
                            </span>
                            <span 
                                className="navigation_single_column ml-4 d-sm-none d-lg-block pt-1"
                                data-toggle="tooltip"
                                title={"TAB2 Support"}
                                onClick={() => window.open("https://forms.gle/WSiiD4CxV45iiiER6", "_blank")}
                                >
                                    <img src={supportIcon} alt="TAB2 Support" />
                            </span>
                        </div>
                        <div className="col-2 text-center">
                            <DojoTime />
                        </div>
                        <div className="col-1 text-right">
                            <nav className="navbar navbar-expand-sm">
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <div className="dropdown ml-5 ml-md-3 ">
                                        <button className="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {user.first_name}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Link 
                                                className="dropdown-item" 
                                                to="/"
                                                >Dashboard</Link>

                                            <div className="dropdown-divider"></div>

                                            <Link 
                                                className="dropdown-item" 
                                                to="/assignment-reviews"
                                                onClick={() => {
                                                    window.location.href = "/assignment-reviews";
                                                  }}
                                                >Cohort Sheets</Link>

                                                <div className="dropdown-divider"></div>
                                            
                                            <Link 
                                                className="dropdown-item" 
                                                to="/my-tickets"
                                                >My Tickets</Link>
                                            <Link 
                                                className="dropdown-item" 
                                                to="/open-tickets"
                                                >Open Tickets</Link>

                                            <div className="dropdown-divider"></div>

                                            <Link 
                                                className="dropdown-item" 
                                                to="/dojooverflow"
                                                >DojoOverflow</Link>

                                                {
                                                    checkAccess(USER_LEVELS.LEE) &&
                                                        <Link 
                                                            className="dropdown-item" 
                                                            to="/trivjo"
                                                            >Trivjo</Link>
                                                }

                                            
                                            <div className="dropdown-divider"></div>
                                            
                                            <a className="dropdown-item" href="#" onClick={logout}>Logout</a>
                                            <a className="dropdown-item" href="#" onClick={theme.toggle}>Toggle Theme</a>
                                            <a className="dropdown-item" href="#" onClick={onSetPrivacyMode}>
                                                { isInPrivacyMode ? 'Disable' : 'Enable' } Privacy Mode
                                            </a>
                                            
                                            <div className="dropdown-divider"></div>

                                            {
                                                checkAccess(USER_LEVELS.ADMIN) ?
                                                    <Link 
                                                        className="dropdown-item" 
                                                        to="/admin"
                                                        >Admin</Link>
                                                    :
                                                    ""
                                            }

                                            {
                                                checkAccess(USER_LEVELS.ADMIN) &&
                                                <Link 
                                                    className="dropdown-item" 
                                                    to="/testing"
                                                >Testing</Link>
                                            }
                                            
                                            <a className="dropdown-item" href="#" onClick={logout}>Logout</a>
                                        </div>
                                    </div>
                                </div> 
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Navigation;