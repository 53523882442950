import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import alertService from '../../services/AlertService';
import eventHandler, { TIMER_EVENT, CLEAR_ALERT, CLEAR_MY_ALERTS } from '../../events';
import alert_on from "../../assets/alert_on.png";
import alert_off from "../../assets/alert_off.png";

const Alerts = () => {

        const nav = useNavigate()

        const [ alerts, setAlerts ] = useState([]);

        useEffect( () => {

            doHouseKeeping();

            eventHandler.on( TIMER_EVENT, onTimerEvent );
            eventHandler.on( CLEAR_ALERT, onClearAlert );
            eventHandler.on( CLEAR_MY_ALERTS, onClearMyAlerts );

            return () => {
                eventHandler.remove( TIMER_EVENT, onTimerEvent );
                eventHandler.remove( CLEAR_ALERT, onClearAlert );
                eventHandler.remove( CLEAR_MY_ALERTS, onClearMyAlerts );
            }
        }, []);

        const onClearMyAlerts= ( e ) => {

            setAlerts([]);
        }

        const onClearAlert = ( e ) => { 
            const { alertId } = e.detail

            setAlerts( alerts => alerts.filter( alert => {
                return alert.id !== alertId;
            } ) );

            alertService.clearAlert(alertId)
                .then( resp => null )
                .catch( err => console.log(err) )
                
        }

        const onTimerEvent = ( e ) => { 
            const { second } = e.detail;

            if ( second % ( 120 * 60 ) === 0 ) doHouseKeeping();
        }

        const doHouseKeeping = () => {

            alertService.getMy()
                .then( alerts => setAlerts( alerts ) )
                .catch( err => console.log(err) )
        }

        return (
            <div 
                id="alerts"
                onClick={() => alerts.length > 0 ? nav('/') : false}
                data-toggle="tooltip"
                title={"Alerts"}
                >
                <div 
                    id="alerts__prompt" 
                    >
                    {
                        alerts.length === 0 ?
                            <div className='alerts--no-alerts'>
                                <img src={alert_off} alt='no alerts'/>
                            </div>
                            :
                            <div >
                                <img src={alert_on} alt='have alerts'/>
                            </div>
                    }
                </div>
            </div>
        )
}

export default Alerts;