import React, { useState, useEffect } from 'react';
import FilterSorterBtn from '../filter_sorter/FilterSorterBtn';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import ValueSorter from '../filter_sorter/sorters/ValueSorter';
import { SORT_TYPES } from '../filter_sorter/sorters/Sorter';
import ValueSortSelector from '../my_cohort_elements/ValueSortSelector';
import ValuesFilter from '../filter_sorter/filters/ValuesFilter';
import AttendanceSheetWeek from './AttendanceSheetWeek';
import StudentRowAttendance from '../my_cohort_elements/StudentRowAttendance';
import ActionButtons, {BasicActionButton, ICONS, SortButton} from '../../elements/ActionButtons';
import useOverlays from '../../../hooks/useOverlays';
import MyCohortContext from '../../../store/MyCohortContext';
import useRoster from '../../../hooks/useRoster';

export const DATE_GROUPS = {
    TODATE: 'to-date',
    OVERALL: 'overall',
}

export default function AttendanceSheet({
}) {

    const { pushOverlay, popOverlay } = useOverlays();
    const { 
        sortedRoster,
        studentsData,
        cohortCalendar,
        inStatsMode,
    } = React.useContext(MyCohortContext);

    const { checkStudentSelected,
        hoveredStudentId,
        setHoveredStudentId,
        clearHoveredStudent,
     } = useRoster();

    const [ dateGroup, setDateGroup ] = useState(DATE_GROUPS.TODATE);
    const [ percentSort, setPercentSort ] = useState(null);
    const [ filteringPercents, setFilteringPercents ] = useState(false);
    const [ percentFilterValues, setPercentFilterValues ] = useState([]);
    const [ selectedWeek, setSelectedWeek ] = useState(null);

    const [ viewWeeks, setViewWeeks ] = useState([]);

    useEffect( () => {

        // open current week
        const now = new Date();
        const currentWeek = Object.keys(cohortCalendar).find( weekStartDate => { 
            let weekStart = new Date(Date.parse(weekStartDate));
            let weekEnd = new Date(weekStart)
            weekEnd.setDate(weekEnd.getDate() + 7);
            return now >= weekStart && now <= weekEnd;
        })
        setViewWeeks( [currentWeek] );

        if ( 'attendance_view_weeks' in filterSorter.settings ) {
            setViewWeeks(filterSorter.settings.attendance_view_weeks);
        }

        if ( 'attendance_filter_type' in filterSorter.settings ) {
            setDateGroup(filterSorter.settings.attendance_filter_type);
        }

    }, []);

    useEffect( () => { 
        
        if ( filterSorter.sorter && filterSorter.sorter.name === 'attendance_percent' ) {
            setPercentSort( filterSorter.sorter.sortType );
        } else {
            setPercentSort(null);
        }

        let attendancePercentFilter = filterSorter.getFilter('attendance_percent');
        if (attendancePercentFilter) {
            setPercentFilterValues(attendancePercentFilter.values);
        } else {
            setPercentFilterValues([]);
        }

    }, [sortedRoster]);// watch the filterSorter.sorter object for changes.

    const onSetPercentSort = (sortDirection) => {

        setPercentSort(sortDirection);

        if ( sortDirection === null ) return;

        filterSorter.addSorter( 
            new ValueSorter(
                'attendance_percent', 
                dateGroup === DATE_GROUPS.TODATE ?
                    'attendance_to_date_percent'
                    :
                    'attendance_overall_percent'
                    , 
                sortDirection
            ) 
        );
    }

    return (
        <div id="attendance_sheet" className='my_cohort__right_panel'>
            <div className='my_cohort__filters_bar layer layer-3 '>
            <div
                    className='my_cohort__filters_bar__header'
                >
                    <div className=''>
                        <FilterSorterBtn
                            toggle={() => {
                                if ( viewWeeks.length ) { // some weeks are open open  - close them all
                                    setViewWeeks([]);
                                    filterSorter.settings.attendance_view_weeks = [];
                                } else { // no weeks are open - open all
                                    setViewWeeks(Object.keys(cohortCalendar));
                                    filterSorter.settings.attendance_view_weeks = Object.keys(cohortCalendar);
                                }
                            }}
                            toggleTooltip='Toggle Weeks'
                            prompt='Toggle Weeks'
                            />
                    </div>
                </div>
                <div className='my_cohort__filters_bar__view_select'>
                    <div className='assignment_reviews__row--filters assign_attend__row'>
                        <div className='assign_attend__percent_col--filters ml-1 mr-2'>
                            <select
                                className="mb-1"
                                value={dateGroup}
                                onChange={(e) => {
                                    setDateGroup(e.target.value);
                                    filterSorter.settings.attendance_filter_type = e.target.value;
                                }}
                            >
                                <option value={DATE_GROUPS.OVERALL}>Overall</option>
                                <option value={DATE_GROUPS.TODATE}>To Date</option>
                            </select>
                            <ActionButtons
                                buttons={[
                                    BasicActionButton({
                                        icon: ICONS.FILTER,
                                        tooltip: 'Filter Attendance',
                                        isSelected: filterSorter.getFilter('attendance_percent'),
                                        onClick: (e) => {
                                            pushOverlay({
                                                component: <ValueSortSelector
                                                    close={() => popOverlay()}
                                                    columnValueName={
                                                        dateGroup === DATE_GROUPS.TODATE ?
                                                            'attendance_to_date_percent'
                                                            :
                                                            'attendance_overall_percent'
                                                    }
                                                    studentsData={studentsData}
                                                    isPercent={true}
                                                    filterValues={percentFilterValues}
                                                    setFilterValues={(values) => {
                            
                                                        if ( values.length > 0) {
                                                            setFilteringPercents(false);
                                                            setPercentFilterValues(values);
                                                            filterSorter.addFilter(new ValuesFilter(
                                                                'attendance_percent',
                                                                values, 
                                                                dateGroup === DATE_GROUPS.TODATE ?
                                                                    'attendance_to_date_percent'
                                                                    :
                                                                    'attendance_overall_percent'
                                                                ))
                                                        } else { // filters were cleared
                                                            setFilteringPercents(false);
                                                            setPercentFilterValues(values);
                                                            filterSorter.removeFilter('attendance_percent');
                                                        }
                                                    }}
                                                />,
                                                position: {x: e.clientX, y: e.clientY}
                                            })
                                           
                                        }
                                    }),
                                    BasicActionButton({
                                        icon: ICONS.CANCEL,
                                        tooltip: 'Clear Filters',
                                        visible: filterSorter.getFilter('attendance_percent') !== undefined,
                                        isSelected: true,
                                        onClick: () => {
                                            setFilteringPercents(false);
                                            setPercentFilterValues([]);
                                            filterSorter.removeFilter('attendance_percent');
                                        }
                                    }),
                                    SortButton({
                                        sortType: percentSort,
                                        setSortType: () => {
                                            onSetPercentSort(
                                                percentSort === SORT_TYPES.ASC ? 
                                                    SORT_TYPES.DESC 
                                                    : 
                                                    SORT_TYPES.ASC
                                                )
                                        }
                                    }),
                                ]}
                            />
                        </div>


                        <div className='attendance_sheet_weeks'>
                            {
                                Object.keys(cohortCalendar).map( (weekStartDate, index) => {

                                    let now = new Date();
                                    let weekStart = new Date(Date.parse(weekStartDate));
                                    let weekEnd = new Date(weekStart)
                                    weekEnd.setDate(weekEnd.getDate() + 7);
                                    let isCurrentWeek = now >= weekStart && now <= weekEnd;
                                    
                                    return(
                                    <AttendanceSheetWeek
                                        studentData={studentsData[sortedRoster[0].id]} // send example for header
                                        attendanceFrequency={cohortCalendar[weekStartDate][0]['attendance']['frequency']}
                                        weekNumber={index + 1}
                                        setIsSelected={(selected) => {
                                            if ( selected ) 
                                                setSelectedWeek(index + 1)
                                            else    
                                                setSelectedWeek(null)
                                        }}
                                        isHeader = {true}
                                        weekStartDate={weekStartDate}
                                        isViewWeek={viewWeeks.includes(weekStartDate)}
                                        isCurrentWeek={isCurrentWeek}
                                        toggleViewWeek={() => {
                                            if ( viewWeeks.includes(weekStartDate) ) {
                                                const newViewWeeks = viewWeeks.filter(week => week !== weekStartDate);
                                                setViewWeeks(newViewWeeks);
                                                filterSorter.settings.attendance_view_weeks = newViewWeeks;
                                            } else {
                                                const newViewWeeks = [...viewWeeks, weekStartDate];
                                                setViewWeeks(newViewWeeks);
                                                filterSorter.settings.attendance_view_weeks = newViewWeeks;
                                            }
                                        }}
                                    />)}
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className='responsive_browser_row_max'>
                {
                    inStatsMode &&
                    <> 
                        <div
                            className='assignment_reviews__row assign_attend__row'
                        >
                            &nbsp;
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </>
                }
                {
                    sortedRoster.map( (student, index) => {

                        return (
                            <>
                                <div className={`assignment_reviews__row assign_attend__row
                                                    ${index % 2 ? '' : 'assignments_browser__student_row--highlight'}
                                                    ${hoveredStudentId === student.id ? 'cohort_list__row--selected_student' : ''}
                                                    ${checkStudentSelected(student.id) ? 'bg-secondary text-white' : ''}
                                                    `
                                                    } key={`assign_attend_row${student['id']}`}
                                                    onMouseEnter={() => setHoveredStudentId(student.id)}
                                                    onMouseLeave={() => clearHoveredStudent()}
                                                    
                                                    >
                                                        
                                    <div className='assign_attend__percent_col mr-3'>
                                        <StudentRowAttendance 
                                            student={student}
                                            dateGroup={dateGroup}
                                            />
                                    </div>

                                    <div className='attendance_sheet_weeks'>
                                        {
                                            Object.keys(cohortCalendar).map( (weekStartDate, index) => {
                                                

                                                return(
                                                    <AttendanceSheetWeek
                                                        studentData={studentsData[student.id]}
                                                        attendanceDays={cohortCalendar[weekStartDate][0]['attendance']['days']}
                                                        attendanceFrequency={cohortCalendar[weekStartDate][0]['attendance']['frequency']}
                                                        weekStartDate={weekStartDate}
                                                        weekNumber={index + 1}
                                                        isSelected={selectedWeek === index + 1}
                                                        isViewWeek={viewWeeks.includes(weekStartDate)}
                                                    />
                                                )}
                                            )
                                        }
                                    </div>
                                </div>

                                <div style={{clear: 'both'}}></div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}