import React from 'react';

const MyCohortStatusSetter = ({
    updateStatus,
    studentStatuses,
    currentStatus = null,
    currentStatusId,
}) => { 

    const onUpdated = (e) => {
        updateStatus(studentStatuses.find((status) => status.id === parseInt(e.target.value)));
    }

    return (
        <select
            className={`
            sem_data__status
            sem_data__status_${currentStatus}
            `}
            onChange={onUpdated}
        >
            {
                currentStatus === null &&
                <option
                    value={null}
                >Select Status</option>
            }
            {
                studentStatuses
                    .sort((a, b) => {
                        return a.title > b.title ? 1 : -1
                    }
                )
                    .map( (status) => {
                    return (
                        <option
                            value={status.id}
                            selected={currentStatusId === status.id}
                        >
                            {status.title}
                        </option>
                    )
                })
            }
        </select>
    )
}

export default MyCohortStatusSetter;