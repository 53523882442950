/* the space above the roster where the use can filter/sort the roster */

import React, { useEffect, useState } from "react";
import filterSorter from "../filter_sorter/MyCohortFilterSorter";
import RosterSorter from "../filter_sorter/sorters/RosterSorter";
import { SORT_TYPES } from "../filter_sorter/sorters/Sorter";
import EmailsFilter from "../filter_sorter/filters/EmailsFilter";
import ValueSorter from "../filter_sorter/sorters/ValueSorter";
import StatusesFilter from "../status_setter/StatusesFilter";
import ActionButtons, { ICONS, SortButton, Label, BasicActionButton, BUTTON_SIZES } from "../../elements/ActionButtons";
import MyCohortSetStatuses from "../status_setter/MyCohortSetStatuses";
import EmailTools from "./EmailTools";
import { SortFilterInstructorFlags } from "../my_cohort_elements/InstructorFlags";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import MyCohortContext from "../../../store/MyCohortContext";
import useRoster from "../../../hooks/useRoster";

const Sorter = ( {
    statuses,
} ) => { 

    const { pushOverlay, popOverlay } = useOverlays();
    const { 
        sortedRoster,
        studentStatuses,
        studentsData,
    } = React.useContext(MyCohortContext); 

    const { selectedStudents, clearSelectedStudents} = useRoster();
    
    const [ currentSortPattern, setCurrentSortPattern ] = useState(null);
    const [ statusesSortPattern, setStatusesSortPattern ] = useState(null);

    const [ filterEmails, setFilterEmails ] = useState("");
    const [ filteredByEmails, setFilteredByEmails ] = useState(false);

    const [ filteredManually, setFilteredManually ] = useState(false);
    const [ selectedStatus, setSelectedStatus ] = useState(null);

    useEffect( () => { 

        if ( filterSorter.sorter && filterSorter.sorter.name === 'roster_sorter' ) { 
            setCurrentSortPattern( filterSorter.sorter.sortPattern );
        } else {
            setCurrentSortPattern( null );
        }

        if ( filterSorter.sorter && filterSorter.sorter.name === 'statuses_sorter' ) { 
            setStatusesSortPattern( filterSorter.sorter.sortType );
        } else {
            setStatusesSortPattern( null );
        }

        let filter = filterSorter.getFilter('emails_filter');
        if ( filter ) {
            setFilterEmails(filter.emails);
            setFilteredByEmails(true);
        } else {
            setFilterEmails("");
            setFilteredByEmails(false); 
        }

        filter = filterSorter.getFilter('manual_email_filter');
        if ( filter ) {
            setFilteredManually(true);
        } else {
            setFilteredManually(false);
        }

    }, [filterSorter.sorter, filterSorter.filters]);

    const sortRoster = (pattern) => filterSorter.addSorter(new RosterSorter(pattern));

    return (
        <div id="sorter" className="row  layer layer-3">
            <div className="col-12">
                <EmailTools
                    filterEmails={filterEmails}
                    filteredByEmails={filteredByEmails}
                    sortedRoster={sortedRoster}
                    setFilterEmails={setFilterEmails}
                    />
                <div className="divider mt-2 mb-2"><div></div></div>
                <div className="row sorter__buttons_row">
                        <div className="col-3 text-center">
                            <ActionButtons
                                size= {BUTTON_SIZES.SMALL}
                                buttons={[
                                    Label({
                                        text: `${sortedRoster.length} / ${filterSorter.roster ? filterSorter.roster.length : 0}`
                                    }),
                                ]}
                                />
                            <span className="ml-4">
                                <SortFilterInstructorFlags />
                            </span>
                        </div>

                        <div className="col-4 text-center">
                            <ActionButtons
                                buttons={[
                                    Label({
                                        text: "First",
                                    }),
                                    SortButton({
                                        sortType: currentSortPattern === SORT_TYPES.FIRST_NAME_ASC ? "asc" : currentSortPattern === SORT_TYPES.FIRST_NAME_DESC ?  "desc" : null,
                                        setSortType: () => sortRoster(currentSortPattern === SORT_TYPES.FIRST_NAME_ASC ? SORT_TYPES.FIRST_NAME_DESC : SORT_TYPES.FIRST_NAME_ASC ),
                                        toolTip: "Sort By First Name"
                                    }),
                                ]}

                            />
                            <span className="ml-2">
                            <ActionButtons
                                buttons={[
                        
                                    Label({
                                        text: "Last",
                                    }),
                                    SortButton({
                                        sortType: currentSortPattern === SORT_TYPES.LAST_NAME_ASC ? "asc" : currentSortPattern === SORT_TYPES.LAST_NAME_DESC ?  "desc" : null,
                                        setSortType: () => sortRoster(currentSortPattern === SORT_TYPES.LAST_NAME_ASC ? SORT_TYPES.LAST_NAME_DESC : SORT_TYPES.LAST_NAME_ASC ),
                                        toolTip: "Sort By Last Name"
                                    }),
                                ]}

                            />

                            </span>
                        </div>

                        <div className="col-3 justify-content-center d-flex gap-2">
                            <div className="ml-1">
                                <ActionButtons
                                    buttons={[
                                        Label({
                                            text: 'Bulk Set',
                                            toolTip:'Bulk Set Statuses',
                                            onClick: (e) => {
                                                pushOverlay({
                                                    component: <MyCohortSetStatuses
                                                        studentStatuses={studentStatuses}
                                                        currentStatus={
                                                            selectedStatus ?
                                                                studentStatuses.find(status => status.id === selectedStatus.id).slug
                                                                :
                                                                null
                                                        }
                                                        close={() => popOverlay()}
                                                        sortedRoster={sortedRoster}
                                                    />,
                                                    position: {x: e.clientX, y: e.clientY}
                                                })
                                            }
                                            
                                        }),
                                        BasicActionButton({
                                            icon: ICONS.FILTER,

                                            onClick: (e) => {
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.PALETTE,
                                                    component: <StatusesFilter
                                                        usedStatuses={sortedRoster.map(student => student.status_id)}
                                                        statuses={statuses}
                                                        close={() => popOverlay()}
                                                    />,
                                                    position: {x: e.clientX, y: e.clientY}
                                                })
                                            },
                                            // onClick: () => setFilteringStatuses(true),
                                            toolTip: "Filter By Status",
                                            isSelected: filterSorter.getFilter('statuses_filter') ? true : false
                                        }),
                                        SortButton({
                                            sortType: statusesSortPattern === SORT_TYPES.ASC ? "asc" : statusesSortPattern === SORT_TYPES.DESC ?  "desc" : null,
                                            setSortType: () => filterSorter.addSorter(new ValueSorter('statuses_sorter', 'status_slug', statusesSortPattern === SORT_TYPES.ASC ? SORT_TYPES.DESC : SORT_TYPES.ASC )),
                                            toolTip: "Sort By Status"
                                        })
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="col-2 text-center">
                            <ActionButtons
                                buttons={[
                                    Label({
                                        text: selectedStudents.length,
                                        visible: selectedStudents.length > 0
                                    }),
                                    BasicActionButton({
                                        icon: ICONS.FILTER,
                                        onClick: 
                                            filteredManually ?
                                                () => {
                                                    if ( selectedStudents.length === 0 ) {
                                                        filterSorter.removeFilter('manual_email_filter');
                                                    } else {
                                                        filterSorter.addFilter(
                                                            new EmailsFilter(
                                                                'manual_email_filter', 
                                                                selectedStudents.map( studentId => studentsData[studentId].email_address)
                                                                )
                                                        );
                                                    }
                                                }
                                                :
                                                () => {
                                                    if ( selectedStudents.length  ) {
                                                        filterSorter.addFilter(
                                                            new EmailsFilter(
                                                                'manual_email_filter', 
                                                                selectedStudents.map( studentId => studentsData[studentId].email_address)
                                                                )
                                                        );
                                                    }
                                                },
                                        toolTip: "Filter By Selection",
                                        isSelected: filteredManually
                                    }),
                                    BasicActionButton({
                                        icon: ICONS.CANCEL,
                                        toolTip: "Clear Selection",
                                        visible: selectedStudents.length > 0,
                                        onClick: () => {
                                            filterSorter.removeFilter('manual_email_filter');
                                            clearSelectedStudents();
                                        },
                                    })
                                ]}
                            />
                        </div>
                </div>
            </div>

        </div>
    )
}

export default Sorter;