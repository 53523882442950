import React from "react";
import { Chart } from "react-google-charts";

const InstructorCohortsStats = ({
        close,
        cohorts
    }) => {console.log(cohorts)

        let stackMem = {};

        cohorts.forEach( cohort => {
            if ( !stackMem[cohort.stack_name] ) stackMem[cohort.stack_name] = 0
            stackMem[cohort.stack_name] ++;
        })


        let data = [
            ["Stack", "Count"]
        ];

        for ( const[key, value] of Object.entries(stackMem) )
            data.push([key, value]);

        return (
            <div className="row">
                <div className="col-12 text-center">
                    <Chart
                        chartType="PieChart"
                        data={data}
                        options={{
                            title: `${cohorts.length} Cohorts`,
                        }}
                        width={"100%"}
                        height={"300px"}
                    />
                </div>
                <div className="col-12 text-center mt-2">
                    <button
                        className="btn btn-info btn-sm"
                        onClick={close}
                    >Close</button>
                </div>
            </div>
        )
    }

export default InstructorCohortsStats;



