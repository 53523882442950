import TABService from './TABService';

class CohortService extends TABService {

    get_active_cohorts = () => 
        this._get(
            `cohort/active`
        )

    get_instructor_cohorts = (instructor_id) =>
        this._get(
            `cohort/instructor/${instructor_id}`
        )

    get_cohort = (cohortId) => 
        this._get(
            `cohort/${cohortId}`
        )  
}

export const cohortService =  new CohortService();
export default cohortService;