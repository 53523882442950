import React from "react";
import AdminTabers from "./AdminTabers";

const Admin = ({

}) => {

    return (
        <div
            className="row loaded_component"
            >
            <div
                className="col-12 layer layer-1">
                <h1>Admin</h1>
                <AdminTabers />
            </div>
        </div>
    )
}

export default Admin;