import React, { useEffect, useState } from 'react';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import useStudent from '../../../hooks/useStudent';
import studentService from '../../../services/StudentService';

export const ACTION_SEND_SI = 0;
export const ACTION_REVIEW = 1;
export const ACTION_ONE_ON_ONE = 2;
export const ACTION_OTHER = 3;
export const ACTION_EXAM_FEEDBACK = 4;
export const ACTION_LIVE_CODE_REVIEW = 5;

const SubstantiveInteraction = ({
    student,
    weekNumber,
    close,
}) => {

    const { pushOverlay } = useOverlays();

    let interaction = student.substantive_interactions[weekNumber] ? student.substantive_interactions[weekNumber] : null;

    return (
        <div className={`
            assignment_reviews__tab__shape_section 
            substantive_interaction_tab--active

            `}
            >
            <div 
                className={`
                assignment_reviews__tab__shape
                assignment_reviews__tab--square

                assignment_reviews__tab_status
                ${student[`week_${weekNumber}_substantive_interaction`]  ? "substantive_interaction_tab--submitted" : ""}
                ${!student[`week_${weekNumber}_substantive_interaction`] && student.status_is_active ? "substantive_interaction_tab--not_yet_submitted" : ""}
                
                ${!student[`week_${weekNumber}_substantive_interaction`] && weekNumber < student.current_week ? 'substantive_interaction_tab--missing' : ''}
                `}
                data-toggle="tooltip"
                title={`${student.name} - ${
                    student[`week_${weekNumber}_substantive_interaction`] ? 
                        (interaction && interaction.send_to_student) ? 
                            'Substantive Interaction Submitted' : 
                            !interaction || !interaction.send_to_student ?
                                'Assignment Review Sent' :
                                'bppg'
                        :
                        'Substantive Interaction Not Submitted'
                }`}

                onClick={(e) => {
                    pushOverlay({
                        // position: {x: e.clientX, y: e.clientY},
                        type: OVERLAY_TYPES.MODAL,
                        component: <SubstantiveInteractionModal 
                            student={student} 
                            close={close}
                            weekNumber={weekNumber}
                            />,
                    });
                }}
            >
                <div>
                    {
                        student[`week_${weekNumber}_substantive_interaction`] ? String.fromCharCode(10004) : <>&nbsp;</>
                    }
                </div>
            </div>
        </div>
    );
}

const SubstantiveInteractionModal = ({
    student,
    close,
    weekNumber,
}) => {

    const { updateStudent } = useStudent();
    
    const [action, setAction] = useState(0);
    const [sendToStudent, setSendToStudent] = useState(false);
    const [sendToSupport, setSendToSupport] = useState(false);
    const [sendToAssignmentFeedback, setSendToAssignmentFeedback] = useState(false);
    const [message, setMessage] = useState('');
    const [canAddInteraction, setCanAddInteraction] = useState(true);

    const oneStepActions = [ACTION_REVIEW, ACTION_ONE_ON_ONE, ACTION_EXAM_FEEDBACK, ACTION_LIVE_CODE_REVIEW];

    useEffect(() => {
        if (student.substantive_interactions[weekNumber]) {
            let interaction = student.substantive_interactions[weekNumber];
            setAction(interaction.action);
            setSendToStudent(interaction.send_to_student);
            setSendToSupport(interaction.send_to_support);
            setSendToAssignmentFeedback(interaction.send_to_assignment_feedback);
            setMessage(interaction.message);
            setCanAddInteraction(false)
        }
    }, []);

    useEffect(() => {

        if (!sendToStudent) {
            setSendToSupport(false);
            setSendToAssignmentFeedback(false);
        
        } else {
            setSendToAssignmentFeedback(true);
        }

    },[sendToStudent]);

    useEffect(() => {
        setSendToStudent(action == ACTION_SEND_SI);
        //setSendToSupport(action == ACTION_SEND_SI);
        //setSendToAssignmentFeedback(action == ACTION_SEND_SI);
    }, [action])

    const submit = () => {

        let interaction = {
            user_id: student.id,
            cohort_id: student.cohort_id,
            action,
            message,
            send_to_student: sendToStudent,
            send_to_support: sendToSupport,
            send_to_assignment_feedback: sendToAssignmentFeedback,
            week: weekNumber,
        }
        studentService.postSubstantiveInteraction(interaction);
        student.substantive_interactions[weekNumber] = interaction;
        student[`week_${weekNumber}_substantive_interaction`] = true;
        updateStudent(student);
        close();
    }

    let canSubmit = 
        oneStepActions.indexOf(action) != -1 || 
        message !== '';

    return (
        <div
            className={`
                window_900
                row
            `}
        >
            <div className='col-12 text-center'>
                <h3>
                    {student.name}
                </h3>
            </div>

            <div className='col-8'>
                <select
                    className='form-control ml-3'
                    value={action}
                    onChange={(e) => setAction(parseInt(e.target.value))}
                    disabled={!canAddInteraction}
                >
                    <option value={0}>New Interaction...</option>
                    <option value={1}>Sent Assignment Review Email</option>
                    <option value={2}>Sent One-on-One Follow-up Email</option>
                    <option value={4}>Sent Exam Feedback</option>
                    {/* <option value={3}>Other Completed Interaction</option> */}
                </select>
            </div>

            <div className='col-4 text-center'>
                {
                    canAddInteraction &&
                        <button
                            className='btn btn-success '
                            disabled={!canSubmit}
                            onClick={submit}
                        >Submit</button>
                }
                <button
                    className='btn btn-danger btn-sm ml-3'
                    onClick={close}
                >Cancel</button>
            </div>

            <div className='col-12 mt-3'>
                {
                    (action == ACTION_SEND_SI) &&

                    <div className="text-center">
                        Document your interaction below or send an email to the student.
                    </div>
                }

                {
                    (oneStepActions.indexOf(action) != -1 || action == ACTION_OTHER) &&
                    <div className="text-center">
                        If you sent an email to the student 
                        &nbsp;<b><u>and assignment-feedback@codingdojo.com</u></b>,<br/>
                        you can stop here and submit your SI.
                    </div>
                }

            </div>

            <div className='col-12 mt-3'>
                <div className='ml-3'>
                    <input 
                        type="checkbox" 
                        checked={sendToStudent}
                        onChange={(e) => setSendToStudent(e.target.checked)}
                        disabled={!canAddInteraction}
                        /> 
                        <span
                            className={`
                                ${sendToStudent ? 'bg-warning text-dark' : ''}
                                ml-1
                                p-1
                                `}
                        >
                                Send to Student
                            </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input 
                        type="checkbox"
                        checked={sendToSupport}
                        onChange={(e) => setSendToSupport(e.target.checked)}
                        disabled={!canAddInteraction || !sendToStudent}
                        />
                        <span
                            className={`
                                ${sendToSupport ? 'bg-warning text-dark' : ''}
                                ml-1
                                p-1
                                `}
                        >
                                Send to Support ( at-risk interactions )
                            </span> 
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input 
                        type="checkbox"
                        checked={sendToAssignmentFeedback}
                        onChange={(e) => setSendToAssignmentFeedback(e.target.checked)}
                        disabled={!canAddInteraction || !sendToStudent}
                        />
                        <span
                            className={`
                                ${sendToAssignmentFeedback ? 'bg-warning text-dark' : ''}
                                ml-1
                                p-1
                                `}
                        >
                                Send to Assignment Feedback email
                            </span> 
                </div>
            </div>

            <div className='col-12 mt-2 ml-3'>
                <div className='text-center'>
                    ( TAB2 Does not add any opening or closing salutations )
                </div>
                <textarea
                    className='form-control'
                    rows={10}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={!canAddInteraction}
                />
            </div>
        </div>
    )
}

export default SubstantiveInteraction;