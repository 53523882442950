import React, { useState} from "react";
import EscalatePanel from "./EscalatePanel";
import ticketService from "../../services/TicketService";
import useOverlays, { OVERLAY_TYPES } from "../../hooks/useOverlays";

const EscalateBtn = ( { 
    closeTicket, 
    ticket, 
    updateTicket, 
    escalatePrompt = "Escalate"
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    const onButtonClicked = (e) => {
        if (ticket.escalated && ! ticket.resolved) {

            pushOverlay({
                type: OVERLAY_TYPES.CONFIRM,
                position: { x: e.clientX, y: e.clientY},
                component: <>Are you sure you want to mark this resolved?</>,
                respond: (response) => {
                    if ( response ) {
                        updateTicket({
                            ...ticket,
                            resolved: 1
                        })

                        ticketService.markResolved(ticket.id)
                            .then(() => {
                            })
                            .catch(err => console.log(err) )
                    }
                }
            
            })

        } else {
            pushOverlay({
                position: {x: e.clientX, y: e.clientY},
                component: <EscalatePanel 
                    closePanel={popOverlay}
                    closeTicket={() => {
                        closeTicket();
                        popOverlay();
                    
                    }}
                    ticket={ticket}
                    updateTicket={updateTicket}
                />
            })
        }
    }

    return (
        <>
            <button
                className="btn btn-sm btn-primary"
                onClick={onButtonClicked}
            >
                {
                    ticket.escalated && ! ticket.resolved ?
                        'Mark Resolved'
                        :
                        <>{escalatePrompt}</>
                }
            </button>
        </>
    )
}

export default EscalateBtn;