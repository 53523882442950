/* centralized source for sorting and filtering students */
/* also provides storage for resetting filter settings when a sheet is reloaded */

class MyCohortFilterSorter {
    
    roster; // will be set by MyHost once loaded
    studentsData; // will be set by MyHost once loaded

    sorter = null; // instance of Sorter class
    filters = []; // dictionary of functions
    settings = {}; // dictionary of settings that can be retrieved when a sheet is reloaded. DO NOT UPDATE DIRECTLY!!!!!

    setSettings = ( updatedSettings ) => {
        this.settings = {
            ...this.settings,
            ...updatedSettings
        }
        this._broadcastRoster();
    }

    deleteSetting = ( settingName ) => { console.log(settingName)
        delete this.settings[settingName];
        console.log(this.settings)
        this._broadcastRoster();
    }

    addFilter = ( filter ) => { // filterData are the settings the function will need in order to filter the student
        this.removeFilter(filter.name, false); // remove any existing filter with the same name
        this.filters.push(filter);
        this._broadcastRoster();
    }

    getFilter = ( name ) => {   
        return this.filters.find( filter => filter.name === name );
    }

    removeFilter = ( name ) => {
        this.filters = this.filters.filter( filter => filter.name !== name );
        this._broadcastRoster();
    }

    clear = () => { 
        this.filters = [];
        this.sorter = null;
        this.settings = {};
        this._broadcastRoster();
    }

    addSorter = ( sorter ) => {
        this.sorter = sorter;
        this._broadcastRoster();
    }

    clearSorter = () => {
        this.sorter = null;
        this._broadcastRoster();
    }

    getSorter = () => this.sorter;

    setRoster = ( roster ) => {
        this.roster = roster;
        //this._broadcastRoster();
    }

    setStudentsData = ( studentsData ) => {
        this.studentsData = studentsData;
        this._broadcastRoster();
    }

    init = ( roster, studentsData ) => {
        this.setRoster(roster);
        this.setStudentsData(studentsData);
        this._broadcastRoster();
    }

    isActive = () => this.sorter !== null || (this.filters && this.filters.length > 0) || this.settings && Object.keys(this.settings).length > 0;

    reset = () => {
        this.filters = [];
        this.sorter = null;
        this.roster = undefined;
        this.studentsData = undefined;
        this.settings = {};

        // call event to reset GUI
        document.dispatchEvent( new CustomEvent('rosterUpdated', {detail: null}) );
    }

    _buildRoster = () => {

        if ( !this.roster || !this.studentsData ) return [];
       
        let finalRoster = [];

        // go through master roster
        this.roster.forEach( student => {

            let addStudent = true;

            // go thorough all filters
            for ( let filter of this.filters ) { 
                if ( filter.apply(student, this.studentsData[student.id]) === false ) addStudent = false;}

            if ( addStudent ) finalRoster.push(this.studentsData[student.id]);

        });

        // sort final roster
        return this.sorter ? 
            this.sorter.sort(finalRoster, this.studentsData) : finalRoster;
    }

    _broadcastRoster = () => {
        document.dispatchEvent( new CustomEvent('rosterUpdated', {detail: this._buildRoster()}) );
    }

}

export default new MyCohortFilterSorter();
