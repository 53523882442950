import React, { useState } from 'react';
import { SORT_TYPES } from './sorters/Sorter';
import sortIcon from '../../../assets/sort_icon.png';
import filterIcon from '../../../assets/filter_icon.png';
import downloadIcon from '../../../assets/download_icon.png';
import viewIcon from '../../../assets/view_icon.png';
import emailIcon from '../../../assets/email_icon.png';
import selectIcon from '../../../assets/select_icon.png';
import statsIcon from '../../../assets/stats_icon.png';
import toggleIcon from '../../../assets/toggle_icon.png';
import reloadIcon from '../../../assets/reload_icon.png';
import openTabIcon from '../../../assets/open_tab_icon.png';
import githubIcon from '../../../assets/github_icon.png';
import historyIcon from '../../../assets/history_icon.png';
import linkIcon from '../../../assets/link_icon.png';
import cohortIcon from '../../../assets/cohort_icon.png';
import calendlyIcon from '../../../assets/calendly_icon.png';
import noteIcon from '../../../assets/note_icon.png';
import legendIcon from '../../../assets/legend_icon.png';
import hubspotIcon from '../../../assets/hubspot_icon.png';
import { ICONS } from '../../elements/ActionButtons';

const FilterSorterBtn = ({ 
    sortDirection,
    isFiltered = false,
    prompt = null, // text
    disabled = false,
    setSortDirection = null, // set only if sorting is enabled
    filterColumn = null, // set only if filtering is enabled

    downloadFiles = null,// function
    downloadFilesTooltip = "Click to Download Files",

    view = null, // function

    // enables 3 button assignment type selector
    selectedAssignmentTypes = null, // array
    setSelectedAssignmentTypes = null, // function

    // enables single button assignment type selector
    assignmentType = null, // string
    toggleSelectedAssignmentType = null, // function

    emailAddress = null, // string
    emailTooltip = "Copy Email to Clipboard",

    select = null, // function,
    isSelected = false,
    selectTooltip = "Select",

    stats = null,
    statsTooltip = "View Stats",

    inStatsMode = false,

    toggle = null,
    toggleTooltip = "Toggle",

    reload = null,
    reloadTooltip = "Reload",

    cancel = null, // function
    cancelTooltip = "Cancel",

    openTab = null, // function
    openTabTooltip = "Open in New Tab",

    github = null, // url
    githubTooltip = "Open in GitHub",

    history = null,
    historyTooltip = "View History",

    link = null, // function
    linkTooltip = "Link",

    calendlyLink = null, // function
    calendlyTooltip = "Copy Calendly Link",

    note = null, // function
    noteTooltip = "Add Note",

    legend = null, // function
    legendTooltip = "Legend",

    hubspotURL = null, // url
    hubspotTooltip = "Open In Hubspot",

    // go to cohort
    cohortTooltip = "Go To Cohort",
    cohortInstructorId = null,
    cohortStartDate = null, // send as date object
    cohortStudentId
}) => {

    const onAssignmentTypeClicked = (assignmentType) => {

        if (selectedAssignmentTypes.indexOf(assignmentType) > -1 && selectedAssignmentTypes.length > 1) {
            setSelectedAssignmentTypes(selectedAssignmentTypes.filter((type) => type !== assignmentType));
        } else {
            setSelectedAssignmentTypes([...selectedAssignmentTypes, assignmentType]);
        }
    }

    return (
        <div
            className={`
                filter_sorter_btn
                }
            `}
        >
            {
                prompt !== null &&
                    <div className="filter_sorter_btn__prompt">
                        {prompt}
                    </div>
            }
            {
                filterColumn !== null &&
                    <div
                        className={`
                            filter_sorter_btn__filter
                            filter_sorter_btn--${isFiltered ? 'active' : ''}
                        `}
                        data-toggle="tooltip" 
                        title={`Click to filter column`}
                        onClick={filterColumn}
                    >
                        <img src={filterIcon} alt="filter icon" />
                    </div>
            }

            {
                setSortDirection !== null &&
                    <div 
                        className={`
                            filter_sorter_btn__sorter
                            filter_sorter_btn--${sortDirection === null ? '' : 'active'}
                        `}
                        data-toggle="tooltip" 
                        title={`Click to sort column`}
                        onClick={setSortDirection}
                    >
                        <img src={
                            sortDirection === null ? sortIcon : 
                                sortDirection === SORT_TYPES.ASC ? ICONS.UP : ICONS.DOWN
                                
                        } alt="sort icon" />
                    </div>
            }

            {
                downloadFiles &&
                    <div 
                        className={`
                            filter_sorter_btn__sorter
                        `}
                        data-toggle="tooltip" 
                        title={downloadFilesTooltip}
                        onClick={downloadFiles}
                    >
                        <img src={downloadIcon} alt={downloadFilesTooltip} />
                    </div>

            }

            {
                cancel &&
                    <div
                        onClick={cancel}
                        data-toggle="tooltip" 
                        title={cancelTooltip}
                    >X</div>
            }

            {
                view &&
                    <div
                        onClick={view}
                        data-toggle="tooltip" 
                        title={`Click to View`}
                    >
                        <img src={viewIcon} alt="view icon" />
                    </div>
            }

            {
                hubspotURL &&
                    <div
                        data-toggle="tooltip"
                        title={hubspotTooltip}
                        onClick={() => window.open(hubspotURL, '_blank').focus()}
                    >
                        <img src={hubspotIcon} alt={hubspotTooltip}/>
                    </div>
            }

            {
                legend &&
                    <div
                        onClick={legend}
                        data-toggle="tooltip" 
                        title={legendTooltip}
                    >
                        <img src={legendIcon} alt="legend icon" />
                    </div>
            }

            {
                stats &&
                    <div
                        onClick={stats}
                        data-toggle="tooltip" 
                        title={statsTooltip}
                        className={`
                        
                        filter_sorter_btn--${inStatsMode ? 'stats_mode' : ''}
                        `}
                    >
                        <img src={statsIcon} alt={statsTooltip} />
                    </div>
            }

            { 
                selectedAssignmentTypes &&
                    <>
                        <div
                            className={`filter_sorter_btn--${selectedAssignmentTypes.indexOf('core') > -1  ? 'active' : ''}`}
                            data-toggle="tooltip" 
                            title={`Click to ${selectedAssignmentTypes.indexOf('core') > -1 ? 'hide': 'view'} CORE assignments`}
                            onClick={() => onAssignmentTypeClicked('core')}
                        >
                            <div>
                                <div className="filter_sorter_btn__shape filter_sorter_btn__shape--square"></div>
                            </div>
                        </div>
                        <div
                            onClick={() => onAssignmentTypeClicked('practice')}
                            data-toggle="tooltip" 
                            title={`Click to ${selectedAssignmentTypes.indexOf('practice') > -1 ? 'hide': 'view'} PRACTICE assignments`}
                            className={`filter_sorter_btn--${selectedAssignmentTypes.indexOf('practice') > -1 ? 'active' : ''}`}
                        >
                            <div>
                                <div className="filter_sorter_btn__shape filter_sorter_btn__shape--diamond"></div>
                            </div>
                        </div>

                        <div
                            onClick={() => onAssignmentTypeClicked('optional')}
                            data-toggle="tooltip" 
                            title={`Click to ${selectedAssignmentTypes.indexOf('optional') > -1 ? 'hide': 'view'} OPTIONAL assignments`}
                            className={`filter_sorter_btn--${selectedAssignmentTypes.indexOf('optional') > -1 ? 'active' : ''}`}
                        >
                            <div>
                                <div className="filter_sorter_btn__shape filter_sorter_btn__shape--circle"></div>
                            </div>
                        </div>
                    </>
            }

            {
                assignmentType &&
                <div
                    onClick={toggleSelectedAssignmentType}
                    data-toggle="tooltip" 
                    title={`Click to toggle ${assignmentType} assignments`}
                    className={`
                        filter_sorter_btn--${isSelected ? 'active' : ''}
                    `}
                >
                    <div className="filter_sorter_btn__prompt mr-1">
                        {assignmentType.charAt(0).toUpperCase() + assignmentType.slice(1)}
                    </div>
                    <div>
                        <div 
                            className={`
                                filter_sorter_btn__shape filter_sorter_btn__shape--${assignmentType === 'core' ? 'square' : assignmentType === 'practice' ? 'diamond' : 'circle'}
                                
                            `}></div>
                    </div>
                </div>
            }

            {
                emailAddress &&
                <DaButton
                    onClick={() => navigator.clipboard.writeText(emailAddress)}
                    toolTip={emailTooltip}
                    icon={emailIcon}
                    />
            }

            {
                history &&
                <DaButton
                    onClick={history}
                    toolTip={historyTooltip}
                    icon={historyIcon}
                    />
            }

            {
                note &&
                <DaButton
                    onClick={note}
                    toolTip={noteTooltip}
                    icon={noteIcon}
                    />
            }

            {
                link &&
                <DaButton
                    onClick={link}
                    toolTip={linkTooltip}
                    icon={linkIcon}
                    />
            }

            {
                select &&
                <DaButton
                    onClick={select}
                    toolTip={selectTooltip}
                    icon={selectIcon}
                    />
            }

            {
                (cohortInstructorId && cohortStartDate) &&
                <DaButton
                    onClick={() => {
                        window.location = `/assignment-reviews?instructor_id=${cohortInstructorId}&cohort_start_date=${new Date(cohortStartDate).toISOString().slice(0, 10)}&suid=${cohortStudentId}`
                    }}
                    toolTip={cohortTooltip}
                    icon={cohortIcon}
                    />
            }

            {
                calendlyLink &&
                <DaButton
                onClick={() => navigator.clipboard.writeText(calendlyLink)}
                    toolTip={calendlyTooltip}
                    icon={calendlyIcon}
                    />
            }

            {
                toggle &&
                    <div
                        onClick={toggle}
                        data-toggle="tooltip"
                        title={toggleTooltip}
                        className={`
                            filter_sorter_btn--${isSelected ? 'active' : ''}
                        `}
                    >
                        <img src={toggleIcon} alt={toggleTooltip}/>
                    </div>
            }
            {
                reload &&
                    <div
                        onClick={reload}
                        data-toggle="tooltip"
                        title={reloadTooltip}
                        className={`
                            filter_sorter_btn--${isSelected ? 'active' : ''}
                        `}
                    >
                        <img src={reloadIcon} alt={reloadTooltip}/>
                    </div>
            }

            {
                openTab &&
                    <div
                        onClick={openTab}
                        data-toggle="tooltip"
                        title={openTabTooltip}
                        className={`
                            filter_sorter_btn--${isSelected ? 'active' : ''}
                        `}
                    >
                        <img src={openTabIcon} alt={openTabTooltip}/>
                    </div>
            }    

            {
                github &&
                    <div
                        onClick={github}
                        data-toggle="tooltip"
                        title={githubTooltip}
                        className={`
                            filter_sorter_btn--${isSelected ? 'active' : ''}
                        `}
                    >
                        <img src={githubIcon} alt={githubTooltip}/>
                    </div>
            }     
        </div>
    )

}

export default FilterSorterBtn;

const DaButton = ({

    onClick,
    toolTip,
    icon,
    isSelected = false,
    xClass = "" // additional class info
}) => {

    const [ wasClicked, setWasClicked ] = useState(false);

    return(
        <div
            onClick={(e) => {
                e.preventDefault();
                onClick();
                setWasClicked(true);
                setTimeout(() => setWasClicked(false), 1500);
            }}
            data-toggle="tooltip"
            title={toolTip}
            className={`
                filter_sorter_btn--${isSelected ? 'active' : ''}
                filter_sorter_btn--${wasClicked ? 'clicked' : ''}
                ${xClass}
            `}
        >
            <img src={icon} alt={toolTip}/>
        </div>
    )
}