import React, { useState } from "react";
import { TrackAssignmentSelector, TrackAssignmentsList } from "./TrackAssignmentSelector";

const TestComponent = () => { 

    const [ response, setResponse ] = useState(null);

    return (
        <div className='row layer layer-1'>
            <div className="col-5 layer layer-2">
                This part is for getting the track, chapter and assignment for a new rubric
                <TrackAssignmentSelector
                    onUpdate={(data) => setResponse(data)}
                />
                <pre className="text-light">
                    Include the following object in the new rubric info labeled as 'assignment': ( Do not jsonify, send as raw object ) <br/><br/>
                    { JSON.stringify(response, null, 2) }
                </pre>
            </div>

            <div className="col-2 layer layer-2"></div>

            <div className='col-5 layer layer-2'>
                This is for collecting the track, chapter and assignment for an existing rubric
                <TrackAssignmentsList
                    rubric_id={ 25 }
                />
            </div>
        </div>
    )
}

export default TestComponent;