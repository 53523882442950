import React, {useState, useContext} from "react";
import trivjoLibraryService from "../../../services/TrivjoLibraryService";
import TrivjoQuestions from "../questions/TrivjoQuestions";
import TrivjoUpdateLibraryInfo from "./TrivjoUpdateLibraryInfo";
import useOverlays, { ConfirmDialogBox } from "../../../hooks/useOverlays";

const TrivjoEditLibrary = ( { 
    library,
    updateLibrary,
    isInPlayMode = false,
} ) => { 

    const { pushOverlay, popOverlay } = useOverlays();

    const [ editLibrary, setEditLibrary ] = useState({...library});

    const updated = JSON.stringify(editLibrary) !== JSON.stringify(library);

    const onSave = () => { 
        trivjoLibraryService.update({
            id: editLibrary.id,
            name: editLibrary.name,
            tj_category_id: editLibrary.tj_category_id,
            image_url: editLibrary.image_url,
        })
            .then((updatedLibrary) => {
                updateLibrary(updatedLibrary);
                setEditLibrary(updatedLibrary);
            })
            .catch((error) => console.log(error))
    }

    const onDeleteLibrary = (e) => {

        pushOverlay({
            position: { x: e.clientX, y: e.clientY },
            component: <ConfirmDialogBox
                component={<>Are you sure you want to delete this library?</>}
                followUpComponent={<>You have questions in this library.<br />Are you sure you want to delete it?</>}
                doubleConfirm={library.question_count > 0}
                respond={(response) => {
                    if ( response ) {       
                        trivjoLibraryService.destroy(library.id)
                            .then(() => updateLibrary(null))
                            .catch((error) => console.log(error))
                            .finally(() => popOverlay());
                    } else { 
                        popOverlay();
                    }
                }}
            />,
        })
    }

    return (
        <div id="tivjo_edit_library" className="row">
            {
                !library ?
                    <div className="col-12 text-center">
                        Loading...
                    </div>
                    :
                    <>
                        <div className="col-12">
                            <TrivjoUpdateLibraryInfo
                                library={editLibrary}
                                updateLibrary={setEditLibrary}
                                onSave={onSave}
                                onDeleteLibrary={onDeleteLibrary}
                                updated={updated}
                                finished={popOverlay}
                                saveLibrary={onSave}
                                deleteLibrary={onDeleteLibrary}
                                isInPlayMode={isInPlayMode}
                            />
                            
                        </div>
                        <div className="col-12">
                            <TrivjoQuestions 
                                library={library}
                                updateLibrary={updateLibrary}
                                isInPlayMode={isInPlayMode}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default TrivjoEditLibrary;