import React, { useEffect, useRef, useState } from "react";
import studentService from '../../../services/StudentService';
import FoundStudent from './FoundStudent';
import {useNavigate} from "react-router-dom";

const SearchStudents = () => { 

    const ref = useRef();
    const nav = useNavigate()

    const [ searchText, setSearchText ] = useState("");
    const [ students, setStudents ] = useState(null);
    const [searchDebounceTimeout, setSearchDebounceTimeout] = useState();

    let lastSearchText = "";

    const searchTextUpdated = async (newText) => {
        clearTimeout(searchDebounceTimeout);
        
        if ( newText.length < 3 ) {
            setStudents(null);
        } else if ( newText.length > 2 && newText !== lastSearchText ) {
            setSearchDebounceTimeout(setTimeout(runSearchText, 250, newText));
        } 

        lastSearchText = newText;
        setSearchText(newText);
    }

    const runSearchText = (newText) => {
        studentService.searchStudents(newText)
        .then ( resp => setStudents(resp.map( student => ({
            ...student,
            prompt: `${student.first_name} ${student.last_name} ${student.instructor ? `(${student.instructor})` : ""} `
        }))) )
    }

    const selectStudent = (e, student) => {
        e.preventDefault();

        lastSearchText = student.prompt;
        setSearchText(student.prompt);
        setStudents(null);
        nav(`student/pull/${student.id}`);
            setSearchText("");
        // let searchText = document.getElementById("search_text")
        // searchText.focus(); 
    }

    return (
        <div id='search_students' className="row">
            <div className="col-12 ">
                <input 
                    name={Math.random()} /* prevents browser autocompelte */
                    id="search_text"
                    type="text"
                    className="form-control"
                    value={ searchText }
                    placeholder="Enter Student Name or Email Address"
                    onChange={ e => searchTextUpdated(e.target.value) }
                    />
            </div>
            {
                students !== null && students.length > 0 &&
                    <div id='search_students__list'>
                        {
                                students.map( student =>
                                    <div 
                                        key={`found_student_${student.id}`} 
                                        className="row"
                                        onClick={ e => selectStudent(e, student)}
                                        >
                                        <div className="col-12">
                                            <FoundStudent student={ student }/>
                                        </div>
                                    </div>
                                    )
                        }
                    </div>
                
            }
        </div>
    )
}

export default SearchStudents;