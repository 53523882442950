import React, { useState } from "react";

const SemToolsSelector = ({
    sems,
    cohorts,
    close,
    loadStudents
}) => {

    const [previousCohorts] = useState(cohorts.filter( cohort => cohort.has_expired));
    const [currentCohorts] = useState(cohorts.filter( cohort => cohort.is_in_session));
    const [upcomingCohorts] = useState(cohorts.filter( cohort => cohort.is_upcoming));
    const [selectedCohorts, setSelectedCohorts] = useState([]);
    const [selectedSems, setSelectedSems] = useState([]);

    const cohortSelected = (cohort_id) => {
        if (selectedCohorts.includes(cohort_id)) {
            setSelectedCohorts(selectedCohorts.filter( id => id !== cohort_id));
        } else {
            setSelectedCohorts([...selectedCohorts, cohort_id]);
        }
    }

    const semSelected = (sem_id) => {
        if (selectedSems.includes(sem_id)) {
            setSelectedSems(selectedSems.filter( id => id !== sem_id));
        } else {
            setSelectedSems([...selectedSems, sem_id]);
        }
    }
console.log('selectedSems', selectedSems);
    return (
        <div className="row layer layer-1 p-1 window_900">
            <div className="col-12 ">
                <b>Select One or More SEMs:</b>
                <div className="row ">
                    {
                        sems.map( sem => (
                            <div className="col-3" key={sem.id}>
                                <div 
                                    className={`
                                        hover_text
                                        ${ selectedSems.includes(sem.id) ? 'bg-success' : '' }
                                    `}
                                    onClick={() => semSelected(sem.id)}
                                > 
                                        {sem.first_name} {sem.last_name}
                                    </div>
                            </div>
                        ))
                    }
                    <div className="col-12 mt-2 text-right">
                        <button
                            className="btn btn-sm btn-warning"
                            onClick={() => setSelectedSems([])}
                            disabled={selectedSems.length === 0}
                        >Clear Selected SEMs</button>

                        <button
                            className="btn btn-sm btn-success ml-2"
                            onClick={() => loadStudents(selectedSems, selectedCohorts)}
                            disabled={selectedSems.length === 0 && selectedCohorts.length === 0}
                        >Load Students</button>

                        <button
                            className="btn btn-sm btn-info ml-2"
                            onClick={close}
                        >Close</button>
                    </div>
                </div>
            </div>
            <div className="divider col-12 mt-2 mb-2"><div></div></div>
            <div className="col-12 ">
                <b className="">Select One or More Cohorts:</b>
                <div className="row mt-2">
                    <div className="col-12">
                        <CohortList 
                            title="Previous Cohorts"
                            cohorts={previousCohorts}
                            selectedCohorts={selectedCohorts}
                            cohortSelected={cohortSelected}
                            />
                    </div>
                    <div className="col-12">
                        <CohortList
                            title="Current Cohorts"
                            cohorts={currentCohorts}
                            selectedCohorts={selectedCohorts}
                            cohortSelected={cohortSelected}
                            />
                    </div>
                    <div className="col-12">
                        <CohortList
                            title="Upcoming Cohorts"
                            cohorts={upcomingCohorts}
                            selectedCohorts={selectedCohorts}
                            cohortSelected={cohortSelected}
                            />
                    </div>
                </div>
            </div>
            <div className="col-12 text-right">

                <button
                    className="btn btn-sm btn-success mr-2"
                    onClick={() => {
                        loadStudents(selectedSems, selectedCohorts);
                    }}
                    disabled={selectedCohorts.length === 0 && selectedSems.length === 0}
                >Load Students</button>

                <button
                    className="btn btn-sm btn-warning mr-2"
                    onClick={() => {
                        setSelectedCohorts([]);
                    }}
                    disabled={selectedCohorts.length === 0}
                >Clear Selected Cohorts</button>

                <button
                    className="btn btn-sm btn-info"
                    onClick={() => {
                        close()
                    }}
                >Close</button>
            </div>
        </div>
    )
}

export default SemToolsSelector;

const CohortList = ({
    title,
    cohorts,
    cohortSelected,
    selectedCohorts
}) => {

    return (
        <>
            <h4>{title}</h4>
            <table className="table table-sm text-light">
                {
                    cohorts
                        .map( cohort =>{ 

                            return(
                                <tr

                                    className={`
                                        cohort_selector__cohort
                                        ${ selectedCohorts.includes(cohort.cohort_id) ? 'bg-success' : '' }
                                        `}
                                    onClick={() => {
                                        cohortSelected(cohort.cohort_id);
                                    }}
                                    >
                                        <td >
                                            {cohort.instructor_name}
                                        </td>
                                        <td>
                                            {cohort.stack_name}
                                        </td>
                                        <td>
                                            {cohort.cohort_start_date}
                                        </td>
                                        <td>
                                            {cohort.cohort_end_date}
                                        </td>
                                </tr>
                            )}
                        )
                }
            </table>
        </>
    )
}
