/*
    TA Buddy Service
*/

import { config } from '../config'; 
import axios from './TABAxios';

class TABService {
    model = null;

    _get = (path) => {
        return new Promise((resolve, reject) => {
            axios.get(`${config.API_URL}/${path}`)
                .then ( resp => {
                    resolve(resp.data);
                })
                .catch( err => reject(err))
        })
    }

    _post = (path, content, headers = {}) => {
        return new Promise((resolve, reject) => {
            axios.post(`${config.API_URL}/${path}`, content, headers)
                .then( resp => {
                    resolve(resp.data);
                })
                .catch( err => reject(err))
        })
    }

    _patch = (path, content, headers = {}) => {
        return new Promise((resolve, reject) => {
            axios.patch(`${config.API_URL}/${path}`, content, headers)
                .then( resp => {
                    resolve(resp.data)
                })
                .catch( err => reject(err));
        })
    }

    _delete = (path) => {
        return axios.delete(`${config.API_URL}/${path}`)
    }

    create = (entity) => {
        return this._post(
            `${this.model}`,
            entity
        )
    }

    destroy = (id) => { // deprecated use delete instead
        return this._delete(
            `${this.model}/${id}`
        )
    }

    // same as destroy. Added for consistency; does not include 'delete' in the path
    delete = (id) => {
        return this._delete(
            `${this.model}/${id}`
        )
    }

    get = (id) => {
        return this._get(
            `${this.model}/${id}`
        )
    }

    getMy = () => {
        return this._get(
            `${this.model}/my`
        )
    }

    get_all = () => {
        return this._get(
            `${this.model}`
        )
    }

    update = (entity) => {
        return this._patch(
            `${this.model}/update`,
            entity
        )
    }
}

export default TABService;