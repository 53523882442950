/* extend this class to create a new filter */
/* because there is no memory between sheets, keep track of your filter settings for each sheet in this class */

export default class Filter {

    constructor( name) {
        this.name = name;
    }

    apply = ( student, studentData ) => () => {} // override this method in the child class; return true/false
}