
export const TIMER_EVENT = 'TIMER_EVENT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const CLEAR_MY_ALERTS = 'CLEAR_MY_ALERTS';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
export const OVERLAY_CLOSE_CLICK = 'OVERLAY_CLOSE_CLICK';
export const RELOAD_COHORT = 'RELOAD_COHORT';

export const UPDATE_STUDENT = 'UPDATE_STUDENT'; // detail={student: {updated student object}, localOnly(optional): bool}
                                                            // localOnly: if true, only update local state, do not send to server;
                                                            // default = false
export const UPDATE_SHEET_STUDENTS = 'UPDATE_SHEET_STUDENTS'; // detail={students: [{updated student object}], localOnly(optional): bool}

const eventHandler = {
    on(event, callback) {
        document.addEventListener(event, callback);
    },
    //data param should always be of an object type, which can then be destructured on the receiving end --PM 1/7/23
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};
  
export default eventHandler;

class TimerEvent {
    second = 0;

    constructor() {

        setInterval(() => {
            this.second ++;
            
            eventHandler.dispatch(TIMER_EVENT, { second: this.second, minute: Math.floor( this.second / 60 ) })
            
        }, 1000)
    }
}

new TimerEvent();

if (process.env.NODE_ENV === 'production')
    window.addEventListener("error", e => {
        console.log(e);
        window.alert("There has been an unknown error. Redirecting to home page.");
        window.location.href = "/";
    })