import React, { useEffect, useState } from "react";
import eventHandler, { TIMER_EVENT } from '../../events';
import helpers from "../../helpers/Helpers";

const Timer = ( { startMinutes, callback } ) => {

    const [ minutes, setMinutes ] = useState(startMinutes); 

    let startTime = new Date();

    useEffect( () => {
        eventHandler.on( TIMER_EVENT, updateTime );

        return () => {
            eventHandler.remove( TIMER_EVENT, updateTime );
        }
    }, [])

    const updateTime = () => {
        setMinutes( helpers.minutesBetween(startTime, new Date()) + startMinutes );
        if ( callback ) callback();
    }

    let hours = parseInt(minutes / 60);

    return (
        <span >
            {hours}<span className="timer-blink">:</span>{("00" + (minutes - ( hours * 60 ))).slice(-2)}
        </span>
    )
}

export default Timer;