import React, { useState, useEffect } from 'react';
import FilterSorterBtn from '../filter_sorter/FilterSorterBtn';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import ValueSorter from '../filter_sorter/sorters/ValueSorter';
import { SORT_TYPES } from '../filter_sorter/sorters/Sorter';
import PercentDisplay from '../my_cohort_elements/PercentDisplay';
import ValueSortSelector from '../my_cohort_elements/ValueSortSelector';
import ValuesFilter from '../filter_sorter/filters/ValuesFilter';
import { DATE_GROUPS } from '../attendance_sheet/AttendanceSheet';
import { CORE_ASSIGNMENTS_DISPLAY } from '../assignments_sheet/AssignmentAttendanceSheet';
import BeltDisplay from './BeltDisplay';
import ExamSheetsStats from './ExamSheetsStats';
import StudentExamAssignment from './assign_exams/StudentExamAssignment';
import FiltersExamAssignment from './assign_exams/FiltersExamAssignment';
import ExamEmail from './exam_email/ExamEmail';
import { BELT_LEVELS } from './BeltDisplay';
import ManualHelper, { MANUAL_LINKS } from '../../../helpers/ManualHelper';
import AddTempExam from './assign_exams/AddTempExam';
import ActionButtons, { Label, BasicActionButton, ICONS, SortButton, BUTTON_SIZES } from '../../elements/ActionButtons';
import  eventHandler, { UPDATE_STUDENT } from '../../../events';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import MyCohortContext from '../../../store/MyCohortContext';
import useRoster from '../../../hooks/useRoster';

export const calculateBPR = (roster, cohortStartDate) => {

    const unsubmittedExamPolicyDate = new Date('2023-07-03'); //after 7/3/2023, only submitted exams count towards BPR
    
    let submitted = 0;
    let passed = 0;
    roster.forEach( student => { 
        if ( 
                student.belt_level !== null && 
                student.belt_level >= BELT_LEVELS.SUBMITTED &&
                    (
                        (
                            new Date(cohortStartDate) >= unsubmittedExamPolicyDate && 
                            student.belt_level !== BELT_LEVELS.NOT_SUBMITTED
                        ) 
                        || new Date(cohortStartDate) < unsubmittedExamPolicyDate 
                    ) 
            ) { // skip unsumbitted exams; rule only on cohorts on/after 7/3/2023
                    submitted++;
                    if ( student.belt_level >= 0 ) passed++;
                    if ( student.id === 83828) console.log(student)
                }
    })

    return submitted === 0 ? 0 : (passed / submitted * 100).toFixed(0);
}

export default function ExamsSheet({
}) {

    const { pushOverlay, popOverlay } = useOverlays();
    const {
        sortedRoster,
        studentsData,
        cohortInfo,
        inStatsMode,
    } = React.useContext(MyCohortContext);

    const { checkStudentSelected,
        hoveredStudentId,
        setHoveredStudentId,
        clearHoveredStudent,
    } = useRoster();

    const [ dateGroup, setDateGroup ] = useState(DATE_GROUPS.OVERALL)
    const [ percentSort, setPercentSort ] = useState(null);
    const [ beltsSort, setBeltsSort ] = useState(null); 
    const [ percentFilterValues, setPercentFilterValues ] = useState([]);
    const [ viewingStats, setViewingStats ] = useState(false);
    const [ assignExamsList, setAssignExamsList ] = useState(null); // list of students to assign exams to [student1, student2, ...
    const [ viewExamTabsLegend, setViewExamTabsLegend ] = useState(false);
    const [ temporaryExam, setTemporaryExam ] = useState(null);
    const [ activeExams, setActiveExams ] = useState(cohortInfo.instructor_info.open_exams);

    useEffect( () => { 
        
        if ( filterSorter.sorter && filterSorter.sorter.name === 'exam_assignments_percent' ) {
            setPercentSort( filterSorter.sorter.sortType );
        } else {
            setPercentSort(null);
        }

        if ( filterSorter.sorter && filterSorter.sorter.name === 'exam_belts_sort' ) {
            setBeltsSort( filterSorter.sorter.sortType );
        } else {
            setBeltsSort(null);
        }

    }, [sortedRoster]);// watch the filterSorter.sorter object for changes.

    useEffect( () => {
        if (temporaryExam )
            setActiveExams([temporaryExam, ...cohortInfo.instructor_info.open_exams])
    }, [temporaryExam])

    useEffect( () => {

        if ( beltsSort === null ) return;

        filterSorter.addSorter( 
            new ValueSorter(
                'exam_belts_sort', 
                'belt_level',
                beltsSort
            ) 
        );

    }, [beltsSort])

    useEffect( () => {

        if ( percentSort === null ) return;

        filterSorter.addSorter( 
            new ValueSorter(
                'exam_assignments_percent', 
                percentSort === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                    'core_assignments_to_date_percent'
                    :
                    'core_assignments_overall_percent',
                percentSort
            ) 
        );

    }, [percentSort])

    useEffect( () => {
        if ( 'exams_core_sort_type' in filterSorter.settings ) {
            setDateGroup(filterSorter.settings.exams_core_sort_type);
        }

    }, [])

    const clearExams = () =>{ 
        sortedRoster.forEach(student => {
            if (student.temp_exam) {
                eventHandler.dispatch(UPDATE_STUDENT,{student: {
                    ...student,
                    temp_exam: null,
                }, localOnly: true})
            }
        })}


    let examDueDate = new Date(cohortInfo.cohort_end_date);
    examDueDate.setDate(examDueDate.getDate() -2); // due date is 2 days before cohort end date

    let minimumAssignments = Math.ceil(sortedRoster[0].core_assignments_total_overall * .90);
    if ( minimumAssignments == sortedRoster[0].core_assignments_total_overall ) minimumAssignments -- ; // 90% or 1 assignment
    
    const itemExamsCount = sortedRoster.filter(student => student.temp_exam).length;

    return (
        <div id="exams_sheet" className='my_cohort__right_panel'>
            <div className='my_cohort__filters_bar layer layer-3 '>
            <div
                    className='my_cohort__filters_bar__header'
                >
                    <div className='text-left'>
                        <ManualHelper linkSlug={MANUAL_LINKS.EXAMS_SHEET} />
                    </div>
                </div>
                <div className='my_cohort__filters_bar__view_select'>
                    <div className='assignment_reviews__row--filters assign_attend__row'>
                        <div className='assign_attend__percent_col--filters'>
                            <select
                                className="mb-1"
                                value={dateGroup}
                                onChange={(e) => {
                                    setDateGroup(e.target.value);
                                    filterSorter.settings.exams_core_sort_type = e.target.value;}}
                            >
                                <option value={CORE_ASSIGNMENTS_DISPLAY.OVERALL}>Overall</option>
                                <option value={CORE_ASSIGNMENTS_DISPLAY.TODATE}>To Date</option>
                            </select>
                            <ActionButtons
                                buttons={[
                                    BasicActionButton({
                                        icon: ICONS.FILTER,
                                        isSelected: filterSorter.getFilter('exam_attendance_percent'),
                                        onClick: (e) => {
                                            pushOverlay({
                                                component: <ValueSortSelector
                                                    close={() => popOverlay()}
                                                    columnValueName={
                                                        dateGroup === DATE_GROUPS.TODATE ?
                                                            'core_assignments_to_date_percent'
                                                            :
                                                            'core_assignments_overall_percent'
                                                    }
                                                    studentsData={studentsData}
                                                    isPercent={true}
                                                    filterValues={percentFilterValues}
                                                    setFilterValues={(values) => { 
                            
                                                        if ( values.length > 0) {
                                                            setPercentFilterValues(values);
                                                            filterSorter.addFilter(new ValuesFilter(
                                                                'exam_attendance_percent',
                                                                values, 
                                                                dateGroup === DATE_GROUPS.TODATE ?
                                                                    'core_assignments_to_date_percent'
                                                                    :
                                                                    'core_assignments_overall_percent'
                                                                ))
                                                        } else { // filters were cleared
                                                            setPercentFilterValues(values);
                                                            filterSorter.removeFilter('exam_attendance_percent');
                                                        }
                                                    }}
                                                />,
                                                position: {x: e.clientX, y: e.clientY},
                                            })
                                        }
                                    }),
                                    BasicActionButton({
                                        icon: ICONS.CANCEL,
                                        visible: filterSorter.getFilter('exam_attendance_percent') !== undefined,
                                        isSelected: true,
                                        onClick: () => {
                                            setPercentFilterValues([]);
                                            filterSorter.removeFilter('exam_attendance_percent');
                                        }
                                    }),
                                    SortButton({
                                        sortType: percentSort,
                                        setSortType: () => {
                                            setPercentSort(
                                                percentSort === SORT_TYPES.ASC ? 
                                                    SORT_TYPES.DESC 
                                                    : 
                                                    SORT_TYPES.ASC
                                                )
                                        },
                                    }),
                                ]}
                            />
                        </div>

                        <div className='exams_sheet__belts_column d-flex justify-content-center'>
                            <FilterSorterBtn
                                sortDirection={beltsSort}
                                setSortDirection={() => {
                                    setBeltsSort(
                                        beltsSort === SORT_TYPES.ASC ?
                                            SORT_TYPES.DESC
                                            :
                                            SORT_TYPES.ASC
                                    )
                                }}
                            />
                            <br/>
                            <span
                                className='text-center font-weight-bold ml-1'
                            >
                                {
                                    calculateBPR(sortedRoster, cohortInfo.cohort_start_date)
                                }%
                                
                            </span>
                        </div>

                        <div 
                            className={`
                            exams_sheet__exam_codes_column
                            exams_sheet__exam_codes_column--filters
                            `}
                            >
                                <ActionButtons
                                    buttons={[
                                        Label({
                                            text: "Bulk Assign Exams",
                                            onClick: () => {
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.MODAL,
                                                    component: <FiltersExamAssignment
                                                        sortedRoster={sortedRoster}
                                                        cohortInfo={cohortInfo}
                                                        activeExams={activeExams}
                                                        clearExams={clearExams}
                                                        close={() => popOverlay()}
                                                        setTemporaryExam={setTemporaryExam}
                                                        sendExamCodes={() => {
                                                            popOverlay();
                                                            setAssignExamsList(sortedRoster.filter(student => student.temp_exam));
                                                        
                                                        }}
                                                    />
                                                })
                                            },
                                        }),
                                        Label({
                                            text: "Add Temp Exam Code",
                                            onClick: () => 
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.MODAL,
                                                    component: <AddTempExam
                                                        stackId={cohortInfo.stack_id}
                                                        addExam={(exam) => {
                                                            popOverlay();
                                                            setTemporaryExam(exam);
                                                        }}
                                                        close={() => popOverlay()}
                                                    />
                                                }),
                                        }),
                                    ]}
                                />
                                {
                                    itemExamsCount > 0 &&
                                        <div className="mt-1">
                                            <ActionButtons
                                                buttons={[
                                                    Label({
                                                        text: "Clear Exam Assignments",
                                                        onClick: () => clearExams(),
                                                        bgColor: 'warning',
                                                        textColor: 'dark',
                                                    }),
                                                    Label({
                                                        text: "Send Exam Codes",
                                                        onClick: () => setAssignExamsList(sortedRoster.filter(student => student.temp_exam)),
                                                        bgColor: 'success',
                                                    }),
                                                ]}
                                            />
                                        </div>
                                }
                            </div>
                        <div 
                            className={`
                                    exams_sheet__exams_column
                                    text-center
                                    font-weight-bold
                            `}
                        >
                            <span className='mr-2'>Exams</span>
                            <ActionButtons
                                size={BUTTON_SIZES.SMALL}
                                buttons={[
                                    Label({
                                        text: "Assignments Legend",
                                        onClick: (e) => 
                                            pushOverlay({
                                                position: {x: e.clientX, y: e.clientY},
                                                component: <ExamTabsLegend
                                                    close={() => popOverlay()}
                                                />
                                            
                                            }),
                                    })
                                ]}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className='responsive_browser_row_max'>
                {
                    inStatsMode &&
                    <> 
                        <div
                            className=' text-center mt-1 mb-1'
                        >
                            <FilterSorterBtn
                                stats={() => setViewingStats(true)}
                                inStatsMode={inStatsMode}
                            />
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </>
                }
                {
                    sortedRoster.map( (student, index) => {

                        // const student = studentsData[rosterStudent.id];
                        return (
                            <>
                                <div className={`assignment_reviews__row assign_attend__row
                                                    ${index % 2 ? '' : 'assignments_browser__student_row--highlight'}
                                                    ${hoveredStudentId === student.id ? 'cohort_list__row--selected_student' : ''}
                                                    ${checkStudentSelected(student.id) ? 'bg-secondary text-white' : ''}
                                                    `
                                                    } key={`assign_attend_row${student['id']}`}
                                                    onMouseEnter={() => setHoveredStudentId(student.id)}
                                                    onMouseLeave={() => clearHoveredStudent()}
                                                    
                                                    >
                                                        
                                    <div className='assign_attend__percent_col mt-1 '>
                                        <PercentDisplay
                                            percent={parseFloat(
                                                dateGroup === DATE_GROUPS.TODATE ?
                                                    studentsData[student.id]['core_assignments_to_date_percent']
                                                    :
                                                    studentsData[student.id]['core_assignments_overall_percent']
                                                )
                                            }
                                            highPercent={1}
                                            bottomPercent={.9}
                                        />
                                        <div>
                                            {
                                                dateGroup === DATE_GROUPS.TODATE ?
                                                <>
                                                    {studentsData[student.id]['core_assignments_to_date']}/{studentsData[student.id]['core_assignments_total_to_date']}
                                                </>
                                                :
                                                <>
                                                    {studentsData[student.id]['core_assignments_overall']}/{studentsData[student.id]['core_assignments_total_overall']}
                                                </>
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className='exams_sheet__belts_column d-flex justify-content-center'>
                                        <BeltDisplay
                                            belt_level={studentsData[student.id]['belt_level']}
                                            belt_score={studentsData[student.id]['belt_score']}
                                            exams={studentsData[student.id]['exams']}
                                        />
                                    </div>
                                    
                                    <div 
                                    className={`
                                        exams_sheet__exam_codes_column
                                        `}
                                    >
                                        <StudentExamAssignment
                                            student={student}
                                            activeExams={activeExams}
                                            key={`student_exam_assignment_${student.id}_${Math.random()}`}
                                        />
                                    
                                    </div>

                                    <div 
                                        className={`
                                                exams_sheet__exams_column
                                                pt-1
                                        `}
                                    >
                                        <ExamsDisplay
                                            student={student}
                                            />
                                    </div>

                                    
                                </div>

                                <div style={{clear: 'both'}}></div>
                            </>
                        )
                    })
                }
            </div>

            {
                viewingStats &&
                    <div className='modal_overlay modal_overlay--30'>
                        <ExamSheetsStats
                            sortedRoster={sortedRoster}
                            cohortStartDate={cohortInfo.cohort_start_date}
                            close={() => setViewingStats(false)}
                            />
                    </div>
            }

            {
                assignExamsList &&
                <div className='modal_overlay modal_overlay--50'>
                    <ExamEmail
                        minimumAssignments={minimumAssignments}
                        dueDate={examDueDate}
                        students={assignExamsList}
                        close={() => setAssignExamsList(false)}
                    />
                </div>
            }
        </div>
    )
}

export const EXAM_STATUSES = {
    OPEN: 1,
    SUBMITTED: 2,
    FAILED: 3,
    PASSED: 4,
    UNSUMBMITTED: 5,
    DEPLOY_WINDOW: 6,
}

export const getExamStatus = (exam) => {

    let status = null;

    if ( 
        exam.score === 0 && 
        exam.submitted_at !== null
        ) {
            status = EXAM_STATUSES.SUBMITTED;

            //if ( exam.minutes_since_submission !== null && exam.minutes_since_submission < 1441 ) status = EXAM_STATUSES.DEPLOY_WINDOW;
        }

    if ( exam.score === 0 && exam.submitted_at === null && exam.expired ) status = EXAM_STATUSES.UNSUMBMITTED;
    if ( exam.score > 0 && exam.score < 8 ) status = EXAM_STATUSES.FAILED;
    if ( exam.score >= 8 ) status = EXAM_STATUSES.PASSED;
    if ( exam.started_at !== null && exam.submitted_at === null && !exam.expired ) status = EXAM_STATUSES.OPEN;
    
    return status
}

export const ExamTabsLegend = ({ close }) => {

    return <div className='row layer layer-1 justify-content-center'>
        <div className='col-6'>
            <ExamTab
                student={{id:"open_demo"}}
                examTitle='Open'
                status={EXAM_STATUSES.OPEN}
                demo={true}
            />
        </div>

        <div className='col-6'>
            <ExamTab
                student={{id:"submitted_demo"}}
                examTitle='Submitted'
                status={EXAM_STATUSES.SUBMITTED}
                demo={true}
            />
        </div>

        <div className='col-6'>
            <ExamTab
                student={{id:"assigned_demo"}}
                examTitle='Sent Exam Code'
                status={'assigned'}
                demo={true}
            />
        </div>

        <div className='col-6'>
            <ExamTab
                student={{id:"passed_demo"}}
                examTitle='Passed'
                status={EXAM_STATUSES.PASSED}
                demo={true}
            />
        </div>

        <div className='col-6'>
            <ExamTab
                student={{id:"failed_demo"}}
                examTitle='Failed'
                status={EXAM_STATUSES.FAILED}
                demo={true}
            />
        </div>

        <div className='col-6'>
            <ExamTab
                student={{id:"assigned_demo"}}
                examTitle='Unsubmitted'
                status={EXAM_STATUSES.UNSUMBMITTED}
                demo={true}
            />
        </div>

        <div className='col-12 text-center'>
            <button
                className='btn btn-info'
                onClick={() => close()}
            >Close</button>
        </div>
    </div>
}

export const ExamsDisplay = ({
    student
}) => { 
    
    return (
        <div className={`
            exams_sheet__exams_display
            `}>
            {
                student['exams'].map( exam => {

                    const status = getExamStatus(exam);
                    let toolTipText = `${exam.title} - ${["", "Open", "Submitted", "Failed", "Passed", "Unsubmitted", "Deploy Window"][status]}`
                    let submittedAt = new Date(exam.submitted_at);
                    submittedAt = submittedAt.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }); 
                    
                    if ( status === EXAM_STATUSES.SUBMITTED ) {
                        const hours = Math.floor(exam.minutes_since_submission / 60);
                        toolTipText += ` ${submittedAt} (${hours}h ${exam.minutes_since_submission - (hours * 60)}m)`
                    }

                    if ( status === EXAM_STATUSES.DEPLOY_WINDOW ) {
                        const hours = Math.floor(exam.minutes_since_submission / 60);
                        const depHours = Math.floor((1440 - exam.minutes_since_submission) / 60);

                        toolTipText += `\n${submittedAt} (${hours}h ${exam.minutes_since_submission - (hours * 60)}m)
                            \n${depHours}h  ${exam.minutes_since_submission - (hours * 60)}m remaining
                            `
                    }

                    if ( status === EXAM_STATUSES.OPEN ) {
                        const hours = Math.floor(exam.minutes_since_open / 60);
                        const depHours = Math.floor((1440 - exam.minutes_since_open) / 60);
                        let startedAt = new Date(exam.started_at);
                        startedAt = startedAt.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }); 

                        toolTipText += `\n${startedAt} (${hours}h ${exam.minutes_since_open - (hours * 60)}m)
                            \n${depHours}h  ${exam.minutes_since_open - (hours * 60)}m remaining
                            `
                    }
                    
                    return (
                        <ExamTab
                            student={student}
                            examTitle={exam.title}
                            tooltip={toolTipText}
                            status={getExamStatus(exam)}
                            />
                    )
                })
            }

            {
                (student.assigned_exam && student.historical_exam_ids.indexOf(student.assigned_exam.id) < 0 ) &&
                    <ExamTab 
                        student={student} 
                        examTitle={student.assigned_exam.title}
                        tooltip={"Assigned Exam"}
                        status={'assigned'}
                        />
            }
        </div>
    )

}

export const ExamTab = ({
    student,
    examTitle,
    status,
    tooltip,
    demo = false
}) => {if ( student.id === 80367 ) console.log(student)

    if ( student.assigned_exam && student.assigned_exam.added_by )
        tooltip += '\nissuer: ' + student.assigned_exam.added_by + ' @ ' + student.assigned_exam.added_on

    return <div
                className={`
                    noselect
                    exams_sheet__exams_display_exam
                    exams_sheet__exams_display_exam--${demo ? 'demo' : ''}
                    exams_sheet__exams_display_exam--${status}
                `}
                data-toggle="tooltip" 
                title={tooltip}
                key={`exam_display_assigned_${student.id}`}
                >
                {examTitle}
            </div>
}