/*global chrome*/ // This has to be her to make chrome work.
import { config } from '../config'

export const DOWNLOADER_STATES ={
    NOT_INSTALLED: 0,
    INSTALLED: 1,
    NOT_CHROME: 2
}

class AssignmentDownloaderService {

    constructor() { 
        this.getStatus((status) => {
            this.status = status;
        });
    }

    downloadAssignments = (
        instructorName,
        instructorId,
        cohortDate,
        TitleOfDownload,
        downloadList = [],
        githubList = []
    ) => { 
        chrome.runtime.sendMessage(config.DLDR_ID, {
            action: "downloadAssignments",
            data: {
                cohortData: {
                    instructorName,
                    instructorId,
                    cohortDate,
                    TitleOfDownload
                },
                downloadData: {
                    downloadList,
                    githubList
                }
            }
        })
    }
    
    getStatus = (callback) => {

        if (typeof chrome != 'object') return callback(DOWNLOADER_STATES.NOT_CHROME);
        
        if(typeof chrome.runtime != 'object') return callback(DOWNLOADER_STATES.NOT_INSTALLED);

        chrome.runtime.sendMessage('pbnjipaeiiajjaonfnflnnpgpooalemj',{action: "getVersion"}, // check to see if we can communicate with the ext.
                (response) => {
                    if(chrome.runtime.lastError) {
                        return callback(DOWNLOADER_STATES.NOT_INSTALLED);
                    }
                    return callback(DOWNLOADER_STATES.INSTALLED);
                });
    }
}

export default new AssignmentDownloaderService();