import React, { useEffect, useState } from "react";
import eventHandler, { TIMER_EVENT } from '../events';

const DojoTime = ( props ) => {

    const [ tick, setTick ] = useState(0)
    

    useEffect( () => {
        eventHandler.on( TIMER_EVENT, updateTick )

        return () => {
            eventHandler.remove( TIMER_EVENT, updateTick )
            updateTick()
        }
    }, [])

    const updateTick = () => setTick( tick => tick + 1)

    let now = new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    let date = now.slice(0, now.indexOf(','));
    now = now.slice(date.length + 2);
    let time = now.slice(0, now.indexOf(" ") - 3)
    now = now.slice(now.indexOf(" ") + 1)

    return (
        <div>
            <div>Dojo Time</div>
            {
                `${date} ${time} ${now}`
            }
            &nbsp;PT
        </div>
    )
}

export default DojoTime;