import React from 'react';
import { SHEETS } from '../../helpers/Constants';
import LoadingSpinner from '../modal_assets/LoadingSpinner';
import MyCohortSEMSheet from './sem_sheet/MyCohortSEMSheet';
import AssignmentAttendanceSheet from './assignments_sheet/AssignmentAttendanceSheet';
import AttendanceSheet from './attendance_sheet/AttendanceSheet';
import ExamsSheet from './exams_sheet/ExamsSheet';
import PartTimeSheet from './instructor_sheet/InstructorSheet';
import MyCohortContext from '../../store/MyCohortContext';


const MyCohortSheets = ({
}) => { 

    const {
        sheetsData,
        selectedSheet,
    } = React.useContext(MyCohortContext);

    return (
        <div className='row'>
            <div  className="col-12">   
                <div id="my_cohort__sheets">
                {
                    selectedSheet === SHEETS.ASSIGNMENTS ?
                    <>
                        {
                            sheetsData === null ? // || cohortCalendar === null
                                <LoadingSpinner />
                                :
                                <AssignmentAttendanceSheet />
                        }
                    </>
                    :
                    <></>
                }

                {
                    selectedSheet === SHEETS.ATTENDANCE &&
                        <AttendanceSheet />
                }

                {
                    selectedSheet === SHEETS.EXAMS &&
                    <ExamsSheet />
                }

                {
                    selectedSheet === SHEETS.SEM ?
                        <MyCohortSEMSheet/>
                    : 
                    <></>
                }

{
                    selectedSheet === SHEETS.INSTRUCTOR ?
                        <PartTimeSheet/>
                    : 
                    <></>
                }
                </div>
            </div>
        </div>
    )
}

export default MyCohortSheets;