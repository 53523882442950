import React from 'react';

import ManualHelper from "../../helpers/ManualHelper";

import { SORT_TYPES } from '../my_cohort//filter_sorter/sorters/Sorter';
import sortIcon from '../../assets/sort_icon.png';
import filterIcon from '../../assets/filter_icon.png';
import downloadIcon from '../../assets/download_icon.png';
import viewIcon from '../../assets/view_icon.png';
import emailIcon from '../../assets/email_icon.png';
import selectIcon from '../../assets/select_icon.png';
import statsIcon from '../../assets/stats_icon.png';
import toggleIcon from '../../assets/toggle_icon.png';
import reloadIcon from '../../assets/reload_icon.png';
import openTabIcon from '../../assets/open_tab_icon.png';
import githubIcon from '../../assets/github_icon.png';
import historyIcon from '../../assets/history_icon.png';
import linkIcon from '../../assets/link_icon.png';
import cohortIcon from '../../assets/cohort_icon.png';
import calendlyIcon from '../../assets/calendly_icon.png';
import noteIcon from '../../assets/note_icon.png';
import legendIcon from '../../assets/legend_icon.png';
import hubspotIcon from '../../assets/hubspot_icon.png';
import spinnerIcon from '../../assets/loading_spinner.gif';
import editIcon from '../../assets/edit_icon.png';
import addIcon from '../../assets/add_icon.png';
import cancelIcon from '../../assets/cancel_icon.png';
import checkmarkIcon from '../../assets/checkmark_icon.png';
import redFlagIcon from '../../assets/red_flag_icon.png';
import upIcon from '../../assets/up_icon.png';
import downIcon from '../../assets/down_icon.png';

const ActionButtons = ({ 
    buttons = [],

    size = BUTTON_SIZES.MEDIUM,
    direction = DIRECTIONS.ROW,
}) => {

    return (
        <div
            className={`
                action_btn--${size}
                action_btn
                ${direction === DIRECTIONS.COLUMN ? 'action_btn--column' : ''}
            `}
        >
            {
                buttons
                    .map( button => button)
            }

        </div>
    )

}

export default ActionButtons;

export const ICONS = {
    SORT: sortIcon,
    FILTER: filterIcon,
    DOWNLOAD: downloadIcon,
    VIEW: viewIcon,
    EMAIL: emailIcon,
    SELECT: selectIcon,
    STATS: statsIcon,
    TOGGLE: toggleIcon,
    RELOAD: reloadIcon,
    OPEN_TAB: openTabIcon,
    GITHUB: githubIcon,
    HISTORY: historyIcon,
    LINK: linkIcon,
    COHORT: cohortIcon,
    CALENDLY: calendlyIcon,
    NOTE: noteIcon,
    LEGEND: legendIcon,
    HUBSPOT: hubspotIcon,
    SPINNER: spinnerIcon,
    EDIT: editIcon,
    ADD: addIcon,
    CANCEL: cancelIcon,
    CHECKMARK: checkmarkIcon,
    RED_FLAG: redFlagIcon,
    UP: upIcon,
    DOWN: downIcon,
}

export const BUTTON_SIZES = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
}

export const DIRECTIONS = {
    ROW: 'row',
    COLUMN: 'column',
}

export const FlagButton = ({
    flagColorClass,
    onClick = null,
    xClass = "", // additional class info
}) => {
    
    return (
        <div
            className={`
                action_btn__flag-${flagColorClass}
                ${onClick ? 'action_btn__flag--active' : ''}
                ${xClass}
            `}
            onClick={onClick}
        >
            &nbsp;&nbsp;&nbsp;&nbsp;
            {/* <img src={redFlagIcon} alt="flag"/> */}
        </div>
    )
}

export const Label = ({
    text,
    xClass = "", // additional class info,
    visible = true,
    onClick = null,
    toolTip = null,
    bgColor = null,
    textColor = 'white',
    disabled=false,
    inStatsMode = false,
}) => {
    if ( !visible ) return null;

    if ( inStatsMode ) {
        bgColor = 'warning';
        textColor = 'dark';
        xClass += 'font-weight-bold'
    }

    return(
        <div
            className={`
                action_btn__prompt
                bg-${bgColor ? bgColor : onClick && !disabled ? 'info' : 'secondary'}
                text-${onClick && !disabled ? textColor : disabled ? 'dark' : 'light'}
                noselect
                ${xClass}
                ${onClick && !disabled ? 'action_btn__prompt--active' : ''}
            `}
            data-toggle="tooltip"
            title={toolTip}
            onClick={onClick}
        >
            {
                text
            }
        </div>
    )
}

export const LinkActionButton = ({
    text,
    onClick,
    xClass = "", // additional class info,
    visible = true,
    toolTip = null
}) => {

    if ( !visible ) return null;
    return(
        <a
            href="#"
            className={`
                action_btn--link_button
                noselect
                ${xClass}
            `}
            
            onClick={( e => {
                e.preventDefault();
                onClick();
            })}
        >
            {
                text
            }
        </a>
    )
}

export const ManualActionButton = ({
    linkSlug
}) => 
    <ManualHelper
        linkSlug={linkSlug}
    />

// triggers functionality provided for onClick/
export const BasicActionButton = ({
    onClick,
    toolTip,
    icon = null,
    text = "!ERROR!",
    isSelected = false,
    visible = true,
    xClass = "", // additional class info
    isLoading = false,
    disabled = false,
    inStatsMode = false,
}) => { 

    //const [ wasClicked, setWasClicked ] = useState(false);
    let wasClicked = false;

    if ( !visible ) return null;

    if ( isLoading ) {
        icon = spinnerIcon;
        disabled = true;
    };

    return(
        <div
            onClick={(e) => {
                e.preventDefault();

                if ( disabled ) return;

                onClick(e);
                // setWasClicked(true);
                // setTimeout(() => setWasClicked(false), 1500);
            }}
            data-toggle="tooltip"
            title={toolTip}
            className={`
                action_btn--${disabled ? 'disabled ' : 'active'}
                action_btn--${isSelected ? 'selected' : ''}
                action_btn--${wasClicked ? 'clicked' : ''}
                action_btn--${inStatsMode ? 'stats_mode' : ''}
                ${xClass}
            `}
        >
            {
                icon !== null ?
                    <img src={icon} alt={toolTip}/>
                    :
                    <span className='font-weight-bold'>{text}</span>
            }
        </div>
    )
}

// copies provided content to clipboard
export const CopyToClipboardButton = ({
    toolTip,
    clipboardContent,
    isSelected = false,
    xClass = "", // additional class info
    visible = true,
    icon = ICONS.OPEN_TAB,
}) => {
    return (
        <BasicActionButton
            onClick={() => navigator.clipboard.writeText(clipboardContent)}
            toolTip={toolTip}
            icon={icon}
            isSelected={isSelected}
            xClass={xClass}
            visible={visible}
            />
    )
}

// open link in a new tab
export const OpenURLButton = ({
    icon,
    toolTip,
    url,
    isSelected = false,
    xClass = "", // additional class info
    visible = true,
    newTab = false
}) => {
    return (
        <BasicActionButton
            onClick={() => window.open(url, newTab === true ? '_blank' : '_self').focus()}
            toolTip={toolTip}
            icon={icon}
            isSelected={isSelected}
            xClass={xClass}
            visible={visible}
            />
    )
}

// sort directions
export const SortButton = ({
    sortType,
    setSortType,
    toolTip,
    visible,
    xClass = "" // additional class info
}) => {
    return (
        <BasicActionButton
            toolTip={toolTip}
            icon={sortType === null ? sortIcon : sortType === SORT_TYPES.ASC ? upIcon : downIcon}
            onClick={() => {
                if (sortType === SORT_TYPES.ASC) {
                    setSortType(SORT_TYPES.DESC);
                } else {
                    setSortType(SORT_TYPES.ASC);
                }
            }}
            isSelected={sortType !== null}
            xClass={xClass}
            visible={visible}
        />
    )
}
