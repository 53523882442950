import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../store/AppContext";
import ActionButtons, { BasicActionButton, ICONS } from "../components/elements/ActionButtons";

/*
    Handles management of the overlays stack
*/

const useOverlays =() => {

    const { overlaysStack, setOverlaysStack, overlaysListeners, setOverlaysListeners } = useContext(AppContext);
    const [ confirmCloseMessage, setConfirmCloseMessage ] = useState(null);

    // start position for overlays
    const left = 50;
    const top = 35;

    const pushOverlay = ({
        component = null,
        type = OVERLAY_TYPES.PALETTE,
        position = null,
        close = null, // if set, a close button will be added to the overlay
        respond = null, // used for dialogs
    }) => { console.log(overlaysStack)
        setOverlaysStack([new Overlay(type, component, position, close, respond), ...overlaysStack]);
    }
    
    const popOverlay = () => {setOverlaysStack(overlaysStack.slice(1));}
    
    const clearOverlays = () => {
        console.log("Clear Overlays")
        setOverlaysStack([]);
    }
    
    const displayOverlays = () => { 
        
        if ( overlaysStack.length == 0 ) return <></>;

        return (
            <div 
                id="resp_overlay"
                >
                {
                    [...overlaysStack] // copy the array so we can reverse it
                        .reverse()
                        .map( (overlay, index) => {
                            return (
                                <OverlayInner 
                                    overlay={overlay}
                                    layerIndex={overlaysStack.length - index - 1}
                                    cover={index < overlaysStack.length - 1}
                                    defaultPosition={{x: index * 20 + left , y: index * 20 + top}}
                                />
                            )
                        })
                }
            </div>
        )
    }

    const listenForUpdates = (id, callback) => {
        setOverlaysListeners({...overlaysListeners, [id]: callback});
    }

    const updateOverlay = (id, data) => {
        if ( overlaysListeners[id] ) overlaysListeners[id](data);
    }

    return {
        pushOverlay,
        popOverlay,
        clearOverlays,
        displayOverlays,
        confirmCloseMessage,
        setConfirmCloseMessage,
        listenForUpdates,
        updateOverlay,
    }
}

const OverlayInner = ({
    overlay,
    layerIndex,
    cover,
    defaultPosition,
}) => { 

    let finalPosition = null;

    useEffect(() => { 

        const self = document.getElementById(`resp_overlay__inner_${layerIndex}`);
        const { height, width } = self.getBoundingClientRect();

        // position overlay
        finalPosition = overlay.position ? {x: overlay.position.x, y: overlay.position.y} : null
  
        const margin = 10; // margin from edge of screen
        const { height: parentHeight, width: parentWidth } = document.getElementById('resp_overlay').getBoundingClientRect();

        if ( finalPosition ) { // position specified
            
            // horizontal positioning
            if ( // cursor if more than 2/3 of the way across the screen or overlay would be off the right of the screen, position to left of cursor
                finalPosition.x > 2 * (parentWidth / 3) ||
                finalPosition.x + width > parentWidth - margin
                ) { 
                finalPosition.x -= width;
            } else if (
                finalPosition.x < parentWidth / 3 ||
                finalPosition.x < margin
                ) { // cursor if less than 1/3 of the way across the screen or overlay would be off the left of the screen, position to right of cursor
                // finalPosition.x += width;
            }

            // off the bottom
            if (finalPosition.y + height > parentHeight - margin) { console.log("XX")
                finalPosition.y = parentHeight - height - margin;
            }
            
        } else { 
            finalPosition = defaultPosition;
        }
        
        // make sure overlay is not off  of the screen
        self.style.maxHeight = (parentHeight - margin - finalPosition.y) + 'px';
        self.style.maxWidth = (parentWidth - margin - finalPosition.x) + 'px';

        self.style.top = finalPosition.y + 'px';
        self.style.left = finalPosition.x + 'px';
    });

    return (
        <div 
            id={`resp_overlay__inner_${layerIndex}`}
            class={`
                resp_overlay__inner
                resp_overlay__inner__${overlay.type.toLowerCase()}
                ${cover ? 'resp_overlay__inner--disabled' : ''}
                row
            `}

        >
            <div className="col-12">
                <div 
                    class="row resp_overlay__inner__main layer layer-1"
                    >
                        {
                            overlay.close &&
                                <OverlayHeader close={overlay.close} />
                        }
                        <div className="col-12 resp_component_holder">
                            {
                                overlay.type == OVERLAY_TYPES.CONFIRM ?
                                    <ConfirmDialogBox 
                                        component={overlay.component}
                                        respond={overlay.respond} 
                                        />
                                :
                                    overlay.type == OVERLAY_TYPES.ALERT ?
                                        <AlertDialogBox
                                            component={overlay.component}
                                            respond={overlay.respond}
                                        />
                                    :
                                    overlay.component   
                            }
                        </div>
                </div>
            </div>

            {
                cover &&
                <div className="resp_overlay__inner--cover"></div>
            } 
        </div>
    )
}

const AlertDialogBox = ({
    component,
    respond = null,
}) => {

    const { popOverlay } = useOverlays();

    return (
        <div className="row window_400">
            <div className="col-12">
                <div className="resp_overlay__inner__dialog_box__content">
                    { component }
                </div>
            </div>
            <div className="resp_overlay__dialog_buttons">
                {
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => {
                            popOverlay(); // order matters here
                            if ( respond ) respond();
                        }}
                    >
                        OK
                    </button>
                }   
            </div>
        </div>
    )

}

const ConfirmDialogBox = ({
    component, // pass element
    respond,
    followUpComponent = null, // pass element
    doubleConfirm= false,
}) => {

    const { popOverlay } = useOverlays();

    const [ confirmed, setConfirmed ] = useState(false);

    const onRespond = (response) => {
        if ( doubleConfirm && response && !confirmed ) {
            setConfirmed(true);
            return;
        }

        popOverlay();
        respond(response);
    }

    return (
        <div className="row window_400">
            <div className="col-12">
                <div className="resp_overlay__inner__dialog_box__content">
                    { confirmed ? followUpComponent : component }
                </div>
            </div>
            <div className="resp_overlay__dialog_buttons">
                <button
                    className='btn btn-sm btn-success'
                    onClick={() => onRespond(true)}
                >
                    Yes
                </button>

                <button
                    className='btn btn-sm btn-danger'
                    onClick={() => onRespond(false)}
                >
                    No
                </button>
            </div>
        </div>
    )
}

const OverlayHeader = ({
    close
}) => { 
        return (
            <div className='resp_overlay__header'>
                <ActionButtons
                    buttons={[
                        BasicActionButton({
                            icon: ICONS.CANCEL,
                            onClick: close,
                        })
                    ]}
                />
            </div>
        )
}

const OVERLAY_TYPES = {
    MODAL: 'MODAL',
    PALETTE: 'PALETTE',
    ALERT: 'ALERT',
    CONFIRM: 'CONFIRM',
}

export { useOverlays as default, OVERLAY_TYPES, AlertDialogBox, ConfirmDialogBox };

class Overlay {
    constructor(type, component, position, close, respond) {
        this.type = type;
        this.component = component;
        this.position = position;
        this.close = close;
        this.respond = respond;
    }
}