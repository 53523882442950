import {useState, useContext} from 'react'; 
import ActionButtons, { BasicActionButton, ICONS } from '../../elements/ActionButtons';
import { AppContext } from '../../../store/AppContext';
import SimpleStudentHeader from '../my_cohort_elements/SimpleStudentHeader';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';

const StackProgressGenerator = ({
    currentProgress = null,
    updateProgress,
    student = null
}) => { 

    const {stackList, stackObj} = useContext(AppContext);
    const { popOverlay, pushOverlay } = useOverlays();

    // const [stackProgressString, setStackProgressString] = useState("Java/PnA");
    //const [commonStacks, setCommonStacks] = useState(stackList.slice(0,15).filter((common) => common.id !== 6));
    const [selectedStacks, setSelectedStacks] = useState(currentProgress || []);
    const [selectedStackFromDropdown, setSelectedStackFromDropdown] = useState(stackList[0].id);

    const addToSelectedStacks = (stackId) => {
        setSelectedStacks((prevState) => [...prevState, stackId])
    }

    const removeFromSelectedStacks = (id) => {
        console.log("RemovingStack:", stackObj[id] )
        setSelectedStacks((prevState) => prevState.filter((stackId) => stackId !== id))
    }

    const reorderStackProgression = (idx, indexTarget=1) => {
        const selectedStacksListCopy = [...selectedStacks];

            let swapTarget = idx - indexTarget;
            let temp = selectedStacksListCopy[swapTarget]
            selectedStacksListCopy[swapTarget] = selectedStacksListCopy[idx]
            selectedStacksListCopy[idx] = temp;
            setSelectedStacks(selectedStacksListCopy);
        
    }

    // const sortByTextLength = (a,b) => {
    //     if (a.name.length > b.name.length) {
    //         return 1
    //     } else if (a.name.length < b.name.length ) {
    //         return -1
    //     } else {
    //         return 0
    //     }
    // } 

    return (
            
                <div id="stack_progress__modal" className="stack_progress__modal__section row layer layer-1">
                    <div className="col-12 sub_note text-center mb-2">
                        {
                            student ?
                                <SimpleStudentHeader student={student}/>
                                :
                                <h5>All Selected Students Will Be Updated</h5>
                        }
                        
                        Click on a stack or stack combo to add to list
                    </div>
                    
                    <div className='col-8'>
                        <div className='row'>
                            <div className=" col-5">
                                <h4>Part-Time Combos</h4>
                                {/*
                                Python - 4
                                Java - 8
                                Javascript - 16
                                C# - 7
                                P&A - 9 
                                */}
                                <p className="hover_text" onClick={() => setSelectedStacks([4,9])}>Python/P&A</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,16,8,9])}>Python/Javascript/Java/P&A</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,8,16,9])}>Python/Java/Javascript/P&A</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([16,8,9])}>Javascript/Java/P&A</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,8,9])}>Python/Java/P&A</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,16,9])}>Python/Javascript/P&A</p>
                            </div>
                            <div className="col-6">
                                <h4 >Full-Time Combos</h4>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,16,8])}>Python/Javascript/Java</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,8,16])}>Python/Java/Javascript</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,16,7])}>Python/Javascript/C#</p>
                                <p className="hover_text" onClick={() => setSelectedStacks([4,8,7])}>Python/Java/C#</p>
                            </div>

                            <div className="col-12">

                                <h4>Choose From All Stacks</h4>
                                <div className="d-flex justify-content-around">

                                        <select 
                                            className="form-control" 
                                            onChange={(e) => {setSelectedStackFromDropdown(parseInt(e.target.value))} }
                                        >
                                            {stackList && stackList.map((stackValue, idx ) => (
                                                <>
                                                    <option value={stackValue.id} key={stackValue.id}>{stackValue.name}</option>
                                                </>
                                            )) }
                                        </select>
                                        <span
                                            className="ml-2 mt-1">
                                                <ActionButtons
                                                    buttons={[
                                                        <BasicActionButton
                                                            icon={ICONS.ADD}
                                                            onClick={() => addToSelectedStacks(selectedStackFromDropdown)}
                                                        />,
                                                    ]}
                                                />
                                            </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div className={"col-4 h-75 p-3"}>

                        <div className="row">
                            <div className="col-12">

                                {selectedStacks.map((selectedStackId, idx) => (
                                    <div className="row">
                                        <div className="col bg-secondary text-light m-1 ">
                                            <span>{stackObj[selectedStackId]}</span>
                                        </div>
                                        <div className="col">
                                            <ActionButtons
                                                buttons={[
                                                    idx !== 0 && <BasicActionButton
                                                        icon={ICONS.UP}
                                                        onClick={() => {reorderStackProgression(idx)}}
                                                        />,
                                                        idx !== selectedStacks.length - 1 && <BasicActionButton
                                                        icon={ICONS.DOWN}
                                                        onClick={() => {reorderStackProgression(idx, -1)}}
                                                        />,
                                                        <BasicActionButton
                                                        icon={ICONS.CANCEL}
                                                        onClick={() => {removeFromSelectedStacks(selectedStackId)}}
                                                    />,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-12 mt-3 text-center">
                                <button 
                                    className="btn w-100 btn-md btn-primary" 
                                    onClick={(e) => updateProgress(e, selectedStacks)}
                                    disabled={selectedStacks.length === 0}
                                    >Update</button>
                                <button className="btn w-100 btn-md btn-info mt-3" onClick={popOverlay}>Cancel</button>
                                <button 
                                    className="btn w-100 btn-md btn-warning mt-3" 
                                    onClick={(e) => 
                                        pushOverlay({
                                            type: OVERLAY_TYPES.CONFIRM,
                                            position: { x: e.clientX, y: e.clientY },
                                            component: <>Are you sure you want to clear the stack progress?</>,
                                            respond: (response) => {
                                                if (response) updateProgress(e, []);
                                            }
                                        })
                                    }
                                    disabled={selectedStacks.length === 0}
                                    >Clear Progress</button>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
    )
}

export default StackProgressGenerator;

export const StackProgressDisplay = ({
    student,
    stackObj,
    updateProgress = null,
}) => {

    //const [ USER_LEVELS, checkAccess ] = useUserAccess();
    const canActivate = false; //checkAccess(USER_LEVELS.INSTRUCTOR);
    const stackProgress = JSON.parse(student.stack_progress)

    return (
        <div 
            className={`
                stack_progress__display
                ${canActivate ? "stack_progress__display--active" : "noselect"}`}
            onClick={(e) => {if ( canActivate && updateProgress ) updateProgress(e)}}
            >
        {
            stackProgress.length === 0 ? 
                <div className='stack_progress__display_stack'>
                    N/A
                </div>
                : 
                stackProgress.map( (stackId, idx) => (
                    <>
                        {
                            stackId &&
                            <>
                                <span 
                                    className={stackId === student.stack_id && stackProgress.length > 1 ? "stack_progress__display_stack--current" : ""}
                                >
                                    {stackObj[stackId]}
                                </span>

                                {
                                    idx < stackProgress.length - 1 && 
                                    <span className="">
                                        -
                                    </span>
                                }
                            </>
                        }
                    </>
                ))}
        </div>
    )
}