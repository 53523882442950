/* Collect image and text for questions and answers */

import React, { useState } from "react";
import ImageSelector from "../../elements/image_selector/ImageSelector";

const FrameBuilder = ({
    imageUrl,
    setImageUrl,
    text,
    setText,
    disabled = false,
}) => {

    const [ isSelectingImage, setIsSelectingImage ] = useState(false);

    return (
        <>

            <div className="col-12 text-center mb-2 sub_note">
                Must have text and/or image. 
            </div>
            <div>
                <ImageSelector
                        currentImage={imageUrl}
                        updateImage={(newImage) => 
                            {
                                setImageUrl(newImage);
                                setIsSelectingImage(false);
                            }}
                        isSelectingImage={isSelectingImage}
                        setIsSelectingImage={setIsSelectingImage}
                        disabled={disabled}
                    />
                <textarea
                    className="form-control mt-1"
                    rows="4"
                    placeholder="Text"
                    value={text}
                    disabled={disabled}
                    onChange={(e) => setText(e.target.value)}
                ></textarea>
            </div>
        </>
    )
}

export default FrameBuilder;