import React from 'react';
import { DOWNLOADER_STATES } from '../../../services/AssignmentDownloaderService';

const DownloaderModal = ({ 
    close,
    assignmentDownloaderStatus 
}) => {

    return(
        <div className="modal_overlay modal_overlay--50 ">
            <div className='row layer layer-2'>
                <div className='col-12 text-center'>

                    <p>To download assignments, you need to do the following:</p>
                    <div>
                        {
                            assignmentDownloaderStatus === DOWNLOADER_STATES.NOT_CHROME &&
                                <>
                                    <a 
                                        href={`${'https://www.google.com/chrome/dr/download'}`} 
                                        target='_blank'>
                                        Install Chrome
                                    </a>
                                    <p>Then...</p>
                                </>

                        }
                        <div>
                            <a 
                                href="https://sites.google.com/codingdojo.com/tab2-manual/cohort-sheets/assignments-sheet#h.4590k4y5bsv6" 
                                target='_blank'
                                >Install Assignment Downloader</a>
                            <p className='mt-2'>
                                If you have installed the downloader, and on Chrome, and still receiving this message, be sure have logged into the extension.
                            </p>
                        </div>
                    </div>
                    <button
                        className='btn btn-info mt-3'
                        onClick={close}
                        >Close</button>
                </div>
            </div>
        </div>
    )

}

export default DownloaderModal;