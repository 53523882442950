import React, { useEffect, useState, useContext, useRef } from 'react';
import ViewTickets from './ViewTickets';
import ticketService from '../../services/TicketService';
import alertService from '../../services/AlertService';
import Paginator from '../elements/Paginator';
import { AppContext } from '../../store/AppContext';
import QueryBuilder from '../../helpers/QueryBuilder';
import eventHandler, { CLEAR_MY_ALERTS, CLOSE_OVERLAY, OVERLAY_CLOSE_CLICK } from '../../events';
import helpers from '../../helpers/Helpers';
import useBtrSearchParams from '../../hooks/useBtrSearchParams';
import useOverlays, { OVERLAY_TYPES } from '../../hooks/useOverlays';
import ViewTicket from './ViewTicket';

const ticketFilters = {
    assignmentReviews: 1,
    tickets: 2,
    both: 3
}

const TicketsBrowser = ( { student = null, initialTicketQuery = null, showName = false, 
                            canAddTickets = false, filter = null, decrementTicketCount = false,
                            breakdownTickets=false } ) => {

    const { setViewTicket } = useContext(AppContext);
    const { pushOverlay, popOverlay } = useOverlays();

    const [ searchResponse, setSearchResponse ] = useState(null);
    const [ isTicketsLoaded, setIsTicketsLoaded ] = useState(false);
    const [disableAddTicketBtn, setDisableAddTicketBtn] = useState(false);
    const [ creatingTicket, setCreatingTicket ] = useState(false);
    const [ contactSource, setContactSource ] = useState("");
    const [ search, setSearch, deleteParam ] = useBtrSearchParams();
    const { isExamOpen } = helpers.getExamsAlerts(student?.exam_data);
    const [ ticketQuery, setTicketQuery ] = useState(
        initialTicketQuery || 
        new QueryBuilder()
            .test(`student_id = ${student.id}`)
        );

    const [ ticketsFilter, setTicketsFilter ] = useState(ticketFilters.both);

    useEffect(() => {

        eventHandler.on( OVERLAY_CLOSE_CLICK, onCloseClicked)

        return () => eventHandler.remove( OVERLAY_CLOSE_CLICK, onCloseClicked )
    }, [])

    useEffect(() => { 
        searchTickets();
    }, [ticketQuery])

    useEffect(() => { 
        if (search.get('ticket')) {
            pushOverlay({
                type: OVERLAY_TYPES.MODAL,
                component: <ViewTicket
                    ticketId={search.get('ticket')}
                    updateTicket={onTicketUpdated}
                    decrementTicketCount={decrementTicketCount}
                    close={() => {
                        deleteParam('ticket');
                        popOverlay();
                    
                    }}
                />
            })
            //setViewTicket(TicketOverlay({ticketId:search.get('ticket'),updateTicket:onTicketUpdated,decrementTicketCount:decrementTicketCount}))
        } else if (search.get('ticket') === null || search.get('ticket') === undefined ) {
            setViewTicket(null)
        }
    }, [search.get('ticket')])

    const onCloseClicked = () => {
        eventHandler.dispatch( CLOSE_OVERLAY );
        deleteParam('ticket');
    };
    
    const openTicket = () => {
        setCreatingTicket(true);

        ticketService.open(student.id, contactSource)
            .then( ticket => {
                ticket = {...ticket, current_open_time: 1, date_loaded: new Date() };
                /*if the ticket is opened before the seachResponse has loaded in, store it in the searchResp early
                NOTE: This can result in ticket count being off by 1 if a ticket is opened with amazing timing that would result
                in a ticket being added JUST after the count query runs (which is a quick query that runs within the last second or two
                of the ticketSearch; but I feel like that possible error in a display that's not even useful in that context is worth
                the speed that this provides in terms of opening tickets..I have yet to manage to force this bug. 11/1/22*/
                if(searchResponse === null) {
                    setSearchResponse(prevResp => ({
                        ...prevResp,
                        tickets: prevResp?.tickets? [ticket,...prevResp.tickets] : [ticket]
                    }))
                }
                else {
                    setSearchResponse(prevResp => ({
                        ...prevResp,
                        tickets: [ticket, ...prevResp.tickets] ,
                        total: prevResp.total + 1
                    }))
                }
                setSearch({ticket:ticket.id})
            } )
            .catch ( err => console.log( err ) )
            .finally ( ticket => setCreatingTicket( false ) )
        
    }

    const searchTickets = ( page = 1 ) => { 
        //utilize dedicated loading state to avoid mis-displays concerning ticket loading when page is refreshed with ticket overlay up
        setIsTicketsLoaded(false);

        //added this back because it's needed when opening an early ticket and bouncing back from another page of tickets (ie pagination)
        setSearchResponse(null);

        /*added in a specialized state for disabling open ticket for everything except first page..little hacky but prevents accidental
        opening of tickets as other pages load..might even extend to have it disable the button entirely when not on page 1*/
        if(page !== 1) setDisableAddTicketBtn(true);

        ticketService.search({query: ticketQuery.lines(), page})
            .then( resp => { 
                const respTickets = resp.results.map( ticket => ({
                    ...ticket,
                    current_open_time: ticket.time_open,
                    date_loaded: new Date()
                }))

                //if there was a ticket opened before the resp came back, toss it in before the rest
                setSearchResponse(prevResp => ({
                    ...resp,
                    tickets: prevResp?.tickets? [...prevResp.tickets, ...respTickets] : respTickets
                }))
                
                setIsTicketsLoaded(true);
            })
            .catch( err => console.log(err) )
    }

    const onFilterTickets = ( filter ) => { 

        let newQuery = {...ticketQuery}; // have to do this to trigger useEffect to pull new tickets

        newQuery.remove('source');
        
        switch(filter) {
            case ticketFilters.assignmentReviews:
                newQuery.and();
                newQuery.test(`source = 6`);
                break;

            case ticketFilters.tickets:
                newQuery.and();
                newQuery.test(`source != 6`);
                break;

            default:
                break;
        }
console.log(newQuery.lines())
        setTicketsFilter(filter);
        setTicketQuery(newQuery);
    }

    const onTicketUpdated = (uTix, updateTicket = false, decSearchResTotal = false) => { 

        setSearchResponse( ( response => ({
            ...response,
            tickets: response === null ? [] : response.tickets
                .map( ticket => ticket.id === uTix.id ? uTix : ticket)
                .filter( ticket => { 
                    let stillValid = true;
                    if ( filter !== null ) 
                        Object.keys(filter).forEach( key => { 
                            stillValid =  key in ticket === false || filter[key] === ticket[key] ? stillValid : false
                        });

                    return stillValid;
                } )
        })))
        /*Allows for ticket DB update calls, when needed, but defaults to false for most.
        Eg. ticket close requires update, but comment add does not. Both need to update
        state via the onTicketUpdated method above, but only one should update DB.*/
        if(updateTicket) {
            ticketService.update( uTix )
                .then ( ticket => {})
                .catch( err => console.log(err) )
        }
        //Maintains proper ticket count for alert and open ticket displays on dashboard. Sorta dirty but better than passing around the setter I think.
        if(decSearchResTotal) {
            setSearchResponse(prevResponse => ({
                ...prevResponse,
                total: prevResponse.total - 1
            }))
        }

    }

    const onClearAlerts = () => 
        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            component: <>Are you sure you want to clear all alerts?</>,
            respond: (response) => {
                if ( response ) {
                    eventHandler.dispatch( CLEAR_MY_ALERTS);

                    alertService.clearMy()
                    .then()
                    .catch( err => console.log(err) )

                    setSearchResponse({
                        page: 0,
                        pages: 0,
                        results: [],
                        tickets: [],
                        total: 0

                    }); // clear all tickets
                }
            }
        })

    return (
        <div className='row layer layer-2 p-2 tickets_browser'>

            {
                canAddTickets &&
                    <>
                        <div className='col-6 '>
                            {
                                creatingTicket ?
                                <>Creating Ticket...</>
                                :
                                <select 
                                    className='form-control'
                                    value={contactSource}
                                    onChange={(e) => setContactSource(e.currentTarget.value)}
                                    >
                                    <option value={""}>
                                        Select a Contact Source
                                    </option>
                                    <option 
                                        value={ticketService.TICKET_SOURCE_DOJO_HALL}
                                        >Zoom</option>
                    
                                    <option 
                                        value={ticketService.TICKET_SOURCE_DISCORD}
                                        >Discord</option>
                    
                                    <option 
                                        value={ticketService.TICKET_SOURCE_DM}
                                        >DM</option>
                    
                                    <option 
                                        value={ticketService.TICKET_SOURCE_CALENDLY}
                                        >Calendly</option>
                    
                                    <option 
                                        value={ticketService.TICKET_SOURCE_EMAIL}
                                        >Email</option>
                                </select>
                            }
                            
                        </div>
                        <div className='col-6'>
                                        
                            <button
                                className={`btn btn-success`}
                                onClick={ (e) => {
                                    if (isExamOpen) {
                                        pushOverlay({
                                            type: OVERLAY_TYPES.CONFIRM,
                                            position: { x: e.clientX, y: e.clientY},
                                            component: <>Student has an exam open, are you sure you want to continue?</>,
                                            respond: (response) => {
                                                if ( response ) {
                                                    openTicket(e);
                                                }
                                            }
                                        })
                                    } else openTicket(e);
                                }}
                                disabled={ creatingTicket || ! contactSource || disableAddTicketBtn }
                            >
                                Open Ticket
                            </button>
                            {
                                (ticketQuery && ticketQuery.lines().filter( entry => entry['column'] === 'alert_user_id' ).length > 0 && searchResponse && searchResponse.tickets.length > 0) &&
                                <button
                                    className='btn btn-warning btn-sm ml-2'
                                    onClick={onClearAlerts}
                                >Clear Alerts</button>
                                
                            }
                        </div>
                    </>
            }
            
            <div className='col-12 mt-2'>
                <div className='row justify-content-center'>
                    <div className='col-4'>
                        <input 
                            type="radio" 
                            name="ticket_filter" 
                            checked={ ticketsFilter === ticketFilters.assignmentReviews }
                            onChange={() => onFilterTickets(ticketFilters.assignmentReviews)} />Assignment Reviews
                    </div>
                    <div className='col-2'>
                        <input 
                            type="radio" 
                            name="ticket_filter" 
                            checked={ ticketsFilter === ticketFilters.tickets }
                            onChange={() => onFilterTickets(ticketFilters.tickets)}/>Tickets
                    </div>
                    <div className='col-3'>
                        <input 
                            type="radio" 
                            name="ticket_filter" 
                            checked={ ticketsFilter === ticketFilters.both }
                            onChange={() => onFilterTickets(ticketFilters.both)}/>All
                    </div>
                </div>
                    <Paginator 
                        isTicketsLoaded={isTicketsLoaded}
                        searchResponse={searchResponse}
                        onPageSelected={searchTickets}
                        itemPrompt={'ticket'}
                        />
            </div>
                
            
            {
                isTicketsLoaded &&
                    <ViewTickets
                        tickets={searchResponse.tickets}
                        displayStudentInfo={student === null}
                        showName={showName}
                        updateTicket={onTicketUpdated} 
                        breakdownTickets={breakdownTickets}
                        />
                    
            }
        </div>
                    
    )
}

export default TicketsBrowser;