import React, { useEffect, useState, useContext } from 'react';
import cohortService from "../../../services/CohortService";
import Select from 'react-select';
import InstructorCohortsStats from './InstructorCohortsStats';
import { AppContext } from '../../../store/AppContext';
import instructorService from '../../../services/InstructorService';

const CohortSelector = ( { 
    cohortSelected, 
    loadCohortError,
    close
}) => { 

    const { user } = useContext(AppContext);

    const [ instructors, setInstructors ] = useState(null);

    useEffect(() => {

        // load instructors
        instructorService.get_all()
        .then( instructors => setInstructors(instructors) )
        .catch( err => alert('Invalid Request') )

    }, []);

    const MODES = {
        ACTIVE_COHORTS: 1,
        HISTORICAL_COHORTS: 2,
        HISTORICAL_STATS: 3
    }

    const [ mode, setMode ] = useState(MODES.ACTIVE_COHORTS);
    const [ selectedInstructor, setSelectedInstructor ] = useState(null);
    const [ currentUserCohorts, setCurrentUserCohorts ] = useState(null); // true if the user has cohorts to display in CohortSelectorChoices
    const [ activeCohorts, setActiveCohorts ] = useState(null);
    const [ instructorCohorts, setInstructorCohorts ] = useState(null); // cohorts for the selected instructor    
    const [ selectedCohorts, setSelectedCohorts ] = useState(null); // the cohorts to display in CohortSelectorChoices

    useEffect(() => {
        cohortService.get_active_cohorts()
            .then( cohorts => {
                setActiveCohorts(cohorts);
                if ( mode === MODES.ACTIVE_COHORTS ) setSelectedCohorts(cohorts);
            })
            .catch( err => console.log(err) );

    }, []);

    useEffect(() => {   
        if ( selectedCohorts ) {
            setCurrentUserCohorts(selectedCohorts.filter( cohort => cohort.instructor_id === user.instructor_id ))
        }
        
    }, [selectedCohorts]);

    useEffect(() => {
        
        if ( mode === MODES.ACTIVE_COHORTS ) {
            setSelectedCohorts(activeCohorts);
        } else if ( mode === MODES.HISTORICAL_COHORTS ) {
            if ( selectedInstructor === null ) {
                setSelectedCohorts(null);
            } else {
                if ( instructorCohorts === null ) {
                    loadInstructorCohorts(selectedInstructor.id);
                } else {
                    setSelectedCohorts(instructorCohorts);
                }
            }
        }

    }, [mode]);

    const loadInstructorCohorts = (instructorId) => {
        setInstructorCohorts(null);
        setSelectedCohorts(null);
        cohortService.get_instructor_cohorts(instructorId)
            .then( cohorts => {
                setInstructorCohorts(cohorts);
                setSelectedCohorts(cohorts);
            })
            .catch( err => console.log(err) );
    
    }

    const selectOptions = ! instructors ? [] : instructors.map( instructor => 
        ({
            id: instructor.id,
            label: instructor.first_name + ' ' + instructor.last_name
        })
    );

    const selectValue = selectOptions.filter(option => option.id == selectedInstructor?.id);

    return (
        <div
            id="cohort_selector"
            className='row layer layer-1'
        >
            {
                loadCohortError &&
                <div className='col-12 text-center bg-danger mb-2'>
                    <h6 className='text-light mt-1'>{loadCohortError}</h6>
                </div>
            }

            {
                mode === MODES.ACTIVE_COHORTS &&
                <>
                    <div className='col-3 '>
                        <h5>Active Cohorts ({selectedCohorts?.length || 0})</h5>
                    </div>
                    <div className='col-6 text-center'>
                        {
                            (currentUserCohorts && currentUserCohorts.length > 0) &&
                                <div>
                                    <span className='mr-2'>Your Cohorts:</span>
                                    {
                                        currentUserCohorts
                                            .sort( (a, b) => a.cohort_start_date > b.cohort_start_date ? 1 : -1 )
                                            .map( cohort =>
                                                <button
                                                    key={cohort.cohort_id}
                                                    className='btn btn-sm btn-success mr-2'
                                                    onClick={() => cohortSelected(cohort.cohort_id)}
                                                    >{cohort.stack_name} {cohort.cohort_start_date}</button>
                                        )
                                    }
                                </div>
                        }
                    </div>
                    <div className='col-3 text-right mb-2'>
                        <button
                            className='btn btn-sm btn-info'
                            onClick={() => setMode(MODES.HISTORICAL_COHORTS)}
                            >Historical Cohorts</button>
                            {
                                close &&
                                    <button
                                        className='btn btn-danger btn-sm ml-2'
                                        onClick={close}
                                    >Cancel</button>
                            }
                    </div>

                    <div className='col-12 text-center font-weight-bold mb-2 cohort_selector__cohort--out_of_cohort'>
                        Cohorts in yellow, at the bottom, have ended or have not started yet.
                    </div>
                </>
            }

            {
                (mode === MODES.HISTORICAL_COHORTS || mode === MODES.HISTORICAL_STATS) &&
                <>
                    <div className='col-3 mb-3'>
                        <h5>Historical Cohorts ({selectedCohorts?.length || 0})</h5>
                    </div>
                    <div className='col-5'>
                        <Select
                            classNamePrefix={"react_select"}
                            options={ selectOptions }
                            isLoading={instructors === null}
                            placeholder={instructors === null ? 'Loading Instructors...' : 'Select an Instructor'}
                            onChange={ instructor => {
                                setSelectedInstructor(instructor);
                            }}
                            value={selectValue}
                            styles = {{
                                control: (provided, state) => ({
                                    ...provided,
                                    color: 'black',
                                }),
                            }}
                        />
                    </div>
                    <div className='col-1'>
                        <button
                            className='btn btn-success'
                            onClick={() => loadInstructorCohorts(selectedInstructor.id)}
                        >Load</button>

                    </div>
                    <div className='col-3 text-right'>
                        <button 
                            className='btn btn-sm btn-warning'
                            onClick={() => setMode(mode===MODES.HISTORICAL_COHORTS ? MODES.HISTORICAL_STATS : MODES.HISTORICAL_COHORTS)}
                            >Stats</button>
                        <button
                            className='btn btn-sm btn-info ml-2'
                            onClick={() => setMode(MODES.ACTIVE_COHORTS)}
                            >Active Cohorts</button>
                            {
                                close &&
                                    <button
                                        className='btn btn-danger btn-sm ml-2'
                                        onClick={close}
                                    >Cancel</button>
                            }
                    </div>
                </>
            }

            {
                mode === MODES.HISTORICAL_STATS &&
                <div className='col-12'>
                    <InstructorCohortsStats
                        close={() => setMode(MODES.HISTORICAL_COHORTS)}
                        cohorts={selectedCohorts}
                    />
                </div>
            }
            {
                mode !== MODES.HISTORICAL_STATS &&
                    <div className='col-12'>
                        <table
                            className='table table-sm table-striped table-hover text-light'
                            >
                            <thead>
                                <tr>
                                    <th className='text-center'>Instructor</th>
                                    <th className='text-center'>Stack</th>
                                    <th className='text-center'>Start Date</th>
                                    <th className='text-center'>End Date</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedCohorts === null ?
                                        <tr>
                                            <td colSpan='4' className='text-center'>
                                                Loading...
                                            </td>
                                        </tr>
                                        :
                                        selectedCohorts.length === 0 ?
                                            <tr>
                                                <td colSpan='4' className='text-center'>
                                                    No cohorts found.
                                                </td>
                                            </tr>
                                            :
                                            selectedCohorts
                                                .sort(
                                                    (a, b) => {

                                                        if ( mode === MODES.ACTIVE_COHORTS ) {
                                                        if (a.instructor_name < b.instructor_name) return -1;
                                                        if (a.instructor_name > b.instructor_name) return 1;
                                                        return 0;
                                                        } else if ( mode === MODES.HISTORICAL_COHORTS ) {
                                                            if (a.cohort_start_date > b.cohort_start_date) return -1;
                                                            if (a.cohort_start_date < b.cohort_start_date) return 1;
                                                            return 0;
                                                        }
                                                    }
                                                )
                                                .sort(
                                                    (a, b) => 
                                                        a.is_in_session === b.is_in_session ? 0 : a.is_in_session ? -1 : 1
                                                )
                                                .map( cohort =>{ 

                                                    let now = new Date();
                                                    const isSelected = now >= new Date(Date.parse(cohort.cohort_start_date)) && now <= new Date(Date.parse(cohort.cohort_end_date));
                                                    
                                                    return(
                                                        <tr

                                                            className={`
                                                                cohort_selector__cohort
                                                                cohort_selector__cohort--${ mode === MODES.ACTIVE_COHORTS && !cohort.is_in_session ? 'out_of_cohort' : ''}
                                                                ${ mode === MODES.HISTORICAL_COHORTS && isSelected ? 'bg-success' : ''}
                                                                `}
                                                            onClick={() => {
                                                                cohortSelected(cohort.cohort_id);
                                                            }}
                                                            >
                                                                <td >
                                                                    {cohort.instructor_name}
                                                                </td>
                                                                <td>
                                                                    {cohort.stack_name}
                                                                </td>
                                                                <td>
                                                                    {cohort.cohort_start_date}
                                                                </td>
                                                                <td>
                                                                    {cohort.cohort_end_date}
                                                                </td>
                                                        </tr>
                                                    )}
                                                )
                                }
                            </tbody>
                        </table>
                    </div>
            }
            

            
        </div>
    )
}

export default CohortSelector;