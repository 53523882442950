/* the squares on the assignment review sheets */

import React, { useContext, useEffect } from 'react';
import ReviewEditor from './ReviewEditor';
import { ASSIGNMENT_REVIEW_STATUS } from '../../../helpers/Constants';
import eventHandler, { CLOSE_OVERLAY, OVERLAY_CLOSE_CLICK } from '../../../events';
import { ONTIME_STATUSES } from './AssignmentAttendanceSheet';
import { ASSIGNMENT_TYPES } from './AssignmentAttendanceSheet';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import MyCohortContext from '../../../store/MyCohortContext';

const ReviewTab = ( { 
    review,
    weekNumber,
    reviewStatus, 
    student, 
    filtered, 
    assignment, 
    assignmentType,
    setReviewStatus,

    displayDaysOffset = false,
    demoMode = false
}) => { // console.log(review)

    const { pushOverlay, popOverlay, updateOverlay } = useOverlays();
    const {
        sheetsData,
    } = useContext(MyCohortContext);
    
    
    useEffect( () => {

        eventHandler.on( OVERLAY_CLOSE_CLICK, onCloseClicked)

        return () => eventHandler.remove( OVERLAY_CLOSE_CLICK, onCloseClicked )
    }, [])

    useEffect( () => {
        updateOverlay( 'review_editor', { student })
    }, [student])

    const onCloseClicked = () => eventHandler.dispatch( CLOSE_OVERLAY );

    let dayDiff = 0 // number of days to display in the offset
    if ( ! demoMode ){
        dayDiff =assignment.onTimeStatus === ONTIME_STATUSES.UNSUBMITTED ?
            Math.floor(new Date() / (1000 * 60 * 60 * 24)) - Math.floor(new Date(assignment.dueDate) / (1000 * 60 * 60 * 24))
            :
            Math.abs(Math.ceil(assignment.onTimeOffset / (1000 * 60 * 60 * 24)))
}
    return (
        <div 
            className={`
                assignment_reviews__column 
                review_tab__column 
                noselect
            
                ${reviewStatus > 0 && !demoMode ? 'assignment_reviews__column--active':''}
                ${filtered  ? 'assignment_reviews__column--filtered' : ''}
                `
            }
            key={`${student ? student['email_address'] : ""}${review && 'assignments' in review ? review.assignment['id'] : 0}`}

            onClick={(e)=> { 
                if ( reviewStatus ) { 
                    pushOverlay({
                        type: OVERLAY_TYPES.MODAL,
                        component: <ReviewEditor
                            assignment={assignment.assignment}
                            weekNumber={weekNumber}
                            student={student}
                            studentStatuses={sheetsData.student_statuses}
                            setReviewStatus={setReviewStatus}
                            close={popOverlay}
                            />
                    })
                }
            }}

            data-toggle="tooltip" 
            title={`${ student ? student.first_name : ""} ${student ? student.last_name : ""}
            ${assignment ? assignment.assignment['title'] : ""}(${assignment? assignment['type'] : ""})
            ${assignment ? `Due: ${assignment.dueDate.toLocaleDateString()}`: ""}
            ${assignment && assignment.dateSubmitted ? `Submitted: ${new Date(assignment.dateSubmitted).toLocaleDateString()}`: "Unsubmitted"}
            `}
            >
               
                    <div className='assignment_reviews__tab__shape_section'>
                        <div className={`
                        assignment_reviews__tab__shape

                        assignment_reviews__tab--${
                            assignmentType === ASSIGNMENT_TYPES.CORE ? 
                                'square' 
                                : 
                                assignmentType === ASSIGNMENT_TYPES.PRACTICE?
                                    'diamond'
                                    :
                                    'circle'
                        }

                        assignment_reviews__tab_status
                        assignment_reviews__tab_status--${reviewStatus}

                        `}>
                            <div>
                                {
                                    reviewStatus === ASSIGNMENT_REVIEW_STATUS.SUBMITTED ? "S" :
                                        reviewStatus === ASSIGNMENT_REVIEW_STATUS.NEEDS_REVIEW ? '!' :
                                            reviewStatus === ASSIGNMENT_REVIEW_STATUS.CHECKED && review && review.notify_student ? String.fromCharCode(10004) :
                                                reviewStatus === ASSIGNMENT_REVIEW_STATUS.CHECKED && review && !review.notify_student ? <>&nbsp;</> :
                                                    reviewStatus === ASSIGNMENT_REVIEW_STATUS.NEEDS_FOLLOW_UP ? 'F' :
                                                        !assignment || assignment.onTimeStatus === ONTIME_STATUSES.UNSUBMITTED ? ' ' :
                                                            <>&nbsp;</>
                                }
                            </div>
                        </div>
                    </div>
                

                {
                    displayDaysOffset &&
                        <div className={`
                            assignment_reviews__tab_ontime_offset
                            assignment_reviews__tab_ontime_offset--${
                                assignment.onTimeStatus === ONTIME_STATUSES.UNSUBMITTED ?
                                        dayDiff > 0 ? 'late' : ''
                                        :
                                        dayDiff > 0 ? assignment.onTimeStatus : ONTIME_STATUSES.ONTIME // if 0, force to ontime
                                
                            }
                        `}>
                            <div>
                                {
                                    assignment.onTimeStatus === ONTIME_STATUSES.UNSUBMITTED ?
                                        dayDiff > 0 ? `${dayDiff}` : `0`
                                        :
                                        dayDiff
                                }
                            </div>
                        </div>

                }
        </div>
    )
}

export default ReviewTab;

/** multiple assgnments: jls9000@hotmail.com */

