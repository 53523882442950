import TABService from './TABService';
import Helpers from '../helpers/Helpers';

class StudentService extends TABService {

    model = 'student';

    _hideStudentInfo = ( student ) => {
        return({
            ...student,
            email_address: "***@***.com",
            last_name: student.last_name.substring(0,1) + ".",
            full_name: student.first_name + " " + student.last_name.substring(0,1) + ".",
            instructor_email_address: "instructor@codingdojo.com"
        })
    }

    getStackInfo = ( student_id ) => {
        return this._get(
            `${this.model}/stack-info/${student_id}`
        )
    }

    searchStudents = ( search_pattern ) => {
        return new Promise((resolve, reject) => {
            this._post(
                `${this.model}/search`,
                { search_pattern }
            )
            .then ( students => { 
                resolve(Helpers.getPrivacyMode() ? // if in privacy mode, shorten last name
                    students.map( student => (
                        {
                            ...student,
                            last_name: student.last_name.substring(0,1) + ".",
                        } 

            )) : students);
            })
            .catch( err => reject(err))
        })
    }

    pullStudent = ( student_id ) => 
        this._get(
            `${this.model}/pull/${student_id}`
        )

    updateStudents = ( students ) => { 
        let updateItems = [];

        students.forEach( student => {
            const updateItem = this._preprocessStudent(student);
            if (updateItem) updateItems.push(updateItem);
        })

        return (
            this._post(
                `${this.model}/update-students`, updateItems
            ))
    }

    updateStudent = ( student ) => 
        new Promise((resolve, reject) => {
            this.updateStudents([student])
                .then( response => resolve(response))
        })
        
    _preprocessStudent = ( student ) => { // process student update data before sending to backend
      
        // build log
        const excludeColumns = ['snapshot', 'data_issues', 'assignments', 'isUpdating', 'status_slug', 'isSelected']
        const jsonifyColumns = ['rollbacks_json', 'instructor_flags']
        let log = [];
        let updateItems = {
            id: student.id,
        };

        Object.keys(student).forEach( key => { //console.log(student, key)
            
            if ( 
                excludeColumns.indexOf(key) === -1 && 
                key in student.snapshot &&
                JSON.stringify(student[key]) !== JSON.stringify(student.snapshot[key])
                ) { 
                    if ( jsonifyColumns.indexOf(key) > -1 ) {
                        updateItems[key] = JSON.stringify(student[key]);
                    } else updateItems[key] = student[key];
                    
                    log.push({
                        column_name: key,
                        previous_value: JSON.stringify(student.snapshot[key]),
                        updated_value: JSON.stringify(student[key]),
                        log_note: student.log_note ? student.log_note : null
                    })
                }
        })
        
        return Object.keys(updateItems).length === 0 ? null : {
            ...updateItems,
            id: student.id,

            // needed by BE
            cohort_id: student.cohort_id,
            tab_id: student.tab_id,
            log: log
        };
    }

    pullSheetHistory = ( student_id, cohort_id ) => {
        return this._get(
            `${this.model}/pull-sheet-history/${student_id}/${cohort_id}`
        )
    }

    pullHistory = ( user_id ) => {
        return this._get(
            `${this.model}/history/${user_id}`
        )
    }

    sendExamEmails = ( queueItems ) => 
        this._post(
            `${this.model}/queue-exam-emails`,
            [...queueItems]
        )

    sendEOWReminderEmails = ( queueItems ) =>
        this._post(
            `${this.model}/queue-eow-emails`,
            [...queueItems]
        )

    pullRetakeHistory = ( user_id ) => 
        this._get(
            `${this.model}/pull-retake-history/${user_id}`
    )

    postSubstantiveInteraction = ( interaction ) => 
        this._post(
            `${this.model}/substantive-interaction`,
            interaction
        )
}

export default new StudentService();
export const studentService = new StudentService();