import React, { useEffect, useState } from 'react';

import Ticket, { TICKET_MODES } from '../ticket/Ticket';
import CommentsBrowser from '../comments/CommentsBrowser';
import TracksBrowser from '../ticket/TracksBrowser';
import ticketService from '../../services/TicketService';
import TopicsSelector from '../ticket/TopicsSelector';
import Timer from '../elements/Timer';
import helpers from '../../helpers/Helpers';
import { useSearchParams } from 'react-router-dom';
import LinkToClipboard from '../elements/LinkToClipboard';
import EscalateBtn from './EscalateBtn';
import LoadingSpinner from '../modal_assets/LoadingSpinner';
import ActionButtons, { ICONS, BasicActionButton } from '../elements/ActionButtons';

const ViewTicket = ( { 
    ticketId, 
    updateTicket, 
    showName = false, 
    decrementTicketCount = false ,
    close = null
} ) => {
    
    const [ ticket, setTicket ] = useState(null);
    const [ closing, setClosing ] = useState(false);
    const [ finalizingClosing, setFinalizingClosing ] = useState(false);
    const [ editOpenTime, setEditOpenTime ] = useState(null);
    const [ search, setSearch ] = useSearchParams();

    useEffect( () => {
        
        ticketService.pullTicket( ticketId )
            .then( ticket => {
                setTicket({
                    ...ticket,
                    date_loaded: new Date()
                })
                updateTicket({
                    ...ticket,
                    date_loaded: new Date()
                })
            })
            .catch( err => console.log(err) )

    }, [ticketId])

    const onEscalateClose = () => {
        setEditOpenTime( helpers.minutesBetween(ticket.date_loaded, new Date()) + ticket.time_open );
        
        setClosing(true);
        finalizeClose(true);
    }

    const finalizeClose = ( skipChecks = false ) => { 
        //ensure user isn't submitting blank time..eventually use some sort of CSS to show invalid values/a small message
        if (isNaN(editOpenTime)) return window.alert("Please enter a valid time.");
        if ( skipChecks === false && ticket.comment_count === 0 ) return window.alert("A comment is required before closing this ticket.");

        let confirmCloseMessage = "";
        // confirm user wants to close ticket if it's been open for more than 2 hours
        if ( editOpenTime + ticket.time_open >= 60 || helpers.minutesBetween(ticket.date_loaded, new Date()) + ticket.time_open >= 60 ) confirmCloseMessage += "The open time for this ticket is more than on hour.\nPlease adjust if necessary.\n\n"
        
        //allow for bypass of confirm when using "update and close", as that's confirmation enough
        if ( (skipChecks === false && !closing || confirmCloseMessage !== "") && !window.confirm(confirmCloseMessage + 'Are you sure you want to close this ticket?') ) return;

        setFinalizingClosing(true);

        ticketService.close(ticket.id, editOpenTime !== null ? editOpenTime : helpers.minutesBetween(ticket.date_loaded, new Date()) + ticket.time_open )
            .then( ticket => onUpdateTicket(ticket, false, decrementTicketCount))
            .catch( err => console.log(err))
            .finally( resp => {
                setClosing(false);
                setFinalizingClosing(false);
                setEditOpenTime(0);
                setSearch({});
                close();
            })
    }

    const initiateClose = () => {
        setEditOpenTime( helpers.minutesBetween(ticket.date_loaded, new Date()) + ticket.time_open )
        setClosing(true)
    }

    const minutesToFormat = ( total_minutes ) => {
        let hours = parseInt(total_minutes / 60);
        let minutes = total_minutes - ( hours * 60 );

        return (`${hours}:${("00" + minutes).slice(-2)}`);
    }

    const onUpdateTicket = ( ticket, autoUpdate = false, decSearchResTotal = false ) => {
        setTicket(ticket);
        /*decSearchResTotal is an extention of the onTicketUpdated in TicketBrowser functionality.
        Unforunately, this default is required here because this state event trigger is called by other functions
        like comment adds that shouldn't be decrementing the searchResponse.total state value in onTicketUpdated.*/
        updateTicket(ticket, autoUpdate, decSearchResTotal);
    }

    const TicketStatus = ( props ) => {

        const { ticket } = props;

        const status = ticket.status === 1 ? 'Open' : 'Closed';

        return (
            <div className={`
                text-center 
                ticket__status 
                ticket__status-${status}
                `}>
                {
                    status === 'Open' ?
                        <div className={`text-center ticket__status ticket__status--${ticket.time_open < 21 ? "ok" : ticket.time_open < 46 ? "warning" : "danger"}`}>
                            {status}  |  <Timer 
                                            startMinutes={helpers.minutesBetween(ticket.date_loaded, new Date) + ticket.time_open}
                                            />
                        </div>
                        :
                        <div className=''>
                            {status} |  {minutesToFormat(ticket.total_time_open)}
                            {
                                (ticket.resolved === 0) &&
                                    <div className='text-center'>
                                        <EscalateBtn 
                                            closeTicket={onEscalateClose} 
                                            ticket={ticket}
                                            updateTicket={onUpdateTicket}
                                        />
                                    </div>
                            }
                        </div>
                }
            </div>
        )
    }

    if ( ticket === null ) return <div id='view_ticket' className='row layer layer-2 p-1 text-center window_1200'><div className='col-12 text-center'>Loading Ticket...</div></div>

    return (
        <div 
            id='view_ticket'  
            className='row window_1200'
                    >
                    <div className='col-12 text-right'>
                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: ICONS.CANCEL,
                                    onClick: close
                                })
                            ]}
                        />
                    </div>
            <div className='col-12'>
                <div className='row'>
                    <div className='col-2 text-center'>
                        <TicketStatus
                            ticket={ticket}
                        />
                    </div>
                    <div className={`col-10`}>
                        {
                            closing &&
                                <div className='col-12'>
                                    <fieldset className='row' disabled={finalizingClosing}>
                                        <div className='col-3'>
                                            <input 
                                                type="number" 
                                                className='form-control'
                                                step={1} 
                                                min={1} 
                                                autoFocus="autoFocus" /* hack to keep focus */
                                                value={editOpenTime} 
                                                onChange={(e) => setEditOpenTime(parseInt(e.target.value))}
                                                onKeyDown={e => helpers.keyboardShortcut({e,callback:finalizeClose,keyCode:"Enter",useCtrl:true})}
                                                />
                                        </div>
                                        <div className='col-3 header-text'>
                                            Minute
                                            {
                                                editOpenTime === 1 ? "" : "s"
                                            }
                                        </div>
                                        <div className='col-6 text-center'>
                                            <button
                                                className='btn btn-sm btn-warning'
                                                onClick={ () => finalizeClose(false)}
                                            >Close</button>
                                            <button
                                                className='btn btn-sm btn-info ml-2'
                                                onClick={ () => {
                                                    setClosing(false);
                                                    setEditOpenTime(null);
                                                } }
                                            >Cancel</button>
                                            <div className='mt-2'></div>
                                            {
                                                (ticket.resolved === 0 ) &&
                                                    <div className='text-center'>
                                                        <EscalateBtn 
                                                            closeTicket={onEscalateClose} 
                                                            ticket={ticket}
                                                            updateTicket={onUpdateTicket}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </fieldset>
                                </div>
                        }
                        {
                            !closing &&
                                <div className='col-12 mb-2'>
                                    <div className='row'>
                                        {
                                            ticket.status === 1 &&
                                                <div className='col-7 subheader-text text-center'>
                                                    {helpers.formatDate(ticket.date_added)}&nbsp;
                                                    by {ticket.staff_name}&nbsp;
                                                    <LinkToClipboard link={ticket.staff_email} />
                                                </div>
                                        }
                                        <div className='col-5 text-center'>
                                            {
                                                ticket.status === 1 &&
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                                <button
                                                                    className='btn btn-sm btn-danger'
                                                                    onClick={() => finalizeClose()}
                                                                    disabled={closing}
                                                                >Close Ticket</button>
                                                                <button
                                                                    className='btn btn-sm btn-warning ml-2'
                                                                    onClick={initiateClose}
                                                                    disabled={closing}
                                                                >Update Time and Close</button>
                                                                <div className='mt-2'></div>
                                                                {
                                                                    (ticket.resolved === 0) &&
                                                                        <div className='text-center'>
                                                                            <EscalateBtn 
                                                                                closeTicket={onEscalateClose} 
                                                                                ticket={ticket}
                                                                                updateTicket={onUpdateTicket}
                                                                            />
                                                                        </div>
                                                                }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                <div className='divider mt-2 mb-2'><div></div></div>
            </div>
            <div className='col-6'>
                <div className='row'>
                    
                    <div className='col-12 '>
                        <Ticket 
                            ticket={ticket} 
                            showName={showName}
                            ticketUpdated={onUpdateTicket} 
                            mode={TICKET_MODES.FULL}
                            />

                        
                        {
                            ticket.close_date === null &&
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <div className='create_ticket__header mb-2'>
                                            Topics: <span className='sub_note'>( Keep new topics to 1 or 2 words. Do not add stacks. )</span>
                                        </div>
                                        <TopicsSelector 
                                            ticketId={ticket.id}
                                            selectedTopics={ticket.topics}
                                        />
                                    </div>
                                    <div className='col-12 mt-3'>
                                        <div className='create_ticket__header'>
                                            Assignment:
                                        </div>
                                        <TracksBrowser 
                                            trackId={ticket.track_id}
                                            canUpdateTrack={false}
                                            key={'tracks_browser'}
                                            selectedModuleId={ticket.assignment_id !== null ? ticket.assignment_id : null}
                                            updateSelectedModule={( module ) => { 

                                                if ( module )
                                                    onUpdateTicket({
                                                        ...ticket,
                                                        assignment_id: module.id,
                                                        assignment_name: module.title
                                                    }, true)
                                                else
                                                    onUpdateTicket({
                                                        ...ticket,
                                                        assignment_id: null,
                                                        assignment_name: null
                                                    }, true)
                                            }}
                                        />  
                                    </div>
                                </div>
                        }  
                    </div>
                </div>
            </div>


            <div className='col-6 layer layer-2 p-2 rounded'>
                
                <div className='create_ticket__header'>
                    Comments:
                </div>
                <div className='view_ticket__comments_browser'>
                    <CommentsBrowser 
                        ticketId={ticket.id}
                        commentAdded={( comment ) =>
                            onUpdateTicket({
                                ...ticket,
                                comment_count: ticket.comment_count + 1,
                                last_comment: comment.text
                            },false)
                        }
                        />
                </div>
            </div>
            {
                finalizingClosing &&
                    <LoadingSpinner />
            }
        </div>
    )
}

export default ViewTicket;