import React from 'react';
import Ticket, {TICKET_MODES} from '../ticket/Ticket';

const ViewTickets = ( { tickets,viewTicket, updateTicket = null, displayStudentInfo = false, showName = false, breakdownTickets = false } ) => {

    return (
        <div className='row layer layer-1 view_tickets'>
                    
            {
                tickets
                    .sort( (a, b) => 
                        a.status > b.status ? 1 : a.status < b.status ? -1 : 0
                    )
                    .map( ticket => 
                        <Ticket 
                            ticket={ticket}
                            displayStudentInfo={displayStudentInfo}
                            ticketUpdated={updateTicket}
                            viewTicket={viewTicket}
                            mode={TICKET_MODES.COMPACT}
                            key={`ticket_${ticket.id}`} 
                            showName={showName}
                            />
                    )
            }
        </div>
    )
}

export default ViewTickets;