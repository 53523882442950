import React, { useContext } from 'react';
import { useEffect } from 'react';
import { AppContext } from '../../store/AppContext';
import WikiDisplay from './WikiDisplay';

const WikiResult = ({ wiki, selected, selectWiki, removeParam, stacks }) => {

    const { viewTicket, setViewTicket } = useContext(AppContext);

    useEffect(() => {
        if (selected) setViewTicket(WikiDisplay({wiki}))
    }, [selected])

    const wikiClicked = () => {
        selectWiki({ wiki: wiki.id })
    }

    return (
        <>
            <div
                className='row mb-2'
                onClick={wikiClicked}
            >
                <div className='col-12 layer layer-3 layer-3_hover'>
                    {wiki.title}
                </div>
            </div>
        </>
    )
}

export default WikiResult;