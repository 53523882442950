const defaultConfig = {
}

const dev = {
    API_URL: "https://ta-buddy.codingdojo.com/tab2",  /* "https://ta-buddy.codingdojo.com/tab2" "http://localhost:5000/tab2" */
    DLDR_ID: "pbnjipaeiiajjaonfnflnnpgpooalemj", 
    DOWNLOADER_LINK: "https://sites.google.com/codingdojo.com/tab2-manual/assignment-reviews#h.50efqb6you2h",
    TRIVJO_URL: "http://localhost:5173",
}

const prod = {
    API_URL: "https://ta-buddy.codingdojo.com/tab2",
    DLDR_ID: "pbnjipaeiiajjaonfnflnnpgpooalemj",
    DOWNLOADER_LINK: "https://sites.google.com/codingdojo.com/tab2-manual/assignment-reviews#h.50efqb6you2h"
}

const test = {
    API_URL: "https://ta-buddy.codingdojo.com/tab2"  /*"http://localhost:5000" */ 
}

export const config = process.env.NODE_ENV === 'development' ? {...dev, ...defaultConfig} : process.env.NODE_ENV === 'test' ?  {...test, ...defaultConfig}  : {...prod, ...defaultConfig} ;