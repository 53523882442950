import React, { useContext } from 'react';
import TicketsBrowser from './tickets_browser/TicketsBrowser';
import { AppContext } from '../store/AppContext';
import QueryBuilder from '../helpers/QueryBuilder';

const MyTickets = () => {

    const { user } = useContext(AppContext);

    return (
        <div className='row justify-content-center my_tickets'>
            <div className='col-12'>
                <h1 className='text-center mb-2'>My Tickets</h1>
                <TicketsBrowser 
                    initialTicketQuery={
                        new QueryBuilder()
                            .test(`staff_id = ${user.id}`)
                    }
                    showName={true}/>
            </div>
        </div>
    )
}

export default MyTickets;