import { useState } from "react";

const SimpleCheckColumn = ({
    student,
    updateStudent,
    column = null,
    isChecked = false,
    disabled = false,
    character = 10004,
    rawResponse = null, // set this if you want to use this component for something other than updating a student

}) => { 

    const checkValue = column !== null ? student[column] ? true : false : isChecked;
    
    const updateColumn = () => {
        if ( rawResponse ) {
            rawResponse(! checkValue);

        } else {
            updateStudent({
                ...student, 
                [column]: student[column] ? 0 : 1, // need to use numbers for history tracking
            });
        }
    }
    return (
            <span 
                className={`
                    simple_check_column
                    ${disabled ? "noselect" : "simple_check_column--active"}
                `
                }
                onClick={() => {
                    if ( ! disabled )updateColumn();
                }}
            >
                <div>
                    {checkValue ? String.fromCharCode(character) : <>&nbsp;</>}
                </div>
            </span>
    )
}

export default SimpleCheckColumn;