/*
    Lets user select an exam.
    When selected, the temp_exam is updated in the student object.
*/

import React, { useState }  from 'react';
import FilterSorterBtn from '../../filter_sorter/FilterSorterBtn';
import useStudent from '../../../../hooks/useStudent';
import ActionButtons, { ICONS, BasicActionButton, Label, CopyToClipboardButton} from '../../../elements/ActionButtons';

const StudentExamAssignment = ({ 
    student,
    activeExams,
    issueExam = null, // function to issue exam to student
    }) => {

        const { updateStudent, saveStudent } = useStudent();
        
        const [ selectedExam, setSelectedExam ] = useState(student.temp_exam);
        const [ viewExamHistory, setViewExamHistory ] = useState(false);

        // build list of available exams
        const availableExams = activeExams.filter(exam => student.historical_exam_ids.indexOf(exam.id) === -1)
        
    return (
        <div 
            className={`
                student_exam_assigner
                
                `}
            >
            {
                student.exams.length === 3 ?
                    <div 
                        className="student_exam_assigner--no_exams"
                    >No More Attempts</div>
                    :
                    activeExams.length === 0 ?
                        <div 
                            className="student_exam_assigner--no_exams"
                        ></div>
                        :
                        availableExams.length === 0 ?
                        <>
                            <div 
                                className="student_exam_assigner--no_exams"
                            >No Exams Available</div>
                            <FilterSorterBtn

                                history={() => setViewExamHistory(true)}
                                historyTooltip='Exam History'
                            />
                            </>
                            :
                            <>
                                <div>
                                    <select
                                        onChange={(e) => {

                                            let selectedExam = activeExams.find(exam => exam.code === e.target.value);
                                            setSelectedExam(selectedExam);
                                            updateStudent({
                                                ...student,
                                                temp_exam: {
                                                    id: selectedExam.id,
                                                    title: selectedExam.title,
                                                    code: selectedExam.code,
                                                }
                                            });

                                            // eventHandler.dispatch(UPDATE_SHEET_STUDENT,{student: updatedStudent, localOnly: true})

                                            // if (setTempExam) setTempExam(tempExam);
                                                
                                        }}
                                        className={`
                                            ${selectedExam ? 'student_exam_assigner--exam_assigned' : ''}
                                        `}
                                        >
                                            {
                                                ! selectedExam &&
                                                <option value={null}>Select Exam</option>
                                            }
                                        
                                            {
                                                availableExams.map(exam => {
                                                    return (
                                                        <option
                                                            key={exam.code}
                                                            value={exam.code}
                                                            selected={selectedExam && selectedExam.code === exam.code}
                                                        >
                                                            {exam.title} exp: {new Date(exam.end_time).toLocaleDateString()} 
                                                        </option>
                                                    )
                                                })
                                            }
                                    </select>
                                </div>
                                <div className=''>
                                    <ActionButtons
                                        buttons={[
                                            BasicActionButton({
                                                icon: ICONS.HISTORY,
                                                tooltip: 'Exam History',
                                                onClick: () => setViewExamHistory(true)
                                            }),
                                            CopyToClipboardButton({
                                                icon: ICONS.LINK,
                                                tooltip: 'Copy Exam Code',
                                                content: selectedExam ? selectedExam.code : null,
                                                visible: selectedExam ? true : false
                                            }),
                                            BasicActionButton({
                                                icon: ICONS.CANCEL,
                                                tooltip: 'Cancel',
                                                visible: selectedExam ? true : false,
                                                onClick: () => {
                                                    setSelectedExam(null);
                                                    updateStudent({
                                                        ...student,
                                                        temp_exam: null
                                                    })
                                                }
                                            
                                            }),
                                            Label({
                                                text: "Issue Code",
                                                visible: issueExam && selectedExam ? true : false,
                                                onClick: () => {issueExam(selectedExam)}
                                            })
                                        ]}

                                    />
                                </div>
                            </>
            }
            {
                viewExamHistory &&
                    <div className='modal_overlay modal_overlay--30'>
                        <div className='row layer layer-1'>
                            {
                                student.historical_exams.length === 0 &&
                                    <div className='col-12 text-center'>
                                        No Exams
                                    </div>
                            }
                            {
                                student.historical_exams.length > 0 &&
                                <>
                                    {
                                        student.historical_exams.map(exam => {
                                            return (
                                                <div 
                                                    key={exam.id}
                                                    className='col-12 text-center'
                                                >
                                                    {exam.title} 
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                            <div className='col-12 text-center mt-2'>
                                <button
                                    className='btn btn-info'
                                    onClick={() => setViewExamHistory(false)}
                                >Close</button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default StudentExamAssignment;