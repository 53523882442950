import React, { useState } from 'react';
import { Chart } from "react-google-charts";

const AssignmentAttendanceStats = ({
    statsData,
    currentWeek
}) => { console.log(statsData)

    currentWeek = currentWeek <= statsData.courseWeeks ? currentWeek : statsData.courseWeeks;

    const toDateOverallData = [
        ["Time Span", "Attendance", "Assignments"],
        ["To Date", statsData.toDateAttendancePercent, statsData.toDateAssignmentPercent],
        ["Overall", statsData.overallAttendancePercent, statsData.overallAssignmentPercent],
    ]

    const data = [
        ["Time Span", "Attendance", "Assignments"],
    ]

    for ( let week = 0; week < currentWeek; week++ ) {
        data.push([`Week ${week+1}`, statsData.attendancePercents[week], statsData.onTimePercents[week]]);
    }
    
    return (
        <div className='row'>
            <div className='col-4 mt-2'>
                All assignment submissions and attendance
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={toDateOverallData}
                    options={{
                    }}
                />
            </div>
            <div className='col-8 mt-2'>
             Attendance = % students who attended / Assignments = % of submitted assignments that were on time
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={data}
                    options={{
                    }}
                    />
            </div>
        </div>
    )
}

export default AssignmentAttendanceStats;