import React, { useState } from 'react';
import AddWiki from './AddWiki';
import SearchWikis from './SearchWikis';

export const WIKIBROWSERMODES = {
    search: 0,
    add: 1,
}

const WikiBrowser = () => {

    const [ mode, setMode ] = useState(WIKIBROWSERMODES.search)

    return (
        <div className='row justify-content-center layer layer-1'>
            <div className='col-12 text-center header-text'>
                DojoOverflow
            </div>
            <div className='col-8 layer layer-2'>
                {
                    mode === WIKIBROWSERMODES.add ?
                        <AddWiki setBrowserMode={setMode}/>
                        :
                        <SearchWikis setBrowserMode={setMode}/>
                }
            </div>
        </div>
    )
}

export default WikiBrowser;