import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import studentService from '../../services/StudentService';
import TicketsBrowser from '../tickets_browser/TicketsBrowser';
import helpers from '../../helpers/Helpers';
import CommentsBrowser from '../comments/CommentsBrowser';
import SubmittedAssignments from './SubmittedAssignments';
import LinkToClipboard from '../elements/LinkToClipboard';
import FilterSorterBtn from '../my_cohort/filter_sorter/FilterSorterBtn';
import { StudentHistoryDisplay } from './history/StudentHistory';
import PullStudentExams from './PullStudentExams';
import ActionButtons, { ICONS, CopyToClipboardButton, BasicActionButton, OpenURLButton } from '../elements/ActionButtons';
import PullStudentSEMSettings from './PullStudentSEMSettings'; 
import useStudent from '../../hooks/useStudent';
import useOverlays, { OVERLAY_TYPES } from '../../hooks/useOverlays';

const PullStudent = () => {

    const { student_id, ticket_id } = useParams();
    const { processStudent, saveStudent, addStudentUpdateListener, removeStudentUpdateListener } = useStudent();
    const [ student, setStudent ] = useState();
    const [ viewHistory, setViewHistory ] = useState(false);
    const [ viewAssignments, setViewAssignments ] = useState(false);
    const [ hasLoadError, setHasLoadError ] = useState(false);
    const [ studentStatuses, setStudentStatuses ] = useState([]);
    const [ stackList, setStackList ] = useState([]);

    useEffect(() => {
        addStudentUpdateListener(onUpdateStudent);

        return () => {
            removeStudentUpdateListener(onUpdateStudent);
        }
    }, [])

    useEffect(() => {

        if ( student_id )
            pullStudent(student_id);

    }, [student_id])

    const pullStudent = (studentId) => {
        setStudent();
        setHasLoadError(false);
        studentService.pullStudent(studentId)
            .then( resp => {
                setStudentStatuses(resp.student_statuses);
                setStackList(resp.stacks_lists);
                setStudent(processStudent(resp.student, resp.student.cohort_calendar));
            })
            .catch( err => {
                console.log(err)
                setHasLoadError(true);
            } )
    }

    const onUpdateStudent = (event) => { console.log(event)

        const { student, localOnly } = event.detail;
        
        setStudent(student);

        if ( !localOnly ) {
            studentService.updateStudent(student)
                .then( resp => {
                    if ( resp.success ) {
                        setStudent(processStudent(resp.student));
                        saveStudent(resp.student);
                    }
                })
        }
    }

    let isInCohort = false;

    if (student) { // add one day to end date to make it inclusive
        let tEndDate = new Date(student.cohort_end_date)
        tEndDate.setDate(tEndDate.getDate() + 1)
    
        isInCohort = student ? helpers.todayIsBetweenTwoDates(student.cohort_start_date, tEndDate) : false;
    }

    return (
        <div id="pull_student" className='row'>
            <div className='col-6 layer layer-2 p-2'>
                {
                    student === undefined ?
                            <>Loading...</>
                            :
                            student === null ?
                                <div className='row p-1'>
                                    <div className='col-12 mt-3'>
                                        <p>
                                            Unable to pull information for this student.<br/>
                                            Most likely the student is between cohorts and LP3 has not opened their next course yet.<br/>
                                            Once the student has had their course open, you will be able to pull their information.
                                        </p>
                                    </div>
                                </div>
                                :
                                <div className='row p-1'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <FilterSorterBtn
                                                    emailAddress={student.email_address}

                                                    history={() => setViewHistory(true)}

                                                    note={() => setViewAssignments(true)}
                                                    noteTooltip='View Assignments'

                                                    hubspotURL={'https://app.hubspot.com/contacts/5092698/objects/0-1/views/all/list?query=' + encodeURIComponent(student.email_address)}
                                                />
                                                <span id="pull_student__name" className=" ml-2">
                                                    {student.first_name} {student.last_name} 
                                                </span>
                                            </div>
                                            <div className='divider col-12 mt-2 mb-2'><div></div></div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='text-center mb-2'><b><u>Current cohort</u></b></div>
                                                {
                                                    isInCohort === false &&
                                                    <div className="text-light font-weight-bold text-center bg-danger mb-2">COHORT ENDED</div>
                                                }
                                                <CurrentCohortInfo
                                                    student={student}
                                                    studentStatuses={studentStatuses}
                                                />
                                            </div> 
                                            
                                        </div> 
                                    </div>
                                    
                                    <div className='col-12 mt-3'>
                                        <div className='subheader-text'>
                                            Recent Comments:
                                        </div>
                                        <div id="pull_student__comments" className='layer layer-3'>
                                            <CommentsBrowser studentId={student.id} getRecent={true} />
                                        </div>
                                        
                                    </div>
                                    <div className='col-12 text-center mt-2 sub_note'>
                                        If a student needs help with a locked platform or questions unrelated to their studies, refer them to the Student Experience Managers <LinkToClipboard link={'support@codingdojo.com'} />.
                                    </div>
                                </div> 
                }
            </div>
            <div className='col-6'>
                {
                    student === undefined || student === null ?
                        <></>
                        :
                        <>
                            <TicketsBrowser 
                                student={student} 
                                canAddTickets={true} 
                                pullTicketId={ticket_id !== undefined ? parseInt(ticket_id) : null}
                                breakdownTickets={true}
                                />
                        </>
                }
            </div>

            {
                viewHistory &&
                <div className="modal_overlay modal_overlay--80">

                    <StudentHistoryDisplay
                        student={student}
                        close={() => setViewHistory(null)}
                        />
                </div>

            }

            {
                viewAssignments &&
                <div className="modal_overlay modal_overlay--80">
                    <SubmittedAssignments 
                        student={student}
                        close={() => setViewAssignments(false)}
                        />
                </div>

            }

            {
                hasLoadError &&
                <div className="modal_overlay modal_overlay--30">
                    <div className='layer layer-1 text-center font-weight-bold'>
                        <p>
                            Sorry, this student cannot be loaded. Some older students cannot be retrieved correctly from LP3.
                        </p>
                        
                        If this is a current student, please contact TAB2 support.
                    </div>
                </div>
            }
        </div>
    )
}

export default PullStudent;

const CurrentCohortInfo = ({
    student,
    studentStatuses,
    updateStudent
}) => { 

    const { pushOverlay, popOverlay, updateOverlay } = useOverlays();

    useEffect( () => {
        updateOverlay( 'student_sem_settings', { student })
    }, [student])

    if ( !student ) return <></>;

    return (
        <div 
            id="pull_student__cohort_info"
            className='row'
            >
            <div className='col-3 text-right'>
                <ActionButtons
                    buttons={[
                        CopyToClipboardButton({
                            icon: ICONS.EMAIL,
                            toolTip: 'Copy Email',
                            clipboardContent: student.instructor_email_address,
                        }),
                        OpenURLButton({
                            icon: ICONS.COHORT,
                            toolTip: "Open Cohort Sheet",
                            url: `/assignment-reviews?cohort_id=${student.cohort_id}&suid=${student.id}`,
                            newTab: false,
                            visible: student.instructor_calendly_link !== "",
                        }),
                        CopyToClipboardButton({
                            icon: ICONS.CALENDLY,
                            toolTip: 'Copy Instructor Calendly Link',
                            clipboardContent: student.instructor_calendly_link,
                        }),
                        CopyToClipboardButton({
                            icon: ICONS.SELECT,
                            toolTip: 'Copy Class Schedule Link',
                            clipboardContent: student.instructor_calendar_link,
                            visible: student.instructor_calendar_link !== "",
                        }),
                        BasicActionButton({
                            icon: ICONS.NOTE,
                            toolTip: 'View Instructor Note',
                            onClick: (e) => 
                                pushOverlay({
                                    type: OVERLAY_TYPES.ALERT,
                                    position: {x: e.clientX, y: e.clientY},
                                    component: <>{student.instructor_note}</>
                                
                                }),
                            visible: student.instructor_note !== "",
                        }),
                    ]}
                />
                <div className='mt-1'></div>
                SEM:
                <div className=""></div>
                Program:
                <br/>
                Track:
                <br/>
                Dates: 
                <br/>
                &nbsp;
                <br/>
                Assignments:
                <br/>
                Attendance:
            </div>
            <div className='col-9'>
                <div className='pull_student__cohort_info__instructor'>{student.instructor}</div>
                <ActionButtons 
                    buttons={[
                        BasicActionButton({
                            icon: ICONS.TOGGLE,
                            toolTip: 'SEM Settings',
                            onClick: (e) => 
                                pushOverlay({
                                    position: {x: e.clientX, y: e.clientY},
                                    component: <PullStudentSEMSettings
                                        key={`sem_settings_${student}`}
                                        student={student}
                                        studentStatuses={studentStatuses}
                                        updateStudent={updateStudent}
                                        close={() => popOverlay()}
                                    />
                                }),
                        }),
                        CopyToClipboardButton({
                            icon: ICONS.EMAIL,
                            toolTip: 'Copy Email',
                            clipboardContent: student.sem_email_address,
                        })
                    ]}
                />
                <span className="ml-2">{student.sem_name || 'N/A'}</span>
                <br/>
                {student.program_name || 'N/A'}
                <br/>
                {student.track_name || 'N/A'}
                <br/>

                {helpers.formatDate(new Date(student.cohort_start_date))} - {helpers.formatDate(new Date(student.cohort_end_date))}
                <br/>

                <div className='row'>  
                    <div className='col-6'>To-Date</div>
                    <div className='col-6'>Overall</div>
                </div>

                <div className='row'>  
                    <div className='col-6'>
                        {
                        student.core_assignments_total_to_date > 0 ?
                        (  (student.core_assignments_to_date / student.core_assignments_total_to_date) * 100).toFixed(0) 
                        : 0
                        }% ({student.core_assignments_to_date}/{student.core_assignments_total_to_date})
                    </div>
                    <div className='col-6'>
                        {
                        student.core_assignments_total_overall > 0 ?
                        ( (student.core_assignments_overall / student.core_assignments_total_overall) * 100).toFixed(0)
                        : 0 
                        }% ({student.core_assignments_overall}/{student.core_assignments_total_overall})
                    </div>
                </div>

                <div className='row'>  
                    <div className='col-6'>
                        {
                            student.attendance_total_to_date > 0 ?
                            ( (student.attendance_to_date/student.attendance_total_to_date) * 100).toFixed(0) 
                            : 0
                        }% ({student.attendance_to_date}/{student.attendance_total_to_date})
                    </div>
                    <div className='col-6'>
                        {
                            student.attendance_total_overall > 0 ?
                            ( (student.attendance_overall/student.attendance_total_overall) * 100 ).toFixed(0) 
                            : 0
                        }% ({student.attendance_overall}/{student.attendance_total_overall})
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <PullStudentExams
                    student={student}
                    />
            </div>
        </div>
    )
}