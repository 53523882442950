import React from "react";

const TrivjoAnswerSelectBox = ({ 
    selected, 
    setSelected,
    disabled = false,
}) => {

    return (
        <div 
            className={`
                tj_answer_select_box 
                ${selected ? "tj_answer_select_box--selected" : disabled ? "" : "tj_answer_select_box--active"}
                `}
            onClick={
                disabled ?
                () => {} :
                ()=>setSelected()}
                >
            &#x2713;
        </div>
    )
}

export default TrivjoAnswerSelectBox;