import TABService from './TABService';

class RBKRubricAssignmentService extends TABService {

    model = 'rbk_rubric_assignment';

    getByRubricId = ( rubric_id ) => 
        this._get(
            `${this.model}/by-rubric/${rubric_id}`
        )
}

export const rubricAssignmentService = new RBKRubricAssignmentService();