/* base for filter and sort buttons */
import React from 'react';

const MyCohortFilterButton = ({ 
    disabled, 
    prompt, 
    onClick, 
    tooltip=null, 
    active=false 
}) => 
        <button 
            className={`
                roster_filter_button 
                ${active ? "active_filter_button" : ""}
                `}
            data-toggle={`${ tooltip? tooltip : ""}`}
            title={tooltip}
            disabled={disabled}
            onClick={onClick}
        >
        {prompt}
    </button>

export default MyCohortFilterButton;