import React, { useEffect, useState } from 'react';
import examService from '../../../../services/ExamService';
import LoadingSpinner from '../../../modal_assets/LoadingSpinner';

const AddTempExam = ({
    addExam,
    close
}) => {

    const [ exams, setExams ] = useState(null);
    const [ selectedExam, setSelectedExam ] = useState(null);

    useEffect(() => {
        examService.getOpenExams()
            .then((res) => setExams(res))
            .catch((err) => console.log(err))

    }, []);

    return(
        <div id="add_temp_exam" className="row ">
            {
                exams === null ?
                    <div className='col-12 text-center'>
                        <LoadingSpinner />
                    </div>
                    :
                    exams.length == 0 ?
                        <div className="col-12 text-center">
                            <h5>No Open Exams Found</h5>
                        </div>
                        :
                        <>
                            <div className='col-12 col-12 text-light bg-info font-weight-bold mt-3 text-center mb-3'>
                                Exam needs to be &nbsp;
                                <a href="https://login.codingdojo.com/admin/exam" target="_blank" rel="noreferrer">
                                    added on LP3
                                </a> before it can be added here.
                            </div>  
                            <div className="col-12">
                                <select 
                                    className='form-control' 
                                    onChange={(e) => setSelectedExam(exams.find( exam => exam.exam_id == parseInt(e.target.value)))}
                                >
                                    {
                                        selectedExam === null &&
                                            <option value={null} selected>
                                                Choose an Exam
                                            </option>
                                    }
                                    {
                                        exams
                                            .sort((a, b) => {
                                                if(a.instructor < b.instructor) { return -1; }
                                                if(a.instructor > b.instructor) { return 1; }
                                                return 0;
                                            })
                                            .map((exam) => (
                                                <option value={exam.exam_id} selected={selectedExam && selectedExam.exam_id == exam.exam_id}>
                                                    {exam.instructor} : {exam.exam_title} exp: {new Date(exam.exam_end_time).toLocaleDateString()}
                                                </option>
                                            ))
                                    }

                                </select>
                                <div className='row'>
                                    <div className='col-12 text-light bg-danger font-weight-bold mt-3 text-center'>
                                        Note: This is a temporary exam. It will not persist after you reload this page.
                                    </div>  
                                </div>
                            </div>
                        </>
            }
            <div className='col-12 text-center mt-2'>
                {
                    exams !== null &&
                        <button 
                            className='btn btn-info'
                            onClick={() => close()}
                        >
                            Close
                        </button>
                }

                {
                    selectedExam !== null &&
                        <button 
                            className='btn btn-warning ml-2'
                            onClick={() => addExam({
                                id: selectedExam.exam_id,
                                title: selectedExam.exam_title,
                                code: selectedExam.exam_code,
                                end_time: selectedExam.exam_end_time
                            })}
                        >
                            Add Exam
                        </button>
                }
            </div>  
        </div>
        
    )
}

export default AddTempExam;