import React, { useState, useEffect } from "react";
import { SORT_TYPES } from "../filter_sorter/sorters/Sorter";
import filterSorter from "../filter_sorter/MyCohortFilterSorter";
import ActionButtons, { FlagButton, ICONS, BasicActionButton, Label, SortButton, BUTTON_SIZES } from "../../elements/ActionButtons";
import { INSTRUCTOR_FLAG_COLORS, INSTRUCTOR_FLAGS } from "../../../helpers/Constants";
import useStudent from "../../../hooks/useStudent";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import ValueSorter from "../filter_sorter/sorters/ValueSorter";
import ValuesFilter from "../filter_sorter/filters/ValuesFilter";

const InstructorFlags = ({
    close,
    setFlag, // send INSTRUCTOR_FLAGS
}) => {

    return (
        <div className={'row'}>
            <div className="col-12">
                <ActionButtons
                    buttons={[
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_NONE}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_NONE)}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_BLUE}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_BLUE)}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_GREEN}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_GREEN)}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_YELLOW}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_YELLOW)}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_RED}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_RED)}
                        />,
                        <BasicActionButton
                            icon={ICONS.CANCEL}
                            onClick={close}
                        />
                    ]}
                    />
            </div>
        </div>
    )
} 

export default InstructorFlags;

export const DisplayInstructorFlag = ({
    student
}) => {

    const { pushOverlay, popOverlay } = useOverlays();
    const { saveStudent } = useStudent();

    let flagColorClass = ['none', 'blue', 'green', 'yellow', 'red'][student.instructor_flags.status];
    if ( student.data_issues.length > 0 ) flagColorClass = 'error';

    return (
        <ActionButtons
            buttons={[
                FlagButton({
                    flagColorClass: flagColorClass,
                    onClick: student.data_issues.length > 0 ?
                        (e) => 
                            pushOverlay({
                                type: OVERLAY_TYPES.ALERT,
                                position: { x: e.clientX, y: e.clientY },
                                component: <>Cannot change flag while data issues are present: <br/><br/> {student.data_issues}</>,
                            })
                        :
                        (e) => pushOverlay({
                        type: OVERLAY_TYPES.PALETTE,
                        component: InstructorFlags({
                            close: () => popOverlay(),
                            setFlag: (flag) => {
                                saveStudent({
                                    ...student,
                                    instructor_flags: {
                                        ...student.instructor_flags,
                                        status: flag
                                    }
                                })
                                popOverlay();
                            }
                        }),
                        position: {
                            x: e.clientX + 20,
                            y: e.clientY
                        }
                    })
                })
            ]}
            />
    )
}

export const SortFilterInstructorFlags = ({
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    const [ flagsSortPattern, setFlagsSortPattern ] = useState(null);
    const [ isFiltered, setIsFiltered ] = useState(false);

    useEffect(() => {
        if ( filterSorter.sorter && filterSorter.sorter.name === 'flags_sorter' ) { 
            setFlagsSortPattern( filterSorter.sorter.sortType );
        } else {
            setFlagsSortPattern( null );
        }

        setIsFiltered( filterSorter.getFilter('instructor_flags_filter') ? true : false );
    }, [filterSorter.sorter, filterSorter.filters])

    return (
        <>
            <ActionButtons
                size={BUTTON_SIZES.SMALL}
                buttons = {[
                    SortButton({
                        sortType: flagsSortPattern === SORT_TYPES.FIRST_NAME_ASC ? "asc" : flagsSortPattern === SORT_TYPES.FIRST_NAME_DESC ?  "desc" : null,
                        setSortType: () => filterSorter.addSorter(new ValueSorter('flags_sorter', 'instructorFlagStatus', flagsSortPattern === SORT_TYPES.ASC ? SORT_TYPES.DESC : SORT_TYPES.ASC )),
                        toolTip: "Sort Instructor Flags"
                    }),
                    BasicActionButton({
                        icon: ICONS.FILTER,
                        isSelected: isFiltered,
                        toolTip: "Filter By Instructor Flag",
                        onClick: (e) => { 
                            pushOverlay({
                            type: OVERLAY_TYPES.PALETTE,
                            component: <InstructorFlagsSorter 
                                close={() => popOverlay()} 
                                setFlags={(flags) => {
                                    filterSorter.addFilter(
                                        new ValuesFilter(
                                            'instructor_flags_filter',
                                            flags,
                                            'instructorFlagStatus',
                                        )
                                    )
                                    popOverlay();
                                    }}
                                />,
                            position: {
                                x: e.clientX,
                                y: e.clientY
                            
                            }
                        })}
                    }),
                ]}
            />
        </>
    )
}

export const InstructorFlagsSorter = ({
    close,
    setFlags,
}) => { 

    const [ selectedFlags, setSelectedFlags ] = useState([]);

    useState(() => {
        let filter = filterSorter.getFilter('instructor_flags_filter');
        if ( filter ) {
            setSelectedFlags(filter.values);
        } else {
            setSelectedFlags([]);
        }
    }, [filterSorter.sorter, filterSorter.filters])

    const setFlag = (flag) => {
        if ( selectedFlags.includes(flag) ) {
            setSelectedFlags(selectedFlags.filter(f => f !== flag));
        } else {
            setSelectedFlags([...selectedFlags, flag]);
        }
    }

    return (
        <div className='row'>
            <div className="col-12 text-center sub_note mb-1">
            Select Flags to Filter By
            </div>
            <div className="col-12 text-center mb-1">
                <ActionButtons
                    buttons={[
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_NONE}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_NONE)}
                            xClass={! selectedFlags.includes(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_NONE) ? 'action_btn--dimmed' : ''}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_BLUE}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_BLUE)}
                            xClass={! selectedFlags.includes(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_BLUE) ? 'action_btn--dimmed' : ''}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_GREEN}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_GREEN)}
                            xClass={! selectedFlags.includes(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_GREEN) ? 'action_btn--dimmed' : ''}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_YELLOW}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_YELLOW)}
                            xClass={! selectedFlags.includes(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_YELLOW) ? 'action_btn--dimmed' : ''}
                        />,
                        <FlagButton
                            flagColorClass = {INSTRUCTOR_FLAG_COLORS.INSTRUCTOR_FLAG_RED}
                            onClick={() => setFlag(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_RED)}
                            xClass={! selectedFlags.includes(INSTRUCTOR_FLAGS.INSTRUCTOR_FLAG_RED) ? 'action_btn--dimmed' : ''}
                        />
                    ]}
                    />
            </div>
            <div className="col-12 text-center">
                <ActionButtons
                    buttons={[
                        Label({
                            text: 'Apply',
                            bgColor: 'success',
                            onClick: () => {setFlags(selectedFlags)},
                            visible: selectedFlags.length > 0
                        }),
                        Label({
                            text: 'Clear',
                            bgColor: 'warning',
                            onClick: () => {
                                filterSorter.removeFilter('instructor_flags_filter');
                                close();
                            },
                            visible: selectedFlags.length > 0
                        }),
                        Label({
                            text: 'Cancel',
                            onClick: close
                        }),
                    ]}
                    />
            </div>
        </div>
    )
}