import React from "react";
import { Chart } from "react-google-charts";
import { calculateBPR } from "./ExamsSheet";

const calculateBeltsData = (roster, cohortStartDate) => {

    let totalSubmits = 0;
    let totalFails = 0;
    let totalPasses = 0;
    let toalOrange = 0;
    let totalRed = 0;
    let totalBlack = 0;
    let totalYellow = 0;
    let totalWhite = 0;

    const unsubmittedExamPolicyDate = new Date('2023-07-03');

    roster.forEach(studentData => { 

        if ( studentData.belt_level !== null ){

            if (
                ( new Date(cohortStartDate) >= unsubmittedExamPolicyDate && studentData.belt_level !== -4 )||
                new Date(cohortStartDate) < unsubmittedExamPolicyDate
                ) 
                    totalSubmits++;

            if ( studentData.belt_level === -1 ) totalFails++;
            else if ( studentData.belt_level >= 0 ) {

                totalPasses++;

                switch (studentData.belt_level) {
                    case 0: // yellow
                        totalYellow++;  
                        break;
                    case 1: // red
                        totalRed++;
                        break;
                    case 2: // black
                        totalBlack++;
                        break;
                    case 3: // orange
                        toalOrange++;
                        break;
                    case 4: // white
                        totalWhite++;   
                        break;
                }

            }
        }
    })

    return ({
        totalSubmits,
        totalFails,
        totalPasses,
        toalOrange,
        totalRed,
        totalBlack,
        totalYellow,
        totalWhite
    });
}

const ExamSheetsStats = ({ 
    sortedRoster,
    cohortStartDate,
    close
 }) => { 


    return (
        <div className="row layer layer-1">
            <div 
                className="col-12 text-center mb-2"
            >
                <button
                    className="btn btn-sm btn-info"
                    onClick={close}
                >Close</button>
            </div>
            
            <div className="col-12">
                <ExamsChart
                    roster={sortedRoster}
                    cohortStartDate={cohortStartDate}
                />
            </div>
        </div>
    )
}

export default ExamSheetsStats;

export const ExamsChart = ({
    roster,
    cohortStartDate
}) => { console.log(roster)

    const { totalSubmits, totalFails, totalPasses, toalOrange, totalRed, totalBlack, totalYellow, totalWhite } = calculateBeltsData(roster, cohortStartDate);


    let data = [
        ["Percent", "Count"],
        ["Black", totalBlack],
        ["Red", totalRed],
        ["Orange", toalOrange],
        ["Yellow", totalYellow],
        ["White", totalWhite],
        ["Failed", totalFails],
        ["Unchecked", totalSubmits - (totalPasses + totalFails)]
    ];

    return (
        <>
        {
            totalSubmits === 0 ?
                <div className="mr-2 text-center">
                    No Submitted Exams
                </div>
                :
                <Chart
                chartType="PieChart"
                data={data}
                options={{
                    title: `BPR: ${calculateBPR(roster, cohortStartDate)}%`,
                    colors: ["#000000", "#FF0000", "#FFA500", "#FFFF00", "#FFFFFF", "#808080", "#06d5a5"],
                    textColor: ["black", "red", "orange", "yellow", "white", "gray", "green"],
                    is3D: true,
                }}
                width={"100%"}
                height={"300px"}
            />
        }
            
        </>
    )

}