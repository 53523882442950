import React, {useState, useEffect, useContext} from "react";
import TrivjoQuestionBuilder from "./TrivjoQuestionBuilder";
import trivjoQuestionService from "../../../services/TrivjoQuestionService";
import LoadingSpinner from "../../modal_assets/LoadingSpinner";
import TrivjoQuestion from "./TrivjoQuestion";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";

const TrivjoQuestions = ({ 
    library,
    updateLibrary,
    isInPlayMode,
}) => { 

    const { pushOverlay } = useOverlays();

    const [ questions, setQuestions ] = useState(undefined);

    useEffect(() => { 
        trivjoQuestionService.getByLibrary(library.id)
        .then((questions) => setQuestions(questions))
        .catch((error) => { console.log(error) })
    }, []);

    useEffect(() => { 
        if ( updateLibrary )
            updateLibrary({
                ...library,
                question_count: questions ? questions.length : 0
            })
    }, [questions]);

    const onAddQuestion = (question) => setQuestions([question, ...questions]);

    const onRemoveQuestion = (question) => setQuestions(questions.filter((q) => q.id !== question.id));

    const onUpdateQuestion = (question) => { 
        setQuestions(questions.map((q) => q.id === question.id ? question : q));
    }

    return (
        <div id="trivjo_questions" className="row">
            {
                questions === undefined ?
                    <div className="col-12 text-center">
                        <LoadingSpinner isOverlay={true} />
                    </div>
                    :
                    <div className="row">
                        <div className="col-12 text-center font-bold mt-3">
                            { 
                                questions.length > 0 &&
                                <span>
                                    <b>{ questions.length } Question{ questions.length === 1 ? "" : "s"}</b>
                                </span>
                            }
                        
                        
                            <span className="ml-2">
                                {
                                    !isInPlayMode &&
                                        <button 
                                            className="btn btn-sm btn-primary"
                                            onClick={() => {
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.MODAL,
                                                    component: <TrivjoQuestionBuilder 
                                                        question={{
                                                            id: 0, // test editQuestion.id === 0 to determine if new or existing
                                                            image_url: "", //https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg
                                                            text: "",
                                                            correct_answer_id: null,
                                                            points: 10,
                                                            time: 15,
                                                            question_count: 0
                                                        }}
                                                        addQuestion={onAddQuestion}
                                                        updateQuestion={onUpdateQuestion}
                                                        removeQuestion={onRemoveQuestion}
                                                        library={library}
                                                    />
                                                })
                                            }}
                                        >
                                            {
                                                questions.length === 0 ?
                                                    "Add Your First Question!"
                                                    :
                                                    "Add A Question"
                                            }
                                        </button>

                                }
                            </span>
                        </div> 

                        <div className="col-12 trivjo_questions__list mt-2 justify-content-center">
                            {   
                                questions.length === 0 ?
                                    "No Questions Yet!"
                                    :
                                    <>
                                        {
                                            questions.map((question) => 
                                                { 
                                                    return (
                                                        <TrivjoQuestion
                                                            key={`question_${question.id}`}
                                                            question={question}
                                                            updateQuestion={onUpdateQuestion}
                                                            removeQuestion={onRemoveQuestion}
                                                            library={library}
                                                            isInPlayMode={isInPlayMode}
                                                        />
                                                    )}
                                                )
                                        }
                                    </>
                            }
                        </div>
                    </div>
            }
            
        </div>
    )

}

export default TrivjoQuestions;