import React from 'react';

export const getPercentColor = (
    percent, // send as decimal
    bottomPercent = .5, // any values BELOW this will be red
    highPercent = 1 // any values equal to or above this will be green
    ) => {
    

    const divRange = (highPercent - bottomPercent) / 4;

    return  percent < bottomPercent ? 'ff0000'
        : percent < bottomPercent + (divRange * 2) ? 'FFA500'
        : percent < bottomPercent + (divRange * 3) ? 'ffff00'
        : percent < bottomPercent + (divRange * 4) ? 'f0f012'
        : '06d5a5'
    }

const PercentDisplay = ({
    percent, // send as decimal
    bottomPercent = .5,
    highPercent = 1
}) => { 

    return (
        <div 
            className={`noselect percent_display`}
            style={{
                backgroundColor: `#${getPercentColor(percent, bottomPercent, highPercent)}`,
                opacity: .9,
            }}
            >
            {parseInt(percent * 100)}%
        </div>
    )
}

export default PercentDisplay;