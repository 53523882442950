import React from "react";
import TrivjoLibraries from  "./library/TrivjoLibraries";

const Trivjo = () => {

    return (
        <div className="row">
            <div className="col-12 layer layer-2 p-2 text-center">
                <h1>Trivjo</h1>
            </div>
            <div className="col-12 layer layer-1 p-2">
                <TrivjoLibraries />
            </div>
        </div>
    );
}

export default Trivjo;