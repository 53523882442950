import React, { useState, useEffect, useContext } from 'react';
import { studentService } from '../../../services/StudentService';
import LoadingSpinner from '../../modal_assets/LoadingSpinner';
import SimpleStudentHeader from '../my_cohort_elements/SimpleStudentHeader';
import ActionButtons, { ICONS, BasicActionButton, Label, BUTTON_SIZES } from '../../elements/ActionButtons';
import useUserAccess from '../../../hooks/useUserAccess';
import { AppContext } from '../../../store/AppContext';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import useStudent from '../../../hooks/useStudent';

const RollbacksModal = ({
    studentOrig,
    waitForStudentUpdated = false
}) => { 

    const { saveStudent } = useStudent();

    const { user } = useContext(AppContext);
    const [ USER_LEVELS, checkAccess ] = useUserAccess();
    const { listenForUpdates, popOverlay, pushOverlay } = useOverlays();

    const [ rollbackData, setRollbackData ] = useState();
    const [ updatingStudent, setUpdatingStudent ] = useState(false);
    const [ student, setStudent ] = useState(studentOrig);

    useEffect(() => {

        listenForUpdates('sem_rollbacks_modal', (data) => {
            setStudent(data.student);
        })

        studentService.pullRetakeHistory(student.id)
            .then(response => setRollbackData(processRollbackData(response)))
            .catch(error => console.log(error))
    }, [])

    useEffect(() => { 
        if ( rollbackData !== undefined ) {
            setRollbackData(processRollbackData([...rollbackData.rollbackData]));
            setUpdatingStudent(false);
        }
    }, [student])

    const processRollbackData = (rollbackData) => {

        let foundCurrentStack = false;
        let canVoid = true;
        let rollbacksCount = 0;

        const mergeData = (data, student) => {
            
            data.rollBacks = student.rollbacks_json.rollBacks ? 
                student.rollbacks_json.rollBacks.filter( rollback => 
                    rollback.stack_id === data.stack_id && rollback.program_id === data.program_id 
                    )
                : []

            data.voids = student.rollbacks_json.voids ?
                student.rollbacks_json.voids.filter( rollback => rollback.stack_id === data.stack_id && rollback.program_id === data.program_id)
                : []
            
            data.excludes = student.rollbacks_json.excludes ?
                student.rollbacks_json.excludes.filter( exclude => exclude.stack_id === data.stack_id && exclude.program_id === data.program_id)
                : []

            data.adjusted_retakes = data.retakes - data.rollBacks.length - data.voids.length;
                // - data.rollBacks.filter( rb => rb.stack_id !== student.stack_id || rb.program_id !== student.program_id || rb.cohort_start_date !== student.cohort_start_date_short).length 
                // - data.voids.filter( rb => rb.stack_id !== student.stack_id || rb.program_id !== student.program_id || rb.cohort_start_date !== student.cohort_start_date_short).length;

            data.isCurrent = data.stack_id === student.stack_id && data.program_id === student.program_id
            if ( data.isCurrent ) foundCurrentStack = true;

            data.excluded = data.excludes.length > 0;
            data.canRollback = false;
            //  !data.excluded && 
            //                     ((data.isCurrent && student.attempt_order || 0 - data.rollBacks.length - data.voids.length >= 0) ||
            //                     (!data.isCurrent && data.adjusted_retakes > 0 ))

            data.canVoid = !data.excluded &&
                                (data.isCurrent && student.attempt_order || 0 - data.rollBacks.length - data.voids.length >= 0) ||
                                (!data.isCurrent && data.adjusted_retakes > 0 && data.voids.length < 1 && !data.excluded)
            
            if ( !data.excluded ) rollbacksCount += data.rollBacks.length;

            if ( !data.excluded && data.voids.length ) canVoid = false;

            return data;
        }

        rollbackData.forEach( entry => {
            entry = mergeData(entry, student);
        });

        if ( !foundCurrentStack ) {
            rollbackData.push(mergeData({
                isCurrent: true,
                program_id: student.program_id,
                stack_id: student.stack_id,
                program_name: student.program_name,
                stack_name: student.stack_name,
                retakes: 0,
                rollBacks: [],
                voids: [],
                excludes: [],
                excluded: false,
                adjusted_retakes: 0
            }, student))
        }

        return {
            rollbackData,
            canVoid,
            canRollback: rollbacksCount < 3
        };
    }

    return (
        <div 
        id="sem_rollbacks">
            
            <div
                className='
                    row
                    layer layer-1
                    window_600
                    '
            >
                <div className='col-10 text-center mb-2'>
                    <SimpleStudentHeader student={student} />
                </div>
                <div className='col-2'>
                    <button
                        className="btn btn-sm btn-info"
                        onClick={popOverlay}
                        >Close</button>
                </div>

                {
                    ( rollbackData === undefined ) &&
                        <div> 
                            <LoadingSpinner />
                        </div>
                }

                {
                    rollbackData !== undefined &&
                    <>
                        <div className='col-12 layer layer-2'>
                            <div className='row'>
                                <div className='col-5'>
                                    Program - Stack
                                </div>
                                <div className='col-1 text-center'>
                                    Raw Retakes
                                </div>
<div className='col-1 text-center'>
                                    Adj Retakes
                                </div>
                                <div className='col-5 text-center font-weight-bold'>
                                    <RollbacksDisplay student={student} />
                                </div>
                                <div className='col-12 divider mt-1 mb-1'><div></div></div>
                            </div>
                            {
                                rollbackData.rollbackData.map( entry => 
                                    <div
                                        className={`row layer layer-3 mb-4 p-2 `}
                                        key={`rollbackmodal_${entry.id}`}
                                        >
                                            {
                                                entry.isCurrent &&
                                                    <div className='col-12 text-center font-weight-bold mb-2'>
                                                        <u>Current Stack</u>
                                                    </div>
                                            }
                                            <div className='col-5' style={{opacity: `${entry.excluded ? '.3' : '1'}`}}>
                                                {entry.program_name} - {entry.stack_name}
                                            </div>
                                            <div className='col-1 text-center' style={{opacity: `${entry.excluded ? '.3' : '1'}`}}>
                                                {entry.retakes}
                                            </div>
                                            <div className='col-1 text-center ' style={{opacity: `${entry.excluded ? '.3' : '1'}`}}>
                                                {entry.adjusted_retakes > 0 ? entry.adjusted_retakes : 0}
                                            </div>
                                            <div className='col-5 text-center'>
                                                {
                                                    ( entry.canRollback && rollbackData.canRollback && checkAccess(USER_LEVELS.LEAD) )  &&
                                                    
                                                    <RollbackBTN 
                                                        student={student}
                                                        programName={entry.program_name}
                                                        stackName={entry.stack_name}
                                                        defaultCohortStartDate={
                                                            entry.isCurrent ?
                                                            student.cohort_start_date
                                                            :
                                                            null
                                                        }
                                                        addRollback={(selectedDate) => {
                                                            let rollbackData = structuredClone(student.rollbacks_json);
                                                            if ( rollbackData.rollBacks.find( rollback => rollback.stack_id === entry.stack_id && rollback.program_id === entry.program_id && rollback.cohort_start_date === selectedDate) !== undefined ||
                                                                rollbackData.voids.find( rollback => rollback.stack_id === entry.stack_id && rollback.program_id === entry.program_id && rollback.cohort_start_date === selectedDate) !== undefined) {
                                                                alert('There is already a rollback or void for this cohort.');
                                                                return;
                                                            }
                                    
                                                            rollbackData.rollBacks.push({
                                                                program_id: entry.program_id,
                                                                stack_id: entry.stack_id,
                                                                cohort_start_date: selectedDate,
                                                                user_name: `${user.first_name} ${user.last_name}`,
                                                                date: new Date().toISOString().split('T')[0]
                                                            })
                                                            setUpdatingStudent(true);
                                                            saveStudent({
                                                                ...student,
                                                                rollbacks_json: rollbackData,
                                                                log_note: `Added rollback for ${entry.program_name} ${entry.stack_name} for ${selectedDate}`
                                                            })
                                                        }}
                                                    />

                                                }

                                                {
                                                    ( entry.canVoid && rollbackData.canVoid && checkAccess(USER_LEVELS.LEAD)) &&
                                                            <span className='ml-2'>
                                                                <RollbackBTN 
                                                                    student={student}
                                                                    label="Add Void"
                                                                    programName={entry.program_name}
                                                                    stackName={entry.stack_name}
                                                                    defaultCohortStartDate={
                                                                        entry.isCurrent ?
                                                                        student.cohort_start_date
                                                                        :
                                                                        null
                                                                    }
                                                                    addRollback={(selectedDate) => {
                                                                        let rollbackData = structuredClone(student.rollbacks_json);if ( rollbackData.rollBacks.find( rollback => rollback.stack_id === entry.stack_id && rollback.program_id === entry.program_id && rollback.cohort_start_date === selectedDate) !== undefined ||
                                                                        rollbackData.voids.find( rollback => rollback.stack_id === entry.stack_id && rollback.program_id === entry.program_id && rollback.cohort_start_date === selectedDate) !== undefined) {
                                                                            alert('There is already a rollback or void for this cohort.');
                                                                            return;
                                                                        }
                                                                        rollbackData.voids.push({
                                                                            program_id: entry.program_id,
                                                                            stack_id: entry.stack_id,
                                                                            cohort_start_date: selectedDate,
                                                                            user_name: `${user.first_name} ${user.last_name}`,
                                                                            date: new Date().toISOString().split('T')[0]
                                                                        })
                                                                        saveStudent({
                                                                            ...student,
                                                                            rollbacks_json: rollbackData,
                                                                            log_note: `Added void for ${entry.program_name} ${entry.stack_name} for ${selectedDate}`
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                }

                                                {
                                                    !entry.isCurrent  &&
                                                    <span className='ml-2'>
                                                        <ActionButtons
                                                            size={BUTTON_SIZES.SMALL}
                                                            buttons={[
                                                                BasicActionButton({
                                                                    icon: entry.excluded ? ICONS.ADD : ICONS.CANCEL,
                                                                    toolTip: `${entry.excluded ? 'Include in' : 'Exclude from'} calculations`,
                                                                    onClick: () => {
                                                                        let updatedRollbackData = structuredClone(student.rollbacks_json);
                                                                        if ( !entry.excluded ) {
                                                                            updatedRollbackData.excludes.push({stack_id: entry.stack_id, program_id: entry.program_id, retakes: entry.retakes});
                                                                        } else {
                                                                            updatedRollbackData.excludes = updatedRollbackData.excludes ? 
                                                                                updatedRollbackData.excludes.filter( exclude => exclude.stack_id !== entry.stack_id || exclude.program_id !== entry.program_id)
                                                                                : [];
                                                                        }
                                                                        setUpdatingStudent(true);
                                                                        saveStudent({
                                                                            ...student,
                                                                            rollbacks_json: updatedRollbackData,
                                                                            log_note: `${entry.excluded ? 'Included' : 'Excluded'} ${entry.program_name} ${entry.stack_name}`
                                                                        })
                                                                    },
                                                                    visible: checkAccess(USER_LEVELS.LEAD)
                                                                }),
                                                                Label({
                                                                    text: entry.excluded ? ' Include in Calculations' :  'Exclude',
                                                                    visible: checkAccess(USER_LEVELS.LEAD)
                                                                })
                                                            ]}
                                            
                                                            />
                                                    </span>
                                                }
                                                
                                            </div>
                                            {
                                                entry.voids.length + entry.rollBacks.length > 0 && 
                                                    <>
                                                        <div className='col-1'></div> 
                                                        <div className='col-9'>
                                                            <div 
                                                                className={`
                                                                    row
                                                                `}
                                                                style={{opacity: `${entry.excluded ? '.3' : '1'}`}}
                                                                >
                                                                <div className='col-4'>
                                                                    Rollbacks:
                                                                    {
                                                                        entry.rollBacks.map( rollback =>
                                                                            <div key={`rollback_${rollback.program_id}_${rollback.stack_id}_${rollback.cohort_start_date}`}>
                                                                                <ActionButtons
                                                                                    size={BUTTON_SIZES.SMALL}
                                                                                    buttons={[
                                                                                        BasicActionButton({
                                                                                            icon: ICONS.CANCEL,
                                                                                            onClick: (e) => {
                                                                                                pushOverlay({
                                                                                                    type: OVERLAY_TYPES.CONFIRM,
                                                                                                    position: {x: e.clientX, y: e.clientY,},
                                                                                                    component: <>Are you sure you want to remove this rollback?</>,
                                                                                                    respond: (response) => {
                                                                                                        if ( response ) {
                                                                                                            let rollbackData = structuredClone(student.rollbacks_json);
                                                                                                            rollbackData.rollBacks = rollbackData.rollBacks.filter( 
                                                                                                                rb => rb.stack_id !== rollback.stack_id || 
                                                                                                                rb.program_id !== rollback.program_id ||
                                                                                                                rb.cohort_start_date !== rollback.cohort_start_date
                                                                                                                );
                                                                                                            
                                                                                                            setUpdatingStudent(true);
                                                                                                            saveStudent({
                                                                                                                ...student,
                                                                                                                rollbacks_json: rollbackData,
                                                                                                                log_note: `Removed rollback for ${entry.program_name} ${entry.stack_name}`
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                            },
                                                                                            visible: !entry.excluded && checkAccess(USER_LEVELS.LEAD)
                                                                                        }),
                                                                                        Label({
                                                                                            text: rollback.cohort_start_date + ' by ' + rollback.user_name
                                                                                        })
                                                                                    ]}

                                                                                    />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className='col-4'>
                                                                    Voids:
                                                                    {
                                                                        entry.voids.map( rollback =>
                                                                            <div key={`rollback_${rollback.program_id}_${rollback.stack_id}_${rollback.cohort_start_date}`}>
                                                                                <ActionButtons
                                                                                    size={BUTTON_SIZES.SMALL}
                                                                                    buttons={[
                                                                                        BasicActionButton({
                                                                                            icon: ICONS.CANCEL,
                                                                                            onClick: (e) => 
                                                                                                pushOverlay({
                                                                                                    type: OVERLAY_TYPES.CONFIRM,
                                                                                                    position: {x: e.clientX, y: e.clientY,},
                                                                                                    component: <>Are you sure you want to remove this void?</>,
                                                                                                    respond: (response) => {
                                                                                                        if ( response ) {
                                                                                                            let rollbackData = structuredClone(student.rollbacks_json);
                                                                                                            rollbackData.voids = rollbackData.voids.filter( 
                                                                                                                rb => rb.stack_id !== rollback.stack_id || 
                                                                                                                rb.program_id !== rollback.program_id ||
                                                                                                                rb.cohort_start_date !== rollback.cohort_start_date
                                                                                                                );
                                                                                                            
                                                                                                            setUpdatingStudent(true);
                                                                                                            saveStudent({
                                                                                                                ...student,
                                                                                                                rollbacks_json: rollbackData,
                                                                                                                log_note: `Removed void for ${entry.program_name} ${entry.stack_name} for ${rollback.cohort_start_date}`
                                                                                                            })

                                                                                                            pushOverlay({
                                                                                                                type: OVERLAY_TYPES.ALERT,
                                                                                                                position: {x: e.clientX, y: e.clientY,},
                                                                                                                component: <>Void removed.</>,
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                }),
                                                                                            visible: !entry.excluded && checkAccess(USER_LEVELS.LEAD)
                                                                                        }),
                                                                                        Label({
                                                                                            text: rollback.cohort_start_date + ' by ' + rollback.user_name
                                                                                        })
                                                                                    ]}

                                                                                    />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                    </div>  
                                )
                            }
                            
                        </div>
                    </>
                }
            </div>

            {
                (updatingStudent && waitForStudentUpdated) &&
                    <LoadingSpinner />
            }
        </div>
    )
}

const RollbackBTN = ({
    student,
    programName,
    stackName,
    addRollback,
    defaultCohortStartDate = null,
    label = 'Add Rollback',
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    const [ selectedDate, setSelectedDate ] = useState(
        defaultCohortStartDate
            ? new Date(defaultCohortStartDate).toISOString().split('T')[0]
            : null);

    return (
        <>
            <ActionButtons
                size={BUTTON_SIZES.SMALL}
                buttons={[
                    BasicActionButton({
                        icon: ICONS.ADD,
                        label: label,
                        onClick: (e) => 
                            pushOverlay({
                                position: {x: e.clientX, y: e.clientY,},
                                component: <div className='window_400 text-center'>
                                <SimpleStudentHeader student={student} />
                                <u>{label} for {programName} {stackName} <br/></u>
                                Cohort Start Date: <br/>
                                <input 
                                    type='date' 
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                />
                                <div className='mt-2'>
                                    <button
                                        className='btn btn-sm btn-success'
                                        disabled={selectedDate === null}
                                        onClick={() => {
                                            addRollback(selectedDate);
                                            popOverlay();
                                        }}
                                    >
                                        {label}
                                    </button>
    
                                    <button
                                        className='btn btn-sm btn-info ml-2'
                                        onClick={popOverlay}
                                        >Cancel</button>
                                </div>
                            </div>
                            }),
                    }),
                    Label({
                        text: label
                    })
                ]}

            />
            
        </>
    )
}

export const RollbackJSONModel = {
    excludes: [], // { program_id, stack_id}
    rollBacks: [], // { program_id, stack_id, cohort_start_date, user_name, date }
    voids: [], // { program_id, stack_id, cohort_start_date, user_name, date }
    adjusts: [], // { program_id, stack_id, adjust_count, user_name, date }
}

export const RollbacksDisplay = ({ // changes to this need to be made in tab_students_model.build_retake_text()
    student,
}) => {

    const [ USER_LEVELS, checkAccess ] = useUserAccess();
    const { pushOverlay, updateOverlay } = useOverlays();

    const canActivate = checkAccess(USER_LEVELS.INSTRUCTOR);

    useEffect(() => { 
        updateOverlay('sem_rollbacks_modal', {student});
    }, [student])

    // handle excludes
    let finalRetakes = student.total_retakes;
    let excludesList = [];
    student.rollbacks_json.excludes.forEach( exclude => {
        excludesList.push(`${exclude.program_id}-${exclude.stack_id}`); // for later lookups
        finalRetakes -= exclude.retakes; // if found, subtract from total_retakes
    });

    // extract voids
    let finalVoids = student.rollbacks_json.voids ? 
        student.rollbacks_json.voids.filter( rollback =>  excludesList.indexOf(`${rollback.program_id}-${rollback.stack_id}`) === -1 )
        : []
    let hasVoid = finalVoids.length > 0;

    // subtract rollbacks that are not excluded for the current cohort
    // finalRetakes -= student.rollbacks_json.rollBacks.filter( rollback => 
    //     excludesList.indexOf(`${rollback.program_id}-${rollback.stack_id}`) === -1 
    //     ).length;
    finalRetakes -= finalVoids.filter( v => excludesList.indexOf(`${v.program_id}-${v.stack_id}`) === -1 ).length
    
    return (
        <>
            <span
                onClick={(e) => {
                    if ( canActivate ) 
                        pushOverlay({
                            position: {x: e.clientX, y: 50,},
                            component:<RollbacksModal 
                                key={`rollbacksmodal_${Math.random()}`}
                                studentOrig={student}
                                waitForStudentUpdated={true}
                            />
                        })
                }}
                className={`${canActivate ? "hover_text" : "noselect"}`}
            >
                {finalRetakes < 0 ? 0 : finalRetakes} {hasVoid > 0 ? 'V' : ''}
            </span>
        </>
    )
}

export default RollbacksModal;