import React, { useState, useContext } from 'react';

import TagSelector from './TagSelector';
import { AppContext } from '../../store/AppContext';
import wikiService from '../../services/WikiService';
import { WIKIBROWSERMODES } from './WikiBrowser';

const AddWiki = ( { setBrowserMode } ) => {

    const { wikiData } = useContext(AppContext);

    const [ selectedTags, setSelectedTags ] = useState([]);
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ code, setCode ] = useState("");
    const [ solution, setSolution ] = useState("");
    const [ stack, setStack ] = useState(0); // default to N/A
    const [ submitting, setSubmitting ] = useState(false);

    const cancelWiki = () => {

        if ( 
                (
                    selectedTags.length > 0 || 
                    title !== "" || 
                    description !== "" ||
                    code !== "" ||
                    solution !== "" ||
                    stack !== 0
                ) &&
               ! window.confirm("Are you sure you want to cancel?")
                ) return

        setBrowserMode(WIKIBROWSERMODES.search);
    }

    const submitWiki = () => {

        setSubmitting(true)

        wikiService.add({
            title,
            description,
            code,
            solution,
            stack_id: stack,
            tag_ids: selectedTags
        })
            .then( resp => {
                window.alert("Your article has been added");
                setBrowserMode(WIKIBROWSERMODES.search);
            })
            .catch( err => console.log(err))
            .finally( resp => setSubmitting(false))
    }

    let canSubmit =
        (title !== "" && description !== "" ) &&
            (stack !== 0 || selectedTags.length > 0)

    return (
        <div id='add_wiki' className='row p-2'>
            <div className='col-8'>
                <div>
                    <span className='header-text'>title</span>
                    <span className='required ml-2'>required</span>
                </div>
                <input 
                    className='form-control'
                    type='text'
                    onChange={(e) => setTitle(e.target.value)}
                />
                <span className='subheader-text'>  ( do not copy past an error here. Make a human understandable title )</span>
            </div>
            <div className='col-4'>
                <div className='header-text'>
                    stack
                </div>
                <select
                    className='form-control'
                    onChange={(e) => setStack(e.target.value)}
                >
                    <option value={0}>N/A</option>
                    {
                        wikiData.stacks
                            .sort((a , b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
                            .map( stack =>
                                <option
                                    value={stack.id}
                                    key={`stack_key_${stack.id}`}
                                >
                                    {stack.name}
                                </option>
                            )
                    }
                </select>
            </div>
            <div className='col-12 mt-2'>
                <div>
                    <span className='header-text'>tags</span>
                    <span className='required ml-2'>
                        choose a stack or at least 1 tag
                    </span>
                </div>
                
                {
                    <TagSelector selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                }
                
            </div>
            <div className='col-12'>
                <div>
                    <span className='header-text'>description</span>
                    <span className='required ml-2'>required</span>
                </div>
                <textarea
                    className='form-control'
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>

            </div>
            <div className='col-12'>
                <div className='header-text'>
                    code
                </div>
                <textarea
                    className='form-control'
                    onChange={(e) => setCode(e.target.value)}
                ></textarea>
            </div>
            <div className='col-12'>
                <div>
                    <span className='header-text'>solution</span>
                    <span className='required ml-2'>include for fixes</span>
                </div>
                <textarea
                    className='form-control'
                    onChange={(e) => setSolution(e.target.value)}
                ></textarea>
            </div>
            <div className='col-12 text-center mt-2'>
                <button
                    className='btn btn-sm btn-success'
                    disabled={!canSubmit || submitting}
                    onClick={submitWiki}
                >
                    add article
                </button>
                <button
                    className='btn btn-sm btn-danger ml-2'
                    disabled={submitting}
                    onClick={cancelWiki}
                >
                    cancel
                </button>
            </div>
        </div>
    )
}

export default AddWiki;