import React, { useState, useEffect } from "react";
import TrivjoAddLibrary from "./TrivjoAddLibrary";
import TrivjoViewLibrary from "./TrivjoViewLibrary";
import LoadingSpinner from "../../modal_assets/LoadingSpinner";
import trivjoLibraryService from "../../../services/TrivjoLibraryService";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import useTrivjo from "../../../hooks/useTrivjo";

const TrivjoLibraries = ({
}) => {

    const { pushOverlay, popOverlay } = useOverlays();
    const { startPlay } = useTrivjo();
    
    const [ libraries, setLibraries ] = useState(undefined);
    const [ working, setWorking ] = useState(false);

    useEffect(() => {
        trivjoLibraryService.get_all()
            .then((data) => setLibraries(data));
    }, []);

    const addLibrary = (library) => setLibraries([library, ...libraries]);

    const updateLibrary = (library) => { 
        setLibraries(libraries.map((l) => l.id === library.id ? library : l));
    }

    return (
        <div className="row">
            {
                !libraries ?
                    <div className="col-12 p-2 text-center">
                        Loading...
                    </div>
                    :
                    <>
                        <div className="col-12 p-2">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Owner</th>
                                        <th scope="col">Published</th>
                                        <th scope="col">Questions</th>
                                        <th scope="col">Category</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        libraries.map((library) => {
                                            return (
                                                <tr key={`tj_lib_${library.id}`}>
                                                    <td>{library.name}</td>
                                                    <td>{library.taber_name}</td>
                                                    <td>
                                                        {
                                                            library.published ? 'Yes': 'No'
                                                        }
                                                    </td>
                                                    <td>{library.question_count}</td>
                                                    <td>{library.category_name}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-sm btn-primary ml-2"
                                                            onClick={() => {
                                                                pushOverlay({
                                                                    type: OVERLAY_TYPES.MODAL,
                                                                    component: <TrivjoViewLibrary
                                                                    library={library}
                                                                    updateLibrary={updateLibrary}
                                                                    />
                                                                })
                                                            }}
                                                        >View</button>
                                                        <button
                                                            className="btn btn-sm btn-success ml-2"
                                                            onClick={() => {
                                                                setWorking(true);
                                                                startPlay(library.id)
                                                                    .then((response) => {
                                                                        pushOverlay({
                                                                            type: OVERLAY_TYPES.MODAL,
                                                                            component: <TrivjoViewLibrary
                                                                            library={library}
                                                                            isInPlayMode={true}
                                                                            />
                                                                        })
                                                                    })
                                                                    .catch((error) => console.log(error))
                                                                    .finally(() => setWorking(false));
                                                            }}
                                                        >Play!</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 p-2 text-center">
                            <button 
                                className="btn btn-sm btn-primary"
                                onClick={(e) => 
                                    pushOverlay({
                                        position: { x:e.clientX, y:e.clientY },
                                        component: <TrivjoAddLibrary 
                                            addLibrary={(library) => {
                                                addLibrary(library);
                                                popOverlay();
                                                }}
                                            updateLibrary={updateLibrary}
                                        />
                                    })
                                }
                            >Add Library</button>
                        </div>
                    </>
            }

            {
                working &&
                    <LoadingSpinner isOverlay={true} />
            }
        </div>
    );
}

export default TrivjoLibraries;