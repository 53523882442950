import TABService from './TABService';

class InstructorNotesService extends TABService {

    model = 'instructor_notes';

    get = () => {
        return this._get(this.model)
    }

    update = ( note ) => {
        return this._post(
            `${this.model}/update`,
            note
        )
    }
}

export default new InstructorNotesService();