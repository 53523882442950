import React from "react";

const FoundStudent = ( props ) => {

    const { student} = props;

    return (
        <div className="row">
            <div 
                className="col-12 layer-2_hover"
            >
                {student.prompt}
            </div>
        </div>
    )

}

export default FoundStudent;