import React, { useEffect, useState } from "react";
import Creatable from 'react-select/creatable';
import topicService from "../../services/TopicService";

const TopicsSelector = ( props ) => {

    const { ticketId } = props;

    const [ topics, setTopics ] = useState(null);
    const [ selectedTopics, setSelectedTopics ] = useState(props.selectedTopics); //TODO update to remove 'props' param
    const [ updating, setUpdating ] = useState(false);

    useEffect( () => {

        topicService.get_all()
            .then( topics => {
                setTopics(topics.filter( topic => selectedTopics.filter( sTopic => sTopic.id === topic.id ).length === 0));
            } )
            .catch( err => console.log(err) )
    }, [])

    const addTopic = ( topic ) => {

        setUpdating(true);

        topicService.addTopic(topic, ticketId)
            .then( topic => {
                setSelectedTopics(
                    [
                        topic,
                        ...selectedTopics
                    ]
                )
            })
            .catch( err => console.log(err) )
            .finally( resp => setUpdating(false) )
    }

    const onSelectChange = ( action ) => { 
        setUpdating(true);

        switch ( action.action ) {
            case "select-option":
                topicService.attachTopic( ticketId, action.option.id )
                    .then( topic => {
                        setSelectedTopics(
                            [
                                topic,
                                ... selectedTopics
                            ]
                        )
                        setTopics( topics.filter( tpc => tpc.id !== topic.id ));
                        setUpdating(false);
                    })
                    .catch( err => console.log(err) )
                break;

            case 'remove-value':
                topicService.detachTopic( ticketId, action.removedValue.value )
                        .then( topic => {
                                setSelectedTopics(
                                    selectedTopics.filter( tpc => tpc.id !== topic.id )
                                )
                                setTopics(
                                    [
                                        topic,
                                        ...topics
                                    ]
                                )
                                setUpdating(false);
                            })
                        .catch( err => console.log(err) )
                break;
            
            default:
                setUpdating(false);
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <Creatable 
                    classNamePrefix={"react_select"}
                    options={topics ? topics.map( topic => {return ({id:topic.id, label: topic.name})} ) : []} 
                    isMulti
                    placeholder={'Select Topics...'}
                    isLoading={ topics === null || updating === true }
                    isDisabled={ topics === null || updating === true }
                    isValidNewOption={() => false}
                    isClearable={false}
                    value={selectedTopics ? selectedTopics.map( topic => ({value:topic.id, label: topic.name}) ) : []}
                    // onCreateOption={(e) => addTopic(e)}
                    onChange={(e, action) => onSelectChange(action)}
                    />
            </div>
        </div>
    )
}

export default TopicsSelector;