/* filtering for entire cohort in reviews */
import Filter from './Filter';

export default class ReviewsFilter extends Filter {

    constructor(name, filters, assignmentId = null) { console.log(assignmentId)
        super(name);
        this.filters = filters;
        this.assignmentId = assignmentId;
    }

    apply = ( student, studentData ) => { 

        let addStudent = false;

        if ( this.assignmentId ) { // filtering by assignment

            addStudent = this._checkStudentAssignment( this.assignmentId, studentData );

        } else { // filtering by cohort

            /* go through each assignment. If it is a match, add the student to found students */
            for ( let assignmentId in studentData['assignments'] )
                if ( this._checkStudentAssignment( assignmentId, studentData ) ) return true;

            return false;
        }

        return addStudent;
    }

    _checkStudentAssignment = ( assignmentId, studentData ) => 
        this.filters.indexOf(parseInt(studentData['assignments'][assignmentId]?.status)) > -1
}