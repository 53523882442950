import React, { useState } from "react";
import ImageSelector from "../../elements/image_selector/ImageSelector";
import TrivjoCategorySelector from "./TrivjoCategorySelector";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";
import { config } from "../../../config";
import useTrivjo from "../../../hooks/useTrivjo";

const TrivjoUpdateLibraryInfo = ({
    // library: defaultLibrary,
    library,
    updateLibrary,
    updated,
    isInPlayMode,
    saveLibrary,
    deleteLibrary,
}) => {

    const { popOverlay, pushOverlay } = useOverlays();

    const { trivjo } = useTrivjo();

    return (
        
        <div className="row">
        <div className="col-6">
            <input
                className="form-control"
                placeholder="Library Name"
                disabled={isInPlayMode}
                value={library.name}
                onChange={(e) => updateLibrary({...library, name: e.target.value})}
            />
            <div className="mt-2">
                <TrivjoCategorySelector
                    disabled={isInPlayMode}
                    selectedCategoryId={library.tj_category_id}
                    setSelectedCategory={(category) => 
                        updateLibrary({
                            ...library, 
                            tj_category_id: parseInt(category.id),
                            category_name: category.name
                        })}
                />
            </div>
            <div className={`col-12 text-center mt-5`}>
                {
                    isInPlayMode &&
                        <>
                            <button 
                                className="btn btn-sm btn-danger"
                                onClick={(e) => {
                                    pushOverlay({
                                        type: OVERLAY_TYPES.CONFIRM,
                                        position: { x: e.clientX, y: e.clientY },
                                        component: <>Are you sure you want to end this game?</>,
                                        respond: (response) => {
                                            if (response) {
                                                popOverlay();
                                        }}
                                    })
                                }}
                            >End Game</button>
                            <button
                                className="btn btn-sm btn-success ml-3"
                                onClick={() => window.open(`${config.TRIVJO_URL}/gameboard/${trivjo.gameId}`)}
                            >Open Game Board</button>
                        </>
                }
                {
                    !isInPlayMode &&
                        <>
                            <button 
                                className="btn btn-sm btn-primary"
                                disabled={!updated}
                                onClick={() => saveLibrary()}
                            >Save</button>
                            <button 
                                className="btn btn-sm btn-secondary ml-2"
                                onClick={() => {
                                    if (updated && !window.confirm("Are you sure you want to discard your changes?")) return;
                                    
                                    popOverlay();
                                }}
                            >Finished</button>
                            <button 
                                className="btn btn-sm btn-danger ml-2"
                                onClick={deleteLibrary}
                            >Delete</button>
                        </>
                }
            </div>
        </div>
        <div className="col-6">
            <ImageSelector
                disabled={isInPlayMode}
                currentImage={library.image_url}
                updateImage={(newImage) => 
                    {
                        updateLibrary({...library, image_url: newImage});
                    }}
            />
        </div>
    </div>
        
        
    )
}

export default TrivjoUpdateLibraryInfo;