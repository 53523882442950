
export const SORT_TYPES = {
    ASC: 'asc',
    DESC: 'desc',
    FIRST_NAME_ASC: 'fa',
    FIRST_NAME_DESC: 'fd',
    LAST_NAME_ASC: 'la',
    LAST_NAME_DESC: 'ld'
}

/* extend this class to create a new sorter */
export default class Sorter {

    constructor( name ) {
        this.name = name;
    }

    sort = ( roster, studentsData = null ) => () => {} // override this method in the child class; return sorted roster}
}