/* sort sutdentsData object by column value */
import Sorter, {SORT_TYPES} from "./Sorter";

export default class ValueSorter extends Sorter {

    constructor(name, column, sortType = SORT_TYPES.ASC) { 
        super(name);
        this.column = column;
        this.sortType = sortType;
    }

    sort = ( roster, studentsData ) => {

        return (
        [...roster].sort((student_a, student_b) => {
            let a = studentsData[student_a.id];
            let b = studentsData[student_b.id];

            if ( a[this.column] === null || a[this.column] === undefined ) return 1
            if ( b[this.column] === null || b[this.column] === undefined ) return -1
            
            return this.sortType === SORT_TYPES.ASC ?
                a[this.column] > b[this.column] ? 1 : a[this.column] < b[this.column] ? -1 : 0
                :
                a[this.column] < b[this.column] ? 1 : a[this.column] > b[this.column] ? -1 : 0
        }));}
}
