import TABService from "./TABService";

class ProbationService extends TABService {
    model = "academic_probation";

    get_by_student = (student_id) => {
        
            return this._get(`${this.model}/${student_id}`);
    };

    add_probation = (probation_data) => {
        return this._post(`${this.model}`, probation_data);
    } 

    update_probation = (probation_data) => {
        return this._post(`${this.model}/update`, probation_data);
    }
}

export default new ProbationService();
