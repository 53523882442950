import { EXAM_STATUS } from "./Constants";

class Helpers {

    today = new Date();

    todayIsBetweenTwoDates = ( startDate, endDate ) => this.today >= new Date(startDate) && this.today <= new Date(endDate);

    formatDate = ( date ) => {
        return new Date(date).toDateString().substring(4);
    }

    millToTime = ( milliseconds ) => {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        const secondVal = seconds % 60;
        const minuteVal = minutes % 60;
        const hourVal = hours % 24;
        let string = '';

        if (days >= 1)
            string += `${days} day${days!==1?'s':''}`;
        if ((days >= 1 && hourVal >= 1) || (days >= 1 && minuteVal >= 1))
            string += ' | ';
        if (hourVal >= 1)
            string += `${this.padTo2Digits(hourVal)} hour${hourVal!==1?'s':''}`;
        if (hourVal >= 1 && minuteVal >= 1)
            string += ' | ';
        if (minuteVal >= 1)
            string += `${this.padTo2Digits(minuteVal)} minute${minuteVal!==1?'s':''}`;

        return {
            string,
            shortString: `${this.padTo2Digits(hourVal)}:${this.padTo2Digits(minuteVal)}:${this.padTo2Digits(secondVal)}`,
            seconds,
            minutes,
            hours,
            days
        };
    }

    minutesBetween = ( startDate, endDate) => Math.floor(Math.abs(endDate - startDate ) / 1000 / 60 )

    padTo2Digits = (num) => num.toString().padStart(2, '0');

    secToTime = ( seconds ) => this.millToTime( seconds * 1000 )

    timeSince = ( date ) => this.millToTime( this.today - new Date(date));

    dateToUTC = ( date ) => new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
    date.getUTCMinutes(), date.getUTCSeconds());

    generateUUID = () => { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    /* Local Storage Array Helpers */
    localStorageObjAdd = (objName, addKey, addVal) => {
        if (localStorage.getItem(objName) === null) return false;

        let obj = JSON.parse(localStorage.getItem(objName));
        obj = {...obj, [addKey]:addVal};
        localStorage.setItem(objName,JSON.stringify(obj));
    }

    localStorageObjRemove = (objName, remKey) => {
        if (localStorage.getItem(objName) === null) return false;

        const obj = JSON.parse(localStorage.getItem(objName));
        delete obj[remKey]
        localStorage.setItem(objName,JSON.stringify(obj));
    }

    localStorageObjFind = (objName, findKey) => {
        if (localStorage.getItem(objName) === null) return false;
        
        const obj = JSON.parse(localStorage.getItem(objName));
        // Object.hasOwn()
        return obj.hasOwnProperty(findKey)? obj[findKey]:false;
    }

    keyboardShortcut = ({e,callback,keyCode,useShift=false,useCtrl=false}) => {
        const isShift = useShift && e.shiftKey;
        const isCtrl = useCtrl && e.ctrlKey;

        if((isShift || isCtrl) && e.code === keyCode) { //if shift/ctrl key (if wanted) is pressed and they press whatever keycode passed in
            e.preventDefault(); //prevent normal keypress stuff from happening (eg. enter key entering text down)
            e.stopPropagation(); //prevent event from bubbling up and triggering similar event on higher level
            callback();
        }
    }

    /* EXAM STATUS */
    getExamStatusAndCurrentTime = ( exam ) => {
        const currentTime = this.secToTime(exam.time_since_submitted === null ? exam.time_since_open : exam.time_since_submitted);

        //define submissionTime as null unless it can be calculated
        let submissionTime = null;
        
        /* pull completion time from query data, if it exists and calculate deployment window boolean */
        if (exam.completion_time !== null ) submissionTime = this.secToTime(exam.completion_time);

        /*kept a 15 minute buffer on the minutes for deployment window to make plenty room for instructor exceptions,
        but not too much as to cause too many false-alarms*/
        return ({
            currentTime,
            submissionTime
        })
    }

    getExamsAlerts = (examData) => {
        let isExamOpen = false;
        let isInDeploymentWindow = false;

        if (examData !== undefined) {
            examData.forEach( exam => {
                if (exam.exam_status === EXAM_STATUS.EXAM_STATUS_OPEN) {
                    isExamOpen = true
                } else if (exam.exam_status === EXAM_STATUS.EXAM_STATUS_DEPLOY) {
                    isInDeploymentWindow = true
                }
            })
        }
        return {
            isExamOpen,
            isInDeploymentWindow
        }
    }

    /* Privacy Mode */
    getPrivacyMode = () => {
        return localStorage.getItem('is_in_privacy_mode') === 'true';
    };
    privatizeName = (fullName) => {
        const nameArr = fullName.split(' ');
        return `${nameArr[0]} ${nameArr[1][0]}.`;
    }

    getProgress = (current, total) => {

        let result = parseInt(current)/parseInt(total)
        return Math.floor(result * 100)
    }

    /* formatting for student data */
    studentDataToGSheetsData = (students) => {
        let data = "";
        const delimiter = "\t";
        const fields = [
            'first_name', 
            'last_name', 
            'email_address', 
            'attendance_to_date',
            'attendance_to_date_percent',
            'core_assignments_to_date',
            'core_assignments_to_date_percent',

        ];
        for ( let field of fields ) {
            data += field + delimiter;
        }
        data += '\n';
        students.forEach(student => {
            console.log(student)
            for ( let field of fields ) {
                data += student[field] + delimiter;
            }
            data += '\n';
        });
        return data;
    }
}

const helpers = new Helpers();
export default helpers;
