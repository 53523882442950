import React, { useState, useContext, useEffect } from 'react';
import MyCohortStatusSetter from '../my_cohort/status_setter/MyCohortStatusSetter';
import { ProbationsDisplay } from '../my_cohort/sem_sheet/ProbationsModal';
import SimpleCheckColumn from '../my_cohort/sem_sheet/SimpleCheckColumn';
import NotesModal from '../my_cohort/sem_sheet/NotesModal';
import ActionButtons, { ICONS, BasicActionButton } from '../elements/ActionButtons';
import { RollbacksDisplay } from '../my_cohort/sem_sheet/RollbacksModal';
import { StackProgressDisplay } from '../my_cohort/sem_sheet/StackProgressGenerator';
import { AppContext } from '../../store/AppContext';
import useStudent from '../../hooks/useStudent';
import useOverlays from '../../hooks/useOverlays';

const PullStudentSEMSettings = ({
    student: studentOrig,
    studentStatuses,
}) => {

    const { stackObj } = useContext(AppContext);
    const { saveStudent } = useStudent();
    const { pushOverlay, popOverlay, listenForUpdates } = useOverlays();

    const [ student, setStudent ] = useState(studentOrig);

    useEffect(() => {
        listenForUpdates('student_sem_settings', (data) => { console.log(data)
            setStudent(data.student);
        })
    }, [])

    return (
        <div id='pull_student_sem_settings' className='row layer layer-1'>
            <div className="col-12">
                <div className="row layer layer-2 p-2">
                    <div className="col-4 mt-2">
                        Status:
                    </div>
                    <div className="col-8 d-flex ">
                        <MyCohortStatusSetter
                            studentStatuses={studentStatuses}
                            currentStatus={student.status_slug}
                            currentStatusId={student.status_id}
                            updateStatus={ status => saveStudent({ 
                                ...student,
                                status_id: status.id,
                                status_slug: status.slug
                            }) }
                            />
                    </div>

                    <div className="col-4 mt-2">
                        Retake:
                    </div>
                    <div className="col-8 d-flex">
                        {student.attempt_order > 0 ? student.attempt_order : <>&nbsp;</>}
                    </div>

                    <div className="col-4 mt-2">
                        Notes:
                    </div>
                    <div className="col-8 d-flex">
                        <ActionButtons
                            key={`notes_${student.id}`}
                            buttons={[
                                BasicActionButton({
                                    icon: student.notes ? ICONS.EDIT : ICONS.ADD,
                                    onClick: (e) => 
                                        pushOverlay({
                                            position: { x: e.clientX, y: e.clientY },
                                            component: <NotesModal
                                                student={student}
                                                updateStudent={saveStudent}
                                                close={() => popOverlay()}
                                            />
                                        }),
                                    isSelected: student.notes !== null
                                })
                            ]}
                        />
                    </div>

                    <div className="col-4 mt-2">
                        Probations:
                    </div>
                    <div className="col-8 d-flex">
                        <ProbationsDisplay
                            student={student}
                            />
                    </div>

                    <div className="col-4 mt-2">
                        Rollbacks:
                    </div>
                    <div className="col-8  d-flex">
                        <RollbacksDisplay
                            student={student}
                            />
                    </div>

                    <div className="col-4 mt-2">
                        Stack Progression:
                    </div>
                    <div 
                        className="col-8 d-flex"
                        // onClick={() => 
                        //     pushOverlay({
                        //         component: <StackProgressGenerator
                        //             close={() => popOverlay()}
                        //             currentProgress = {JSON.parse(student["stack_progress"])}
                        //             updateProgress={(e, stacks) => {
                        //                 console.log(stacks);
                        //                 saveStudent({...student, stack_progress: JSON.stringify(stacks)})
                        //                 popOverlay()
                        //             }}
                        //         />
                        //     })
                        // }
                        >
                        <StackProgressDisplay 
                            student={student}
                            stackObj={stackObj}
                            />
                    </div>

                    <div className="col-4 mt-2">
                        Accommodations:
                    </div>
                    <div className="col-8 d-flex">
                        <SimpleCheckColumn
                            student={student}
                            column="has_accommodation"
                            disabled={true}
                        />
                    </div>

                    <div className="col-4 mt-2">
                        B2B:
                    </div>
                    <div className="ccol-8 d-flex ">
                        <SimpleCheckColumn
                            student={student}
                            column="b2b"
                            updateStudent={saveStudent}
                        />
                    </div>

                </div>
            </div>
            
            <div className='col-12 text-center mt-3'>
                <button className='btn btn-info' onClick={popOverlay}>Close</button>
            </div>
        </div>
    );
}

export default PullStudentSEMSettings;