/* basic filter for emails */
import Filter from './Filter.js';

export default class EmailsFilter extends Filter {

    constructor( name, emails = [] ) { console.log(emails);
        super(name);
        this.emails = emails;
    }

    apply = ( student, studentData ) => this.emails.includes(student.email_address);

}