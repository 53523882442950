// extracts values from column for user to filter by

import React, { useState, useEffect } from "react";
import ActionButtons, { Label } from "../../elements/ActionButtons";

const TextSortSelector = ({
    texts:defaultTexts, // {id, text}
    selectedTextIds, // [id, id]
    setSelectedTexts, // [id, id]
    close,
}) => { console.log(defaultTexts)

    const [selectedIds, setSelectedIds] = useState([]);
    const [ texts, setTexts ] = useState([]);

    useEffect(() => {

        // remove duplicates
        let usedIds = {};
        setTexts(
            defaultTexts.filter((text) => {
                if (!usedIds[text.id]) {
                    usedIds[text.id] = true;
                    return true;
                }
                return false;
            })
        )

        setSelectedIds(selectedTextIds);
    }, [selectedTextIds])

    return (
        <div id="text_sort_selector" className=" layer layer-1 row ">
            <div className="col-12 text-center sub_note mb-2">
                Click one or more of the values below.
            </div>
            {texts.map((value) => (
                <div
                    className={`
                        col-12
                        mb-1
                        text_sort_selector__text
                        text_sort_selector__text--${
                            selectedIds.indexOf(value.id) === -1
                                ? "unselected"
                                : "selected"
                        }`}
                    key={`value_sort_selector__text-value_${value}`}
                    onClick={() => { 
                        if (selectedIds.indexOf(value.id) > -1) {console.log("includes");
                        setSelectedIds(
                                selectedIds.filter(
                                    (val) => val !== value.id
                                )
                            );
                        } else {
                            setSelectedIds([
                                ...selectedIds,
                                value.id,
                            ]);
                        }
                    }}
                >
                    {value.text}
                </div>
            ))}
            <div className="col-12 text-center mt-5">
                <ActionButtons
                    buttons={[
                        Label({
                            text: "Set Filter",
                            onClick: () => {
                                setSelectedTexts(selectedIds);
                                close();
                            },
                            disabled: selectedIds.length === 0,
                        }),
                        Label({
                            text: "Clear Filter",
                            onClick: () => {
                                setSelectedTexts([]);
                            },
                            disabled: selectedIds.length === 0,
                        }),
                        Label({
                            text: "Done",
                            onClick: () => {
                                close();
                            },
                        })
                    ]}
                    />
            </div>
        </div>
    );
};

export default TextSortSelector;
