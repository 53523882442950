import React, { useEffect, useState } from 'react';
import eventHandler, { CLOSE_OVERLAY, OVERLAY_CLOSE_CLICK } from '../../events';
import { ASSIGNMENT_REVIEW_STATUS } from '../../helpers/Constants';
import { ASSIGNMENT_TYPES, ReviewTabsLegend } from '../my_cohort/assignments_sheet/AssignmentAttendanceSheet';
import ReviewTab from '../my_cohort/assignments_sheet/ReviewTab';

const SubmittedAssignments = ( { 
    close,
    student
} ) => { 

    useEffect( () => {

        eventHandler.on( OVERLAY_CLOSE_CLICK, onCloseClicked)

        return () => eventHandler.remove( OVERLAY_CLOSE_CLICK, onCloseClicked )
    }, [])

    const onCloseClicked = () => eventHandler.dispatch( CLOSE_OVERLAY );

    let chapters = {};

    const chaptersByType = {
        'core': [],
        'practice': [],
        'optional': []
    }

    for (const [key, assignment] of Object.entries(student.assignments)) { 

        if ( ! ( assignment.assignment.chapter_id  in chapters) ) {
            const chapterEntry = {
                title: assignment.assignment.chapter_title || assignment.assignment.title,
                id: assignment.assignment.chapter_id,
                assignments: []
            }

            chapters[assignment.assignment.chapter_id] = chapterEntry;
            chaptersByType[assignment.type].push(chapterEntry);
        }
        chapters[assignment.assignment.chapter_id].assignments.push(assignment);
    }

    return (
        <>
            <div id='overlay_wrapper__inner' className="row layer layer-2 justify-content-center"  >
                <ReviewTabsLegend />
                <div className=' col-12 text-center mt-3'>Click on Icon Next to Assignment to View Feedback</div>
                <div className='col-12 divider'><div></div></div>
                <div className="col-9">
                    <div className='row'>
                    {
                        Object.entries(chapters).map( (value) => { 
                            let chapter = value[1]; 
                            return(
                                <div className='col-6' key={`sub_chapter_${chapter.id}`}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            {chapter.title}
                                        </div>
                                        <div className='col-12 ml-3'>
                                            {
                                                chapter.assignments.map( assignment => 
                                                    <div className='row mt-2' key={`sub_chapter_assignment_${assignment.id}`}>
                                                        <div 
                                                            className={`col-2 
                                                                        assignment_reviews__column 
                                                                        `}
                                                        >

                                                                <ReviewTab
                                                                    assignment={assignment}
                                                                    assignmentType={assignment.type}
                                                                    displayDaysOffset={false}
                                                                    reviewStatus={assignment.status}
                                                                    student={student}
                                                                    />
                                                            
                                                        </div>
                                                        <div className='col-1'>
                                                        </div>
                                                        <div className='col-8'>
                                                                {assignment.assignment.title}
                                                        </div>
                                                    </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
                <div className='col-12 text-center mt-2'>
                    <button
                        className='btn btn-primary'
                        onClick={close}
                    >Close</button>

                </div>
            </div>
        </>
    )


}

export default SubmittedAssignments;