import React, { useState, useEffect } from "react";
import { trackService } from "../../services/TrackService";
import { rubricAssignmentService } from "../../services/RBKRubricAssignmentService";
import Select from 'react-select';
import ActionButtons, { ICONS, BasicActionButton, BUTTON_SIZES } from "../elements/ActionButtons";
import useOverlays, { OVERLAY_TYPES } from "../../hooks/useOverlays";

export const TrackAssignmentSelector = ({ 
    onUpdate,
    save = null,
}) => {

    const { pushOverlay } = useOverlays();
    
    const [ tracks, setTracks ] = useState(null);
    const [ chapters, setChapters ] = useState(null);
    const [ assignments, setAssignments ] = useState(null);

    let [ selectedTrack, setSelectedTrack ] = useState(null);
    let [ selectedChapter, setSelectedChapter ] = useState(null);
    let [ selectedAssignment, setSelectedAssignment ] = useState(null);

    const [ assignmentUrl, setAssignmentUrl ] = useState("");

    useEffect(() => { 
        trackService.getAllTracks()
            .then(res => {
                setTracks(res);
                if ( selectedTrack !== null && selectedChapter !== null && ! chapters )
                    loadChapters();
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    useEffect(() => { 
        loadChapters();
    }, [selectedTrack]);

    useEffect(() => {
        if ( selectedChapter === null ) return;

        setAssignments(null);
        setSelectedAssignment(null);
        console.log(selectedChapter )
        setAssignments(chapters.find( chapter => chapter.id == selectedChapter.id).assignments);
    }, [selectedChapter]);

    useEffect(() => {
        onUpdate({
            track_id: selectedTrack?.id,
            track_title: selectedTrack?.label,
            chapter_id: selectedChapter?.id,
            chapter_title: selectedChapter?.label,
            assignment_id: selectedAssignment?.id,
            assignment_title: selectedAssignment?.label
        });
    }, [selectedTrack, selectedChapter, selectedAssignment]);

    const loadChapters = () => {
        
        if ( selectedTrack === null ) return;

        setChapters(null);
        setAssignments(null);
        setSelectedAssignment(null);
        setSelectedChapter(null);
        trackService.getTrackModules(selectedTrack.id)
            .then(res => {
                setChapters(res);

                if ( assignmentUrl ) { // if assignmentUrl is set, then we need to parse it and set the selectedTrack, selectedChapter, and selectedAssignment
                    const parts = assignmentUrl.split('/');
                    setAssignmentUrl("");
                    const chapter = res.find( chapter => chapter.id == parts[5] );
                    if ( chapter ) {
                        setSelectedChapter({
                            id: chapter.id,
                            label: chapter.title
                        
                        });

                        setAssignments(chapter.assignments);
                    }
                    
                    const assignment = chapter.assignments.find( assignment => assignment.id == parts[6] );
                    if ( assignment )
                        setSelectedAssignment({
                            id: assignment.id,
                            label: assignment.title
                        });
                }
            })
            .catch(err => {
                console.log(err);
            })

    }

    const parseAssignmentUrl = (e) => {
        let parts = assignmentUrl.split('/');
        
        if ( parts.length < 5 ) {
            pushOverlay({
                type: OVERLAY_TYPES.ALERT,
                position: { x: e.clientX, y: e.clientY},
                component: <>Invalid URL</>,
            })
            return;
        }

        const track = tracks.find( track => track.id == parts[4] );
        setSelectedTrack( {
            id: track.id,
            label: track.title
        });
    }

    if ( ! tracks ) return null;

    const ddStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            minHeight: "0",
            textColor: "black", 
            fontSize: "0.8rem",
            height: "3rem",
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? "#e6e6e6" : "white",
            color: "black"
        }),
        }

    return (
        <div className="row layer layer-3">

            <div className="col-8 mt-2">
                <div className="sub_note text-center mb-2">Paste URL to assignment.
                    <span 
                        onClick={(e) =>
                            pushOverlay({
                                type: OVERLAY_TYPES.ALERT,
                                position: { x: e.clientX, y: e.clientY},
                                component: <>Copy the URL to the assignment on the platform and paste it here. Then click the Use URL button.</>,
                            
                            })
                        }
                        className="ml-1 infolink" ></span></div>
                <input
                    type="text"
                    className="form-control"
                    placeholder="https://..."
                    value={ assignmentUrl }
                    onChange={ (e) => {
                        setAssignmentUrl(e.target.value);
                    }}
                    />
            </div>
            <div className="col-4 mt-4">
                <button 
                    className="btn btn-sm btn-info mt-2"
                    onClick={parseAssignmentUrl}
                    disabled={ assignmentUrl === null || assignmentUrl === undefined || assignmentUrl === "" }
                >Use URL</button>

                { save ?
                    <button
                        className="btn btn-sm btn-success mt-2 ml-2"
                        onClick={() => {
                            setSelectedAssignment(null);
                            setSelectedChapter(null);
                            setSelectedTrack(null);
                            save();
                        }}
                        disabled={!selectedTrack && !selectedChapter && !selectedAssignment}
                    >Save</button>
                    : null
                }
            </div>

            <div className="col-12 mt-2">
                <div className="sub_note text-center">Or select from below. 
                    <span 
                        onClick={(e) => 
                            pushOverlay({
                                type: OVERLAY_TYPES.ALERT,
                                position: { x: e.clientX, y: e.clientY},
                                component: <>You can select just the track, or the track and chapter, or the track, chapter and assignment.</>,
                            
                            
                            })
                        }
                        className="ml-1 infolink" ></span>
                </div>
            </div>
            <div className="col-4">
                <div className="text-right">
                    <ActionButtons
                        buttons={[
                            BasicActionButton({
                                icon: ICONS.CANCEL,
                                onClick: () => {
                                    setSelectedTrack(null);
                                    setSelectedChapter(null);
                                    setSelectedAssignment(null);
                                },
                                disabled: selectedTrack === null
                            }),
                        ]}
                        size={BUTTON_SIZES.SMALL}   
                        />
                </div>
                <Select
                    options={ 
                        tracks.map ( track => ({
                            id: track.id,
                            label: track.title
                        }))
                    }
                    value={ selectedTrack}
                    onChange={ (e) => setSelectedTrack(e) }
                    styles={ddStyle}
                    placeholder="Select Track"
                    />

            </div>
            <div className="col-4">
                <div className="text-right">
                    <ActionButtons
                        buttons={[
                            BasicActionButton({
                                icon: ICONS.CANCEL,
                                onClick: () => {
                                    setSelectedChapter(null);
                                    setSelectedAssignment(null);
                                },
                                disabled: selectedChapter === null
                            }),
                        ]}
                        size={BUTTON_SIZES.SMALL} 
                        />
                </div>
                <Select
                    options={ 
                        chapters ?
                        chapters.map ( chapter => ({
                            id: chapter.id,
                            label: chapter.title
                        }))
                        : null
                    }
                    value={ selectedChapter }
                    onChange={e => setSelectedChapter(e)}
                    styles={ddStyle}
                    placeholder="Select Chapter"
                    isDisabled={ chapters === null || selectedTrack === null }
                    />

            </div>
            <div className="col-4">
                <div className="text-right">
                    <ActionButtons
                        buttons={[
                            BasicActionButton({
                                icon: ICONS.CANCEL,
                                onClick: () => {
                                    setSelectedAssignment(null);
                                },
                                disabled: selectedAssignment === null
                            }),
                        ]}
                        size={BUTTON_SIZES.SMALL} 
                        />
                    </div>

                <Select
                    options={ 
                        assignments ?
                        assignments.map ( assignment => ({
                            id: assignment.id,
                            label: assignment.title
                        }))
                        : null
                    }
                    value={ selectedAssignment }
                    onChange = { (e) => setSelectedAssignment(e) }
                    isDisabled ={ assignments === null || selectedChapter === null }
                    placeholder="Select Assignment"
                    styles={ddStyle}
                    />
            </div>
        </div>
    )
};

export const TrackAssignmentsList = ({
    rubric_id
}) => {

    const { pushOverlay } = useOverlays();

    let [ newAssignment, setNewAssignment ] = useState(null);

    let [ assignments, setAssignments ] = useState([]);

    useEffect(() => {
        rubricAssignmentService.getByRubricId(rubric_id)
            .then(res => {
                setAssignments(res);
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const saveAssignment = () => {

        setAssignments([
            ...assignments,
            newAssignment
        ]);

        rubricAssignmentService.create({
            ...newAssignment,
            rubric_id
        })
        .finally(() => setNewAssignment(null))
    }

    const deleteAssignment = (assignmentId) => { console.log(assignmentId)
        setAssignments(assignments.filter( a => a.id !== assignmentId ));
        rubricAssignmentService.delete(assignmentId);
    }

    return (
        <div className="row layer layer-3">
            <div className="col-12">
                <TrackAssignmentSelector 
                    
                    onUpdate={(data) => setNewAssignment(data)}
                    save = { saveAssignment }
                    cancel = { () => {} }
                />
            </div>
            <div className="col-12">

                { 
                    assignments.length === 0 ?
                    <div className="text-center mt-2">No assignments selected</div>
                    :
                    <table className="table table-sm table-striped table-hover mt-2">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Track</th>
                                <th>Chapter</th>
                                <th>Assignment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                assignments.map( assignment => {
                                    return (
                                        <tr>
                                            <td>
                                                <ActionButtons
                                                    buttons={[
                                                        BasicActionButton({
                                                            icon: ICONS.CANCEL,
                                                            onClick: (e) => 
                                                                pushOverlay({
                                                                    type: OVERLAY_TYPES.CONFIRM,
                                                                    position: { x: e.clientX, y: e.clientY},
                                                                    component: <>Are you sure you want to remove this assignment?</>,
                                                                    respond: (response) => {
                                                                        if ( response ) {
                                                                            deleteAssignment(assignment.id);
                                                                            pushOverlay({
                                                                                type: OVERLAY_TYPES.ALERT,
                                                                                position: { x: e.clientX, y: e.clientY},
                                                                                component: <>Assignment removed</>,
                                                                            })
                                                                        }
                                                                    }
                                                                }),
                                                            visible: assignments.length > 1,
                                                            disabled: false,
                                                            toolTip: "Remove assignment"
                                                        }),
                                                        BasicActionButton({
                                                            icon: ICONS.OPEN_TAB,
                                                            onClick: () => {
                                                                console.log(assignment.chapter_id)
                                                                window.open(`https://login.codingdojo.com/m/${assignment.track_id}/${assignment.chapter_id || 0}/${assignment.assignment_id || 0}`, '_blank').focus();
                                                            },
                                                            toolTip: "Open assignment in new tab",
                                                            visible: assignment.assignment_id !== null && assignment.assignment_id !== undefined
                                                        })
                                                    ]}
                                                    size={BUTTON_SIZES.SMALL} 
                                                    />
                                            </td>
                                            <td>{ assignment.track_title }</td>
                                            <td>{ assignment.chapter_title }</td>
                                            <td>{ assignment.assignment_title }</td>
                                        </tr>
                                    )})
                            }
                        </tbody>
                    </table>
                    }
            </div>
        </div>
    )
}