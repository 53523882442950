import React, { useEffect, useState, useeffect } from 'react';
import ValuesFilter from '../filter_sorter/filters/ValuesFilter';
import FilterSorterBtn from '../filter_sorter/FilterSorterBtn';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';

const StatusesFilter = ({ 
    statuses, 
    usedStatuses,
    close
}) => { 

    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [ filter, setFilter ] = useState(null);

    useEffect( () => {

        let filter = filterSorter.getFilter('statuses_filter');
        if ( filter ) {
            setFilter(filter);
            setSelectedStatuses(filter.values);
        } else {
            setFilter(null);
        }

    }, [])

    const onToggleStatus = (statusId) => {
        if (selectedStatuses.includes(statusId)) {
            setSelectedStatuses(selectedStatuses.filter( (id) => id !== statusId));
        } else {
            setSelectedStatuses([...selectedStatuses, statusId]);
        }
    }

    const onToggleStatusesGroup = (groupToggleOn, statuses) => {

        let newSelectedStatuses = [...selectedStatuses];

        for ( let status of statuses ) {
            if (groupToggleOn && !newSelectedStatuses.includes(status.id)) {
                newSelectedStatuses.push(status.id);
            } else if (!groupToggleOn && selectedStatuses.includes(status.id)){
                newSelectedStatuses = newSelectedStatuses.filter( (id) => id !== status.id);
            }
        }

        setSelectedStatuses(newSelectedStatuses);
    }

    
    let activeStatuses = [];
    let inactiveStatuses = [];
    statuses.forEach( (status) => {
        if ( usedStatuses.indexOf(status.id) > -1)
            status.student_is_active  ? activeStatuses.push(status) : inactiveStatuses.push(status)
    })

    return (
        <div
            id="my_cohort__statuses_filter"
            className="row">
                <div className="col-12 mb-3">
                    <StatusGroup 
                        title="Active"
                        statuses={activeStatuses} 
                        selectedStatuses={selectedStatuses} 
                        toggleStatus={onToggleStatus}
                        toggleStatusesGroup={onToggleStatusesGroup}
                    />
                </div>
                <div className="col-12 mb-3">
                    <StatusGroup 
                        title="Inactive"
                        statuses={inactiveStatuses} 
                        selectedStatuses={selectedStatuses} 
                        toggleStatus={onToggleStatus}
                        toggleStatusesGroup={onToggleStatusesGroup}
                    />
                </div>
                <div className="col-12 text-center mt-2">

                    <button
                        className="btn btn-sm btn-success"
                        disabled={
                            !filter && selectedStatuses.length === 0 ||
                            (filter && JSON.stringify(filter.values) === JSON.stringify(selectedStatuses))
                        }
                        onClick={() => {
                            let statusesFilter = new ValuesFilter('statuses_filter', selectedStatuses, 'status_id');
                            filterSorter.addFilter(statusesFilter);
                            close();
                        }}
                    >Apply Updates</button>

                    <button
                        className="btn btn-sm btn-primary ml-2"
                        onClick={close}
                    >Close</button>

                    {
                        filter &&
                        <button
                            className="btn btn-sm btn-warning ml-2"
                            onClick={() => {
                                filterSorter.removeFilter('statuses_filter');
                                setSelectedStatuses([]);
                                setFilter(null);
                            }}
                        >Clear Filter</button>
                    }
                </div>
        </div>
    )
}

export default StatusesFilter;

const StatusGroup = ({
    title,
    statuses,
    selectedStatuses,
    toggleStatus,
    toggleStatusesGroup
}) => {

    const [ groupToggleOn, setGroupToggleOn ] = useState(false);

    useEffect( () => {
        toggleStatusesGroup(groupToggleOn, statuses);
    }, [groupToggleOn])

    return (
        <div className="row">
            <div className="col-12 mb-2">
                {title} Student Statuses
                <span className="ml-3">
                    <FilterSorterBtn
                        toggle={() => setGroupToggleOn(!groupToggleOn)}
                        toggleTooltip='Toggle Status Group'
                    />
                </span>
            </div>

        {
            statuses
            .sort((a, b) => {
                return a.slug > b.slug ? 1 : -1
            })
            .map( (status) => {
                return (
                    <div 
                        className="col-6 mb-1"
                    >
                        <div
                            className={`
                                noselect
                                statuses_filter__status
                                sem_data__status_${status.slug}
                                statuses_filter__status--${selectedStatuses.includes(status.id) ? 'selected' : 'unselected'}
                            `}
                            onClick={() => toggleStatus(status.id)} 
                        >
                            {status.title}
                        </div>
                    </div>
                )
            })
        }
        </div>
    )
}