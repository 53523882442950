import TABService from './TABService';

class TrivjoQuestionService extends TABService {

    model = 'tj_question';

    getByLibrary = (libraryId) =>
        this._get(
            `${this.model}/by-library/${libraryId}`,
        );

}

export default new TrivjoQuestionService();