import React, { useContext } from 'react';
import { ATTENDANCE_STATUSES } from '../../../helpers/Constants';
import ActionButtons, { Label } from '../../elements/ActionButtons';


const AttendanceSheetWeek = ({
    studentData,
    weekStartDate,
    attendanceFrequency,
    weekNumber,
    isViewWeek,
    isCurrentWeek = false,
    toggleViewWeek = null,
    isHeader = false,
}) => {

    let totalAttendance = 0; // count total days attendance was taken
    let totalPresent = 0; // count total days present
    let totalAbsent = 0; // count total days absent
    let totalLate = 0; // count total days late
    let thisWeeksAttendance = [];

    // extract this week's assignments
    if ( !isHeader ) {
        let startDay = new Date(new Date(Date.parse(weekStartDate)).getTime() + (1000 * 60 * 60 * 24 * 1)); // add 1 day to offset server time diff
        let endDay = new Date(new Date(startDay.getTime() + (1000 * 60 * 60 * 24 * 6)))
        
        // get assignments between 2 dates
        for (const [key, value] of Object.entries(studentData['attendance_details'])) { 
            let assignmentDate = new Date(new Date(Date.parse(`${key.slice(0,4)}-${key.slice(4,6)}-${key.slice(6,8)}`)).getTime() + (1000 * 60 * 60 * 24 * 1));
            
            if ( assignmentDate >= startDay && assignmentDate <= endDay ) thisWeeksAttendance.push(value);
        }

        // calculate week's attendance
        for (const periods of thisWeeksAttendance) { 

            for (const [key, period] of Object.entries(periods)) { 

                totalAttendance++;

                switch(period.status) {
                    case ATTENDANCE_STATUSES.PRESENT: // present
                        totalPresent++;
                        break;
                    case ATTENDANCE_STATUSES.ABSENT: // absent
                        totalAbsent++;
                        break;
                    case ATTENDANCE_STATUSES.TARDY: // late
                        totalLate++;
                        break;
                    case ATTENDANCE_STATUSES.UNKNOWN: // ??
                        totalPresent++;
                        break;
                    case ATTENDANCE_STATUSES.EXCUSED: // excused
                        totalPresent++;
                        break;
                    case 6: // never submitted attendance
                        if ( new Date() > Date.parse(period.date_attended) )
                            totalAbsent++;
                        break;
                }
            }
        }
    }

    return (
        <div className="attendance_sheet__week">
            {
                isHeader &&
                    <div className="attendance_sheet__week_header">
                        <div className={`
                            attendance_sheet__week_header_date
                            ${isCurrentWeek ? 'bg-info' : ''}
                            `}>
                                

                            <div className="week_count">
                                <ActionButtons
                                    buttons={[
                                        Label({
                                            text:`Week ${weekNumber}`,
                                            onClick: () => toggleViewWeek(),
                                            bgColor: isViewWeek ? 'success' : null,
                                        })
                                    ]}
                                /> 
                            </div>
                        </div>
                        {
                            !isViewWeek &&
                                <> 
                                    {weekStartDate.slice(5, 7)}/{weekStartDate.slice(8, 10)}/{weekStartDate.slice(2, 4)}
                                </>
                        }

                        {
                            isViewWeek &&
                                <div
                                    className='attendance_sheet__week_full'
                                >
                                    {
                                        Object.keys(studentData['attendance_details'])
                                            .filter((date) => {
                                                let startDay = new Date(new Date(Date.parse(weekStartDate)).getTime() + (1000 * 60 * 60 * 24 * 1)); // add 1 day to offset server time diff
                                                let endDay = new Date(new Date(startDay.getTime() + (1000 * 60 * 60 * 24 * 6)))
                                                let assignmentDate = new Date(new Date(Date.parse(`${date.slice(0,4)}-${date.slice(4,6)}-${date.slice(6,8)}`)).getTime() + (1000 * 60 * 60 * 24 * 1));
                                                
                                                if ( assignmentDate >= startDay && assignmentDate <= endDay ) return true;
                                                else return false;
                                            })
                                            .map((date) => {

                                                return (
                                                    <AttendanceSheetWeekDay
                                                        key={date}
                                                        date={`${weekStartDate.slice(5, 7)}/${weekStartDate.slice(8, 10)}/${weekStartDate.slice(2, 4)}`}
                                                        attendanceFrequency={Object.keys(studentData['attendance_details'][date]).length}
                                                        />
                                                )
                                            })
                                    }
                                </div>
                        }

                        {
                            ! isViewWeek &&
                            <div
                                className='attendance_sheet__week_student_summary'
                            >
                                <div 
                                    className="attendance_sheet__tab noselect"
                                    >
                                    {String.fromCharCode(10004)}
                                </div>
                                <div 
                                    className="attendance_sheet__tab noselect"
                                    >
                                    L
                                </div>
                                <div 
                                    className="attendance_sheet__tab noselect"
                                    >
                                    X
                                </div>
                            </div>
                        }
                        
                    </div>
            }

            {
                !isHeader &&
                    <div className="attendance_sheet__week_student">
                        {
                            !isViewWeek &&
                                <div
                                    className='attendance_sheet__week_student_summary'
                                >
                                    <div 
                                        className="attendance_sheet__tab attendance_sheet__tab--status-present noselect"
                                        style={{opacity: totalPresent === 0 ? 0.2 : 1}}
                                        data-toggle="tooltip" 
                                        title={`${studentData['first_name']} ${studentData['last_name']} was present ${totalPresent} out of ${totalAttendance} days`}
                                        >
                                        {totalPresent}
                                    </div>
                                    <div 
                                        className="attendance_sheet__tab attendance_sheet__tab--status-late noselect"
                                        style={{opacity: totalLate === 0 ? 0.2 : 1}}
                                        data-toggle="tooltip" 
                                        title={`${studentData['first_name']} ${studentData['last_name']} was late ${totalLate} out of ${totalAttendance} days`}
                                        >
                                        {totalLate}
                                    </div>
                                    <div 
                                        className="attendance_sheet__tab attendance_sheet__tab--status-absent noselect"
                                        style={{opacity: totalAbsent === 0 ? 0.2 : 1}}
                                        data-toggle="tooltip" 
                                        title={`${studentData['first_name']} ${studentData['last_name']} was absent ${totalAbsent} out of ${totalAttendance} days`}
                                        >
                                        {totalAbsent}
                                    </div>
                                </div>
                        }

                        {
                            isViewWeek &&
                                <div className="attendance_sheet__week_student_details">
                                    {
                                        thisWeeksAttendance.map((attend) => { 
                                           return ( <AttendanceSheetWeekDay
                                                studentAttendanceData={attend}
                                                date={`${attend.period_1['date_attended'].slice(6,8)}/${attend.period_1['date_attended'].slice(4,6)}/${attend.period_1['date_attended'].slice(2,4)}`} // YYYY-MM-DD
                                                attendanceFrequency={attendanceFrequency}
                                                studentData={studentData}
                                                />)
                                        })
                                    }
                                </div>
                        }
                    </div>
            }
        </div>
    )

}

export default AttendanceSheetWeek;

const AttendanceSheetWeekDay = ({
    studentAttendanceData, // if null, treat as header row
    date,
    attendanceFrequency,
    studentData
}) => {

    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

    return(
        <div className="attendance_sheet__week_day">
            {
                ! studentAttendanceData &&
                    <>
                        <div className='attendance_sheet__week_day_header'>
                            {date}
                        </div>
                        <div className="attendance_sheet__week_day_tabs">
                            {
                                Array.apply(null, Array(attendanceFrequency)).map(() => 
                                    <div className='attendance_sheet__tab'>&nbsp;</div>)
                            }
                        </div>
                    </>
            }

            {
                studentAttendanceData &&
                    <div className='attendance_sheet__week_day_tabs'>
                        {
                            Object.keys(studentAttendanceData).map((key) => {
                                const status = parseInt(studentAttendanceData[key]['status']);
                                
                                return (
                                    <div 
                                        className={`
                                            attendance_sheet__tab
                                            attendance_sheet__tab--status-${
                                                status === 1 || status === 4 || status === 5 ?
                                                    'present'
                                                    :
                                                    status === 2 || (status===6 && new Date() > Date.parse(studentAttendanceData[key].date_attended) ) ?
                                                        'absent'
                                                        :
                                                        status === 6 ?
                                                        'unsubmitted'
                                                        :
                                                        'late'
                                            }
                                            noselect
                                        `}
                                        
                                        data-toggle="tooltip" 
                                        title={`${studentData.first_name} ${studentData.last_name} - ${studentAttendanceData[key]['date_attended']}`}
                                    >
                                        {status === 1 || status === 4 || status === 5?
                                            String.fromCharCode(10004)
                                            :
                                            status === 2 || (status===6 && new Date() > Date.parse(studentAttendanceData[key].date_attended) ) ?
                                                'X'
                                                :
                                                status === 6 ?
                                                String.fromCharCode(160)
                                                :
                                                'L'}
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )

}