import React, { useEffect, useState, useContext } from 'react';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import ValueSorter from '../filter_sorter/sorters/ValueSorter';
import { SORT_TYPES } from '../filter_sorter/sorters/Sorter';
import PercentDisplay from '../my_cohort_elements/PercentDisplay';
import ValueSortSelector from '../my_cohort_elements/ValueSortSelector';
import ValuesFilter from '../filter_sorter/filters/ValuesFilter';
import SimpleCheckColumn from './SimpleCheckColumn';
import NotesModal from './NotesModal';
import { ProbationsDisplay } from './ProbationsModal';
import ActionButtons, { ICONS, BasicActionButton, SortButton, CopyToClipboardButton, Label } from '../../elements/ActionButtons';
import { RollbacksDisplay} from './RollbacksModal';
import { AppContext } from '../../../store/AppContext';
import useUserAccess from '../../../hooks/useUserAccess';
import StudentSheetHistory from './StudentSheetHistory';
import StackProgressGenerator, { StackProgressDisplay } from './StackProgressGenerator';
import eventHandler, { UPDATE_SHEET_STUDENTS } from '../../../events';
import BeltDisplay from '../exams_sheet/BeltDisplay';
import NextStackBreakdown from './NextStackBreakdown';
import StudentRowAttendance from '../my_cohort_elements/StudentRowAttendance';
import TextSortSelector from '../my_cohort_elements/TextSortSelector';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import MyCohortContext from '../../../store/MyCohortContext';
import useStudent from '../../../hooks/useStudent';
import useRoster from '../../../hooks/useRoster';

const MyCohortSEMSheet = ({ 
}) => {
    
    const { stackObj } = useContext(AppContext);
    const [ USER_LEVELS, checkAccess ] = useUserAccess();
    const { pushOverlay, popOverlay } = useOverlays();
    const {
        sortedRoster,
        studentsData, 
        inStatsMode,
        sheetsData,
    } = useContext(MyCohortContext);
    const { saveStudent } = useStudent();
    const { 
        checkStudentSelected,
        hoveredStudentId,
        setHoveredStudentId,
        clearHoveredStudent
    } = useRoster();

    const [sorter, setSorter] = useState(null);
    const [ filters, setFilters ] = useState({}); // key == filter name, value == filter object

    const [probationsStudent, setProbationsStudent] = useState(null);
    const [ rollbacksStudent, setRollbacksStudent ] = useState(null);

    const [percentsSortType, setPercentsSortType] = useState('to-date');
    const [availablePrograms, setAvailablePrograms] = useState([]);

    const [filterProgramIds, setFilterProgramIds] = useState([]); // array of program ids to filter by

    useEffect(() => {

        setSorter(filterSorter.getSorter());

        setFilters(
            // if no filters, or filters length is 0, set to null
            filterSorter.settings.sem_filters !== undefined && Object.keys(filterSorter.settings.sem_filters).length > 0 ? 
            filterSorter.settings.sem_filters : {}
        )

        if (
            'sem_percent_sort_type' in filterSorter.settings
        ){ 
            setPercentsSortType(filterSorter.settings.sem_percent_sort_type);
        }
        else setPercentsSortType('to-date');

        if ( rollbacksStudent ) { // replace rolbackStudent with updated student
            const updatedStudent = sortedRoster.find(student => student.id === rollbacksStudent.id);
            setRollbacksStudent(updatedStudent);
        }

        const availProgs = {};
        sortedRoster.forEach((student) => availProgs[student.program_id] = student.program_name);
        setAvailablePrograms(Object.keys(availProgs).map((id) => ({id: parseInt(id), text: availProgs[id]})));

    }, [sortedRoster]);

    const assignSorter = ( name, column, sortType ) => 
        filterSorter.addSorter(
            new ValueSorter(
                    name,
                    column,
                    sortType
                )
        );
    
    const addFilter = ( filter ) => {
        filterSorter.addFilter(filter);
        filterSorter.setSettings({
            sem_filters: {
                ...filters,
                [filter.name]: filter
            }
        })
    }

    const removeFilter = ( name ) => {
        filterSorter.removeFilter(name);

        let tFilters = {...filters};
        delete tFilters[name];
        
        if ( tFilters.length > 0 ) {
            filterSorter.setSettings({
                sem_filters: tFilters
            });
        }
        else { console.log('deleting sem_filters')
            filterSorter.deleteSetting('sem_filters');
        }
    }

    const bulkAssignStackProgression = (e, stacks) => {

        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: {x: e.clientX, y: e.clientY},
            component: <>Are you sure you want to update stack progression for {sortedRoster.length} student{sortedRoster.length != 1 ? 's' : ''}?</>,
            respond: (response) => {
                if ( response ){
                    //popOverlay();
                    const stackProgressJSON = JSON.stringify(stacks);
                    
                    eventHandler.dispatch(UPDATE_SHEET_STUDENTS, {
                        students: sortedRoster.map((student) => ({
                            ...student,
                            stack_progress: stackProgressJSON
                        })), 
                        localOnly: false
                    })}
            }
        })
    }

    const onSortHandler = ( name, column, sortType) => {
        filterSorter.addSorter(
            new ValueSorter(name, column, sortType)
        );}

    const weeks = [];
    if ( sortedRoster.length > 0 ) Object.keys(sortedRoster[0]['attendance_details']).forEach((week, i) => weeks.push(i + 1));

    return (
        <div className='my_cohort__right_panel'>
            <div className='my_cohort__filters_bar layer layer-3 '>
                <div className='my_cohort__filters_bar__header'>
                    {
                        checkAccess(USER_LEVELS.LEAD) &&
                            <ActionButtons
                                buttons={[
                                    Label({
                                        text: "Generate Next Stack Report",
                                        onClick: () => 
                                            pushOverlay({
                                                type: OVERLAY_TYPES.MODAL,
                                                component: <NextStackBreakdown
                                                    statuses={sheetsData.student_statuses}
                                                />
                                            }),
                                    }),
                                ]}
                                />
                    }
                </div>
                
                {/* build weeks and filters row  */}
                <div className="sem_data__filter_row ">
                    {
                        checkAccess(USER_LEVELS.LEAD) &&
                        <span className="sem_data__column sem_data__filter_column ">
                            <div className="sem_data__column__title">Sheets<br/>History</div>
                        </span>
                    }

                    <span className="sem_data__column sem_data__filter_column  sem_data__column--175">
                        <div className="sem_data__column__title">SEM</div>
                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: ICONS.FILTER,
                                    isSelected: filters && ('sem_sem_names' in filters),
                                    onClick: (e) =>
                                        pushOverlay({
                                            position: {x: e.clientX, y: e.clientY},
                                            component: <TextSortSelector
                                                texts={
                                                    filterSorter.roster.map( student => ({id: student.sem_user_id, text: student.sem_name}))
                                                }
                                                selectedTextIds={filters["sem_sem_names"] ? filters["sem_sem_names"].values : []}
                                                setSelectedTexts={(values) => { 
                                                    setFilterProgramIds(values);
                                                    if (values.length > 0) {
                                                        popOverlay();
                                                        addFilter(
                                                            new ValuesFilter(
                                                                "sem_sem_names",
                                                                values,
                                                                "sem_user_id"
                                                            )
                                                        );
                                                    } else {
                                                        // filters were cleared
                                                        removeFilter("sem_sem_names");
                                                    }
                                                }}
                                                close={() => popOverlay()}
                                            /> 
                                        })
                                }),
                                BasicActionButton({
                                    icon: ICONS.CANCEL,
                                    visible: filters && ('sem_sem_names' in filters),
                                    isSelected: true,
                                    onClick: () => removeFilter('sem_sem_names')
                                }),
                                SortButton({
                                    sortType: sorter && sorter.name === "sem_sem_names" ? sorter.sortType : null,
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_sem_names",
                                            "sem_name",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                        );
                                    }
                                }),
                            ]}
                        />
                    </span>

                    <span 
                        className="sem_data__column sem_data__filter_column sem_data__column--250"
                        >

                        <div>
                            <select
                                onChange={(e) => {
                                    setPercentsSortType(e.target.value);
                                    filterSorter.settings['sem_percent_sort_type'] = e.target.value;
                                }}
                            >
                                <option value="to-date" selected={percentsSortType === 'to-date'}>To Date</option>
                                <option value="overall" selected={percentsSortType === 'overall'}>Overall</option>
                                {
                                    weeks.map((week, i) => (
                                        <option value={`week_${i + 1}`} selected={percentsSortType === `week_${i + 1}`}>Week {i + 1}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="sem_data__column  sem_data__column--250">
                            <div className="sem_data__column sem_data__filter_column ">
                                <div className="sem_data__column__title">
                                    Assignments
                                </div>

                                <ActionButtons
                                    buttons={[
                                        BasicActionButton({
                                            icon: ICONS.FILTER,
                                            isSelected: filters && ('sem_assignments_percent' in filters),
                                            onClick: (e) => 
                                                pushOverlay({
                                                    position: {x: e.clientX, y: e.clientY},
                                                    component: <ValueSortSelector
                                                        close={() => popOverlay()}
                                                        columnValueName={
                                                            percentsSortType === 'to-date' ? 
                                                                'core_assignments_to_date_percent'
                                                                :
                                                                'core_assignments_overall_percent'
                                                        }
                                                        studentsData={studentsData}
                                                        isPercent={true}
                                                        filterValues={filters["sem_assignments_percent"] ? filters["sem_assignments_percent"].values : []}
                                                        setFilterValues={(values) => {
                                                            if (values.length > 0) {
                                                                popOverlay();
                                                                addFilter(
                                                                    new ValuesFilter(
                                                                        "sem_assignments_percent",
                                                                        values,
                                                                        percentsSortType === 'to-date' ? 
                                                                            'core_assignments_to_date_percent'
                                                                            :
                                                                            'core_assignments_overall_percent'
                                                                    )
                                                                );
                                                            } else {
                                                                // filters were cleared
                                                                popOverlay();
                                                                removeFilter("sem_assignments_percent");
                                                            }
                                                        }}
                                                    />
                                                })
                                        }),
                                        BasicActionButton({
                                            icon: ICONS.CANCEL,
                                            visible: filters && ('sem_assignments_percent' in filters),
                                            isSelected: true,
                                            onClick: () => removeFilter('sem_assignments_percent')
                                        }),
                                        SortButton({
                                            sortType: sorter && sorter.name === "sem_assignments_percent" 
                                                ? sorter.sortType : null,
                                            setSortType: () => {
                                                assignSorter(
                                                    'sem_assignments_percent',

                                                    percentsSortType === 'to-date' ? 
                                                            'core_assignments_to_date_percent'
                                                            :
                                                            percentsSortType === 'overall' ?
                                                                'core_assignments_overall_percent'
                                                                :
                                                                `week_${percentsSortType.split('_')[1]}_assignment_percent`,

                                                    sorter && sorter.sortType === SORT_TYPES.ASC
                                                        ? SORT_TYPES.DESC
                                                        : SORT_TYPES.ASC
                                                )
                                            }
                                        }),
                                    ]}
                                />
                            </div>

                            <div className="sem_data__column sem_data__filter_column">
                                <div className="sem_data__column__title">
                                    Attendance
                                </div>
                                <div>
                                    <ActionButtons
                                        buttons={[
                                            BasicActionButton({
                                                icon: ICONS.FILTER,
                                                isSelected: filters && ('sem_attendance_percent' in filters),
                                                onClick: (e) => 
                                                    pushOverlay({
                                                        position: {x: e.clientX, y: e.clientY},
                                                        component: <ValueSortSelector
                                                            close={() => popOverlay()}
                                                            columnValueName={
                                                                percentsSortType === 'to-date' ? 
                                                                    'attendance_to_date_percent'
                                                                    :
                                                                    'attendance_overall_percent'
                                                            }
                                                            studentsData={studentsData}
                                                            isPercent={true}
                                                            filterValues={filters["sem_attendance_percent"] ? filters["sem_attendance_percent"].values : []}
                                                            setFilterValues={(values) => {
                                                                if (values.length > 0) {
                                                                    popOverlay();
                                                                    addFilter(
                                                                        new ValuesFilter(
                                                                            "sem_attendance_percent",
                                                                            values,
                                                                            percentsSortType === 'to-date' ? 
                                                                                'attendance_to_date_percent'
                                                                                :
                                                                                'attendance_overall_percent'
                                                                        )
                                                                    );
                                                                } else {
                                                                    // filters were cleared
                                                                    popOverlay();
                                                                    removeFilter("sem_attendance_percent");
                                                                }
                                                            }}
                                                        />
                                                    })
                                            }),
                                            BasicActionButton({
                                                icon: ICONS.CANCEL,
                                                visible: filters && ('sem_attendance_percent' in filters),
                                                isSelected: true,
                                                onClick: () => removeFilter('sem_attendance_percent')
                                            }),
                                            SortButton({
                                                sortType: sorter && sorter.name === "sem_attendance_percent" 
                                                    ? sorter.sortType : null,
                                                setSortType: () => {
                                                    assignSorter(
                                                        'sem_attendance_percent',

                                                        percentsSortType === 'to-date' ? 
                                                                'attendance_to_date_percent'
                                                                :
                                                                percentsSortType === 'overall' ?
                                                                    'attendance_overall_percent'
                                                                    :
                                                                    `week_${percentsSortType.split('_')[1]}_attendance_percent`,

                                                        sorter && sorter.sortType === SORT_TYPES.ASC
                                                            ? SORT_TYPES.DESC
                                                            : SORT_TYPES.ASC
                                                    )
                                                }
                                            }),
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">
                            Probations
                        </div>

                        <ActionButtons
                            buttons={[
                                SortButton({
                                    sortType: sorter && sorter.name === "sem_probation_values" 
                                        ? sorter.sortType : null,
                                    isSelected: sorter && sorter.name === "sem_probation_values",
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_probation_values",
                                            "probations",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                        );
                                    },
                                    toolTip: "Sort by Probation",
                                })
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">
                            
                            Retakes
                            
                        </div>

                        <ActionButtons
                            buttons={[
                                SortButton({
                                    sortType: sorter && sorter.name === "sem_rollback_values" 
                                        ? sorter.sortType : null,
                                    isSelected: sorter && sorter.name === "sem_rollback_values",
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_rollback_values",
                                            "total_retakes",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                        );
                                    },
                                    toolTip: "Sort by Retakes",
                                })
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">Returning</div>
                        <ActionButtons
                            buttons={[
                                SortButton({
                                    sortType: sorter && sorter.name === "sem_retake" 
                                        ? sorter.sortType : null,
                                    isSelected: sorter && sorter.name === "sem_retake",
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_retake",
                                            "attempt_order",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                        );
                                    },
                                    toolTip: "Sort by Returning",
                                })
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">
                            
                            Belts
                            
                        </div>

                        <ActionButtons
                            buttons={[
                                SortButton({
                                    sortType: sorter && sorter.name === "sem_belts" 
                                        ? sorter.sortType : null,
                                    isSelected: sorter && sorter.name === "sem_belts",
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_belts",
                                            "belt_level",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                        );
                                    },
                                    toolTip: "Sort by Belt",
                                })
                            ]}
                        />
                    </span>
                    
                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">Notes</div>
                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: sorter && sorter.name === "sem_note" ? ICONS.CANCEL :  ICONS.EDIT,
                                    isSelected: sorter && sorter.name === "sem_note",
                                    onClick: () => {
                                        !sorter || sorter.name !== "sem_note" ?
                                            onSortHandler(
                                                "sem_note",
                                                "notes",
                                                SORT_TYPES.ASC
                                            )
                                            :
                                            filterSorter.clearSorter();
                                    }
                                })
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">
                            Accommodations
                        </div>

                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: sorter && sorter.name === "sem_accommodations" ? ICONS.CANCEL :  ICONS.CHECKMARK,
                                    isSelected: sorter && sorter.name === "sem_accommodations",
                                    onClick: () => {
                                        !sorter || sorter.name !== "sem_accommodations" ?
                                            onSortHandler(
                                                "sem_accommodations",
                                                "has_accommodation",
                                                SORT_TYPES.DESC
                                            )
                                            :
                                            filterSorter.clearSorter();
                                    }
                                })
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">B2B</div>

                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: sorter && sorter.name === "sem_b2b" ? ICONS.CANCEL :  ICONS.CHECKMARK,
                                    isSelected: sorter && sorter.name === "sem_b2b",
                                    onClick: () => {
                                        !sorter || sorter.name !== "sem_b2b" ?
                                            onSortHandler(
                                                "sem_b2b",
                                                "b2b",
                                                SORT_TYPES.DESC
                                            )
                                            :
                                            filterSorter.clearSorter();
                                    }
                                })
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column">
                        <div className="sem_data__column__title">Next Stack</div>

                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: ICONS.FILTER,
                                    toolTip: "Filter by Next Stack",
                                    isSelected: filters && ('sem_next_stack' in filters),
                                    onClick: (e) => 
                                        pushOverlay({
                                            position: {x: e.clientX, y: e.clientY},
                                            component:<TextSortSelector
                                                texts={
                                                    // extract all the next stacks from the roster
                                                    filterSorter.roster.map( student => ({
                                                        id: Object.keys(stackObj).find((key) => stackObj[key] === student.next_stack), // get the id of the stack
                                                        text: student.next_stack
                                                    }))
                                                }
                                                selectedTextIds={filters["sem_next_stack"] ? filters["sem_next_stack"].values : []}
                                                setSelectedTexts={(values) => { 
                                                    setFilterProgramIds(values);
                                                    if (values.length > 0) {
                                                        popOverlay();
                                                        addFilter(
                                                            new ValuesFilter(
                                                                "sem_next_stack",
                                                                values.map((id) => stackObj[id]),
                                                                "next_stack"
                                                            )
                                                        );
                                                    } else {
                                                        // filters were cleared
                                                        removeFilter("sem_next_stack");
                                                    }
                                                }}
                                                close={() => popOverlay()}
                                            />
                                        })
                                }),
                                BasicActionButton({
                                    icon: ICONS.CANCEL,
                                    visible: filters && ('sem_next_stack' in filters),
                                    isSelected: true,
                                    onClick: () => removeFilter('sem_next_stack')
                                }),
                                SortButton({
                                    sortType: sorter && sorter.name === "sem_next_stack_values" 
                                        ? sorter.sortType : null,
                                    isSelected: sorter && sorter.name === "sem_next_stack_values",
                                    setSortType: ( sortType ) => {
                                        onSortHandler(
                                            "sem_next_stack_values",
                                            "next_stack",
                                            sortType
                                        )

                                    } ,
                                    toolTip: "Sort Next Stack",
                                }),
                            ]}
                        />
                    </span>

                    <span className="sem_data__column sem_data__filter_column sem_data__column--300">
                        <div className="sem_data__column__title">
                            Stack Progression
                        </div>

                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: ICONS.FILTER,
                                    toolTip: "Filter by Stack Progression",
                                    isSelected: filters && ('sem_stack_progress' in filters),
                                    onClick: (e) => 
                                        pushOverlay({
                                            position: {x: e.clientX, y: e.clientY},
                                            component: <TextSortSelector
                                                texts={
                                                    filterSorter.roster.map( student => ({id: student.stack_progress_full, text: student.stack_progress_full}))
                                                }
                                                selectedTextIds={filters["sem_stack_progress"] ? filters["sem_stack_progress"].values : []}
                                                setSelectedTexts={(values) => { 
                                                    setFilterProgramIds(values);
                                                    if (values.length > 0) {
                                                        popOverlay();
                                                        addFilter(
                                                            new ValuesFilter(
                                                                "sem_stack_progress",
                                                                values,
                                                                "stack_progress_full"
                                                            )
                                                        );
                                                    } else {
                                                        // filters were cleared
                                                        removeFilter("sem_stack_progress");
                                                    }
                                                }}
                                                close={() => popOverlay()}
                                            />
                                        })
                                }),
                                BasicActionButton({
                                    icon: ICONS.CANCEL,
                                    visible: filters && ('sem_stack_progress' in filters),
                                    isSelected: true,
                                    onClick: () => removeFilter('sem_stack_progress')
                                }),
                                SortButton({
                                    icon: sorter && sorter.name === "sem_stack_progress" ? ICONS.CANCEL :  ICONS.SORT,
                                    sortType: sorter && sorter.name === "sem_stack_progress" ? sorter.sortType : null,
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_stack_progress",
                                            "stack_progress_full",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                            )
                                    },
                                    toolTip: "Sort by Stack Progression",
                                }),
                                // Label({
                                //     text: "Bulk Update",
                                //     toolTip: "Update Stack Progressions ( multiple students )",
                                //     onClick: () => 
                                //         pushOverlay({
                                //             type: OVERLAY_TYPES.MODAL,
                                //             component: <StackProgressGenerator
                                //                 student={probationsStudent}
                                //                 close={() => popOverlay()}
                                //                 updateProgress={bulkAssignStackProgression}
                                //             />
                                //         }),
                                //     visible: checkAccess(USER_LEVELS.INSTRUCTOR)
                                // })

                            ]}
                        />

                    </span>

                    <span className="sem_data__column sem_data__filter_column sem_data__column--250">
                        <div className="sem_data__column__title">
                            Program
                        </div>

                        <ActionButtons
                            buttons={[
                                BasicActionButton({
                                    icon: ICONS.FILTER,
                                    toolTip: "Filter by Program Name",
                                    isSelected: filters && ('sem_program_name' in filters),
                                    onClick: (e) =>
                                        pushOverlay({
                                            position: {x: e.clientX, y: e.clientY},
                                            component: <TextSortSelector
                                                texts={availablePrograms}
                                                selectedTextIds={filterProgramIds}
                                                setSelectedTexts={(values) => { 
                                                    setFilterProgramIds(values);
                                                    if (values.length > 0) {
                                                        popOverlay();
                                                        addFilter(
                                                            new ValuesFilter(
                                                                "sem_program_name",
                                                                values,
                                                                "program_id"
                                                            )
                                                        );
                                                    } else {
                                                        // filters were cleared
                                                        removeFilter("sem_program_name");
                                                    }
                                                }}
                                                close={() => popOverlay()}
                                            />
                                        })
                                     
                                }),
                                BasicActionButton({
                                    icon: ICONS.CANCEL,
                                    visible: filters && ('sem_program_name' in filters),
                                    isSelected: true,
                                    onClick: () => removeFilter('sem_program_name')
                                }),
                               SortButton({
                                    icon: sorter && sorter.name === "sem_program_name" ? ICONS.CANCEL :  ICONS.SORT,
                                    sortType: sorter && sorter.name === "sem_program_name" ? sorter.sortType : null,
                                    setSortType: () => {
                                        onSortHandler(
                                            "sem_program_name",
                                            "program_name",
                                            sorter && sorter.sortType === SORT_TYPES.ASC
                                                ? SORT_TYPES.DESC
                                                : SORT_TYPES.ASC
                                            )
                                    },
                                    toolTip: "Sort by Stack Progression",
                                }),
                            ]} 
                        />
                    </span>

                </div>
            </div>

            <div className="responsive_browser_row_max">
                {/* stats row */}
                {
                    inStatsMode &&
                    <> 
                        <div
                            className=' text-center mb-1'
                        >
                            &nbsp;
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </>
                }

                {/* Roster data */}
                {sortedRoster.map((student, index) => {

                    return (
                        <>
                            <div
                                className={`assignment_reviews__row assign_attend__row
                                                ${
                                                    index % 2
                                                        ? ""
                                                        : "assignments_browser__student_row--highlight"
                                                }
                                                ${
                                                    hoveredStudentId ===
                                                    student.id
                                                        ? "cohort_list__row--selected_student"
                                                        : ""
                                                }
                                                ${checkStudentSelected(student.id) ? 'bg-secondary text-white' : ''}
                                                `}
                                key={student["id"]}
                                onMouseEnter={() =>
                                    setHoveredStudentId(student.id)
                                }
                                onMouseLeave={() =>
                                    clearHoveredStudent()
                                }
                            >
                                {
                                    checkAccess(USER_LEVELS.LEAD) &&
                                        <div
                                            className={`sem_data__column`}
                                        >
                                            <ActionButtons
                                                buttons={[
                                                    BasicActionButton({
                                                        icon: ICONS.HISTORY,
                                                        toolTip: 'View Sheet Updates History',
                                                        onClick: () => 
                                                            pushOverlay({
                                                                type: OVERLAY_TYPES.MODAL,
                                                                component: <StudentSheetHistory
                                                                    student={student}
                                                                    close={() => popOverlay()}
                                                                />
                                                            })
                                                    })
                                                    ]}
                                                    />
                                        </div>
                                }

                                <div
                                    className="sem_data__column sem_data__column--175 text-left"
                                >
                                    <ActionButtons
                                        buttons={[
                                            CopyToClipboardButton({
                                                content: student.sem_name,
                                                icon: ICONS.EMAIL,
                                                toolTip: "Copy SEM Email",
                                            }),
                                            Label({
                                                text: student.sem_name,
                                                xClass: "sem_data__name_action_button_label"
                                            })
                                        ]}
                                    />
                                </div>
                                
                                <div className="sem_data__column sem_data__column--250">

                                    {/* Assignment percentages */}
                                    <div className="sem_data__column">
                                        <PercentDisplay
                                            percent={parseFloat(
                                                student[
                                                    percentsSortType === 'to-date' ? 
                                                        'core_assignments_to_date_percent'
                                                        :
                                                        percentsSortType === 'overall' ?
                                                            'core_assignments_overall_percent'
                                                            :
                                                            `week_${percentsSortType.split('_')[1]}_assignment_percent`
                                                ]
                                            )}
                                            bottomPercent={0.6}
                                            highPercent={0.9}
                                        />
                                    </div>
                                    
                                    
                                    <div className="sem_data__column  ">
                                        <StudentRowAttendance 
                                            student={student}
                                            sortType={percentsSortType} 
                                        />
                                    </div>
                                </div>

                                <div className="sem_data__column probations_button__container">
                                    <ProbationsDisplay
                                        student={student}
                                        canActivate={checkAccess(USER_LEVELS.INSTRUCTOR)}
                                        updateProbations={() => setProbationsStudent(student)}
                                        />
                                </div>

                                <div className="sem_data__column probations_button__container">
                                    
                                    <RollbacksDisplay
                                        student={student}
                                        />
                                </div>

                                <div 
                                    className="sem_data__column noselect "
                                    data-toggle="tooltip"
                                    title={`${student["first_name"]} ${student["last_name"]} is on retake ${student["attempt_order"]}.
                                    `}
                                    >
                                    {
                                        student["attempt_order"] > 0 ? "Yes" : ""
                                    }
                                </div>

                                <div 
                                    className="sem_data__column  "
                                    >
                                    <BeltDisplay
                                        belt_level={student.belt_level}
                                        belt_score={student.belt_score}
                                        />
                                </div>

                                <div 
                                    className="sem_data__column"
                                    data-toggle="tooltip"
                                    title={` ${student.name}:\n\n--------------------\n${student.notes ? student.notes : "No notes"}
                                    \n\n--------------------\nClick to ${student.notes ? "edit" : "add"} notes
                                    `}
                                    >
                                    <ActionButtons
                                        key={`notes_${student.id}`}
                                        buttons={[
                                            BasicActionButton({
                                                icon: student.notes ? ICONS.EDIT : ICONS.ADD,
                                                onClick: (e) => 
                                                    pushOverlay({
                                                        position: {x: e.clientX, y: e.clientY},
                                                        component: <NotesModal
                                                            student={student}
                                                            close={() => popOverlay()}
                                                        />,
                                                    }),
                                                visible: ! student.notes ? checkAccess(USER_LEVELS.INSTRUCTOR) : true
                                            })
                                        ]}
                                    />
                                </div>
                                
                                <div className="sem_data__column ">
                                    <SimpleCheckColumn
                                        student={student}
                                        column="has_accommodation"
                                        disabled={true}
                                    />
                                </div>

                                <div className="sem_data__column ">
                                    <SimpleCheckColumn
                                        student={student}
                                        column="b2b"
                                        character={10004}
                                        disabled={!checkAccess(USER_LEVELS.INSTRUCTOR)}
                                        updateStudent={(updatedStudent) => {
                                            saveStudent({
                                                ...updatedStudent,
                                                log_note: "B2B Updated"
                                            })
                                        }}
                                    />
                                </div>

                                <div className="sem_data__column small">
                                    {student.next_stack}
                                </div>

                                <div className="sem_data__column  sem_data__column--300 small mt-1">
                                    <StackProgressDisplay
                                        student={student}
                                        stackObj={stackObj}
                                        updateProgress={(e) => {
                                            pushOverlay({
                                                type: OVERLAY_TYPES.MODAL,
                                                component: <StackProgressGenerator
                                                    student={student}
                                                    close={() => popOverlay()}
                                                    currentProgress = {JSON.parse(student["stack_progress"] || [])}
                                                    updateProgress={(e, stacks) => {
                                                        saveStudent({
                                                            ...student,
                                                            log_note: "Stack Progression Updated",
                                                            stack_progress: JSON.stringify(stacks)
                                                        })
                                                        popOverlay()
                                                    }}
                                                />
                                            })
                                        }}
                                    />
                                </div>

                                <div className="sem_data__column  sem_data__column--auto small text-center mt-1">
                                    {student.program_name}
                                </div>
                                
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default MyCohortSEMSheet;