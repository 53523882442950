import React from "react";

const Paginator = ( { 
    isTicketsLoaded, 
    searchResponse, 
    onPageSelected, 
    loadingMessage = 'loading', 
    itemPrompt = 'result' 
} ) => {

    let targetPage, hiPage, lowPage = 0;

    if ( searchResponse ) {
        targetPage = searchResponse.page;
        lowPage = Math.max(1, targetPage - 3);
        hiPage = Math.min(searchResponse.pages, targetPage + 4);
        
        if ( hiPage - lowPage < 7) 
            hiPage = Math.min(searchResponse.pages, hiPage + (7 - (hiPage - lowPage)));

    }


    return (
        <div className="row justify-content-center"> 

            {
                !isTicketsLoaded ?
                    <div className="col-12 text-center">{ loadingMessage }...</div>
                    :
                    <div>
                        <span className='mr-2 header-text text-light'>
                            {
                                `${searchResponse.total > 0 ? searchResponse.total : 'No'} ${itemPrompt}${searchResponse.total === 1 ? '' : 's'}`
                            }
                        </span>
                        { // add a 1...
                            (searchResponse.pages > 1 && lowPage >= 5) &&
                                <span className={`mr-1 header-text`}>
                                    <a href='/' onClick={(e) => {
                                        e.preventDefault();
                                        onPageSelected(1);
                                    }}>1</a>...
                                </span>
                        }
                        { // add a midway point between 1 and the lowPage
                            (searchResponse.pages > 1 && lowPage >= searchResponse.pages / 4) &&
                                <span className={`mr-1 header-text`}>
                                    <a href='/' onClick={(e) => {
                                        e.preventDefault();
                                        onPageSelected(Math.floor(searchResponse.pages / 4));
                                    }}>{
                                        Math.floor(searchResponse.pages / 4)
                                    }</a>...
                                </span>
                        }
                        <>
                            {
                                searchResponse.pages > 1 &&
                                    Object.keys(Array(hiPage - lowPage).fill()).map( ndx => {
                                        ndx = parseInt(ndx) + lowPage - 1; 
                                        
                                        return (
                                            <span 
                                                className={`mr-1 noselect header-text${ndx + 1 === searchResponse.page ? ' paginator__selectedPage':''}`} 
                                                key={`page_${ndx}`}
                                                > 
                                                {
                                                    ndx + 1 === searchResponse.page ?
                                                        <>{ndx + 1}</>
                                                        :
                                                        <a href='/' onClick={(e) => {
                                                            e.preventDefault();
                                                            onPageSelected(ndx + 1);
                                                        }}>{ndx + 1}</a>
                                                }
                                            </span>
                                        )})
                            }
                        </>
                        {
                            (searchResponse.pages > 1 && searchResponse.pages - hiPage >= searchResponse.pages / 4) &&
                                <span className={`mr-1 header-text`}>
                                    ...<a href='/' onClick={(e) => {
                                        e.preventDefault();
                                        onPageSelected(Math.floor(searchResponse.pages - (searchResponse.pages / 4)));
                                    }}>{
                                        Math.floor(searchResponse.pages - (searchResponse.pages / 4))
                                    }</a>
                                </span>
                        }

                        {
                            (searchResponse.pages > 1 && searchResponse.pages - hiPage >= 5) &&
                                <span className={`mr-1 header-text`}>
                                    ...<a href='/' onClick={(e) => {
                                        e.preventDefault();
                                        onPageSelected(searchResponse.pages);
                                    }}>{
                                        searchResponse.pages
                                    }</a>
                                </span>
                        }
                    </div>
            }
            
        </div>
    )
}

export default Paginator;