import React, {useContext} from 'react';
import MyCohortContext from '../../../store/MyCohortContext';
import ActionButtons, { CopyToClipboardButton } from '../../elements/ActionButtons';

const CertificateData = ({

}) => {

    const {
        sortedRoster,
    } = useContext(MyCohortContext);

    return (
        <div
            id="certificate_data"
            className='row window_600'
        >
            <div className='col-12 bg-warning text-danger font-weight-bold text-center'>
                Set student statuses to GRADUATED before using this feature.
            </div>

            <div className='col-6'>

            </div>

            <div className='col-6 p-2 text-center'>
                <p>
                Copy 3rd Party Certificate Data to Clipboard.
                </p>
                <ActionButtons
                    buttons={[
                        CopyToClipboardButton({
                            clipboardContent: 'Certificate Data',
                        })
                    ]}
                />
            </div>

        </div>
    )
}

export default CertificateData;