// User can paste URL to image. Then test that it loads and display it.

import React, { useState } from "react";
import MissingImage from "../../assets/no_image_placeholder.png";

const ImageLink = ({ 
    link, 
    setLink,
    close,
}) => {

        const [ newLink, setNewLink ] = useState(""); 
        const [ previewLink, setPreviewLink ] = useState(link);
    
        return (
            <div className="image_link window_400">
                <div className="image_link__top">
                    <input
                        className="form-control"
                        value={newLink}
                        placeholder="Image URL"
                        onChange={(e) => setNewLink(e.target.value)}
                    />
                </div>
                <div className="image_link__bottom">
                    <div className="image_link__image">
                        <img 
                            alt="preview" 
                            src={previewLink || MissingImage} 
                        />
                    </div>
                    <div className="image_link__buttons">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => setPreviewLink(newLink)}
                            disabled={!newLink}
                        >Preview</button>

                        <button
                            className="btn btn-sm btn-success"
                            onClick={() => setLink(newLink)}
                            disabled={!newLink}
                        >Keep</button>

                        <button
                            className="btn btn-sm btn-info"
                            onClick={() => {
                                setPreviewLink(link);
                                setNewLink("")
                            }}
                            disabled={!previewLink !== link}
                        >Clear</button>

                        <button
                            className="btn btn-sm btn-info"
                            onClick={close}
                        >Cancel</button>
                        
                    </div>
                </div>
            </div>
        )
}

export default ImageLink;
