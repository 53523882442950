import React, { useState } from "react";
import { Chart } from "react-google-charts";

const StatusesStats = ({
    sortedRoster,
    studentsData,
    statuses,
    close = null,
    fullMode = false

}) => { 

    const [ viewByRoster, setViewByRoster ] = useState(true);

    const processStudent = (student, statusCounts) => { 
            
        if ( student.status_is_active ) {
            activeCount++;
        } else {
            inactiveCount++;
        }

        statusCounts[student.status_id]++;
    }

    let inactiveCount = 0;
    let activeCount = 0;

    let statusCounts = {};
    statuses.forEach( (status) => statusCounts[status.id] = 0 );

    if ( viewByRoster ) {
        sortedRoster.forEach( (student) => processStudent(student, statusCounts) );
    } else {
        studentsData.forEach( (student) => processStudent(student, statusCounts) );
    }

    
    let activityData = [
        ["Percent", "Count"],
        ["Active", activeCount],
        ["Inactive", inactiveCount]
    ];

    // status counts to array so can sort from most to least
    let statusCountsArray = [];
    for ( let status of statuses )
        statusCountsArray.push([status.title, statusCounts[status.id]]);

    const data = [
        ["Status", "Count"]
      ];

      statusCountsArray
        .sort( (a, b) => a[1] < b[1] ? 1 : -1 )
        .forEach( (status) => {
            data.push([status[0], status[1]]);
        });
      
    const options = {
        title: "Total Students: " + (viewByRoster ? sortedRoster.length : studentsData.length),
      };

      console.log(data);
    
    return (
        <div className="row layer layer-1 window_400">
            <div className={`col-${fullMode ? '6' : '12'}`}>
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="300px"
                    data={data}
                    options={options}
                />
            </div>
            {
                fullMode &&
                    <div className="col-6">
                        <Chart
                            chartType="PieChart"
                            data={activityData}
                            options={{
                                title: `Active/Inactive`,
                            }}
                            width={"100%"}
                            height={"300px"}
                        />
                    </div>
            }
            {
                close &&
                    <div className="col-12 text-center mt-2">
                        <button
                            className="btn btn-primary"
                            onClick={close}
                        >Close</button>
                    </div>
            }
        </div>
    )
}

export default StatusesStats;