import TABService from './TABService';
import Helpers from '../helpers/Helpers';

class TicketService extends TABService {

    model = 'ticket';

    TICKET_TYPE_ASSIGNMENT = '1';
    TICKET_TYPE_ONEONONE = '2';
    TICKET_TYPE_AP_CHKIN = '3';
    TICKET_TYPE_CODE_REVIEW = '4';
    TICKET_TYPE_BUG = '5';
    TICKET_TYPE_EXAM_REVIEW = '6';

    TICKET_SOURCE_DM = 1;
    TICKET_SOURCE_DISCORD = 2;
    TICKET_SOURCE_DOJO_HALL = 3;
    TICKET_SOURCE_CALENDLY = 4;
    TICKET_SOURCE_EMAIL = 5;
    TICKET_SOURCE_ASSIGNMENT_REVIEW = 6;

    TICKET_STATUS_OPEN = 1;
    TICKET_STATUS_CLOSED = 2;

    _hideTicketInfo = ( student ) => {
        return({
            ...student,
            student_name: Helpers.privatizeName(student.student_name),
            staff_email: '***@codingdojo.com'
        })
    }

    search = ( query ) => { /* key/value pairs */
        return new Promise((resolve, reject) => {
            this._post(
                `${this.model}/search`,
                query
            )
            .then ( searchResults => { 
                if (Helpers.getPrivacyMode()) {
                    searchResults.results = searchResults.results.map( ticket => {
                        return this._hideTicketInfo(ticket)
                    })
                }
                resolve(searchResults);
            })
            .catch( err => reject(err))
        })
    }

    getStarterData = () => {
        this.search(this.TICKET_STATUS_CLOSED)
    }

    close = ( ticket_id, total_time_open ) => 
        this._post(
            `${this.model}/close`,
            {
                ticket_id,
                total_time_open
            }
        )

    escalate = ( ticket_id, comment ) => {
        return this._post(
            `${this.model}/escalate`,
            { ticket_id, comment }
        )
    }

    markResolved = ( ticketId ) => {
        return this._get(
            `${this.model}/resolve/${ticketId}`
        )
    }
    
    open = ( studentId, contactSource ) => {
        return this._get(
            `${this.model}/open/${studentId}/${contactSource}`
        )
    }

    pullTicket = ( ticketId ) => { 
        return new Promise((resolve, reject) => {
            this._get(
                `${this.model}/${ticketId}`
            )
            .then ( ticket => {
                resolve(Helpers.getPrivacyMode() ?
                    {
                        ...ticket,
                        student: {
                            ...ticket.student,
                            full_name: Helpers.privatizeName(ticket.student.full_name),
                            last_name: ticket.student.last_name.substring(0,1) + ".",
                            email_address: "***@***.com"
                        },
                        closed_by_email_address: "***@***.com",
                        closed_by_name: "Staff",

                        opened_by_email_address: "***@***.com",
                        opened_by_name: "Staff",

                        instructor_email_address: "***@***.com",
                        instructor_name: "Instructor"

                    } : ticket);
            })
            .catch( err => reject(err)) 
        })
    }
}

export default new TicketService();