import React, { useEffect, useState } from "react";
import commentService from "../../services/CommentService";
import AddComment from './AddComment';
import helpers from "../../helpers/Helpers";
import { Link, useSearchParams } from "react-router-dom";
import LinkToClipboard from "../elements/LinkToClipboard";

const CommentsBrowser = ( { 
                            studentId = null, 
                            ticketId = null,
                            commentAdded = null,
                            getRecent = false
                        } ) => {

    const [ comments, setComments ] = useState(null);
    const [search, setSearch] = useSearchParams();

    useEffect( () => {

        if ( getRecent ) {

            commentService.getRecent(
                ticketId, 
                studentId)
                .then( comments => {
                    setComments( comments )
                })
                .catch( err => console.log(err) )

        } else {

            commentService.search(
                ticketId, 
                studentId)
                .then( comments => {
                    setComments( comments )
                })
                .catch( err => console.log(err) )
        }


    }, [])

    const onAddComment = ( newComment ) => {
        setComments([newComment, ...comments]);
        commentAdded(newComment);
    }

    const Comment = ( props ) => 
        {
            const { comment, ticket } = props;

            return (
                <div
                    className='col-12  p-2 mt-1 comment'
                    key={`comment_${comment.id}`}
                    >
                    <div>
                        {helpers.formatDate(comment.date_added)}
                        &nbsp; by &nbsp;
                        {comment.poster_name} &nbsp;&nbsp;
                        <LinkToClipboard link={comment.poster_email_address} />
                    
                    </div>
                    <div>
                        
                    </div>
                    <div className='divider'>
                        <div></div>
                    </div>
                    <div className="comment__text">
                        {comment.text}
                    </div>
                    {
                        ticketId === null && comment.ticket_id !== null &&
                                <div className="text-right">
                                    {/* <Link
                                        to={`/student/pull/${studentId}/${comment.ticket_id}`}
                                    >Go To Ticket</Link> */}
                                    <button 
                                        className="btn btn-sm btn-info"
                                        onClick={() => setSearch({ticket:comment.ticket_id})}
                                    >Go To Ticket</button>
                                </div>
                    }
                </div>
            )
        }

    return (
        <div className='col-12'>
            {
                comments === null ?
                    <>
                        loading comments...
                    </>
                    :
                    <div className='row '>
                        {
                            ticketId !== null &&
                                <AddComment addComment={onAddComment} ticketId={ticketId}/>
                        }
                        {
                            comments.length === 0 ?
                                <div className="text-center">No Comments</div>
                                :
                                comments.map( comment => 
                                    <Comment 
                                        key={`comment_browser_comment_${comment.id}`}
                                        comment={comment} 
                                        ticketId={ticketId}
                                    /> 
                                )
                        }
                    </div>
            }
        </div>
    )
}

export default CommentsBrowser;