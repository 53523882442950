/*
    For sorting single assignments

    sortPattern = {
        assignment_id: int,
        sortType: SORT_TYPES 
    }

    NOT YET IMPLEMENTED
*/

import Sorter, { SORT_TYPES } from "./Sorter";

class AssignmentSorter extends Sorter {

    constructor( sortPattern ) {
        super('assignment_sorter');
        this.sortPattern = sortPattern;
    }

    sort = ( roster, studentsData ) => 

        [...roster].sort( (a, b) => {
            let a_status = parseInt(studentsData[a.id]['assignments'][this.sortPattern['assignment_id']].status);
            let b_status = parseInt(studentsData[b.id]['assignments'][this.sortPattern['assignment_id']].status);
            
            return this.sortPattern['sortType'] === SORT_TYPES.ASC ?
                a_status > b_status ? 1 : a_status < b_status ? -1 : 0
                :
                a_status < b_status ? 1 : a_status > b_status ? -1 : 0
        });
}

export default AssignmentSorter;