import React, { useState } from 'react';
import SearchTickets from './SearchTickets';

export const WIKIBROWSERMODES = {
    search: 0,
    add: 1,
}

const SearchTicketBrowser = (props) => {

    const [ mode, setMode ] = useState(WIKIBROWSERMODES.search)

    return (
        <div className='row justify-content-center layer layer-1'>
            <div className='col-12 text-center header-text'>
                Search Tickets
            </div>
            <div className='col-8 layer layer-2'>
                {
                    <SearchTickets setBrowserMode={setMode}/>
                }
            </div>
        </div>
    )
}

export default SearchTicketBrowser;