import React, { useState } from "react";
import LoadingSpinner from "../modal_assets/LoadingSpinner";
import ticketService from "../../services/TicketService";
import useOverlays, { OVERLAY_TYPES } from "../../hooks/useOverlays";

const EscalatePanel = ( { 
    ticket,
    closeTicket, 
    updateTicket 
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    const [ comment, setComment ] = useState('');
    const [ working, setWorking ] = useState(false);

    const onEscalateOnly = (e) => 
        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>You are about to escalate an issue to the instructor. This ticket WILL NOT also be closed.<br/>Do you want to continue?</>,
            respond: (response) => {
                if ( response ) {
                    setWorking(true);
                    updateTicket({
                        ...ticket,
                        escalated: 1
                    })
                    
                    ticketService.escalate(ticket.id, comment)
                        .then(() => popOverlay())
                        .catch(err => console.log(err) )
                        .finally(() => setWorking(false))
                }
            }
        })

    const onEscalateAndClose = (e) => {

        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>You are about to escalate an issue to the instructor. This ticket will also be closed.<br/>Do you want to continue?</>,
            respond: (response) => {
                if ( response ) {
                    setWorking(true);
                    ticketService.escalate(ticket.id, comment)
                        .then(() => {
                            updateTicket({
                                ...ticket,
                                escalated: 1
                            })
                            closeTicket();
                            popOverlay();
                        })
                        .catch(err => console.log(err) )
                        .finally(() => setWorking(false))
                }
            }
        })

    }

    return (
        <div id="escalate_panel" className="row layer layer-1">
            <div className="col-12">
                <p>
                    Please leave a comment outlining your concerns. 
                    This comment be sent to the instructor.
                </p>
            </div>
            <div className="col-12">
                <textarea
                    onChange={(e) => setComment(e.target.value)}
                    ></textarea>
            </div>
            <div className="col-12">
                <button 
                    className="btn btn-sm btn-primary"
                    onClick = {onEscalateAndClose}
                    disabled={comment.length === 0}
                >Escalate and Close</button>
                <button 
                    className="btn btn-sm btn-warning ml-2"
                    onClick = {onEscalateOnly}
                    disabled={comment.length === 0}
                >Escalate Only</button>
                <button 
                    className="btn btn-sm btn-danger ml-2"
                    onClick={(e) => 
                        pushOverlay({
                            type: OVERLAY_TYPES.CONFIRM,
                            position: { x: e.clientX, y: e.clientY},
                            component: <>Are you sure you want to cancel?</>,
                            respond: (response) => {
                                if ( response ) popOverlay();
                            }
                        })}
                >Cancel</button>
            </div>

            {
                working &&
                    <LoadingSpinner />
            }
        </div>
    )
}

export default EscalatePanel;