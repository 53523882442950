import React from 'react';

export const BELT_LEVELS = {
    NOT_SUBMITTED: -4,
    OPEN: -3,
    SUBMITTED: -2,
    FAILED: -1,
    YELLOW: 0,
    RED: 1,
    BLACK: 2,
    ORANGE: 3,
    WHITE: 3,
}

const BeltDisplay = ({ 
    belt_level, 
    belt_score
}) => { 

    let belt = '';
    if (belt_level !== null) {
        if ( belt_level === BELT_LEVELS.SUBMITTED ) {
            belt = 'Submitted';
        }else if ( belt_level === BELT_LEVELS.OPEN) {
            belt = 'Open';
        } else if ( belt_level === BELT_LEVELS.NOT_SUBMITTED) {
            belt = 'Not Submitted';
        } else if ( belt_level === BELT_LEVELS.FAILED || (belt_score === null) ) {
                belt = 'Failed';
        } else {
            belt = ['Yellow', 'Red', 'Black', 'Orange', 'White'][belt_level];
        }
    } 

    return (
        <div className={`
                mt-1
                ${belt !== "" ? "belt" : ''}
                belt--${belt.replace(" ", "_").toLowerCase()}
                assign_attend__percent_col--filters 
                ml-1 
                mr-2
            `}
        >
            {   
                belt ?
                    belt === 'Submitted' || belt==="Failed" || belt==="Open" || belt==="Not Submitted" ?
                        belt
                    :
                    <>
                        {belt}
                        &nbsp;:&nbsp;
                        {belt_score}
                    </>
                    :''
            }
        </div>
    )
}

export default BeltDisplay;