import React, { useContext, useState } from 'react';
import MyCohortContext from '../store/MyCohortContext';

const useRoster = () => {

    const {
        sortedRoster,
        selectedStudents,
        setSelectedStudents,
        studentsData,
        hoveredStudentId,
        setHoveredStudentId,
    } = useContext(MyCohortContext);

    // pass pre-filtered list of students to this function
    const selectStudents = (studentIds) => setSelectedStudents(studentIds);
    const clearSelectedStudents = () => setSelectedStudents([]);

    const addSelectedStudent = (studentId) => {
        if (! selectedStudents.includes(studentId)) {
            setSelectedStudents([...selectedStudents, studentId]);
        }
    }

    const checkStudentSelected = (studentId) => selectedStudents.includes(studentId);

    const removeSelectedStudent = (studentId) => setSelectedStudents(selectedStudents.filter(id => id !== studentId));

    const clearHoveredStudent = () => setHoveredStudentId(null);

    return {
        sortedRoster,
        selectedStudents,
        addSelectedStudent,
        removeSelectedStudent,
        checkStudentSelected,
        selectStudents,
        studentsData,
        clearSelectedStudents,
        hoveredStudentId,
        setHoveredStudentId,
        clearHoveredStudent,
    }
}

export default useRoster;