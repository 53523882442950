import React from "react";

export const MANUAL_URL = "https://sites.google.com/codingdojo.com/tab2-manual/";

export const MANUAL_LINKS = {
    STATS_AND_CHARTS: 'cohort-sheets/charts-and-stats',
    ASSIGNMENTS_SHEET: 'cohort-sheets/assignments-sheet',
    EXAMS_SHEET: 'cohort-sheets/exams-sheet',
    STATUSES: 'cohort-sheets/the-roster#h.ewahf1gnf5xn'
}

export const ManualHelper = ({linkSlug}) => { 

    return (
        <span
            onClick={() => {
                window.open(MANUAL_URL + linkSlug, "_blank");
            }}
            data-toggle="tooltip" 
            title="Click for more info"

        >
            <span className="infolink" ></span>
        </span>
    )
}

export default ManualHelper;