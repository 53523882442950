import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../store/AppContext';
import eventHandler, { CLOSE_OVERLAY, OVERLAY_CLOSE_CLICK } from '../../events'

const OverlayWrapper = () => {

    const { viewTicket, setViewTicket } = useContext(AppContext);

    useEffect( () => {
        eventHandler.on( CLOSE_OVERLAY, clearOverlay );

        return () => eventHandler.remove( CLOSE_OVERLAY, clearOverlay )
    } , [])

    const clearOverlay = () => {
        setViewTicket(null)
    }

    if ( viewTicket === null || viewTicket === undefined )
        return (<></>)

    return (
        <div id='overlay_wrapper'>
            <div className='row'>
                <div className='col-12'>
                    <div className='row layer layer-2 p-2'>
                        <div className='col-12'>
                            {
                                <div className='row'>
                                    <div className='col-12 text-right'>
                                        <button
                                            className='btn btn-danger btn-sm'
                                            onClick={() => eventHandler.dispatch( OVERLAY_CLOSE_CLICK )}
                                        >X</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='row layer layer-1 p-2 overlay_wrapper__active_area'>
                        <div className='col-12'>
                            {viewTicket}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverlayWrapper;