import React, {useState} from 'react';
import MyCohortFilterButton from '../MyCohortFilterButton';

const FiltersSelector = ({ 
    currentFilters,
    applyFilters
}) => {

    const [filters, setFilters] = useState(currentFilters);

    const toggleFilter = ( reviewType, checked ) => {
        let newFilters = [...filters];
        if ( checked ) {
            newFilters.push(reviewType);
        } else {
            newFilters = newFilters.filter( filter => filter !== reviewType );
        }
        setFilters(newFilters);
    }

    return(
        <div id="filters_selector" className='row '>
            <div className='col-12 text-center'>
                Select one or more statuses
            </div>
            <div className='divider col-12 mt-1 mb-2'><div></div></div>

            <div className='col-6'>
                <div className='review_filter__status assignment_reviews__column-status_0'>
                    <input
                        type="checkbox"
                        checked={filters.includes(0)}
                        onChange={ (e) => toggleFilter(0, e.target.checked) }
                    />
                    
                    Unsubmitted
                </div>
            </div>
            <div className='col-6'>
                <div className='review_filter__status assignment_reviews__column-status_1'>
                    <input
                        type="checkbox"
                        checked={filters.includes(1)}
                        onChange={(e) => toggleFilter(1, e.target.checked)}
                    />
                    Submitted
                </div>
            </div>
            <div className='col-6'>
                <div className='review_filter__status assignment_reviews__column-status_3'>
                    <input
                        type="checkbox"
                        checked={filters.includes(3)}
                        onChange={(e) => toggleFilter(3, e.target.checked)}
                    />
                    
                    Checked
                </div>
            </div>
            <div className='col-6'>
                <div className='review_filter__status assignment_reviews__column-status_4'>
                    <input
                        type="checkbox"
                        checked={filters.includes(4)}
                        onChange={(e) => toggleFilter(4, e.target.checked)}
                    />
                    
                    Needs Follow-Up
                </div>
            </div>
            <div className='col-12 text-center'>
                <div className='review_filter__status assignment_reviews__column-status_2'>
                    <input
                        type="checkbox"
                        checked={filters.includes(2)}
                        onChange={(e) => toggleFilter(2, e.target.checked)}
                    />
                    
                    Needs Review (LP3)
                </div>
            </div>
            <div className='col-12 text-center mt-3'>
                <MyCohortFilterButton
                    prompt={'Apply'}
                    onClick={() => applyFilters(filters)}
                    disabled={filters.length === 0}
                />

                <span className='ml-2'>
                    <MyCohortFilterButton
                        prompt={'Clear Filters'}
                        onClick={() => applyFilters([])}
                    />  
                </span>

                <span className='ml-2'>
                    <MyCohortFilterButton
                        prompt={'Cancel'}
                        onClick={() => {
                            applyFilters(null);
                        }}
                    />  
                </span>

            </div>
        </div>
    )
}

export default FiltersSelector;