import React from "react";
import { Chart } from "react-google-charts";
import { ASSIGNMENT_REVIEW_STATUS } from '../../../../helpers/Constants';

const AssignmentStats = ({ 
    close,
    filteredStudentsData,
    assignmentId
 }) => { 

    let submitted = 0;
    let onTimeOffsetTotalDays = 0;
    let assignmentName = ""; // get this when processing the data
    let daysBreakDown = {
        "30+ days late": 0,
        "15 to 30 days late": 0,
        "7 to 14 days late": 0,
        "3 to 6 days late": 0,
        "1 to 2 days late": 0,
        "0 days late": 0,
        "1 to 2 days early": 0,
        "3 to 6 days early": 0,
        "7 to 14 days early": 0,
        "15 to 30 days early": 0,
        "30+  days early": 0,
    }

    filteredStudentsData.forEach( student => { 
        if ( student.assignments[assignmentId] ) { 
            assignmentName = student.assignments[assignmentId].assignment.title;

            if ( student.assignments[assignmentId].status !== ASSIGNMENT_REVIEW_STATUS.NO_ACTIVITY) {

                submitted++;
                let onTimeOffsetDays = parseInt(Math.ceil(student.assignments[assignmentId].onTimeOffset / (1000 * 60 * 60 * 24)));

                if ( onTimeOffsetDays > 30 ) daysBreakDown["30+ days early"]++;
                else if (onTimeOffsetDays > 14) daysBreakDown["15 to 30 days early"]++;
                else if (onTimeOffsetDays > 6) daysBreakDown["7 to 14 days early"]++;
                else if (onTimeOffsetDays > 2) daysBreakDown["3 to 6 days early"]++;
                else if (onTimeOffsetDays > 0) daysBreakDown["1 to 2 days early"]++;
                else if (onTimeOffsetDays === 0) daysBreakDown["0 days late"]++;
                else if (onTimeOffsetDays < -30) daysBreakDown["30+ days late"]++;
                else if (onTimeOffsetDays < -14) daysBreakDown["15 to 30 days late"]++;
                else if (onTimeOffsetDays < -6) daysBreakDown["7 to 14 days late"]++;
                else if (onTimeOffsetDays < -2) daysBreakDown["3 to 6 days late"]++;
                else if (onTimeOffsetDays < 0) daysBreakDown["1 to 2 days late"]++;
                
                
                onTimeOffsetTotalDays +=  onTimeOffsetDays;
            }
        }
    });

    let data = [
        ["Percent", "Count"]
    ];

    for ( const[key, value] of Object.entries(daysBreakDown) ) 
        data.push([key, value]);

    let avgOffsetDays = parseInt(onTimeOffsetTotalDays / submitted);

    return (
        <div 
            id="assignment_stats"
            className="layer layer-2 row window_400"
            >
                <div className="col-12 text-center mb-2">
                    {assignmentName} &nbsp;| &nbsp;
                    {submitted}/{filteredStudentsData.length} ({parseInt(submitted / filteredStudentsData.length * 100)}%)
                    &nbsp;| &nbsp;
                    avg { avgOffsetDays < 1 ? 'late' : 'early'} {Math.abs(parseInt(onTimeOffsetTotalDays / submitted)) } days
                </div>

                <div className="col-12">
                    <Chart
                        chartType="PieChart"
                        data={data}
                        options={{
                            title: `Submitted days early/late`,
                        }}
                        width={"100%"}
                        height={"300px"}
                    />
                </div>
                
                {
                    close &&
                        <div className="col-12 text-center mt-2">
                            <button
                                className="btn btn-info btn-sm"
                                onClick={close}
                            >Close</button>
                        </div>

                }
        </div>
    )

}

export default AssignmentStats;