import React, { useState, createContext, useEffect } from 'react';

import UserService from '../services/UserService';
import wikiService from '../services/WikiService';
import ticketService from '../services/TicketService';
import stackService from '../services/StackService';

const themes = {
    dark: "dark",
    light: "light"
  }

const AppContext = createContext();

function AppProvider({ children }) {

    const [ user, setUser ] = useState(undefined);
    const [ darkTheme, setDarkTheme ] = useState(true);
    const [ wikiData, setWikiData ] = useState({
        tags: [],
        stacks: []
    })

    const [ viewTicket, setViewTicket ] = useState(null); // { ticketId, ticketUpdated }
    const [ isInPrivacyMode, setIsInPrivacyMode ] = useState(false);

    const [ stackList, setStackList ] = useState([]); // array of raw stack objects
    const [ stackObj, setStackObj ] = useState({}); // { stackId: stackName } [stackId] = stackName
    
    const [ overlaysStack, setOverlaysStack ] = useState([]); // 
    const [ overlaysListeners, setOverlaysListeners ] = useState({}); //

    const [ trivjo, setTrivjo ] = useState({});


    useEffect(() => {

        /* handle initial set user */
        if ( window.localStorage.getItem('access_token') !== null && window.localStorage.getItem('refresh_token') !== null) {
            UserService.getUser()
                    .then( resp => {setUser(resp)})
                    .catch( err => console.log(err))
        } else {
            setUser(null);
        }
    }, [])


    // load items needed once the user is logged in
    useEffect(() => {
        if (!user) return;

        wikiService.getStarterData()
            .then( resp => {
                setWikiData(resp);
            })
            .catch( err => console.log(err))

        // load stacks
        stackService.getStacks()
            .then( resp => {
                setStackList(resp);
                let newStackObj = {};
                for (const stack of resp) {
                    let finalName = stack.name;
                    if ( stack.id == 38 ) finalName = "PB"; // Programming Basics
                    if ( stack.id == 99 ) finalName = "WF"; // Programming Basics
                    newStackObj[stack.id] = finalName;
                }
                setStackObj(newStackObj);
            })
            .catch( err => console.log(err))
    }, [user])

    useEffect(() => { //TODO: what does this do?
        if (user) {
            ticketService.search()
        }
    },[])

    useEffect(() => {
        if (viewTicket === null) document.body.classList.remove('modal-open')
        else document.body.classList.add('modal-open')
    },[viewTicket])

    /* handle theme */
    // On mount, read the preferred theme from the persistence;
    useEffect(() => {
        const isDark = !localStorage.getItem('dark') || localStorage.getItem('dark') === 'true'
        setDarkTheme(isDark)
    }, [darkTheme])

    // To toggle between dark and light modes
    const toggle = () => {
        const isDark = !darkTheme
        localStorage.setItem('dark', JSON.stringify(isDark))
        setDarkTheme(isDark)
    }

    const theme = darkTheme ? themes.dark : themes.light

    // privacy mode
    const setPrivacyMode = (mode) => {
        setIsInPrivacyMode(mode);
        if ( mode ) {
            window.localStorage.setItem('is_in_privacy_mode', mode)
        } else {
            window.localStorage.removeItem('is_in_privacy_mode')
        }
    };
    const privacyMode = (text=null, email=null, firstName=null, lastName=null) => {
        if (isInPrivacyMode) {
            if (text)
                return '***'

            if ( email )
                return '***@***.com'

            return `${firstName ? firstName[0] + '.' : ''}${lastName ? lastName[0] + '.' : ''}}`
        } 
    
        if ( text || email )
            return text || email 

        return `${firstName || ''} ${lastName || ''}`;
    }

    useEffect(() => {
        if ( window.localStorage.getItem('is_in_privacy_mode') === null )
            window.localStorage.setItem('is_in_privacy_mode', false)

        setIsInPrivacyMode(window.localStorage.getItem('is_in_privacy_mode') === 'true')
    }, []);

    return (
        <AppContext.Provider value= {{
            user,
            setUser,
            theme: {theme, darkTheme, toggle },
            wikiData,
            viewTicket,
            setViewTicket,
            isInPrivacyMode,
            setPrivacyMode,
            privacyMode,
            stackList,
            stackObj,
            overlaysStack,
            setOverlaysStack,
            overlaysListeners,
            setOverlaysListeners,
            trivjo,
            setTrivjo,
        }}>
            { children }
        </AppContext.Provider>
    )
}

export  { AppProvider, AppContext };