import { useSearchParams } from 'react-router-dom';

const useBtrSearchParams = () => {
    const [search, setSearch] = useSearchParams();

    const deleteParam = (param) => {
        search.delete(param);
        setSearch(search);
    }

    return [search, setSearch, deleteParam];
}

export default useBtrSearchParams;