import TABService from './TABService';

class TrivjoAnswerService extends TABService {

    model = 'tj_answer';

    getByQuestionId = (questionId) => this._get(`${this.model}/by-question/${questionId}`);

}

const trivjoAnswerService = new TrivjoAnswerService();
export default trivjoAnswerService;