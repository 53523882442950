import React from "react";
import ActionButtons, { SortButton } from "../elements/ActionButtons";

const SemToolsStudents = ({
    students
}) => {

    return (
        <div id="sem_tools_students">
            <table
                className="table table-striped table-sm text-light"
            >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Probations</th>
                        <th>Stack</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        students.map((student, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {student.first_name} {student.last_name}
                                    </td>
                                    <td className={`sem_data__status_${student.status_slug}`}>
                                        {student.status}
                                    </td>
                                    <td>
                                        {student.probations}
                                    </td>
                                    <td>{student.stack} ({new Date(student.cohort_start_date).toISOString().slice(0, 10)} - {new Date(student.cohort_end_date).toISOString().slice(0, 10)})</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default SemToolsStudents;