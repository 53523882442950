import React, { useContext } from "react";
import { AppContext } from "../../../store/AppContext";
import TrivjoEditLibrary from "./TrivjoEditLibrary";
import useOverlays from "../../../hooks/useOverlays";

const TrivjoViewLibrary = ( {
    library,
    updateLibrary,
    isInPlayMode = false,
} ) => { 

    const { user } = useContext(AppContext);

    const { popOverlay } = useOverlays();

    return (
        <div 
            id="tivjo_view_library"
            className="row window_1400"
            >
            {
                user.taber_id === library.taber_id ?
                    <div className="col-12">
                        <TrivjoEditLibrary
                            library={library}
                            updateLibrary={updateLibrary}
                            isInPlayMode={isInPlayMode}
                        />
                    </div>
                    :
                    <>
                        <div className="col-12">
                            {library.name}
                        </div>
                        <div className="col-12">
                            <button 
                                className="btn btn-sm btn-secondary"
                                onClick={popOverlay}
                            >Cancel</button>
                        </div>
                    </>
            }
        </div>
    )
}

export default TrivjoViewLibrary;