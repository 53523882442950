import React, { useContext} from 'react';
import filterSorter from './filter_sorter/MyCohortFilterSorter';
import ActionButtons, { Label } from '../elements/ActionButtons';
import MyCohortContext from '../../store/MyCohortContext';
import { SHEETS } from '../../helpers/Constants';
import useOverlays, { OVERLAY_TYPES } from '../../hooks/useOverlays';
import useRoster from '../../hooks/useRoster';
import MyCohortEOSStats from './mycohort_stats/MyCohortEOSStats';

const MyCohortSheetSelector = ({ 
}) => {

    const {
        selectedSheet,
        setSelectedSheet,
        inStatsMode,
        setInStatsMode,
        cohortCalendar,
        sheetsData,
    } = useContext(MyCohortContext);

    const { pushOverlay, popOverlay } = useOverlays();

    const { sortedRoster } = useRoster();

    return (
        <div id="sheet_selector" className="row">
            <div className='col-8 text-center'>

                <button
                    className={`general_btn ${selectedSheet === SHEETS.ASSIGNMENTS ? 'btn-success' : ''}`}
                    disabled={selectedSheet === SHEETS.ASSIGNMENTS}
                    onClick={() => setSelectedSheet(SHEETS.ASSIGNMENTS)}
                >Assignments</button>

                <button
                    className={`general_btn ${selectedSheet === SHEETS.ATTENDANCE ? 'btn-success' : ''}`}
                    disabled={selectedSheet === SHEETS.ATTENDANCE}
                    onClick={() => setSelectedSheet(SHEETS.ATTENDANCE)}
                >Attendance</button>

                <button
                    className={`general_btn ${selectedSheet === SHEETS.EXAMS ? 'btn-success' : ''}`}
                    disabled={selectedSheet === SHEETS.EXAMS}
                    onClick={() => setSelectedSheet(SHEETS.EXAMS)}
                >Exams</button>

                <button
                    className={`general_btn ${selectedSheet === SHEETS.SEM ? 'btn-success' : ''}`}
                    disabled={selectedSheet === SHEETS.SEM}
                    onClick={() => setSelectedSheet(SHEETS.SEM)}
                >SEM</button>

                <button
                    className={`general_btn ${selectedSheet === SHEETS.INSTRUCTOR ? 'btn-success' : ''}`}
                    disabled={selectedSheet === SHEETS.INSTRUCTOR}
                    onClick={() => setSelectedSheet(SHEETS.INSTRUCTOR)}
                >Instructor</button>

                <button
                    className='btn btn-sm btn-warning'
                    onClick={() => filterSorter.clear()}
                    disabled={!filterSorter.isActive()}
                >Clear Filtering</button>
            </div>
                            
            <div className='col-4'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <ActionButtons
                            buttons={[
                                Label({
                                    text: 'Stats Mode',
                                    bgColor: 'warning',
                                    textColor: 'dark',
                                    onClick: () => setInStatsMode(!inStatsMode)
                                }),
                                Label({
                                    text: 'Cohort Stats',
                                    bgColor: 'warning',
                                    textColor: 'dark',
                                    onClick: () => pushOverlay({
                                        type: OVERLAY_TYPES.MODAL,
                                        component: <MyCohortEOSStats
                                            sortedRoster={sortedRoster}
                                            studentsData={filterSorter.studentsData}
                                            cohortCalendar={cohortCalendar}
                                            sheetsData={sheetsData}
                                        />,
                                        close: popOverlay,
                                    })
                                }),
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default MyCohortSheetSelector;   