import React from 'react';
import PercentDisplay from './PercentDisplay';
import { DATE_GROUPS } from '../attendance_sheet/AttendanceSheet';

const StudentRowAttendance = ({
    student,
    dateGroup = DATE_GROUPS.TODATE
}) => { 

    return (
        <div
            className={`
                student_row_attendance
                ml-1
                noselect
                ${student.status_is_active && student["missed_attendance"] > 3 ? 'bg-danger text-light p-1' : ''}
                `}
            
                data-toggle="tooltip"
                title={`${student["first_name"]} ${student["last_name"]} has ${student["missed_attendance"]} consecutive absences.`}
                
        >
            <div>
                <PercentDisplay
                    percent={parseFloat(
                        student[
                            dateGroup === DATE_GROUPS.TODATE ? 
                                'attendance_to_date_percent'
                                :
                                dateGroup === DATE_GROUPS.OVERALL ?
                                    'attendance_overall_percent'
                                    :
                                    `week_${1}_attendance_percent`
                        ]
                    )}
                    bottomPercent={.69}
                />
            </div>
            <div>
                {
                    student.status_is_active ? student.missed_attendance : 0
                }
            </div>
        </div>
    )
}

export default StudentRowAttendance;