import TABService from './TABService';

class AppService extends TABService {

    model = 'app'

    getDashboardData = () =>
        this._get(
            `${this.model}/dashboard`
        )
}

export default new AppService();