import './App.css';
import { useContext } from 'react';
import {BrowserRouter, Routes, Route, Navigate, Outlet} from "react-router-dom";
import { AppContext } from './store/AppContext';
import Login from './components/authentication/Login';
import Navigation from './components/navigation/Navigation';
import PullStudent from './components/pull_student/PullStudent';
import MyTickets from './components/MyTickets';
import WikiBrowser from './components/wiki/WikiBrowser';
import OpenTickets from './components/OpenTickets';
import SearchTicketsBrowser from './components/searchTickets/SearchTicketsBrowser';
import Dashboard from './components/dashboard/Dashboard';
import OverlayWrapper from './components/elements/OverlayWrapper';
import MyCohort from './components/my_cohort/MyCohort';
import Admin from './components/admin/Admin';
import useUserAccess from './hooks/useUserAccess';
import TestComponent from './components/testing/TestComponent';
import useOverlays from './hooks/useOverlays';
import Trivjo from './components/trivjo/Trivjo';
import SemTools from './components/sem_tools/SemTools';

const ProtectedRoute = ({
  children,
  hasAccess
}) => {
  
  if(hasAccess === false) {
      return <Navigate to="/" replace />
  }
  return children;

};

function App() {

  const { user, theme } = useContext(AppContext);
  const { displayOverlays } = useOverlays();
  const [ USER_LEVELS, checkAccess ] = useUserAccess();
  
  return (
    <div className='container-fluid'>
      <link rel="stylesheet" type="text/css" href={`/css/theme-${theme.theme}.css`} /> 
      {
        user === undefined ?
          <></>
          : user === null ?
            <Login/>
            :
            <BrowserRouter>
            <div id='navigation' >
              <Navigation />
            </div>
                <div id='main-content'>
                <Routes>
                  <Route path="/" element={<Dashboard />}/>
                  <Route path="/student/pull/" element={ <PullStudent /> } />
                  <Route path="/student/pull/:student_id" element={ <PullStudent /> } />
                  <Route path="/student/pull/:student_id/:ticket_id" element={ <PullStudent /> } />
                  <Route path="/my-tickets" element={ <MyTickets /> } />
                  <Route path="/open-tickets" element={ <OpenTickets /> } />
                  <Route path="/search-tickets" element={ <SearchTicketsBrowser /> } />
                  <Route path="/dojooverflow" element={ <WikiBrowser /> } />
                  <Route path="/trivjo" element={ 
                    <ProtectedRoute hasAccess={checkAccess(USER_LEVELS.LEE)}>
                        <Trivjo />
                    </ProtectedRoute>
                  } />
                  <Route path="/assignment-reviews" element={ 
                      <MyCohort /> 
                  } />
                  <Route path="/my-cohort" element={<MyCohort />}/>
                 

                  <Route path="/admin" element={ 
                    <ProtectedRoute hasAccess={checkAccess(USER_LEVELS.ADMIN)}>
                      <Admin />
                    </ProtectedRoute>
                  } />


                  <Route path="/sem-tools" element={ 
                    <ProtectedRoute hasAccess={checkAccess(USER_LEVELS.LEAD)}>
                      <SemTools />
                    </ProtectedRoute>
                  } />

                  <Route path="/testing" element={ 
                    <ProtectedRoute hasAccess={checkAccess(USER_LEVELS.ADMIN)}>
                      <TestComponent />
                    </ProtectedRoute>
                  } />

                </Routes>
              </div>
                  <OverlayWrapper />

                  {
                    displayOverlays()
                  }
            </BrowserRouter>
      }
    </div>
  );
}

export default App;
