import React, { useState, useEffect } from "react";
import TrivjoAnswers from "../answers/TrivjoAnswers";
import FrameBuilder from "../question_frame/FrameBuilder";
import useOverlays, { ConfirmDialogBox, OVERLAY_TYPES } from "../../../hooks/useOverlays";
import trivjoQuestionService from "../../../services/TrivjoQuestionService";
import useTrivjo, { PLAY_STATES } from "../../../hooks/useTrivjo";

const TrivjoQuestionBuilder = ({ 
    question: defaultQuestion,
    addQuestion,
    updateQuestion,
    removeQuestion,
    library,
    isInPlayMode = false,
    
} ) => { 

    const { pushOverlay, popOverlay } = useOverlays();
    const { updatePlayState } = useTrivjo();

    const [ question, setQuestion ] = useState(defaultQuestion);
    const [ editQuestion, setEditQuestion ] = useState(defaultQuestion);

    useEffect(() => { 
        if ( isInPlayMode ) updatePlayState(PLAY_STATES.PRE_QUESTION);
    }, [])

    const close = () => popOverlay();

    const createQuestion = () => {
        trivjoQuestionService.create({
            points: editQuestion.points,
            image_url: editQuestion.image_url,
            text: editQuestion.text,
            time: editQuestion.time,
            tj_library_id: library.id,
            fu_text: editQuestion.fu_text,
            fu_image_url: editQuestion.fu_image_url,
        })
            .then((question) => {
                addQuestion(question);
                setEditQuestion(question);
                setQuestion(question);
            })
            .catch((err) => console.log(err))
    }

    const saveQuestion = (question) => { 
        trivjoQuestionService.update(question)
            .then((updatedQuestion) => { 
                updatedQuestion = {...updatedQuestion, ...question }; // merge with existing question to preserve temporary properties
                console.log(updatedQuestion)
                setQuestion(updatedQuestion);
                setEditQuestion(updatedQuestion);
                updateQuestion(updatedQuestion);
            })
            .catch((err) => console.log(err))
    }

    const deleteQuestion = () => {
        trivjoQuestionService.destroy(editQuestion.id)
            .then(() => {
                removeQuestion(editQuestion);
                close();
            })
            .catch((err) => console.log(err))
            .finally(() => close());
    }

    const hasBeenUpdated = JSON.stringify(editQuestion) !== JSON.stringify(question);
    
    return (
        <div 
            id='trivjo_question_builder' 
            className={`
                row 
                window_${editQuestion.id === 0 ? '600' : '1200'}
                tqb_mode_${editQuestion.id === 0 ? 'add' : 'update'}
                `}>
            <div className={`
            
                col-${editQuestion.id === 0 ? '12':'3'}
                `}>
                <div className="row">
                    <div className="col-12">
                        <div className="text-center">
                            <h5>Question</h5>
                        </div>
                        <FrameBuilder
                            imageUrl={editQuestion.image_url}
                            setImageUrl={(newImage) => {setEditQuestion({ ...editQuestion, image_url: newImage })}}
                            text={editQuestion.text}
                            setText={(newText) => setEditQuestion({ ...editQuestion, text: newText })}
                            disabled={isInPlayMode}
                        />

                    </div>
                    <div className="col-12 mt-4">
                        <div className="row">
                            <div className="col-6">
                                Points:
                                <input
                                    className="form-control"
                                    type='number'
                                    disabled={isInPlayMode}
                                    min={1}
                                    value={editQuestion.points}
                                    onChange={(e) => setEditQuestion({ ...editQuestion, points: parseInt(e.target.value) })}
                                    />   
                            </div>
                            <div className="col-6">
                                Time ( seconds ):
                                    <input
                                        className="form-control"
                                        type='number'
                                        disabled={isInPlayMode}
                                        min={1}
                                        value={editQuestion.time}
                                        onChange={(e) => setEditQuestion({ ...editQuestion, time: parseInt(e.target.value) })}  
                                        />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                editQuestion.id !== 0 &&
                <>
                    <div className="col-6">
                        <div className="text-center">
                            <h5>Answers</h5>
                        </div>
                        <TrivjoAnswers
                            questionId={editQuestion.id}
                            correctAnswerId={editQuestion.correct_answer_id}
                            setSelectedAnswer={(answer) => {console.log(answer); saveQuestion({ ...editQuestion, correct_answer_id: answer.id })}}
                            answerAdded={(answer) =>  { 
                                saveQuestion({ 
                                    ...editQuestion, 
                                    answer_count: editQuestion.answer_count + 1,
                                    correct_answer_id: editQuestion.answer_count == 0  ? answer.id : editQuestion.correct_answer_id
                                 });
                            }}
                            answerRemoved={() => saveQuestion({ 
                                ...editQuestion, 
                                answer_count: editQuestion.answer_count - 1 
                            })}
                            disabled={isInPlayMode}
                        />
                    </div>
                    <div className="col-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <h5>Follow-Up</h5>
                                </div>
                                <FrameBuilder
                                    key="follow_up"
                                    imageUrl={editQuestion.fu_image_url}
                                    setImageUrl={(newImage) => {setEditQuestion({ ...editQuestion, fu_image_url: newImage })}}
                                    text={editQuestion.fu_text}
                                    setText={(newText) => setEditQuestion({ ...editQuestion, fu_text: newText })}
                                    disabled={isInPlayMode}
                                />
                                <div className="text-center">
                                    
                                    {
                                        !isInPlayMode ?
                                            (editQuestion.fu_text === "" || editQuestion.fu_text === null)  && (editQuestion.fu_image_url === "" || editQuestion.fu_image_url === null) ?
                                                <span className="sub_note">Enter info above or the answer will be used for the question follow-up.</span>
                                            :
                                                <button
                                                    className="btn btn-sm btn-info mt-2"
                                                    onClick={() => setEditQuestion({ 
                                                        ...editQuestion,
                                                        fu_text: "", 
                                                        fu_image_url: "" 
                                                    })}
                                                    >Clear Info to Use Answer for Follow-up</button>
                                        :
                                            <></>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }
            
                <div className="col-12 text-center mt-1"> 
                    {
                        !isInPlayMode &&
                            <>
                                <button
                                    className="btn btn-sm btn-success"
                                    disabled={!hasBeenUpdated}
                                    onClick={() => {
                                        if ( editQuestion.id === 0 ) {
                                            createQuestion();
                                        } else {
                                            saveQuestion(editQuestion);
                                        }
                                    }}
                                >
                                    {
                                        question.id === 0 ?
                                            <>Create Question</>
                                            :
                                            <>Save Updates</>
                                    }
                                </button>
                                <button
                                    className="btn btn-sm btn-info ml-2"
                                    onClick={
                                        editQuestion.id === 0 ?
                                            (e) => 
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.CONFIRM,
                                                    position: { x: e.clientX, y: e.clientY},
                                                    component: <>Are you sure you want to cancel adding a question?</>,
                                                    respond: (response) => {
                                                        if ( response )  close();
                                                    }
                                                })
                                        :
                                            (e) => { console.log(hasBeenUpdated)
                                                if ( hasBeenUpdated )
                                                    pushOverlay({
                                                        type: OVERLAY_TYPES.CONFIRM,
                                                        position: { x: e.clientX, y: e.clientY},
                                                        component: <>Are you sure you want to cancel updating this question?</>,
                                                        respond: (response) => {
                                                            if ( response ) close();
                                                        }
                                                    })
                                                else close();
                                            }
                                    }
                                >
                                    {
                                        editQuestion.id === 0 ?
                                            <>Cancel</>
                                            :
                                            <>Finished</>
                                    }
                                </button>
                                {
                                    editQuestion.id !== 0 &&
                                    <button
                                        className="btn btn-sm btn-danger ml-2"
                                        onClick={(e) => 
                                            pushOverlay({
                                                position: { x: e.clientX, y: e.clientY},
                                                component: <ConfirmDialogBox
                                                    component={<>Are you sure you want to delete this question?</>}
                                                    followUpComponent={<>You currently have answers for this question.<br/>Are you sure you want to delete it?</>}
                                                    doubleConfirm={editQuestion.answer_count > 0}
                                                    respond={(response) => {
                                                        if ( response ) deleteQuestion();
                                                    }}
                                                />,
                                            })
                                        }
                                    >Delete</button>
                                }
                            </>
                    }

                    {
                        isInPlayMode &&
                            <GameControls 
                                question={question}
                            />
                    }
                </div>
        </div>
    )
}

const GameControls = ({
    question,
}) => {

    const {
        playState,
        remainingTime,
        giveQuestion,
        cancelQuestion,
        giveFollowUp,
        giveLeaderboard,
        revealAnswer,
     } = useTrivjo();

    const { popOverlay, pushOverlay } = useOverlays();

    const questionFinished = 
        playState === PLAY_STATES.POST_QUESTION ||
        playState === PLAY_STATES.REVEAL_ANSWER ||
        playState === PLAY_STATES.GIVING_FOLLOW_UP ||
        playState === PLAY_STATES.GIVING_LEADERBOARD

    const close = playState === PLAY_STATES.PRE_QUESTION || questionFinished;

    const onCancelQuestion = (e) => {
        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>Are you sure you want to cancel this question?</>,
            respond: (response) => {
                if ( response ) cancelQuestion();
            }
        })
    }

    return (
        <div className="row">
            <div className="col-12 text-centera">
                {
                    playState === PLAY_STATES.PRE_QUESTION &&
                        <>
                            <button
                                className="btn btn-sm btn-success ml-2"
                                onClick={() => giveQuestion(question)}
                            >
                                Give Question
                            </button>
                        </>
                }

                {
                    playState === PLAY_STATES.GET_READY &&
                        <>
                            GET READY!
                        </>
                }

                {
                    playState === PLAY_STATES.DISPLAYING_QUESTION &&
                        <>
                            Displaying question before answers: { remainingTime }
                        </>
                }

                {
                    playState === PLAY_STATES.GIVING_QUESTION &&
                        <>
                            Giving Question: { remainingTime }
                        </>
                }
    
                {
                    questionFinished &&
                        <>
                            <button
                                className="btn btn-sm btn-success mr-4"
                                onClick={() => revealAnswer()}
                            >Reveal Answer</button>

                            <button
                                className="btn btn-sm btn-info mr-4"
                                onClick={giveFollowUp}
                            >Follow-up</button>

                            <button
                                className="btn btn-sm btn-info"
                                onClick={giveLeaderboard}
                            >Leader Board</button>
                        </>
                }

            </div>
            <div className="col-12 text-center mt-2">
                <button
                    className="btn btn-sm btn-info"
                    onClick={
                        close ? popOverlay : onCancelQuestion
                    }
                >
                    {
                        close ? "Close" : "Cancel"
                    }
                </button>
            </div>       
        </div>
    )

}

export default TrivjoQuestionBuilder;