import React from "react";
import spinner from '../../assets/loading_spinner.gif';

const LoadingSpinner = ( {
    isOverlay = false
} ) => {
    
        return (
            <div className={`
                loading_spinner text-center
                ${isOverlay ? 'resp_overlay__inner--cover' : ''}
                `}>
                <img src={spinner} />
            </div>
        )

}

export default LoadingSpinner;