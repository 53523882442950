import React, { useContext } from 'react';

import { AppContext } from '../../store/AppContext';

const TagSelector = (props) => {

    const { selectedTags, setSelectedTags } = props;
    
    const { wikiData } = useContext(AppContext);

    const toggleTag = (tagId) => {

        if ( selectedTags.indexOf(tagId) === - 1 ) {
            setSelectedTags([tagId, ...selectedTags]);
        } else {
            setSelectedTags(selectedTags.filter( t => t !== tagId ))
        }
    }

    return (
        <div id='wiki_tag_selector' className='row justify-content-center'>
            {
                wikiData &&
                    wikiData.tags
                        .sort( (a, b) => a.title > b.title ? 1 : a.title < b.title ? -1 : 0)
                        .map( tag =>
                            <div 
                                className="wiki_tag_selector__tag align-middle mr-1 col-2" 
                                key={`tag_selector_tag_${tag.id}`}
                                >
                                <input 
                                    type="checkbox" 
                                    value={tag.id}
                                    checked={selectedTags.indexOf(tag.id) > -1}
                                    onChange={() => toggleTag(tag.id)}
                                    />
                                &nbsp;{tag.title}
                            </div>
                        )
            }
        </div>
    )
}

export default TagSelector;