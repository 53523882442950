import TABService from './TABService';

class WikiService extends TABService {

    model = 'wiki';

    add = ( wiki ) => 
        this._post(
            `${this.model}/add`, wiki
        )

    getStarterData = () => 
        this._get(
            `${this.model}/starter-data`
        )

    search = (search_text, page = 1, stack_id = null, tag_ids = []) =>
        this._post(
            `${this.model}/search`, {search_text, page, stack_id, tag_ids}
        )
}

export default new WikiService();