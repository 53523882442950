import TABService from './TABService';

class TopicService extends TABService {

    model = 'topic';

    addTopic = ( name, ticket_id ) => {
        return this._post(
            `${this.model}/add`,
            {name, ticket_id}
        )
    }

    attachTopic = ( ticket_id, topic_id ) => {
        return this._post(
            `${this.model}/attach`,
            { ticket_id, topic_id }
        )
    }

    detachTopic = ( ticket_id, topic_id ) => {
        return this._post(
            `${this.model}/detach`,
            { ticket_id, topic_id }
        )
    }
}

export default new TopicService();