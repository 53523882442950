import React, { useEffect, useState, useContext } from "react";
import TracksBrowser from "../ticket/TracksBrowser";
import appService from "../../services/AppService";
import TicketsBrowser from "../tickets_browser/TicketsBrowser";
import { AppContext } from "../../store/AppContext";
import QueryBuilder from "../../helpers/QueryBuilder";
import InstructorNotes from "./instructor_notes/InstructorNotes";

const Dashboard = () => {

    const { user } = useContext(AppContext);

    const [ dashData, setDashData ] = useState(null)

    useEffect( () => {

        appService.getDashboardData()
            .then( data => {
                setDashData(data);
            })
            .catch( err => console.log(err) )
    }, [])

    if ( dashData === null ) return ( <>Loading...</> )

    return (
        <div id="dashboard" className="row">
            <div className="col-3 layer layer-1 p-2">
                <div className="row ">
                    <div className="col-12">
                        <InstructorNotes />
                    </div>
                    <div className="col-12 mt-2">
                        <div className="divider"><div></div></div>
                        <div className="dashboard__side_header mb-2">
                            Useful Links:
                        </div>
                        <ul>
                            <li>
                                <a href="https://sites.google.com/codingdojo.com/cd-main-wiki/home" target="_blank">Company Wiki</a>
                            </li>
                            <li>
                                <a href="https://docs.google.com/spreadsheets/d/16zzM0x9LzCjSkl8tMpaN4bWOuhlVZg-ceI8PuoHmGOg/edit#gid=1176746798" target="_blank">U.S. Programs Calendars</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 mt-2">

                        <div className="divider"><div></div></div>
                        <div className="dashboard__side_header mt-2">
                            Track Browser:
                        </div>
                        <TracksBrowser />
                    </div>
                </div>
            </div>
            <div className="col-9 layer layer-3 ">
                <div className="row mt-2">
                    <div className="col-12">
                        <span className="header-text">Tickets</span>
                        <span className="subheader-text">
                            &nbsp;&nbsp;( You have submitted 
                            {
                                `
                                    ${dashData.tickets.total_tickets}
                                    ticket${dashData.tickets.total_tickets !== 1 ? 's' : ''}
                                `
                            }
                            &nbsp;)
                        </span>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <h5 className="ml-3">My Tickets with Alerts:</h5>
                        <TicketsBrowser 
                                initialTicketQuery={
                                    new QueryBuilder()
                                        .test(`alert_user_id = ${user.id}`)
                                }
                                filter={ { has_alert: 1 }}
                                showName={true}
                                decrementTicketCount={true}
                            />
                        
                    </div>

                    <div className="col-12">
                        <h5 className="ml-3">My Open Tickets:</h5>
                        <TicketsBrowser 
                                initialTicketQuery={
                                    new QueryBuilder()
                                        .test(`staff_id = ${user.id}`)
                                        .and()
                                        .test(`status = 1`)
                                }
                                filter={ { status: 1 } }
                                showName={true}
                                decrementTicketCount={true}
                            />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;