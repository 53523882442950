import React, {useState, useEffect} from "react";
import trivjoLibararyService from "../../../services/TrivjoLibraryService";

const TrivjoCategorySelector = ( { 
    selectedCategoryId, 
    setSelectedCategory,
    disabled=false,
} ) => {

    const [ categories, setCategories ] = useState(null);
    const [ category, setCategory ] = useState();

    useEffect(() => { 
        trivjoLibararyService.getCategories()
            .then((data) => {
                setCategories(data);
                if (selectedCategoryId === undefined) {
                    setCategory(data[0]);
                    setSelectedCategory(data[0]);
                } else {
                    setCategory(data.find((cat) => cat.id === selectedCategoryId));
                    setSelectedCategory(data.find((cat) => cat.id === selectedCategoryId));
                }
            })
            .catch((error) => console.log(error));
    }, []);

    if (!categories || !category) return (<div>Loading Categories...</div>)

    return (
        <select 
            className="form-control"
            disabled={disabled}
            onChange={(e) => setSelectedCategory(
                categories.find((cat) => cat.id === parseInt(e.target.value))
            )}
        >
            <option value="">
                {
                    !categories ? "Loading..." : "Select Category"
                }
            </option>
            {
                categories && 

                    categories.map(catx => 
                            <option 
                                key={`category_${catx.id}`}
                                value={catx.id}
                                selected={catx.id === category.id}
                            >{categories && categories.find((cat) => cat.id === catx.id).name}</option>
           )
            }
        </select>
    )
}

export default TrivjoCategorySelector;