class QueryBuilder {

    VALID_OPERATORS = ['>', '<', '=', 'in', '>=', '<=', "!="];

    parts = []

    columns = []

    constructor() {

        return this;
    }

    and = () => {

        this.parts = [
            ...this.parts,
            '&'
        ]
        return this;
    }

    or = () => {

        this.parts = [
            ...this.parts,
            '|'
        ]

        return this;
    }

    test = ( statement ) => {

        let pieces = statement.split(" ").filter( element => element !== "" );

        if ( pieces.length !== 3 ) {
            console.log(`invalid TicketSearchBuilder test: ${statement}`);
            return null;
        } else if ( this.VALID_OPERATORS.indexOf(pieces[1]) < 0 ) {
            console.log(`invalid TicketSearchBuilder operator: ${statement}`);
            return null;
        }

        this.parts = [
            ...this.parts,
            {
                operator: pieces[1],
                column: pieces[0],
                value: pieces[2]
            }
        ]

        return this;
    }

    // update value
    update = ( column, newValue ) => {
        this.parts = this.parts.map( part => {
            if ( part['column'] === column ) part['value'] = newValue;
            return part;
        })
    }

    // remove column
    remove = ( column ) => {
        this.parts = this.parts.filter( part => part['column'] !== column)
        if ( this.parts[this.parts.length - 1] === '&' ) { // remove any dangling operators
            this.parts.pop();
        }
    }

    lines = () => this.parts;
}

export default QueryBuilder;