import React, { useState } from "react";
import LoadingSpinner from "../../modal_assets/LoadingSpinner";
import FrameBuilder from "../question_frame/FrameBuilder";
import trivjoAnswerService from "../../../services/TrivjoAnswerService";
import useOverlays, { OVERLAY_TYPES } from "../../../hooks/useOverlays";

const TrivjoAnswerBuilder = ({ 
    answer: defaultAnswer,
    updateAnswer,
    removeAnswer,
}) => {

    const { popOverlay, pushOverlay } = useOverlays();

    const [ answer, setAnswer ] = useState(defaultAnswer);
    const [ editAnswer, setEditAnswer ] = useState(defaultAnswer);
    const [ working, setWorking ] = useState(false);

    const createAnswer = () => {
        setWorking(true);
        trivjoAnswerService.create(editAnswer)
            .then( (answer) => {
                setAnswer(answer);
                updateAnswer(answer);
            })
            .catch( (error) => {
                console.log("Error creating answer: ", error);
            })
            .finally(() => {
                setWorking(false);
                popOverlay();
            })
    }

    const saveAnswer = () => {
        setWorking(true);
        trivjoAnswerService.update(editAnswer)
            .then( () => {
                setAnswer(editAnswer);
                updateAnswer(editAnswer);
            })
            .catch( (error) => console.log(error))
            .finally(() => setWorking(false))
    }

    const deleteAnswer = () => {
        setWorking(true);
        trivjoAnswerService.destroy(answer.id)
            .then(() => removeAnswer(answer))
            .catch(error => console.log(error))
            .finally(() => {
                setWorking(false);
                popOverlay();
            })
    }
    
    const updated = JSON.stringify(editAnswer) !== JSON.stringify(answer);

    return (
        <div className="row trivjo_answer_builder layer layer-2">
            <div className="col-12 mt-2">
                <div className="row">
                    <div className="col-12">

                        <FrameBuilder
                            imageUrl={editAnswer.image_url}
                            setImageUrl={(url) => setEditAnswer({...editAnswer, image_url: url})}
                            text={editAnswer.text}
                            setText={(text) => setEditAnswer({...editAnswer, text: text})}
                            isSelectingImage={false}
                            setIsSelectingImage={() => {}}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <button 
                            className="btn btn-sm btn-success mr-2"
                            disabled={!updated}
                            onClick={
                                editAnswer.id === 0 ?
                                    () => createAnswer()
                                :
                                    () => saveAnswer()
                            }
                            >Save</button>

                        <button 
                            className="btn btn-sm btn-secondary"
                            onClick={
                                updated ?
                                    (e) =>
                                        pushOverlay({
                                            type: OVERLAY_TYPES.CONFIRM,
                                            position: { x: e.clientX, y: e.clientY },
                                            component: <>Are you sure you want to delete this answer</>,
                                            respond: (response) => {
                                                if (response) deleteAnswer();
                                            }
                                        })
                                :
                                    popOverlay
                            }
                            >Finished</button>
                    </div>
                </div>  
            </div>

            { working && <LoadingSpinner isOverlay={true} />}
        </div>
    )
}

export default TrivjoAnswerBuilder;