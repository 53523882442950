// filters based on array of values

import Filter from './Filter';

export default class ValuesFilter extends Filter {

    constructor(name, values, columnName) { console.log(values)
        super(name);
        this.values = values;
        this.columnName = columnName;
    }

    apply = ( student, studentData ) => this.values.includes(studentData[this.columnName])

}