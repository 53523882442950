import React, { useState } from 'react';
import eventHandler, { UPDATE_STUDENT, RELOAD_COHORT } from '../../../../events';
import { getExamStatus, EXAM_STATUSES } from '../ExamsSheet';
import AddTempExam from './AddTempExam';
import ActionButtons, { Label } from '../../../elements/ActionButtons';
import useStudent from '../../../../hooks/useStudent';
import useOverlays, { OVERLAY_TYPES } from '../../../../hooks/useOverlays';

const FiltersExamAssignment = ({
    activeExams,
    clearExams,
    sendExamCodes,
    setTemporaryExam,
    close,
    sortedRoster,
    cohortInfo,
}) => {

    const { updateMultipleStudents } = useStudent();
    const { pushOverlay, popOverlay } = useOverlays();

    const [ selectedExam, setSelectedExam ] = useState(null);
    const [ addNewExamClicked, setAddNewExamClicked ] = useState(false);
    
    const itemExamsCount = sortedRoster.filter(student => student.temp_exam).length;

    const autoAssignExams = (examToAssign=null) => { 

        let assignedExamCount = 0;
        let finalAssignList = []; // keep list of updated students for updating the backend.
        
        activeExams = examToAssign ? [examToAssign] : activeExams;

        sortedRoster.forEach(student => {
            if (student.exams.length === 3) return; // max attempts reached
            if (student.assigned_exam) return; // already assigned an exam
            
            // check if student has an open exam
            let excludingExamFound = false
            student.exams.forEach(exam => { 
                if (getExamStatus(exam) === EXAM_STATUSES.OPEN) {
                    excludingExamFound = true;
                };
            })
            if (excludingExamFound) return;
            if ( student.id == 89564 ) console.log(student);

            // find a valid code
            let validExamFound = false;
            activeExams.forEach(exam => {
                if (student.historical_exam_ids.indexOf(exam.id) < 0 && !validExamFound) { // available exam found
                    finalAssignList.push({
                        ...student,
                        temp_exam: {
                            id: exam.id,
                            title: exam.title,
                            code: exam.code,
                        }
                    })
                    validExamFound = true;
                }
            })

            if (validExamFound) assignedExamCount++;
        })
        
        if (assignedExamCount === 0) {
            alert(`No students meet the criteria for auto-assignment.`);
            return;
        } else {
            updateMultipleStudents(finalAssignList);
        }

        alert(`${assignedExamCount} students were auto-assigned an exam.`)

    }

    return (
        
        <div id="filters_exam_assignment" className='row justify-content-center'>
            {
                activeExams.length === 0 &&
                    <div className=' col-12 text-center'>
                        <h5>No Active Exams</h5>
                        Use one of the options below to add an exam
                    </div>
            }

            {
                activeExams.length > 0 &&
                    <>
                        <div className='col-12 bg-warning text-center text-danger font-weight-bold'>
                            Filter roster to include only students you wish to assign exams to!
                        </div>
                        <div className="col-6 layer layer-2 p-2 m-1">
                            <div className='text-center'>
                                <h5>Assign Selected Exam</h5>
                            </div>
                            
                            <select
                                className='form-control'
                                onChange={(e) => setSelectedExam(activeExams.find(exam => exam.id === parseInt(e.target.value)))}
                            >
                                {
                                    ! selectedExam &&
                                        <option value={null}>Select Exam</option>
                                }
                                {
                                    activeExams.map(exam => {
                                        return (
                                            <option
                                                key={exam.id}
                                                value={exam.id}
                                                selected={selectedExam && selectedExam.id === exam.id}
                                                >
                                                    {exam.title} exp: {new Date(exam.end_time).toLocaleDateString()}
                                            </option>
                                        )
                                    })
                                }
                            </select>

                            <div className='mt-2'>
                                Will assign to eligible students who:
                                <ul>
                                    <li>Have not taken the selected exam</li>
                                    <li>Have not reached their max attempts</li>
                                    <li>Have not exhausted all available exams</li>
                                    <li>Do not have an exam open</li>
                                    <li>Do not have an unopened exam code</li>
                                    <li className='text-danger bg-warning'>Are filtered in the roster</li>
                                </ul>
                                <div className='text-center'>
                                    <ActionButtons
                                        buttons={[
                                            Label({
                                                text: 'Assign Exam',
                                                onClick: () => {
                                                    autoAssignExams(selectedExam);
                                                    setSelectedExam(null);
                                                    // close();
                                                },
                                                visible: selectedExam && itemExamsCount == 0
                                            }),
                                            Label({
                                                text: 'Copy Exam Code',
                                                onClick: () => {navigator.clipboard.writeText(selectedExam.code)},
                                                visible: selectedExam && itemExamsCount == 0
                                            }),
                                        ]}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="col-5 layer layer-2 m-1 p-2">
                            <div className='text-center'>
                                <h5>Auto-Assign Exams</h5>
                            </div>
                            <p>
                                Exams will be auto assigned to students in the order the exams were created on LP3.
                                Each eligible student will be assigned the first created exam they have not taken yet.
                            </p>
                            Will assign to eligible students who:
                            <ul>
                                <li>Have not reached their max attempts</li>
                                <li>Have not exhausted all available exams</li>
                                <li>Do not have an exam open</li>
                                <li>Do not have an unopened exam code</li>
                                <li className='text-danger bg-warning'>Are filtered in the roster</li>
                            </ul>
                            <div className='text-center'>
                                <ActionButtons
                                    buttons={[
                                        Label({
                                            text: 'Auto Assign Exams',
                                            onClick: () => {
                                                autoAssignExams(selectedExam);
                                                close();
                                            },
                                            visible: itemExamsCount == 0
                                        }),
                                    ]}
                                    />
                            </div>
                        </div>
                    </>
            }
            

            <div className='col-12 text-center mt-3'>
                <button
                    className='btn btn-sm btn-info'
                    onClick={() => {
                        if ( itemExamsCount > 0 ) {
                            if ( ! window.confirm('You have not sent exam emails. Are you sure you want to close this window?')) {
                                return;
                            } else {
                                window.alert('No exam codes were issued and no emails were sent.')
                            }
                        }

                        close();
                    }}
                    >
                        Close
                </button>
                <button 
                    className='btn btn-sm btn-info ml-5'
                    onClick={() => 
                        pushOverlay({
                            type: OVERLAY_TYPES.MODAL,
                            component: <AddTempExam
                                stackId={cohortInfo.stack_id}
                                addExam={(exam) => {
                                    setTemporaryExam(exam);
                                    popOverlay();
                                }}
                                close={popOverlay}
                            />
                        })
                    }
                    >
                        Add Temporary Exam
                </button>
                <a 
                    className='btn btn-sm btn-info ml-2'
                    href="https://login.codingdojo.com/admin/exam" 
                    target="_blank" 
                    rel="noreferrer"
                    onClick={() => setAddNewExamClicked(true)}
                    >
                        Add an exam on LP3
                    </a>
                {
                    itemExamsCount > 0 &&
                        <span className='text-center ml-5'>
                            <button 
                                className='btn btn-sm btn-warning'
                                onClick={() => clearExams()}
                                >
                                    Clear Assigned Exams({itemExamsCount})
                            </button>

                            <button 
                                className='btn btn-sm btn-success ml-2'
                                onClick={() => sendExamCodes()}
                                >
                                    Send Exam Codes({itemExamsCount})
                            </button>
                        </span>
                }
            </div>

            {
                addNewExamClicked &&
                    <div className='modal_overlay modal_overlay--30'>
                        <div className='layer layer-1 text-center'>
                            <button 
                                className='btn btn-sm btn-info mt-5'
                                onClick={ () => {
                                    eventHandler.dispatch(RELOAD_COHORT);
                                }}
                                >
                                    Reload Cohort To Access New Exams
                            </button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default FiltersExamAssignment;
