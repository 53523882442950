import { useContext} from "react"
import { AppContext } from "../store/AppContext"

const useUserAccess = () => {

    const { user } = useContext(AppContext);

    const USER_LEVELS = {
        LEE: 11,
        ADMIN: 10,
        MANAGER: 7,
        LEAD: 6,
        INSTRUCTOR: 5, 
        TA: 4, // default
        DISABLED: 0,
    }

    const checkAccess = (requiredLevel) => user.access >= requiredLevel;

    const getLevelNameFromAccess = (value) => Object.keys(USER_LEVELS).find(key => USER_LEVELS[key] === value);    

    return [
        USER_LEVELS,
        checkAccess,
        getLevelNameFromAccess,
    ]
}

export default useUserAccess