import React from 'react';
import ActionButtons, { ICONS, CopyToClipboardButton} from '../../elements/ActionButtons';

const SimpleStudentHeader = ({ student }) => {
 
  return (
    <h5>
        <ActionButtons
            buttons={[
                CopyToClipboardButton({
                    icon: ICONS.EMAIL,
                    clipboardContent: student.email_address,
                })
            ]}
        />
    <span className="ml-2">{student.full_name || student.name} </span></h5>
  );
}

export default SimpleStudentHeader;