import React, { useEffect, useState } from "react";
import { adminService } from "../../services/AdminService";
import LoadingSpinner from "../modal_assets/LoadingSpinner";
import useUserAccess from "../../hooks/useUserAccess";

const AdminTabers = () => {

    const [ USER_LEVELS, checkAccess, getLevelNameFromAccess ] = useUserAccess();

    const [tabers, setTabers] = useState(null)
    
    useEffect(() => {
        adminService.getTabers()
            .then(res => setTabers(res))
            .catch(err => console.log(err))
    }, [])

    const onUpdateTaber = (updates) => { 
        adminService.updateTaber(updates)
            .then(res => {
                setTabers(tabers.map(taber => {
                    if (taber.id === res.id) {
                        return res
                    } else {
                        return taber
                    }
                }))
            })
            .catch(err => console.log(err))
    }

    const AccessSelector = ({taber}) => 
        taber.access === USER_LEVELS.LEE && ! checkAccess(USER_LEVELS.LEE) ?
        <>Lee</>
        :
        <select
            onChange={(e) => onUpdateTaber({id: taber.id, access: USER_LEVELS[e.target.value]})}
            >
            {
                Object.keys(USER_LEVELS).map((level, i) =>
                    level === 'LEE'  && ! checkAccess(USER_LEVELS.LEE) ?
                    <></>
                    :
                    <option
                        key={i}
                        value={level}
                        selected={taber.access === USER_LEVELS[level]}
                        >
                        {level}
                    </option>
                )
            }
        </select>

    const AccessGroup = ({levelName, tabers}) =>
        <div
            className='row layer layer-3 mt-2 mr-2 p-2'
            >
            <div
                className='col-12'
                >
                <h3>{levelName}</h3>
            </div>
            <div
                className='col-12'
                >
                <table
                    className='table table-striped table-sm table-hover'
                    >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tabers
                                .sort((a, b) => a.first_name.localeCompare(b.first_name))
                                .map((taber, i) =>
                                <tr
                                    key={i}
                                    >
                                    <td>{taber.first_name} {taber.last_name}</td>
                                    <td>
                                        <AccessSelector taber={taber} />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>

    // sort tabers by access level, then by name
    const accessGroups = {}
    if ( tabers ) {
        Object.keys(USER_LEVELS).forEach(levelName => accessGroups[levelName] = [])
        tabers
            .sort((a, b) => a.first_name.localeCompare(b.first_name))
            .forEach(taber => accessGroups[getLevelNameFromAccess(taber.access)].push(taber))

    } else return (<><LoadingSpinner /></>)
    



    return (
        <div
            id="admin_tabers"
            className='row layer layer-2'
            >
            <div
                className='col-12'
                >
                <div className="row">
                    <div className="col-6">
                        <AccessGroup levelName='Admins' tabers={accessGroups.ADMIN} />
                        <AccessGroup levelName='Managers' tabers={accessGroups.MANAGER} />
                    </div>
                    <div className="col-6">
                        <AccessGroup levelName='Leads And SEMs' tabers={accessGroups.LEAD} />
                    </div>
                </div>
            </div>
            <div
                className='col-12'
                >
                <div className="row">
                    <div className="col-6">
                        <AccessGroup levelName='Instructors' tabers={accessGroups.INSTRUCTOR} />
                    </div>
                    <div className="col-6">
                        <AccessGroup levelName='TAs' tabers={accessGroups.TA} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminTabers;