import React from 'react';
import ImageLink from '../ImageLink';
import MissingImage from "../../../assets/no_image_placeholder.png";
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import ActionButtons, { BasicActionButton, ICONS } from '../ActionButtons';

const ImageSelector = ({ 
    currentImage,
    updateImage,
    disabled = false,
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    return (
        <div className="image_selector">
            <div className="image_selector__image">
                <img src={currentImage || MissingImage} alt="current" />
            </div>
            {
                !disabled &&
                    <div 
                        className='image_selector__click_shield'
                        onClick={(e) => 
                            pushOverlay({
                                position: { x: e.clientX, y: e.clientY },
                                component:<ImageLink 
                                    link={currentImage}
                                    setLink={(newLink) => {
                                        updateImage(newLink);
                                        popOverlay();
                                    }}
                                    close={popOverlay}
                                /> 
                            })
                    }
                        >
                        Click To Select Image
                        {
                            currentImage &&
                            <div 
                                className="image_selector__delete_btn"
                                >
                                <ActionButtons
                                    buttons={[
                                        BasicActionButton({
                                            icon: ICONS.CANCEL,
                                            xClass: 'bg-danger',
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.CONFIRM,
                                                    position: { x: e.clientX, y: e.clientY },
                                                    component: <>Are you sure you want to delete this image?</>,
                                                    respond: (response) => {
                                                        if (response) updateImage(null);
                                                    }
                                                })
                                            }
        
                                        })
                                    ]}
                                />
                            </div>
                        }
                    </div>
            }
        </div>
    )
}

export default ImageSelector;