/*
    Displays history of updates to the student sheets; admin access only
*/
import React, { useState, useEffect } from 'react';
import studentService from '../../../services/StudentService';
import LoadingSpinner from '../../modal_assets/LoadingSpinner';
import ActionButtons, { ICONS, CopyToClipboardButton, BasicActionButton } from '../../elements/ActionButtons';

const StudentSheetHistory = ({
    student,
    close
}) => {

    const [ history, setHistory ] = useState(null)

    useEffect(() => {
        studentService.pullSheetHistory(student.id, student.cohort_id)
            .then(res => {

                let byDates = {};

                for (let entry of res) { // group by date
                    const key = new Date(entry.created_at).toISOString().slice(0, 10);

                    if (!byDates[key]) {
                        byDates[key] = [];
                    }

                    byDates[key].push(entry);
                }
                setHistory(byDates);
            })
            .catch(err => {
                console.log(err);
                window.alert("Error Loading");
                close();
            })
    }, [])

    return (
        <div className="row layer layer-1 window_900">
            {
                history === null ?
                <LoadingSpinner />
                :
                Object.keys(history).length === 0 ?
                    <>
                        <div className='col-12 text-center'>
                            No updates have been made
                        </div>
    
                        <div className='col-12 text-center'>
                            <button
                                className='btn btn-info'
                                onClick={close}
                                >
                                Close
                                </button>
                        </div>
                    </>
                :
                <>
                    <div className='col-12 text-center mb-2'>
                        <button
                            className='btn btn-info'
                            onClick={close}
                            >
                            Close
                            </button>
                    </div>
                    {
                        Object.keys(history).reverse().map((key, i) => {
                            const day = history[key];
                            return (
                                <div className='layer layer-2 mb-2 p-2 col-12'>
                                    <h5>{new Date(day[0].created_at).toISOString().split('T')[0]}</h5>
                                    {
                                        day
                                            .sort((a, b) => {
                                                return new Date(b.created_at) - new Date(a.created_at);
                                            })
                                            .map((entry, i) => {
                                            return (
                                                <>
                                                    <div className='row'>
                                                        <div className='col-12 mt-2'>
                                                            <button
                                                                className="btn btn-sm btn-info mr-3" 
                                                                data-toggle="collapse" 
                                                                data-target={`#entry_${entry.id}`} 
                                                                aria-expanded="false" 
                                                                aria-controls={`entry_${entry.id}`} 
                                                            > + </button>
                                                            <ActionButtons
                                                                buttons={[
                                                                    CopyToClipboardButton({
                                                                        icon: ICONS.EMAIL,
                                                                        clipboardContent: entry.staff_email_address
                                                                    })
                                                                ]}
                                                                />
                                                            <span className='ml-2'>
                                                                {entry.staff_first_name} {entry.staff_last_name} - &nbsp;
                                                                {entry.log_note ? entry.log_note : 'No Note'} - &nbsp;
                                                                Column: {entry.column_name}
                                                                
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        id={`entry_${entry.id}`}
                                                        className='row mt-2 collapse multi-collapse'>
                                                        <div className='col-6'>
                                                            Previous Value
                                                            <pre>{JSON.stringify(JSON.parse(entry.previous_value), null, 2)}</pre>
                                                        </div>
                                                        <div className='col-6'>
                                                            UpdatedValue Value
                                                            <pre>{JSON.stringify(JSON.parse(entry.updated_value), null, 2)}</pre>
                                                        </div>

                                                        <div className='col-12 divider mt-2 mb-2'><div></div></div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    <div className='col-12 text-center'>
                        <button
                            className='btn btn-info'
                            onClick={close}
                            >
                            Close
                            </button>
                    </div>
                </>
            }
        </div>
    )
}

export default StudentSheetHistory;