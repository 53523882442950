import TABService from './TABService';

class ExamService extends TABService {

    model = 'exam';

    getOpenExams = () => 
        this._get(
            `${this.model}/open`
        )

}

export default new ExamService();