import React, { useEffect, useState } from "react";
import trackService from "../../services/TrackService";
import Select from 'react-select';

const TracksBrowser = ( props ) => {

    const { trackId = null, selectedModuleId = null, updateSelectedModule = null, canUpdateTrack = true} = props;

    const [ tracks, setTracks ] = useState(null);
    const [ track, setTrack ] = useState(null);
    const [ selectedTrack, setSelectedTrack ] = useState(null);
    const [ selectedModule, setSelectedModule ] = useState(null)

    useEffect( () => { 
        getTracks( trackId ? trackId : -1 );
    }, [])

    const getTracks = (trackId) => {
        setTrack(null);

        trackService.getTracks(trackId)
            .then( resp => {
                setTracks(resp['tracks']);
                setTrack(
                    resp['track'] || []
                );
                setSelectedTrack(
                    resp['tracks'].filter( track => track.id === trackId ) [0]
                ) 

                if ( selectedModuleId ){
                    setSelectedModule( resp['track'].filter( module => module.id === selectedModuleId )[0] );
                
                }
            })
            .catch( err => console.log(err))
    }

    const onModuleSelected = ( moduleId ) => { 

        if ( moduleId === null ){
            setSelectedModule( null )
            if ( updateSelectedModule ) updateSelectedModule ( { id: null, title: null })

       } else {
            let module = track.filter( module => module.id === moduleId )[0];
            setSelectedModule( module )
            if ( updateSelectedModule ) updateSelectedModule ( module )
        }
    }

    return(
        <div className="row">
            <div className="col-12">
                {
                    tracks === null?
                        <>Loading...</>
                        :
                        <>
                        {
                            canUpdateTrack === true ?
                                <Select
                                    classNamePrefix={"react_select"}
                                    options={tracks.map( track => {return ({
                                        id: track.id,
                                        label: track.title
                                    })})}
                                    value={
                                        selectedTrack === null || selectedTrack === undefined ? [] : 
                                        [{
                                            id: selectedTrack.id,
                                            label: selectedTrack.title
                                        }]
                                }
                                    onChange={( selectedItem ) => {
                                        getTracks( selectedItem.id );
                                        setSelectedModule( null )
                                    }}
                                />
                                :
                                <>
                                </>
                        }

                            <div id="" className='row mt-3 align-items-center'>

                                <div id='create_ticket__track_modules' className='col-9'>
                                    {

                                            <Select
                                                isLoading={track === null  || track === undefined}
                                                isDisabled={track === null  || track === undefined}
                                                classNamePrefix={"react_select"}
                                                options={track?.map( assignment => ({
                                                    id: assignment.id,
                                                    label: `${assignment.title} (${assignment.chapter_title})`
                                                }))}
                                                placeholder={'Select Assignment...'}
                                                isClearable={false}
                                                value={selectedModule === null || selectedModule === undefined ? null :
                                                    {
                                                        id: selectedModule.id,
                                                        label: `${selectedModule.title} (${selectedModule.chapter_title})`
                                                    }
                                            }
                                                onChange={( module ) => onModuleSelected(module.id)}
                                            />
                                    }
                                </div>
                                
                                {
                                    selectedModule !== null && selectedModule !== undefined &&
                                        <>
                                            <div className="col-3">
                                                <div className="row">
                                                    <button 
                                                        className="btn btn-md btn-danger mr-2"
                                                        onClick={() => onModuleSelected(null)}
                                                        >
                                                        X
                                                    </button>
                                                    <a 
                                                        href={`https://login.codingdojo.com/m/${selectedModule.track_id}/${selectedModule.chapter_id}/${selectedModule.id}`}
                                                        target="_blank"
                                                        className="btn btn-md btn-success"
                                                    >
                                                        =&gt;
                                                    </a>

                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </>
                }
            </div>
        </div>
        
        
    )
        
}

export default TracksBrowser;