import TABService from './TABService';

class TrackService extends TABService {

    model = 'track';

    getTracks = ( fill_track_id = null ) =>  // used for older queries. Use getAllTracks() instead
        this._post(
            `${this.model}`,
            fill_track_id === null ? {} : {fill_track_id}
        )
    

    getAllTracks = () =>
        this._get(
            `${this.model}`
        )

    getTrackModules = ( track_id ) => 
        this._get(
            `${this.model}/get-modules/${track_id}`
        )
    
}

export const trackService = new TrackService();
export default trackService;