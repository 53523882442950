import React, { useState, useEffect, useContext } from "react";
import trivjoAnswerService from "../../../services/TrivjoAnswerService";
import TrivjoAnswerBuilder from "./TrivjoAnswerBuilder";
import TrivjoAnswer from "./TrivjoAnswer";
import useOverlays from "../../../hooks/useOverlays";

const TrivjoAnswers = ({
    questionId,
    correctAnswerId,
    setSelectedAnswer,
    answerAdded,
    answerRemoved,
    disabled = false,
}) => {

    const { pushOverlay } = useOverlays();

    const [ answers, setAnswers ] = useState(null);

    useEffect(() => {
         trivjoAnswerService.getByQuestionId(questionId)
            .then(answers => {
                setAnswers(answers);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    const onAddAnswer = (answer) => {
        answerAdded(answer);
        setAnswers([...answers, answer]);
    }

    const onRemoveAnswer = (answer) => {
        setAnswers(answers.filter(a => a.id !== answer.id));
        answerRemoved(answer);
        
        trivjoAnswerService.destroy(answer.id)
            .then(() => {
            })
            .catch(error => console.log(error));
    }

    const onUpdateAnswer = (answer) => setAnswers(answers.map(a => a.id === answer.id ? {...a, answer} : a));


    return (
        <>
            <div id="trivjo_answers" className="row">
                {
                    !disabled &&
                        <div className="col-12">
                            <button
                                className="btn btn-sm btn-success"
                                disabled={answers && answers.length >= 4}
                                onClick={(e) =>
                                    pushOverlay({
                                        position: { x: e.clientX, y: e.clientY },
                                        component: <TrivjoAnswerBuilder 
                                            answer={{
                                                id: 0, // test editQuestion.id === 0 to determine if new or existing
                                                image_url: "", //https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg
                                                text: "",
                                                tj_question_id: questionId,
                                            }}
                                            updateAnswer={onAddAnswer}
                                            />
                                    })
                                }
        
                            >Add An Answer</button>
                        </div>

                }

                <div className="col-12">
                    <div className="row">
                        <div 
                            className="col-12 answers_list mt-2"
                            >
                            {
                                answers && answers.length > 0 &&
                                    answers.map((answer) => 
                                        <TrivjoAnswer
                                            key={`answer_${answer.id}`}
                                            answer={answer}
                                            selected={correctAnswerId === answer.id}
                                            setSelected={setSelectedAnswer}
                                            removeAnswer={onRemoveAnswer}
                                            updateAnswer={onUpdateAnswer}
                                            disabled={disabled}
                                        />
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrivjoAnswers;