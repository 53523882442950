/* This component is used to select how the user would like to bulk set statuses. */
import React, { useState } from 'react';
import MyCohortStatusSetter from './MyCohortStatusSetter';
import eventHandler, { UPDATE_SHEET_STUDENTS } from "../../../events";
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';

export const bulkSetStatuses = (roster, status) => {
    /* this sets the new status and updates the backend */
    eventHandler.dispatch(UPDATE_SHEET_STUDENTS, {
        students: roster.map( student => 
            ({
                ...student,
                status: status.id,
                status_id: status.id,
                status_slug: status.slug,
                log_note: `Status updated from ${student.status_slug} to ${status.slug}.`
            })
    )});
}

const MyCohortSetStatuses = ({
    studentStatuses,
    sortedRoster,
}) => {

    const { pushOverlay, popOverlay } = useOverlays();

    const [ selectedStatus, setSelectedStatus ] = useState(null);

    const autoAssignStatuses = (
        e,
        statuses,
        sortedRoster
    ) => {

        const probationStatus = statuses.find(status => status.slug === 'probation');
        const onPaceStatus = statuses.find(status => status.slug === 'on-pace');
        const fallingBehindStatus = statuses.find(status => status.slug === 'falling-behind');

        let finalRoster = [];
        sortedRoster.forEach( student => {

            if ( student.status_is_active ) { // skip inactive statuses
                let autoStatus = null
                if ( student.core_assignments_to_date_percent >= .9  ){
                    autoStatus = onPaceStatus;

                } else if ( student.core_assignments_to_date_percent >= .6 ){
                    autoStatus = fallingBehindStatus;

                } else {
                    autoStatus = probationStatus;
                }

                if ( autoStatus !== null && autoStatus.id !== student.status_id ) finalRoster.push({
                    ...student,
                    autoStatus: autoStatus,
                    log_note: `Auto status update based on ${student.core_assignments_to_date_percent * 100}% of core assignments completed.`
                });
            }
        })

        if ( finalRoster.length === 0 ) {
            pushOverlay({
                type: OVERLAY_TYPES.ALERT,
                position: { x: e.clientX, y: e.clientY},
                component: <>There are no students to Update</>,
            })
            return false;
        }

        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>
                This will update the status of {finalRoster.length} student{finalRoster.length != 1 ? 's' : ''} based on their assignments progress.
            </>,
            respond: (response) => {

                if ( response ) {
                    eventHandler.dispatch(UPDATE_SHEET_STUDENTS, {
                        students: finalRoster.map( student =>
                            ({
                                ...student,
                                status: student.autoStatus.id,
                                status_id: student.autoStatus.id,
                                status_slug: student.autoStatus.slug,
                                log_note: 'Auto status update based on assignments progress.'
                            })
                    )});
                    pushOverlay({
                        type: OVERLAY_TYPES.ALERT,
                        position: { x: e.clientX, y: e.clientY},
                        component: <>Statuses set.</>,
                        respond: popOverlay ,
                    })
                }
            }
        })

        return true;
    }

    const toggleStatuses = (e) => {

        const finalRoster = sortedRoster.filter( student => student.status_id !== selectedStatus.id);

        if ( finalRoster.length === 0 ) {
            pushOverlay({
                type: OVERLAY_TYPES.ALERT,
                position: { x: e.clientX, y: e.clientY},
                component: <>There are no students to Update.</>,
            })
            return false;
        }

        pushOverlay({
            type: OVERLAY_TYPES.CONFIRM,
            position: { x: e.clientX, y: e.clientY},
            component: <>This will toggle the status of {finalRoster.length} student{finalRoster.length != 1 ? 's' : ''} to {studentStatuses.find(status => status.id === selectedStatus.id).title.toUpperCase()}.</>,
            respond: (response) => {
                    if ( response ) {
                        bulkSetStatuses(finalRoster, selectedStatus);
                        setSelectedStatus(null);

                        pushOverlay({
                            type: OVERLAY_TYPES.ALERT,
                            position: { x: e.clientX, y: e.clientY},
                            component: <>Statuses set.</>,
                        })

                        return true;
                    }
                    return false;
                }
        })
    }

    return (
        <div id="my_cohort_set_statuses" className="row justify-content-center">
            <div className='col-12 text-center text-danger font-weight-bold bg-warning'>Filter roster to include only students you wish to assign statuses to!</div>
            <div className="col-6 layer layer-2 p-2 m-1">
                <div className='text-center'>
                    Select a status to be applied to filtered students.
                    
                    <MyCohortStatusSetter
                        studentStatuses={studentStatuses}
                        currentStatusId={selectedStatus}
                        updateStatus={setSelectedStatus}
                    />
                    <button
                        className='btn btn-sm btn-success mt-2'
                        disabled={!selectedStatus}
                        onClick={(e) => toggleStatuses(e)}
                        >Toggle Statuses</button>
                </div>
            </div>
            <div className="col-5 text-center layer layer-2 p-2 m-1">
                <p>
                    Assign statuses to students based on their assignments progress. 
                    <div className='bg-warning text-danger font-weight-bold'>Will be applied to all filtered students with an active status. <br/>( probation, falling behind, on pace )</div>
                </p>
                <button
                    className='btn btn-info'
                    onClick={(e) => {
                        autoAssignStatuses(e, studentStatuses, sortedRoster);
                    }}
                    >Auto Assign Statuses</button>
            </div>
            <div className='col-12 text-center mt-3'>
                <button
                    className='btn btn-info'
                    onClick={popOverlay}
                >Close</button>
            </div>
        </div>
    )
}

export default MyCohortSetStatuses