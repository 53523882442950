import TABService from './TABService';

class AdminService extends TABService {

    model = 'admin';

    ALERT_TYPE_NEW_TICKET = 1
    ALERT_TYPE_NEW_COMMENT = 2

    getTabers = () => 
        this._get(
            `${this.model}/get-tabers`
        )

    updateTaber = (updates) =>
        this._post(
            `${this.model}/update-taber`,
            updates
        )

}

export const adminService = new AdminService();
export default adminService;