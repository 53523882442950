// extracts values from column for user to filter by

import React, { useState } from "react";
import { getPercentColor } from "./PercentDisplay";
import filterSorter from "../filter_sorter/MyCohortFilterSorter";

const ValueSortSelector = ({
    columnValueName, // the attribute to check in studentsData
    studentsData,
    close,
    filterValues,
    setFilterValues,
    isPercent = false,
    bottomPercent = 0.5, // Look at PercentDisplay.js for more info
    highPercent = 1,
}) => {
    const [selectedValues, setSelectedValues] = useState(filterValues);
    const [selectedOperator, setSelectedOperator] = useState("lt");
    const [selectedValue, setSelectedValue] = useState("");

    // extract values
    let values = [];
    filterSorter.roster.forEach((student) => {
        if (values.indexOf(studentsData[student.id][columnValueName]) === -1)
            values.push(studentsData[student.id][columnValueName]);
    });
    values.sort((a, b) => a - b);

    const isText = typeof values[0] === "string";

    return (
        // <div className="modal_overlay modal_overlay--50 ">
            <div id="value_sort_selector" className=" row ">
                <div className="col-6 value_sort_selector">
                    <div className="col-12 text-center sub_note mb-2">
                        Choose one or more of the values below.
                    </div>
                    {values.map((value) => (
                        <div
                            className={`
                                    value_sort_selector__value
                                    value_sort_selector__value--${
                                        selectedValues.indexOf(value) === -1
                                            ? "unselected"
                                            : "selected"
                                    }
                                    `}
                            key={`value_sort_selector__value_${value}`}
                            style={{
                                backgroundColor: `#${
                                    isPercent
                                        ? getPercentColor(
                                              value,
                                              bottomPercent,
                                              highPercent
                                          )
                                        : "ffffff"
                                }`,
                            }}
                            onClick={() => {
                                if (selectedValues.indexOf(value) === -1) {
                                    setSelectedValues([
                                        ...selectedValues,
                                        value,
                                    ]);
                                    setSelectedValue(
                                        isPercent
                                            ? parseInt(value * 100)
                                            : value
                                    );
                                } else {
                                    setSelectedValues(
                                        selectedValues.filter(
                                            (val) => val !== value
                                        )
                                    );
                                }
                            }}
                        >
                            {isPercent ? `${parseInt(value * 100)}%` : value}
                        </div>
                    ))}
                </div>

                <div className="value_sort_selector__batch_select col-6 text-center">
                    {!isText && (
                        <>
                            <div className="col-12 text-center sub_note mb-2">
                                Or select by range. ( ex: &lt; 50% )
                            </div>
                            <div>
                                <select
                                    onChange={(e) =>
                                        setSelectedOperator(e.target.value)
                                    }
                                    value={selectedOperator}
                                >
                                    <option value="lt">&lt;</option>
                                    <option value="lte">&lt;=</option>
                                    <option value="gt">&gt;</option>
                                    <option value="gte">&gt;=</option>
                                </select>
                                <input
                                    className="ml-2 mr-1"
                                    value={selectedValue}
                                    onChange={(e) =>
                                        setSelectedValue(e.target.value)
                                    }
                                />
                                {isPercent && <span>%</span>}
                            </div>
                            <button
                                className="mt-2"
                                onClick={() => {
                                    let newSelectedValues = [];
                                    values.forEach((value) => {
                                        let testValue = isPercent
                                            ? parseFloat(selectedValue * 0.01)
                                            : selectedValue;
                                        if (
                                            (selectedOperator === "lt" &&
                                                value < testValue) ||
                                            (selectedOperator === "gt" &&
                                                value > testValue) ||
                                            (selectedOperator === "lte" &&
                                                value <= testValue) ||
                                            (selectedOperator === "gte" &&
                                                value >= testValue)
                                        ) {
                                            newSelectedValues.push(value);
                                        } else {
                                            newSelectedValues =
                                                newSelectedValues.filter(
                                                    (val) => val !== value
                                                );
                                        }
                                    });
                                    setSelectedValues(newSelectedValues);
                                }}
                            >
                                Select Range
                            </button>
                        </>
                    )}
                    <div className="col-12 text-center mt-5">
                        <button
                            className=""
                            onClick={() => {
                                setFilterValues(selectedValues);
                                close();
                            }}
                            disabled={selectedValues.length === 0}
                        >
                            Set Filter
                        </button>
                        <button
                            className=""
                            onClick={() => {
                                setFilterValues([]);
                                close();
                            }}
                            disabled={selectedValues.length === 0}
                        >
                            Clear Filter
                        </button>
                        <button
                            className=""
                            onClick={() => {
                                close();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        // </div>
    );
};

export default ValueSortSelector;
