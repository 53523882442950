/* assignments sheets only. Not assignments and attendance. */

import React, { useState, useEffect, useContext } from 'react';
import AssignAttendStudentWeek from './AssignAttendStudentWeek';
import PercentDisplay from '../my_cohort_elements/PercentDisplay';
import AssignAttendAssignmentsList from './AssignAttendAssignmentsList';
import filterSorter from '../filter_sorter/MyCohortFilterSorter';
import ValueSorter from '../filter_sorter/sorters/ValueSorter';
import { SORT_TYPES } from '../filter_sorter/sorters/Sorter';
import FilterSorterBtn from '../filter_sorter/FilterSorterBtn';
import ValueSortSelector from '../my_cohort_elements/ValueSortSelector';
import ValuesFilter from '../filter_sorter/filters/ValuesFilter';
import assignmentDownloadService, { DOWNLOADER_STATES } from '../../../services/AssignmentDownloaderService';
import {config} from '../../../config';
import AssignStatsCore from './stats_components/AssignStatsCore';
import AssignmentStats from './stats_components/AssignmentStats';
import CohortAssignmentStats from './stats_components/CohortAssignmentStats';
import ReviewTab from './ReviewTab';
import { ASSIGNMENT_REVIEW_STATUS } from '../../../helpers/Constants';
import ManualHelper, { MANUAL_LINKS } from '../../../helpers/ManualHelper';
import useOverlays, { OVERLAY_TYPES } from '../../../hooks/useOverlays';
import ActionButtons, { ICONS, SortButton, BasicActionButton, Label } from '../../elements/ActionButtons';
import MyCohortContext from '../../../store/MyCohortContext';
import useRoster from '../../../hooks/useRoster';
import SubstantiveInteraction from '../substantive_interaction/SubstantiveInteraction';

export const VIEWS = {
    ATTENDANCE: 'attendance',
    ASSIGNMENTS: 'assignments',
    BOTH: 'both'
}

export const ASSIGNMENT_TYPES = {
    CORE: 'core',
    PRACTICE: 'practice',
    OPTIONAL: 'optional'
}

export const ONTIME_STATUSES = {
    ONTIME: 'ontime',
    LATE: 'late',
    UNSUBMITTED: 'unsubmitted',
    OUTOFDATE: 'outofdate'
}

export const CORE_ASSIGNMENTS_DISPLAY = {
    TODATE: 'to-date',
    OVERALL: 'overall'
}

const AssignmentAttendanceSheet = ({
}) => { 

    const { pushOverlay, popOverlay } = useOverlays();
    const { 
        sortedRoster,
        studentsData,
        cohortCalendar,
        cohortStartDate,
        inStatsMode,
    } = useContext(MyCohortContext);

    const { checkStudentSelected, hoveredStudentId, clearHoveredStudent, setHoveredStudentId } = useRoster();

    const [ selectedWeek, setSelectedWeek ] = useState();
    const [ selectedAssignmentTypes, setSelectedAssignmentTypes ] = useState([ASSIGNMENT_TYPES.CORE]);
    const [ view, setView ] = useState(VIEWS.ASSIGNMENTS);
    const [ percentSort, setPercentSort ] = useState(null);
    const [ coreAssignmentsDisplay, setCoreAssignmentsDisplay ] = useState(CORE_ASSIGNMENTS_DISPLAY.TODATE);

    const [ filteringCoreAssignments, setFilteringCoreAssignments ] = useState(false);
    const [ coreAssignmentsFilterValues, setCoreAssignmentsFilterValues ] = useState([]);

    const [ showNoDownloaderAlert, setShowNoDownloaderAlert ] = useState(false);

    const [ viewWeeks, setViewWeeks ] = useState([]); // keep weekstartdate of weeks to display in full week mode
    

    useEffect( () => { 
        
        if ( filterSorter.sorter && filterSorter.sorter.name === 'core_assignment_percent' ) {
            setPercentSort( filterSorter.sorter.sortType );
        } else {
            setPercentSort(null);
        }

        let coreAssignmentsFilter = filterSorter.getFilter('core_assignments_percent');
        if (coreAssignmentsFilter) {
            setCoreAssignmentsFilterValues(coreAssignmentsFilter.values);
        } else {
            setCoreAssignmentsFilterValues([]);
        }

    }, [sortedRoster]);// watch the filterSorter.sorter object for changes.

    useEffect( () => {
        // determine current week and add to viewWeeks
        
        const now = new Date();
        const currentWeek = Object.keys(cohortCalendar).find( weekStartDate => { 
            let weekStart = new Date(Date.parse(weekStartDate));
            let weekEnd = new Date(weekStart)
            weekEnd.setDate(weekEnd.getDate() + 7);
            return now >= weekStart && now <= weekEnd;
        })
        setViewWeeks( [currentWeek] );

        if ( 'assignment_view_weeks' in filterSorter.settings ) {
            setViewWeeks( filterSorter.settings.assignment_view_weeks );
        }

        if ( 'core_assignments_display' in filterSorter.settings ) {
            setCoreAssignmentsDisplay( filterSorter.settings.core_assignments_display );
        }

        if ( 'selected_assignment_types' in filterSorter.settings ) {
            setSelectedAssignmentTypes( filterSorter.settings.selected_assignment_types );
        }
        
    }, [])

    const updateViewWeeks = (viewWeeks) => {
        filterSorter.settings.assignment_view_weeks = viewWeeks;
        setViewWeeks(viewWeeks);
    }


    const onSetAssignmentPercentSort = (sortDirection) => {

        setPercentSort(sortDirection);
        if ( sortDirection === null ) return;

        filterSorter.addSorter( 
            new ValueSorter(
                'core_assignment_percent', 
                coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                    'core_assignments_to_date_percent'
                    :
                    'core_assignments_overall_percent'
                    , 
                sortDirection
            ) 
        );
    }

    const onDownloadAssignments = ( assignmentId ) => {

        if (assignmentDownloadService.status === DOWNLOADER_STATES.NOT_CHROME || assignmentDownloadService.status !== DOWNLOADER_STATES.INSTALLED) {
            setShowNoDownloaderAlert(true);
            return;
        }

        let downloadList = [];
        let githubList = [];
        let sampleAssignment = null;

        sortedRoster.forEach( student => { 

            let studentRecord = studentsData[student.id];

            if (assignmentId in studentRecord['assignments']) {

                let assignment = studentRecord['assignments'][assignmentId];
                
                if (assignment) { 
                    if ( !sampleAssignment ) sampleAssignment = assignment;

                    if ( assignment.assignment.file_url.indexOf('github') > -1 ) {
                        githubList.push({
                            studentId: student.id,
                            studentFullName: `${student.first_name} ${student.last_name.substring(0,3)}`,
                            submitDate: assignment.assignment.submit_date,
                            fileUrl: assignment.assignment.file_url
                        })
                    } else {
                        downloadList.push({
                            studentId: student.id,
                            studentFullName: `${student.first_name} ${student.last_name.substring(0,3)}`,
                            submitDate: assignment.assignment.submit_date,
                            fileUrl: assignment.assignment.file_url
                        })
                    }
                }
            }
        })

        if (sampleAssignment === null) {
            alert("There are no assignments to download.");
            return;
        }

        assignmentDownloadService.downloadAssignments(
            "Staff",
            1,
            cohortStartDate,
            sampleAssignment.assignment.title,
            downloadList,
            githubList
        )
    }

    const onToggleWeekView = (weekStartDate) => {
        if (viewWeeks.includes(weekStartDate)) {
            updateViewWeeks( viewWeeks.filter( week => week !== weekStartDate ) );
        } else {
            updateViewWeeks( [...viewWeeks, weekStartDate] );
        }
    }

    const onToggleAssignmentType = (assignmentType) => {

        if (selectedAssignmentTypes.length === 1 && selectedAssignmentTypes.includes(assignmentType)) return;
        if (selectedAssignmentTypes.includes(assignmentType)) {
            let newTypes = selectedAssignmentTypes.filter( type => type !== assignmentType );
            setSelectedAssignmentTypes( newTypes );
            filterSorter.settings.selected_assignment_types = newTypes;
        } else {
            let newTypes = [...selectedAssignmentTypes, assignmentType];
            setSelectedAssignmentTypes( newTypes );
            filterSorter.settings.selected_assignment_types = newTypes;
        }
    }

    const displayStudentAssignmentStats = (student) => {
        pushOverlay({
            type: OVERLAY_TYPES.MODAL,
            component: <AssignAttendAssignmentsList
                calendars={{
                    studentCalendar: studentsData[student.id]['calendar']
                }}
                close={() => popOverlay()}
            />,
        })
    }

    // creates a filtered list of students based on sortedRoster
    let filteredStudentsData = [];
    sortedRoster.forEach( student => filteredStudentsData.push(studentsData[student.id]));

    return (
        <div id="assign_attend" className="my_cohort__right_panel">

            {/* top bar with week buttons and filters */}
            <div className='my_cohort__filters_bar layer layer-3 '>
                <div
                    className='my_cohort__filters_bar__header'
                >
                    {
                        inStatsMode &&
                            <ActionButtons
                                buttons={[
                                    Label({
                                        text: 'Assignment Stats',
                                        inStatsMode: true,
                                        onClick: () => 
                                            pushOverlay({
                                                type: OVERLAY_TYPES.MODAL,
                                                component: <CohortAssignmentStats
                                                    studentsData={studentsData}
                                                    close={() => popOverlay()}
                                                />,
                                            }),
                                    })
                                ]}
                            />

                    }
                    <span className="mr-3">
                        <ManualHelper
                            linkSlug={MANUAL_LINKS.ASSIGNMENTS_SHEET}
                        />
                    </span>

                    <FilterSorterBtn
                        assignmentType={ASSIGNMENT_TYPES.CORE}
                        toggleSelectedAssignmentType={() => onToggleAssignmentType(ASSIGNMENT_TYPES.CORE)}
                        isSelected={selectedAssignmentTypes.includes(ASSIGNMENT_TYPES.CORE)}
                        disabled={true}
                    />
                    <FilterSorterBtn
                        assignmentType={ASSIGNMENT_TYPES.PRACTICE}
                        toggleSelectedAssignmentType={() => onToggleAssignmentType(ASSIGNMENT_TYPES.PRACTICE)}
                        isSelected={selectedAssignmentTypes.includes(ASSIGNMENT_TYPES.PRACTICE)}
                    />
                    <FilterSorterBtn
                        assignmentType={ASSIGNMENT_TYPES.OPTIONAL}
                        toggleSelectedAssignmentType={() => onToggleAssignmentType(ASSIGNMENT_TYPES.OPTIONAL)}
                        isSelected={selectedAssignmentTypes.includes(ASSIGNMENT_TYPES.OPTIONAL)}
                    />
                    <span
                        className='ml-5'
                        >
                        <ActionButtons
                            buttons={[
                                Label({
                                    text: 'Legend',
                                    onClick: (e) => 
                                        pushOverlay({
                                            position: {x: e.clientX, y: e.clientY},
                                            component: 
                                                <div className='layer layer-3 row'>
                                                    <div className='col-12 text-center pb-1'>
                                                        <ReviewTabsLegend />
                                                        <button
                                                            className='btn btn-info mt-3'
                                                            onClick={() => popOverlay()}
                                                        >Close</button>
                                                    </div>
                                                </div>,
                                        }) ,
                                }),
                                Label({
                                    text: 'Toggle Weeks',
                                    onClick: () => {
                                        if ( viewWeeks.length ) { // some weeks are open open  - close them all
                                            updateViewWeeks([]);
                                        } else { // no weeks are open - open all
                                            updateViewWeeks(Object.keys(cohortCalendar));
                                        }
                                    }
                                        
                                }),
                            ]}
                        />
                    </span>
                </div>

                {/* build sorters and filters row  */}
                <div className='my_cohort__filters_bar__view_select'>
                    <div className='assignment_reviews__row--filters assign_attend__row'>
                        <div className='assign_attend__percent_col--filters ml-1 mr-2'>
                            <span>
                                Core Assignments
                            </span>
                            <select
                                className="mb-1"
                                value={coreAssignmentsDisplay}
                                onChange={(e) => {
                                    setCoreAssignmentsDisplay(e.target.value);
                                    filterSorter.settings.core_assignments_display = e.target.value;
                                }}
                            >
                                <option value={CORE_ASSIGNMENTS_DISPLAY.OVERALL}>Overall</option>
                                <option value={CORE_ASSIGNMENTS_DISPLAY.TODATE}>To Date</option>
                            </select>
                            <ActionButtons
                                buttons={[
                                    BasicActionButton({
                                        icon: ICONS.FILTER,
                                        isSelected: filterSorter.getFilter('core_assignments_percent'),
                                        onClick: (e) => {
                                            pushOverlay({
                                                component: <ValueSortSelector
                                                    close={() => popOverlay()}
                                                    columnValueName={
                                                        coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                                                            'core_assignments_to_date_percent'
                                                            :
                                                            'core_assignments_overall_percent'
                                                    }
                                                    studentsData={studentsData}
                                                    isPercent={true}
                                                    filterValues={coreAssignmentsFilterValues}
                                                    setFilterValues={(values) => {
                            
                                                        if ( values.length > 0) {
                                                            setFilteringCoreAssignments(false);
                                                            setCoreAssignmentsFilterValues(values);
                                                            filterSorter.addFilter(new ValuesFilter(
                                                                'core_assignments_percent',
                                                                values, 
                                                                coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                                                                    'core_assignments_to_date_percent'
                                                                    :
                                                                    'core_assignments_overall_percent'
                                                                ))
                                                        } else { // filters were cleared
                                                            setFilteringCoreAssignments(false);
                                                            setCoreAssignmentsFilterValues(values);
                                                            filterSorter.removeFilter('core_assignments_percent');
                                                        }
                                                    }}
                                                />,
                                                position: {x: e.clientX, y: e.clientY},
                                            })
                                        }
                                    }),
                                    BasicActionButton({
                                        icon: ICONS.CANCEL,
                                        isSelected: true,
                                        visible: filterSorter.getFilter('core_assignments_percent') !== undefined,
                                        onClick: () => {
                                            setFilteringCoreAssignments(false);
                                            setCoreAssignmentsFilterValues([]);
                                            filterSorter.removeFilter('core_assignments_percent');
                                        }
                                    }),
                                    SortButton({
                                        sortType: percentSort,
                                        setSortType: () => {
                                            onSetAssignmentPercentSort(
                                                percentSort === SORT_TYPES.ASC ? 
                                                    SORT_TYPES.DESC 
                                                    : 
                                                    SORT_TYPES.ASC
                                                )
                                        },
                                    }),
                                ]}
                            />
                        </div>
                        {
                            inStatsMode &&
                                <div className="assign_attend_week__counts assign_attend_week__counts--filter">
                                    Totals by Selected Assignment Types
                                </div>
                        }
                        <span className='assign_attend_weeks'>
                            {
                                Object.keys(cohortCalendar).map( (weekStartDate, index) => { 

                                    const weekStart = new Date(Date.parse(weekStartDate));
                                    let weekEnd = new Date(weekStart)
                                    weekEnd.setDate(weekEnd.getDate() + 7);
                                    const now = new Date();
                                    const isCurrentWeek = now >= weekStart && now <= weekEnd;

                                    return (
                                            <AssignAttendStudentWeek
                                                student={null}
                                                calendarData={cohortCalendar[weekStartDate]}
                                                selectedAssignmentTypes={selectedAssignmentTypes}
                                                setSelectedAssignmentTypes={(newTypes) => {
                                                    setSelectedAssignmentTypes(newTypes);
                                                    filterSorter.settings.selected_assignment_types = newTypes;
                                                }}
                                                isSelected={selectedWeek === index}
                                                weekStartDate={weekStartDate}
                                                cohortStartDate={cohortStartDate}
                                                cohortCalendar={cohortCalendar}
                                                weekNumber={index + 1}
                                                isCurrentWeek={isCurrentWeek}
                                                downloadAssignments={onDownloadAssignments}
                                                setShowAssignmentStatsId={(id, e) =>
                                                    pushOverlay({
                                                        position: {x: e.clientX, y: e.clientY}, 
                                                        component: <AssignmentStats
                                                            close={() => {
                                                                popOverlay();
                                                            }}
                                                            filteredStudentsData={filteredStudentsData}
                                                            assignmentId={id}
                                                        />
                                                    })}
                                                isViewWeek={viewWeeks.indexOf(weekStartDate) > -1}
                                                toggleViewWeek={() => onToggleWeekView(weekStartDate)}
                                            />
                                    )
                                })
                            }

                        </span>
                    </div>
                </div>
            </div>
            
            {/* stats row and roster data */}
            <div className='responsive_browser_row_max'>

                {/* build stats row */}
                {
                    inStatsMode &&
                    <>
                        <div className='assignment_reviews__row assign_attend__row'>
                            <span className='assign_attend__percent_col ml-1 mr-2'>
                                <ActionButtons
                                    buttons={[
                                        Label({
                                            text: 'Core Stats',
                                            inStatsMode: true,
                                            onClick: () => 
                                                pushOverlay({
                                                    type: OVERLAY_TYPES.MODAL,
                                                    component: <AssignStatsCore
                                                        close={() => popOverlay()}
                                                        columnValueName={
                                                            coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                                                                'core_assignments_to_date_percent'
                                                                :
                                                                'core_assignments_overall_percent'
                                                        }
                                                        studentsData={filteredStudentsData}
                                                        isPercent={true}
                                                        filterValues={coreAssignmentsFilterValues}
                                                        coreAssignmentsDisplay={coreAssignmentsDisplay}
                                                        setCoreAssignmentsDisplay={(data) => {
                                                            setCoreAssignmentsDisplay(data);
                                                            filterSorter.settings.core_assignments_display = data;
                                                        }}
                                                    />,
                                                }) ,
                                        })
                                    ]}
                                />
                            </span>
                            <div className="assign_attend_week__counts assign_attend_week__counts--filter">
                                
                            </div>
                            <span className='assign_attend_weeks'>
                                {
                                    Object.keys(cohortCalendar).map( (weekStartDate, index) => {
                                        return (
                                                <AssignAttendStudentWeek
                                                    student={null}
                                                    studentsData={studentsData}
                                                    calendarData={cohortCalendar[weekStartDate]}
                                                    selectedAssignmentTypes={selectedAssignmentTypes}
                                                    setSelectedAssignmentTypes={(newTypes) => {
                                                        setSelectedAssignmentTypes(newTypes);
                                                        filterSorter.settings.selected_assignment_types = newTypes;
                                                    }}
                                                    isSelected={selectedWeek === index}
                                                    weekStartDate={weekStartDate}
                                                    cohortStartDate={cohortStartDate}
                                                    cohortCalendar={cohortCalendar}
                                                    weekNumber={index + 1}
                                                    downloadAssignments={onDownloadAssignments}
                                                    isStatsRow={true}
                                                    setShowAssignmentStatsId={(id, e) =>
                                                        pushOverlay({
                                                            position: {x: e.clientX, y: e.clientY},
                                                            component: <AssignmentStats
                                                                close={() => {
                                                                    popOverlay();
                                                                }}
                                                                filteredStudentsData={filteredStudentsData}
                                                                assignmentId={id}
                                                            />
                                                        })}
                                                    isViewWeek={viewWeeks.indexOf(weekStartDate) > -1}
                                                    setShowWeekStatsStartDate={(weekDate) => 
                                                        pushOverlay({
                                                            type: OVERLAY_TYPES.MODAL,
                                                            component: <CohortAssignmentStats
                                                                close={() => popOverlay()}
                                                                studentsData={studentsData}
                                                                weekStartDate={weekDate}
                                                                />,
                                                        })}
                                                />
                                        )
                                    })
                                }

                            </span>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </>
                }

                {/* display roster data */}
                {
                    sortedRoster.map( (student, index) => {

                        // calculate assignment counts
                        let on_time = 0;
                        let late = 0;
                        let unsubmitted = 0;

                        Object.keys(cohortCalendar).map( (weekStartDate, index) => {
                            let thisWeek = studentsData[student.id].calendar[weekStartDate];
                            selectedAssignmentTypes.forEach( type => {
                                on_time += thisWeek.on_time[type];
                                late += thisWeek.late[type];
                                unsubmitted += thisWeek.unsubmitted[type];
                            })
                        });
                        
                        return (
                            <>
                                <div className={`assignment_reviews__row assign_attend__row
                                                ${index % 2 ? '' : 'assignments_browser__student_row--highlight'}
                                                ${hoveredStudentId === student.id ? 'cohort_list__row--selected_student' : ''}
                                                ${checkStudentSelected(student.id) ? 'bg-secondary text-white' : ''}
                                                `
                                                } key={`assign_attend_row${student['id']}`}
                                                
                                                onMouseEnter={() => setHoveredStudentId(student.id)}
                                                onMouseLeave={() => clearHoveredStudent()}
                                                >
                                                        
                                    <span className='assign_attend__percent_col mr-2'>
                                        <PercentDisplay
                                            percent={parseFloat(
                                                coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                                                    studentsData[student.id]['core_assignments_to_date_percent']
                                                    :
                                                    studentsData[student.id]['core_assignments_overall_percent']
                                                )
                                            }
                                            highPercent={.9}
                                            bottomPercent={.59}
                                        />
                                        <div>
                                            {
                                                coreAssignmentsDisplay === CORE_ASSIGNMENTS_DISPLAY.TODATE ?
                                                <>
                                                    {studentsData[student.id]['core_assignments_to_date']}/{studentsData[student.id]['core_assignments_total_to_date']}
                                                </>
                                                :
                                                <>
                                                    {studentsData[student.id]['core_assignments_overall']}/{studentsData[student.id]['core_assignments_total_overall']}
                                                </>
                                            }
                                            
                                        </div>
                                    </span>

                                    {
                                        inStatsMode &&
                                            <span>
                                                <div className="assign_attend_week__counts mt-1">
                                                    <div 
                                                        className={`
                                                            assign_attend_week__count 
                                                            assign_attend_week__count--${on_time > 0 ? 'on_time' : 'none'}
                                                        `}
                                                        data-toggle="tooltip" title={`${on_time} assignment${on_time === 1 ? '':'s'} submitted on time.
                                                            Click for details.`}
                                                        onClick={() => displayStudentAssignmentStats(student)}
                                                    >
                                                        {on_time}
                                                    </div>
        
                                                    <div 
                                                        className={`
                                                            assign_attend_week__count 
                                                            assign_attend_week__count--${late > 0 ? 'late' : 'none'}
                                                        `}
                                                        data-toggle="tooltip" title={`${late} assignment${late === 1 ? '':'s'} submitted late.
                                                            Click for details.`}
                                                        onClick={() => displayStudentAssignmentStats(student)}
                                                        >
                                                        {late}
                                                    </div>
        
        
                                                    <span 
                                                        className={`
                                                            assign_attend_week__count 
                                                            assign_attend_week__count--${unsubmitted > 0 ? 'unsubmitted' : 'none'}
                                                        `}
                                                        data-toggle="tooltip" title={`${unsubmitted} assignment${unsubmitted === 1 ? '':'s'} are unsubmitted.
                                                            Click for details.`}
                                                        onClick={() => displayStudentAssignmentStats(student)}
                                                        >
                                                        {unsubmitted}
                                                    </span>
                                                </div>
                                            </span>
                                    }
                                    <span className='assign_attend_weeks'>
                                        {
                                            Object.keys(cohortCalendar).map( (weekStartDate, index) => {
                                                return (
                                                        <AssignAttendStudentWeek
                                                            student={student}
                                                            weekNumber={index + 1}
                                                            studentsData={studentsData}
                                                            calendarData={cohortCalendar[weekStartDate]}
                                                            isSelected={selectedWeek === index}
                                                            selectedAssignmentTypes={selectedAssignmentTypes}
                                                            setSelectedAssignmentTypes={(newTypes) => {
                                                                setSelectedAssignmentTypes(newTypes);
                                                                filterSorter.settings.selected_assignment_types = newTypes;
                                                            }}
                                                            view={view}
                                                            weekStartDate={weekStartDate}
                                                            cohortStartDate={cohortStartDate}
                                                            downloadAssignments={onDownloadAssignments}
                                                            setShowAssignmentStatsId={(id, e) =>
                                                                pushOverlay({
                                                                    position: {x: e.clientX, y: e.clientY},
                                                                    component: <AssignmentStats
                                                                        close={() => {
                                                                            popOverlay();
                                                                        }}
                                                                        filteredStudentsData={filteredStudentsData}
                                                                        assignmentId={id}
                                                                    />
                                                                })}
                                                            isViewWeek={viewWeeks.indexOf(weekStartDate) > -1}
                                                            toggleViewWeek={() => onToggleWeekView(weekStartDate)}
                                                        />
                                                )
                                            })
                                        }

                                    </span>
                                </div>
                                <div style={{clear: 'both'}}></div>
                            </>
                        )
                    })
                }
            </div>
            
            {/* Overlays  */}

            {
                showNoDownloaderAlert &&
                <div className="modal_overlay modal_overlay--30">
                    <div className='layer layer-3'>
                        <p>To download assignments, you need to do the following:</p>
                        {
                            assignmentDownloadService.status === DOWNLOADER_STATES.NOT_CHROME &&
                                <span>
                                    <a 
                                        href={`${'https://www.google.com/chrome/dr/download'}`} 
                                        target='_blank'>
                                        Install Chrome to use the Assignment Downloader
                                    </a>
                                </span>

                        }

                        {
                            assignmentDownloadService.status !== DOWNLOADER_STATES.INSTALLED &&
                                <span><a href={config.DOWNLOADER_LINK} target='_blank'>Install Assignment Downloader</a></span> 
                        }
                        <div className='text-center'>
                            <button className='btn btn-primary btn-sm mt-2' onClick={() => setShowNoDownloaderAlert(false)}>OK</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AssignmentAttendanceSheet;

export const ReviewTabsLegend = () => {

    let fakeStudent = {
        'week_0_substantive_interaction': true,
        'week_2_substantive_interaction': false,
        'week_99_substantive_interaction': true,
        'week_100_substantive_interaction': true,
        'substantive_interactions': {
            99: {
                action: 0,
            },
            100: {
                send_to_student: true,
            }
        }
    }

    return (
        <div className='row window_700'>
            <div className='col-6 submitted_assignments__review_demo flex-column'>
                <h6><u>Assignment Reviews</u></h6>
                <div className='d-flex '>
                    <ReviewTab
                        reviewStatus={ASSIGNMENT_REVIEW_STATUS.SUBMITTED}
                        assignmentType={ASSIGNMENT_TYPES.CORE}
                        demoMode={true}
                        />
                    -&nbsp;Submitted
                </div>
                <div className='d-flex mt-1'>
                    <ReviewTab
                        reviewStatus={ASSIGNMENT_REVIEW_STATUS.CHECKED}
                        assignmentType={ASSIGNMENT_TYPES.CORE}
                        demoMode={true}
                        review={{'notify_student': true}}
                        />
                    -&nbsp;Reviewed; sent to student
                </div>
                <div className='d-flex mt-1'>
                    <ReviewTab
                        reviewStatus={ASSIGNMENT_REVIEW_STATUS.CHECKED}
                        assignmentType={ASSIGNMENT_TYPES.CORE}
                        demoMode={true}
                        />
                    -&nbsp;Checked; not sent to student
                </div>
                <div className='d-flex mt-1'>
                    <ReviewTab
                        reviewStatus={ASSIGNMENT_REVIEW_STATUS.NEEDS_FOLLOW_UP}
                        assignmentType={ASSIGNMENT_TYPES.CORE}
                        demoMode={true}
                        />
                    -&nbsp;Needs Follow-Up
                </div>
                <div className='d-flex mt-1'>
                    <ReviewTab
                        reviewStatus={ASSIGNMENT_REVIEW_STATUS.NEEDS_REVIEW}
                        assignmentType={ASSIGNMENT_TYPES.CORE}
                        demoMode={true}
                        />
                    -&nbsp;Requires Review: ( LP3 )
                </div>
            </div>

            <div className='col-6 submitted_assignments__review_demo flex-column'>
                <h6><u>Substantive Interactions</u></h6>
                <div className='d-flex '>
                    <SubstantiveInteraction
                        student={{
                            ...fakeStudent,
                            'status_is_active': false,
                        }}
                        weekNumber={2}
                        />
                    &nbsp;-&nbsp;Inactive Student
                </div>

                <div className='d-flex mt-1'>
                    <SubstantiveInteraction
                        student={{
                            ...fakeStudent,
                            'status_is_active': true,
                        }}
                        weekNumber={100}
                        />
                    &nbsp;-&nbsp;Substantive Interaction Submitted
                </div>

                <div className='d-flex mt-1'>
                    <SubstantiveInteraction
                        student={{
                            ...fakeStudent,
                            'status_is_active': true,
                            current_week: 0,
                        }}
                        weekNumber={1}
                        />
                    &nbsp;-&nbsp;No interactions ( &gt;= current week )
                </div>

                <div className='d-flex mt-1'>
                    <SubstantiveInteraction
                        student={{
                            ...fakeStudent,
                            'status_is_active': true,
                            current_week: 99,
                        }}
                        weekNumber={1}
                        />
                    &nbsp;-&nbsp;No interactions ( prev. weeks )
                </div>

            </div>
        </div>
    )
}