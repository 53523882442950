import React, { useState, createContext, useEffect } from 'react';
import filterSorter from '../components/my_cohort/filter_sorter/MyCohortFilterSorter';
import { SHEETS } from '../helpers/Constants';

const MyCohortContext = createContext();

function MyCohortProvider({ children }) {

    const [ inStatsMode, setInStatsMode ] = useState(false);
    const [ sheetsData, setSheetsData ] = useState(null);
    const [ cohortId, setCohortId ] = useState(null); // used to load cohort from url params
    const [ sortedRoster, setSortedRoster ] = useState(null);
    const [ cohortCalendar, setCohortCalendar ] = useState(null);
    const [ cohortInfo, setCohortInfo ] = useState(null); // {stack_name, cohort_start_date, cohort_end_date}
    const [ studentsData, setStudentsData ] = useState(filterSorter.studentsData);
    const [ studentStatuses, seetStudentStatuses ] = useState([]);
    const [ selectedStudents, setSelectedStudents ] = useState([]);
    const [ hoveredStudentId, setHoveredStudentId ] = useState(null);
    const [ selectedSheet, setSelectedSheet ] = useState(SHEETS.ASSIGNMENTS);

    useEffect(() => {
        setStudentsData(filterSorter.studentsData);
    }, [filterSorter.studentsData])

    useEffect(() => { 
        if ( sheetsData ) seetStudentStatuses(sheetsData.student_statuses);
    }, [sheetsData])

    return (
        <MyCohortContext.Provider value={{ 
            inStatsMode, 
            setInStatsMode,
            sheetsData,
            setSheetsData,
            cohortId,
            setCohortId,
            sortedRoster,
            setSortedRoster,
            cohortCalendar,
            setCohortCalendar,
            cohortInfo,
            setCohortInfo,
            studentsData,
            studentStatuses,
            selectedStudents,
            setSelectedStudents,

            hoveredStudentId,
            setHoveredStudentId,

            selectedSheet,
            setSelectedSheet,
            }}>
            { children }
        </MyCohortContext.Provider>
    )
}

export { MyCohortContext as default, MyCohortProvider };