import TABService from './TABService';

class UserService extends TABService {

    model = 'auth';

    login = (email_address, password) => {
        return new Promise((resolve, reject) => {
            this._post(
                `${this.model}/login`,
                {
                    email_address,
                    password
                }
            )
            .then( resp => resolve(resp))
            .catch( err => reject(err))
        })
    } 

    getUser = () => {
        return this._get(
            `${this.model}/current-user`
        )
    }
}

export default new UserService();