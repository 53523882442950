import TABService from './TABService';
import Helpers from '../helpers/Helpers';

class InstructorService extends TABService {

    model = 'instructor';

    get_all = () => 
        new Promise( (resolve, reject) => {
            this._get(
                `${this.model}`
            )
            .then( instructors => {
                if ( Helpers.getPrivacyMode() ) {
                    instructors = instructors.map( instructor => ({
                        ...instructor,
                        last_name: instructor.last_name.substring(0,1) + ".",
                        email_address: "***@***.com"
                    }))
                }
                resolve(instructors);
            } )
            .catch( err => reject(err) )
        })

    get_roster = ( instructor_id = null, cohort_start_date = null, v = null ) => 
        new Promise( ( resolve, reject ) => {
            this._post(
                `${this.model}/get-roster`,
                { instructor_id, cohort_start_date, v }
            )
            .then( roster => { 
                if ( Helpers.getPrivacyMode() ) {
                    roster.roster = roster.roster.map( (student, index) => ({
                        ...student,
                        last_name: student.last_name.substring(0,1) + ".",
                        email_address: `student_${index}@email.com`
                    }))
                }

                resolve(roster);
            })
            .catch( err => reject(err) )
        })

    get_sheets_data = ( instructor_id = null, cohort_start_date = null, core_only = true ) =>
        this._post(
            `${this.model}/get-sheets-data`,
            { instructor_id, cohort_start_date, core_only }
        )

    get_assignment_reviews = ( instructor_id = null, cohort_start_date = null ) => 
        this._post(
            `${this.model}/get-assignment-reviews`,
            { instructor_id, cohort_start_date }
        )

    get_sem_data = ( instructor_id = null, cohort_start_date = null ) =>
        this._post(
            `${this.model}/get-sem-data`,
            { instructor_id, cohort_start_date }
        )

    get_assignment_attendance_data = (instructor_id = null, cohort_start_date = null) => 
        this._post(
            `${this.model}/get-assignment-attendance-data`,
            {instructor_id, cohort_start_date}
        )
}
export const instructorService = new InstructorService();
export default instructorService;